import {
  BlockElementStyle,
  ElementType,
  LayoutElement as LayoutElementType,
} from '../types/models/Elements';

const flattenBlockIntoElements = (
  elements: LayoutElementType[],
  totalWidth: number, // in pixels
  totalHeight: number, // in pixels
  isRepresentation: boolean = false,
): LayoutElementType[] => {
  const elementsCopied: LayoutElementType[] = JSON.parse(
    JSON.stringify(elements),
  );

  return elementsCopied.reduce((acc, element) => {
    if (element.type !== ElementType.BLOCK) {
      return [...acc, element];
    }

    const { children, style } = element;

    const blockStyle = style as BlockElementStyle;

    if (!children || children.length === 0) {
      return acc;
    }

    const gap = isRepresentation ? 2 : blockStyle.gap;

    const gapInPercentage =
      gap / (blockStyle.direction === 'row' ? totalWidth : totalHeight);

    const spaceWithoutGapPercentage =
      blockStyle.direction === 'row'
        ? element.width - gapInPercentage * (children.length - 1)
        : element.height - gapInPercentage * (children.length - 1);

    const childrenWithRealValues = children.reduce(
      (childrenAcc, child) => {
        if (blockStyle.direction === 'row') {
          child.width = child.width * spaceWithoutGapPercentage;
          child.left = childrenAcc.spaceTaken;
          child.height = child.height * element.height;
          child.top = child.top * element.height + element.top;
        } else {
          child.height = child.height * spaceWithoutGapPercentage;
          child.top = childrenAcc.spaceTaken;
          child.left = child.left * element.width + element.left;
          child.width = child.width * element.width;
        }

        return {
          spaceTaken:
            childrenAcc.spaceTaken +
            (blockStyle.direction === 'row' ? child.width : child.height) +
            gapInPercentage,
          children: [...childrenAcc.children, child],
        };
      },
      {
        spaceTaken: blockStyle.direction === 'row' ? element.left : element.top,
        children: [] as LayoutElementType[],
      },
    ).children;

    return [
      ...acc,
      ...flattenBlockIntoElements(
        childrenWithRealValues,
        totalWidth,
        totalHeight,
        isRepresentation,
      ),
    ];
  }, [] as LayoutElementType[]);
};

export default flattenBlockIntoElements;
