import { useDispatch, useSelector } from 'react-redux';
import { RootState, activatePopup } from '../constants/initialStore';
import { LoginState, useAuth } from './useAuth';
import { useEffect, useRef } from 'react';
import { saveAlbum } from '../api/album';
import { Album, AlbumToSend } from '../types/models/Album';
import { PhotoElementStyle, TextElementStyle } from '../types/models/Elements';
import useCreationType from './useCreationType';

export const useAutoSaveAlbum = () => {
  const dispatch = useDispatch();
  const { userInfo, asklogin, isReady } = useAuth();
  const album = useSelector((state: RootState) => state.creation.present.album);

  const type = useCreationType();

  const albumElements = useSelector(
    (state: RootState) => state.creation.present.elements.value,
  );
  const saveTimeoutRef = useRef<any | null>(null);

  const prevAlbumRef = useRef(album);

  const save = async () => {
    if (type !== 'album') {
      return;
    }
    // if (userInfo?.state !== LoginState.LOGGED_IN) {
    //   // await asklogin(false);
    //   return;
    // }
    const {
      albumPages: _,
      albumBackCover: bc,
      albumCover: c,
      ...restAlbum
    } = album as Album;
    const payload: AlbumToSend = {
      ...(restAlbum as Album),
      albumPages: album!.albumPages
        .concat([album!.albumCover, album!.albumBackCover])
        .map((page) => ({
          ...page,
          pageElements: albumElements
            .filter((el) => el.pageID === page.id)
            .map(({ locked: locked, style: style, ...el }) => ({
              ...el,
              style: JSON.stringify(style) as unknown as
                | TextElementStyle
                | PhotoElementStyle,
            })),
        })),
    };
    await saveAlbum(payload);
    dispatch(activatePopup());
    saveTimeoutRef.current = null;
  };

  useEffect(() => {
    const prevAlbum = prevAlbumRef.current;
    if (!prevAlbum) return;

    if (!isReady) return;
    if (userInfo?.state === LoginState.LOGGED_IN && userInfo.isAdmin) return;
    if (saveTimeoutRef.current !== null) {
      clearTimeout(saveTimeoutRef.current);
    }

    saveTimeoutRef.current = setTimeout(
      save,
      userInfo?.state === LoginState.LOGGED_IN ? 5000 : 5000,
    );
    prevAlbumRef.current = album;
  }, [album, albumElements]);

  return save;
};
