import { styled } from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, changePreviewRatio } from '../../../constants/initialStore';
import { otherFormats } from '../../../constants/otherFormats';
import { ConvertMMToPixel } from '../../../utils/convertCMToPixel';
import colors from '../../../constants/colors';
import { ElementType, MotifElementStyle } from '../../../types/models/Elements';
import ImmutableText from '../../cardElements/textElements/ImmutableText';
import { ImmutableMotif } from '../../cardElements/motifElements/ImmutableMotif';
import ImmutablePhoto from '../../cardElements/photoElements/ImmutablePhoto';

const OtherPreview: React.FC = () => {
  const [maxHeight, setMaxHeight] = useState<number>(99999);
  const [maxWidth, setMaxWidth] = useState<number>(99999);
  const [displayHeight, setDisplayHeight] = useState<number>(0);
  const [displayWidth, setDisplayWidth] = useState<number>(0);
  const maxZoneRef = useRef<HTMLDivElement>(null);

  const creation = useSelector(
    (state: RootState) => state.creation.present.other,
  );
  const menu = useSelector((state: RootState) => state.menu.value);

  const elements = useSelector(
    (state: RootState) => state.creation.present.elements.value,
  );
  const ratio = useSelector((state: RootState) => state.previewRatio.value);

  const dispatch = useDispatch();

  useEffect(() => {
    const updateMaxZoneSize = () => {
      setMaxHeight(maxZoneRef.current?.clientHeight ?? 0);
      setMaxWidth(maxZoneRef.current?.clientWidth ?? 0);
    };

    window.addEventListener('resize', updateMaxZoneSize);
    updateMaxZoneSize();
    return () => window.removeEventListener('resize', updateMaxZoneSize);
  }, [maxZoneRef, menu]);

  useEffect(() => {
    if (!creation) return;
    const format = otherFormats[creation?.format];
    const ratioHeight = maxHeight / ConvertMMToPixel(format.height);
    const ratioWidth = maxWidth / ConvertMMToPixel(format.width);

    if (ratioWidth > ratioHeight && 1 > ratioHeight) {
      dispatch(changePreviewRatio(ratioHeight));
      setDisplayHeight(
        Math.floor(ratioHeight * ConvertMMToPixel(format.height)),
      );
      setDisplayWidth(Math.floor(ratioHeight * ConvertMMToPixel(format.width)));
    } else if (1 > ratioWidth) {
      dispatch(changePreviewRatio(ratioWidth));
      setDisplayHeight(
        Math.floor(ratioWidth * ConvertMMToPixel(format.height)),
      );
      setDisplayWidth(Math.floor(ratioWidth * ConvertMMToPixel(format.width)));
    } else {
      dispatch(changePreviewRatio(1));
      setDisplayHeight(Math.floor(1 * ConvertMMToPixel(format.height)));
      setDisplayWidth(Math.floor(1 * ConvertMMToPixel(format.width)));
    }
  }, [maxHeight, maxWidth, creation]);

  return (
    <MainContainer>
      <Title>{creation?.name}</Title>
      <MaxZone ref={maxZoneRef}>
        <Container
          $height={displayHeight}
          $width={displayWidth}
          $ratio={ratio}
          $bgcolor={creation?.backgroundColor}
          $bgtheme={creation?.backgroundTheme}
        >
          <Watermark src={'/watermark.png'} />
          {elements.map((element) => {
            switch (element.type) {
              case ElementType.TEXT:
                return (
                  <ImmutableText
                    key={element.id}
                    element={element}
                    ratio={ratio}
                  />
                );
              case ElementType.PHOTO:
                return (
                  <ImmutablePhoto
                    key={element.id}
                    src={element.content}
                    element={element}
                    elementstyle={
                      element.style && 'opacity' in element.style
                        ? element.style
                        : null
                    }
                    ratio={ratio}
                  />
                );
              case ElementType.MOTIF:
                return (
                  <ImmutableMotif
                    src={element.content}
                    key={element.id}
                    $element={element}
                    $elementstyle={element.style as MotifElementStyle}
                    $ratio={ratio}
                    $index={element.zIndex}
                  />
                );
            }
          })}
        </Container>
      </MaxZone>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  background-color: ${colors.gray100};
  gap: 40px;
`;

const Title = styled.div`
  font-family: Playfair Display;
  font-size: 24px;
  line-height: 24px;
  color: ${colors.black};
`;

const MaxZone = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div<{
  $width: number;
  $height: number;
  $ratio: number;
  $bgcolor?: string;
  $bgtheme?: string;
}>`
  overflow: hidden;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  background-color: ${(props) =>
    props.$bgcolor && props.$bgcolor !== 'null'
      ? props.$bgcolor
      : colors.gray300};
  background-image: ${(props) =>
    props.$bgtheme && props.$bgtheme !== 'null'
      ? 'url(' + props.$bgtheme + ')'
      : 'none'};
  background-size: cover;

  position: relative;
  transform: translate(0, 0);

  box-sizing: border-box;
`;

const Watermark = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 1000;
`;

export default OtherPreview;
