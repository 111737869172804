import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

type PortalProps = {
  children: ReactNode;
  domNode?: HTMLElement;
  key?: string;
};

const Portal = ({ children, domNode = document.body, key }: PortalProps) =>
  createPortal(children, domNode, key);

export default Portal;
