import { ReactSVG } from 'react-svg';
import { styled } from 'styled-components';
import colors from '../../constants/colors';

interface IconButtonProps {
    title: string;
    disabled?: boolean;
    active?: boolean;
    onClick?: () => void;
}

const ButtonText: React.FC<IconButtonProps> = ({
                                                   title,
                                                   disabled = false,
                                                   active = false,
                                                   onClick,
                                               }) => {
    const handleClick = () => {
        if (onClick && !disabled) {
            onClick();
        }
    };

    return (
        <Container disabled={disabled} onClick={handleClick}>
            <Icon disabled={disabled} $active={active}>
                <Title>{title}</Title>
            </Icon>
        </Container>
    );
};

const Container = styled.button<{ disabled: boolean }>`
  all: unset;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;

  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

const Icon = styled.div<{ disabled: boolean; $active: boolean }>`
  width: 38px;
  height: 38px;
  display: flex;

  justify-content: center;
  align-items: center;

  border: solid 1px ${colors.gray300};
  border-radius: 8px;

  background-color: ${(props) =>
    props.disabled
        ? colors.gray200
        : props.$active
            ? colors.black
            : colors.white};

  box-sizing: border-box;
`;

const Title = styled.div`
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  color: ${colors.black};
`;

export default ButtonText;
