import { styled } from 'styled-components';
import { availableColors } from '../../constants/availableColors';
import ColorItem from './ColorItem';
import colors from '../../constants/colors';
import { useEffect, useRef } from 'react';
import useLittleScreen from '../../hook/useLittleScreen';
import { createPortal } from 'react-dom';

interface ModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  update: ({ color, name }: { color: string; name: string }) => void;
  chosenColor: { color: string; name: string };
}

const ColorPicker: React.FC<ModalProps> = ({
  setVisible,
  update,
  chosenColor,
}) => {
  const littleScreen = useLittleScreen();
  const colorPickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target as Node)
      ) {
        setVisible(false);
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return createPortal(
    <Container ref={colorPickerRef} $isSmall={littleScreen} id="colorPicker">
      {availableColors.map((color, index) => (
        <ColorItem
          key={index}
          color={color}
          update={update}
          active={color.color === chosenColor.color}
        />
      ))}
    </Container>,
    document.body,
  );
};

const Container = styled.div<{ $isSmall: boolean }>`
  width: 280px;
  height: 320px;

  padding: 0 16px 16px;

  top: ${(props) => (props.$isSmall ? '16px' : '41px')};
  left: ${(props) => (props.$isSmall ? 'calc(60% - 280px + 52px)' : 'auto')};

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  gap: 4px;

  position: absolute;

  flex-wrap: wrap;

  background-color: ${colors.white};
  box-sizing: border-box;
  border: solid 1px ${colors.gray200};
  border-radius: 8px;

  z-index: 300;

  overflow: auto;

  & > div {
    margin-top: 12px;
  }
`;

export default ColorPicker;
