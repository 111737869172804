import { landscapeLayouts1Photo } from './landscape/1photo';
import { landscapeLayouts2Photo } from './landscape/2photos';
import { landscapeLayouts3Photo } from './landscape/3photos';
import { landscapeLayouts4Photo } from './landscape/4photos';
import { landscapeLayouts5MorePhoto } from './landscape/5morePhotos';
import { landscapeLayoutsText } from './landscape/text';
import { mugLayouts1Photo } from './mug/1photo';
import { mugLayouts2Photos } from './mug/2photos';
import { mugLayouts3Photos } from './mug/3photos';
import { mugLayouts4Photos } from './mug/4photos';
import { mugLayouts5MorePhotos } from './mug/5morePhotos';
import { mugLayoutsText } from './mug/text';
import { panoramicLayouts1Photo } from './panoramic/1photo';
import { panoramicLayouts2Photos } from './panoramic/2photos';
import { panoramicLayouts3Photos } from './panoramic/3photos';
import { panoramicLayouts4Photos } from './panoramic/4photos';
import { panoramicLayouts5MorePhotos } from './panoramic/5morePhotos';
import { panoramicLayoutsText } from './panoramic/text';
import { panoramicVLayouts1Photo } from './panoramicV/1photo';
import { panoramicVLayouts2Photos } from './panoramicV/2photos';
import { panoramicVLayouts3Photos } from './panoramicV/3photos';
import { panoramicVLayouts4Photos } from './panoramicV/4photos';
import { portraitLayouts1Photo } from './portrait/1photo';
import { portraitLayouts2Photo } from './portrait/2photos';
import { portraitLayouts3Photo } from './portrait/3photos';
import { portraitLayouts4Photo } from './portrait/4photos';
import { portraitLayouts5MorePhoto } from './portrait/5morePhotos';
import { portraitLayoutsText } from './portrait/text';
import { squareLayouts1Photo } from './square/1photo';
import { squareLayouts2Photo } from './square/2photos';
import { squareLayouts3Photo } from './square/3photos';
import { squareLayouts4Photo } from './square/4photos';
import { squareLayouts5MorePhoto } from './square/5morePhotos';

export const portraitLayouts = {
  texte: portraitLayoutsText,
  1: portraitLayouts1Photo,
  2: portraitLayouts2Photo,
  3: portraitLayouts3Photo,
  4: portraitLayouts4Photo,
  5: portraitLayouts5MorePhoto,
};

export const landscapeLayouts = {
  texte: landscapeLayoutsText,
  1: landscapeLayouts1Photo,
  2: landscapeLayouts2Photo,
  3: landscapeLayouts3Photo,
  4: landscapeLayouts4Photo,
  5: landscapeLayouts5MorePhoto,
};

export const squareLayouts = {
  1: squareLayouts1Photo,
  2: squareLayouts2Photo,
  3: squareLayouts3Photo,
  4: squareLayouts4Photo,
  5: squareLayouts5MorePhoto,
};

export const panoramicLayouts = {
  texte: panoramicLayoutsText,
  1: panoramicLayouts1Photo,
  2: panoramicLayouts2Photos,
  3: panoramicLayouts3Photos,
  4: panoramicLayouts4Photos,
  5: panoramicLayouts5MorePhotos,
};

export const panoramicVLayouts = {
  1: panoramicVLayouts1Photo,
  2: panoramicVLayouts2Photos,
  3: panoramicVLayouts3Photos,
  4: panoramicVLayouts4Photos,
};

export const mugLayouts = {
  texte: mugLayoutsText,
  1: mugLayouts1Photo,
  2: mugLayouts2Photos,
  3: mugLayouts3Photos,
  4: mugLayouts4Photos,
  5: mugLayouts5MorePhotos,
};

export const layouts = {
  portrait: portraitLayouts,
  landscape: landscapeLayouts,
  square: squareLayouts,
  panoramic: panoramicLayouts,
  panoramicV: panoramicVLayouts,
  mug: mugLayouts,
};
