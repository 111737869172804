import NumberChooser from './NumberChooser';
import ButtonText from './ButtonText';
import { styled } from 'styled-components';
import React from 'react';

interface ManageImageSizeProps {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  maxSize: number;
  translateKey: string;
}
const ManageElementSize = ({
  translateKey,
  value,
  setValue,
  maxSize,
}: ManageImageSizeProps) => {
  return (
    <>
      <RowTextButton>
        <NumberChooser
          title={translateKey}
          number={value}
          setNumber={setValue}
          step={1}
        />
      </RowTextButton>
      <RowTextButton>
        <ButtonText
          title={'100%'}
          disabled={focus === null}
          onClick={() => {
            setValue(maxSize);
          }}
        />
        <ButtonText
          title={'75%'}
          disabled={focus === null}
          onClick={() => setValue(maxSize * 0.75)}
        />
        <ButtonText
          title={'50%'}
          disabled={focus === null}
          onClick={() => setValue(maxSize * 0.5)}
        />
        <ButtonText
          title={'25%'}
          disabled={focus === null}
          onClick={() => setValue(maxSize * 0.25)}
        />
      </RowTextButton>
    </>
  );
};

export default ManageElementSize;

const RowTextButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: flex-end;
`;
