import { styled } from 'styled-components';
import colors from '../../constants/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  changeAlbum,
  changeCalendar,
  changeCard,
  changeOther,
} from '../../constants/initialStore';
import { Calendar } from '../../types/models/Calendar';
import useCreationType from '../../hook/useCreationType';
import { Album } from '../../types/models/Album';
import useLittleScreen from '../../hook/useLittleScreen';
import styleConstants from '../../constants/styleConstants';

interface ThemeItemProps {
  theme: {
    name: string;
    path: string;
    thumbnailPath: string;
    greyBG: boolean;
  };
  index: number;
}

const ThemeItem: React.FC<ThemeItemProps> = ({ theme, index }) => {
  const dispatch = useDispatch();
  const focus = useSelector((state: RootState) => state.focus.value);

  const littleScreen = useLittleScreen();

  const typeCreation = useCreationType();

  const { card, currentFace } = useSelector((state: RootState) => {
    return {
      card: state.creation.present.card.value,
      currentFace: state.face.value,
    };
  });

  const handleAddTheme = () => {
    if (card.facesSizes[currentFace].bgTheme === theme.path) {
      dispatch(
        changeCard({
          ...card,
          facesSizes: card.facesSizes.map((face, index) => {
            if (index === currentFace) {
              return { ...face, bgTheme: undefined };
            } else {
              return face;
            }
          }),
        }),
      );
    } else {
      dispatch(
        changeCard({
          ...card,
          facesSizes: card.facesSizes.map((face, index) => {
            if (index === currentFace) {
              return { ...face, bgTheme: theme.path };
            } else {
              return face;
            }
          }),
        }),
      );
    }

    if (typeCreation === 'calendrier') {
      dispatch(
        changeCalendar(({ calendarMonths, ...prev }: any) => ({
          ...prev,
          calendarMonths: (calendarMonths as Calendar['calendarMonths']).map(
            (month) =>
              month.id === focus
                ? {
                    ...month,
                    backgroundTheme: theme.path,
                  }
                : month,
          ),
        })),
      );
    } else if (typeCreation === 'album') {
      dispatch(
        changeAlbum(
          ({ albumPages, albumCover, albumBackCover, ...prev }: any) => ({
            ...prev,
            albumCover:
              albumCover.id === focus
                ? { ...albumCover, backgroundTheme: theme.path }
                : albumCover,
            albumBackCover:
              albumBackCover.id === focus
                ? { ...albumBackCover, backgroundTheme: theme.path }
                : albumBackCover,
            albumPages: (albumPages as Album['albumPages']).map((page) =>
              page.id === focus
                ? { ...page, backgroundTheme: theme.path }
                : page,
            ),
          }),
        ),
      );
    } else if (typeCreation === 'autre') {
      dispatch(
        changeOther(({ ...prev }: any) => ({
          ...prev,
          backgroundTheme: theme.path,
        })),
      );
    }
  };

  return (
    <BG $greyBG={theme.greyBG}>
      <Element key={index} src={theme.thumbnailPath} onClick={handleAddTheme} />
    </BG>
  );
};

const BG = styled.div<{ $greyBG: boolean }>`
  width: 60px;
  height: 60px;
  border-radius: 8px;

  box-sizing: border-box;
  background-color: ${(props) =>
    props.$greyBG ? colors.gray400 : colors.white};

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 36px;
    height: 36px;
  }
`;

const Element = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 8px;

  cursor: pointer;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 36px;
    height: 36px;
  }
`;

export default ThemeItem;
