import i18next from '../i18n';

export type Font = {
  name: string;
  regular: string;
  italic: string | null | boolean;
  bold: string | null | boolean;
  boldItalic?: string;
  ascentOverride?: string;
  descentOverride?: string;
};

export const modernFonts: Font[] = [
  {
    name: 'Cabin',
    regular: '/fonts/customization/Cabin_Condensed-Regular.ttf',
    italic: '/fonts/customization/Cabin_Condensed-Italic.ttf',
    bold: '/fonts/customization/Cabin-Bold.ttf',
  },
  {
    name: 'Josefin Sans',
    regular: '/fonts/customization/JosefinSans-Regular.ttf',
    italic: '/fonts/customization/JosefinSans-Italic.ttf',
    bold: '/fonts/customization/JosefinSans-SemiBold.ttf',
  },
  {
    name: 'Josefin Sans Fine',
    regular: '/fonts/customization/JosefinSans-ExtraLight.ttf',
    italic: '/fonts/customization/JosefinSans-ExtraLightItalic.ttf',
    bold: '/fonts/customization/JosefinSans-Light.ttf',
  },
  {
    name: 'Questrial',
    regular: '/fonts/customization/Questrial-Regular.ttf',
    italic: null,
    bold: null,
  },
  {
    name: 'Quicksand',
    regular: '/fonts/customization/Quicksand-Regular.ttf',
    italic: null,
    bold: '/fonts/customization/Quicksand-SemiBold.ttf',
  },
  {
    name: 'Oswald Fine',
    regular: '/fonts/customization/Oswald-Light.ttf',
    italic: null,
    bold: '/fonts/customization/Oswald-Medium.ttf',
  },
  {
    name: 'Poppins',
    regular: '/fonts/customization/Poppins-Regular.ttf',
    italic: '/fonts/customization/Poppins-Italic.ttf',
    bold: '/fonts/customization/Poppins-SemiBold.ttf',
  },
  {
    name: 'Poppins extra',
    regular: '/fonts/customization/Poppins-ExtraBold.ttf',
    italic: '/fonts/customization/Poppins-ExtraBoldItalic.ttf',
    bold: '/fonts/customization/Poppins-Black.ttf',
  },
  {
    name: 'Sansita',
    regular: '/fonts/customization/Sansita-Regular.ttf',
    italic: '/fonts/customization/Sansita-Italic.ttf',
    bold: '/fonts/customization/Sansita-Bold.ttf',
  },
  {
    name: 'Sen',
    regular: '/fonts/customization/Sen-Regular.ttf',
    italic: null,
    bold: '/fonts/customization/Sen-Bold.ttf',
  },
];

export const classicFonts: Font[] = [
  {
    name: 'Abril Fatface',
    regular: '/fonts/customization/AbrilFatface-Regular.ttf',
    italic: null,
    bold: null,
  },
  {
    name: 'Bodoni',
    regular: '/fonts/customization/BodoniModa_72pt-Regular.ttf',
    italic: '/fonts/customization/BodoniModa_72pt-Italic.ttf',
    bold: '/fonts/customization/BodoniModa_18pt-SemiBold.ttf',
  },
  {
    name: 'Bodoni extra',
    regular: '/fonts/customization/BodoniModa_18pt-Bold.ttf',
    italic: '/fonts/customization/BodoniModa_18pt-BoldItalic.ttf',
    bold: '/fonts/customization/BodoniModa_18pt-Black.ttf',
    boldItalic: '/fonts/customization/BodoniModa_18pt-BlackItalic.ttf',
  },
  {
    name: 'Brawler',
    regular: '/fonts/customization/Brawler-Regular.ttf',
    italic: null,
    bold: '/fonts/customization/Brawler-Bold.ttf',
  },
  {
    name: 'Calistoga',
    regular: '/fonts/customization/Calistoga-Regular.ttf',
    italic: null,
    bold: null,
  },
  {
    name: 'Libre Baskerville',
    regular: '/fonts/customization/LibreBaskerville-Regular.ttf',
    italic: '/fonts/customization/LibreBaskerville-Italic.ttf',
    bold: '/fonts/customization/LibreBaskerville-Bold.ttf',
  },
  {
    name: 'Playfair',
    regular: '/fonts/customization/PlayfairDisplay-Regular.ttf',
    italic: '/fonts/customization/PlayfairDisplay-Italic.ttf',
    bold: '/fonts/customization/PlayfairDisplay-SemiBold.ttf',
  },
  {
    name: 'Playfair extra',
    regular: '/fonts/customization/PlayfairDisplay-Bold.ttf',
    italic: '/fonts/customization/PlayfairDisplay-BoldItalic.ttf',
    bold: '/fonts/customization/PlayfairDisplay-Black.ttf',
    boldItalic: '/fonts/customization/PlayfairDisplay-BlackItalic.ttf',
  },
  {
    name: 'Yeseva One',
    regular: '/fonts/customization/YesevaOne-Regular.ttf',
    italic: null,
    bold: null,
  },
];

export const calligraphicFonts: Font[] = [
  {
    name: 'Alex Brush',
    regular: '/fonts/customization/AlexBrush-Regular.ttf',
    italic: null,
    bold: false,
  },
  {
    name: 'Cookie',
    regular: '/fonts/customization/Cookie-Regular.ttf',
    italic: false,
    bold: null,
  },
  {
    name: 'Mea Culpa',
    regular: '/fonts/customization/MeaCulpa-Regular.ttf',
    italic: null,
    bold: null,
  },
  {
    name: 'Memory of 2018',
    regular: '/fonts/customization/Memory of 2018.ttf',
    italic: null,
    bold: null,
  },
  {
    name: 'MonteCarlo',
    regular: '/fonts/customization/MonteCarlo-Regular.ttf',
    italic: null,
    bold: null,
  },
  {
    name: 'Ms Madi',
    regular: '/fonts/customization/MsMadi-Regular.ttf',
    italic: null,
    bold: null,
  },
  {
    name: 'Parisienne',
    regular: '/fonts/customization/Parisienne-Regular.ttf',
    italic: null,
    bold: null,
  },
  {
    name: 'Pinyon Script',
    regular: '/fonts/customization/PinyonScript-Regular.ttf',
    italic: false,
    bold: null,
  },
  {
    name: 'Rouge Script',
    regular: '/fonts/customization/RougeScript-Regular.ttf',
    italic: null,
    bold: false,
  },
  {
    name: 'WindSong',
    regular: '/fonts/customization/WindSong-Regular.ttf',
    italic: false,
    bold: '/fonts/customization/WindSong-Medium.ttf',
  },
];

const handwrittenFonts: Font[] = [
  {
    name: 'Borel',
    regular: '/fonts/customization/Borel-Regular.ttf',
    italic: null,
    bold: null,
    ascentOverride: '150%',
    descentOverride: '50%',
  },
  {
    name: 'Daniel',
    regular: '/fonts/customization/daniel.ttf',
    italic: null,
    bold: '/fonts/customization/danielbd.ttf',
    ascentOverride: '140%',
    descentOverride: '60%',
  },
  {
    name: 'Mathilde',
    regular: '/fonts/customization/mathilde.otf',
    italic: false,
    bold: false,
  },
  {
    name: 'Patrick Hand',
    regular: '/fonts/customization/PatrickHand-Regular.ttf',
    italic: null,
    bold: null,
  },
];

export const fantasyFonts: Font[] = [
  {
    name: 'Advent Pro',
    regular: '/fonts/customization/AdventPro-Regular.ttf',
    italic: '/fonts/customization/AdventPro-Italic.ttf',
    bold: '/fonts/customization/AdventPro-Bold.ttf',
  },
  {
    name: 'Cabin Sketch',
    regular: '/fonts/customization/CabinSketch-Regular.ttf',
    italic: null,
    bold: '/fonts/customization/CabinSketch-Bold.ttf',
  },
  {
    name: 'Chewy',
    regular: '/fonts/customization/Chewy-Regular.ttf',
    italic: null,
    bold: null,
  },
  {
    name: 'Fredericka The Great',
    regular: '/fonts/customization/FrederickatheGreat-Regular.ttf',
    italic: null,
    bold: false,
  },
  {
    name: 'Limelight',
    regular: '/fonts/customization/Limelight-Regular.ttf',
    italic: null,
    bold: null,
  },
  {
    name: 'Lobster',
    regular: '/fonts/customization/Lobster-Regular.ttf',
    italic: false,
    bold: false,
  },
  {
    name: 'Rampart One',
    regular: '/fonts/customization/RampartOne-Regular.ttf',
    italic: null,
    bold: false,
  },
  {
    name: 'Grandstander',
    regular: '/fonts/customization/Grandstander-Regular.ttf',
    italic: '/fonts/customization/Grandstander-Italic.ttf',
    bold: '/fonts/customization/Grandstander-Bold.ttf',
  },
  {
    name: 'DM Mono',
    regular: '/fonts/customization/DMMono-Regular.ttf',
    italic: '/fonts/customization/DMMono-Italic.ttf',
    bold: '/fonts/customization/DMMono-Medium.ttf',
  },
  {
    name: 'Special elite',
    regular: '/fonts/customization/SpecialElite-Regular.ttf',
    italic: null,
    bold: null,
  },
];

export const availableFonts: Font[] = [
  ...modernFonts,
  ...classicFonts,
  ...calligraphicFonts,
  ...handwrittenFonts,
  ...fantasyFonts,
];

export const fontsGroups = [
  {
    label: i18next.t('text.modern', { ns: ['common'] }),
    options: modernFonts.map((font) => ({
      value: `'${font.name}'`,
      label: font.name,
    })),
  },
  {
    label: i18next.t('text.classic', { ns: ['common'] }),
    options: classicFonts.map((font) => ({
      value: `'${font.name}'`,
      label: font.name,
    })),
  },
  {
    label: i18next.t('text.calligraphy', { ns: ['common'] }),
    options: calligraphicFonts.map((font) => ({
      value: `'${font.name}'`,
      label: font.name,
    })),
  },
  {
    label: i18next.t('text.handwritten', { ns: ['common'] }),
    options: handwrittenFonts.map((font) => ({
      value: `'${font.name}'`,
      label: font.name,
    })),
  },
  {
    label: i18next.t('text.fancy', { ns: ['common'] }),
    options: fantasyFonts.map((font) => ({
      value: `'${font.name}'`,
      label: font.name,
    })),
  },
];
