const mapFormatToPriceToAdd: Record<string, number> = {
  SOUCAR30: 0.9,
  SOUCAR20: 0.7,
  SOUCAR14: 0.5,
  SOUA4PAY: 0.8,
  SOUA5PAY: 0.75,
  SOUA4POR: 0.8,
  SPICAR20: 0.7,
  RIGCAR30: 0.9,
  RIGA4PAY: 0.8,
  RIGCAR21: 0.7,
  RIGA4POR: 0.8,
  RIGA5PAY: 0.75,
  LAYFLATA4: 2.4,
  LAYFLAT30: 3,
  CARNSOU10: 0,
  CARNSOU15: 0,
  CARNSPI10: 0,
  CARNSPI15: 0,
};

export const getPriceToAdd = (format: string) => {
  return mapFormatToPriceToAdd[format];
};
