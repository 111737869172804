import { styled } from 'styled-components';
import colors from '../../constants/colors';

interface SwitchProps {
  isChecked: boolean;
  setIsChecked: () => void;
  label: string;
}

const Switch: React.FC<SwitchProps> = ({ isChecked, setIsChecked, label }) => {
  return (
    <Container>
      <SwitchContainer $active={isChecked} onClick={setIsChecked}>
        <Button $active={isChecked} />
      </SwitchContainer>
      {label}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  justify-content: center;
  align-items: center;

  font-family: 'DM Sans';
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: ${colors.black};
`;

const SwitchContainer = styled.div<{ $active: boolean }>`
  width: 44px;
  height: 24px;

  position: relative;

  background-color: ${(props) =>
    props.$active ? colors.dark_green : colors.gray100};
  border-radius: 12px;

  cursor: pointer;
`;

const Button = styled.div<{ $active: boolean }>`
  width: 20px;
  height: 20px;

  position: absolute;
  top: 2px;
  left: ${(props) => (props.$active ? 'auto' : '2px')};
  right: ${(props) => (props.$active ? '2px' : 'auto')};

  background-color: ${colors.white};
  border-radius: 10px;

  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a;
`;

export default Switch;
