import { styled } from 'styled-components';
import colors from '../../constants/colors';
import Switch from '../general/Switch';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  closeMenu,
  cutZoneToggle,
  gridToggle,
  resetFocus,
  zoomTo,
} from '../../constants/initialStore';
import UndoButton from '../functionnalities/UndoButton';
import RedoButton from '../functionnalities/RedoButton';
import { useTranslation } from 'react-i18next';
import useLittleScreen from '../../hook/useLittleScreen';
import styleConstants from '../../constants/styleConstants';
import { MouseEvent } from 'react';
import useCreationType from '../../hook/useCreationType';

const BottomBar: React.FC = () => {
  const displayGrid = useSelector((state: RootState) => state.grid.value);
  const displayCutZone = useSelector((state: RootState) => state.cutZone.value);
  const zoom = useSelector((state: RootState) => state.zoom.value);
  const album = useSelector((state: RootState) => state.creation.present.album);

  const { t } = useTranslation(['common']);

  const dispatch = useDispatch();

  const littleScreen = useLittleScreen();

  const handleClick = () => {
    // dispatch(closeMenu());
    dispatch(resetFocus());
  };

  if (littleScreen) return null;

  return (
    <Container onClick={handleClick}>
      {!littleScreen && (
        <SwitchContainer>
          <Switch
            isChecked={displayGrid}
            setIsChecked={() => dispatch(gridToggle())}
            label={t('general.graduatedGrid')}
          />
          <Switch
            isChecked={displayCutZone}
            setIsChecked={() => dispatch(cutZoneToggle())}
            label={t('general.cuttingArea')}
          />
        </SwitchContainer>
      )}
      <div
        style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <UndoButton />
        <RedoButton />
      </div>
      {!littleScreen && (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
          {album && (
            <Text>
              {album.albumPages.length} {t('album.pages')}
            </Text>
          )}
          <ZoomContainer>
            <MinusButton onClick={() => dispatch(zoomTo(zoom - 0.1))}>
              -
            </MinusButton>
            <ValueContainer>{Math.round(zoom * 100)}%</ValueContainer>
            <PlusButton onClick={() => dispatch(zoomTo(zoom + 0.1))}>
              +
            </PlusButton>
          </ZoomContainer>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 58px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 24px;
  box-sizing: border-box;
  position: relative;

  background-color: ${colors.white};
  border: solid 1px ${colors.gray200};

  @media (max-width: ${styleConstants.breakpointLg}) {
    justify-content: left;
    background-color: transparent;
    border: none;
    bottom: 0px;
    position: absolute;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const ZoomContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 105px;

  font-size: 14px;
  font-weight: 700;
  text-align: center;

  color: ${colors.gray700};
`;

const MinusButton = styled.div`
  display: flex;
  width: 28px;
  height: 28px;

  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  border: solid 1px ${colors.gray300};
  border-right: none;
  border-radius: 8px 0 0 8px;

  cursor: pointer;
`;

const ValueContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  height: 28px;

  padding: 4px;
  box-sizing: border-box;

  justify-content: center;
  align-items: center;
  border: solid 1px ${colors.gray300};
`;

const PlusButton = styled.div`
  display: flex;
  width: 28px;
  height: 28px;

  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  border: solid 1px ${colors.gray300};
  border-left: none;
  border-radius: 0 8px 8px 0;

  cursor: pointer;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.black};
`;

export default BottomBar;
