import { styled } from 'styled-components';
import colors from '../../../constants/colors';
import Button, { buttonType } from '../../general/Button';
import { ReactSVG } from 'react-svg';
import { useDropzone } from 'react-dropzone';
import { useEffect, useState } from 'react';
import { importPhotos } from '../../../api/photos';
import { useTranslation } from 'react-i18next';
import styleConstants from '../../../constants/styleConstants';
import Loader from '../../general/Loader';
import { LogIn } from '../../../api/auth';
import { LoginState, useAuth } from '../../../hook/useAuth';
import { createPortal } from 'react-dom';
import Hotjar from '@hotjar/browser';

interface ModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  folderID?: string;
  addPhotosToImport: (newPhotos: File[]) => void;
}

type FlexDirection =
  | 'column'
  | 'inherit'
  | '-moz-initial'
  | 'initial'
  | 'revert'
  | 'unset'
  | 'column-reverse'
  | 'row'
  | 'row-reverse'
  | undefined;

const baseStyle = {
  width: '100%',
  height: '300px',
  display: 'flex',
  flexDirection: 'column' as FlexDirection,
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 12,
  borderColor: colors.gray400,
  borderStyle: 'dashed',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  fontFamily: 'DM Sans',
  fontSize: '14px',
  fontWeight: 400,
  cursor: 'pointer',
};

const ImportPhotoModal: React.FC<ModalProps> = ({
  visible,
  setVisible,
  folderID,
  addPhotosToImport,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isReady, userInfo, asklogin } = useAuth();

  const onDrop = (newFiles: File[]) => {
    setFiles([...files, ...newFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.jpeg', '.jpg'],
    },
    maxSize: 26214400,
  });

  const { t } = useTranslation(['common']);

  const importPhoto = async () => {
    Hotjar.event('Import de photos');
    addPhotosToImport([...files]);
    setVisible(false);
  };

  useEffect(() => {
    setFiles([]);
    setIsLoading(false);
  }, [visible]);

  return createPortal(
    <Container $show={visible} id="importModal">
      <ComponentContainer>
        <FormTopContainer>
          <div style={{ width: '140px', height: '1px' }}></div>
          <Title>{t('photos.selectPhotos')}</Title>
          <Button
            $type={buttonType['white-noborder']}
            onClick={() => setVisible(false)}
          >
            <ReactSVG src="/svg/cross.svg" />
            {t('general.close')}
          </Button>
        </FormTopContainer>
        <FormContainer>
          <div {...getRootProps({ style: baseStyle })}>
            <input {...getInputProps({ multiple: true })} />
            {files.length > 0 && (
              <p
                style={{
                  color: colors.green,
                  fontSize: '18px',
                  fontWeight: 700,
                  marginBottom: '12px',
                }}
              >
                {files.length} photo(s) chargée(s)
              </p>
            )}
            <ReactSVG src="/svg/upload_photo.svg" />
            <p>
              <b>{t('photos.clickHere')}</b> {t('photos.dragPhotos')}
            </p>
          </div>
          <Button
            style={{ marginTop: '16px' }}
            $type={buttonType.black}
            onClick={importPhoto}
          >
            {isLoading ? <Loader /> : t('photos.validate')}
          </Button>
        </FormContainer>
      </ComponentContainer>
    </Container>,
    document.body,
  );
};

const Container = styled.div<{ $show: boolean }>`
  width: 100%;
  height: 100%;

  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  overflow: visible;

  background-color: rgba(52, 64, 84, 0.7);
`;

const ComponentContainer = styled.div`
  width: 40%;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const FormTopContainer = styled.div`
  height: 84px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  position: relative;

  background-color: ${colors.white};
  color: ${colors.black};

  border-radius: 8px 8px 0 0;
`;

const Title = styled.div`
  font-family: 'Playfair Display';
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${colors.gray100};
  color: ${colors.black};

  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  padding: 24px;
`;

export default ImportPhotoModal;
