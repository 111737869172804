import { useEffect } from 'react';
import { useAuth } from './useAuth';
import { useDispatch } from 'react-redux';
import {
  changeOther,
  endLoading,
  loadElements,
  store,
} from '../constants/initialStore';
import { formats } from '../constants/formats';
import { redirect, useParams, useSearchParams } from 'react-router-dom';
import { otherFormats } from '../constants/otherFormats';
import { createOther, getOngoingOther } from '../api/other';
import { ConvertMMToPixel } from '../utils/convertCMToPixel';
import { defaultElementsOnPage } from '../constants/defaultElementOnPage';

export const createOtherFn = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const formatAsked = searchParams.get('format') || Object.keys(formats)[0];
  const accessory = searchParams.get('accessory') ?? '0';
  const paperId = searchParams.get('paperId') ?? '0';
  const languageAsked = searchParams.get('lng') || 'fr';
  const fromCart = searchParams.get('fromCart') || false;
  const format = otherFormats[formatAsked];
  const { width: mmWidth, height: mmHeight } = format;
  const width = ConvertMMToPixel(mmWidth);
  const height = ConvertMMToPixel(mmHeight);
  const payload = {
    format: formatAsked,
    backgroundColor: '#ffffff',
    backgroundTheme: undefined,
    accessory: accessory,
    paperId: parseInt(paperId),
    elements:
      format.defaultElementsOnPage &&
      defaultElementsOnPage[format.defaultElementsOnPage]
        ? defaultElementsOnPage[format.defaultElementsOnPage](width, height)
        : defaultElementsOnPage['oneFullSizePhoto'](width, height),
  };

  const newCreation = await createOther(payload, languageAsked);
  store.dispatch(
    changeOther({
      ...newCreation,
    }),
  );
  window.location.replace(
    window.location.origin +
      `/autre/${newCreation.id}?lng=${languageAsked}${
        fromCart ? '&fromCart=true' : ''
      }`,
  );
  return newCreation;
};

export const fetchDispatchOther = async (id?: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  const languageAsked = searchParams.get('lng') || 'fr';
  const fromCart = searchParams.get('fromCart') || false;
  const savedOther = await getOngoingOther(id);
  if (!id)
    return window.location.replace(
      window.location.origin +
        `/autre/${savedOther.id}?lng=${languageAsked}${
          fromCart ? '&fromCart=true' : ''
        }`,
    );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  if (!savedOther) return redirect('/404');
  const { ownerID: _1, ...rest } = savedOther;
  const creation = rest;
  store.dispatch(changeOther(creation));
  store.dispatch(
    loadElements(
      creation.elements.map((element) => ({
        ...element,
        style: element.style
          ? JSON.parse(element.style as unknown as string)
          : undefined,
      })),
    ),
  );
  store.dispatch(endLoading());
  return creation;
};

export const useFetchOrCreateOther = () => {
  const { userInfo, isReady, useNewAccessToken } = useAuth();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (isReady) {
      const newToken = searchParams.get('token');
      if (newToken) {
        const res = useNewAccessToken(newToken);
        if (res) {
          const urlActuelle = window.location.href;
          const urlSansToken = urlActuelle.replace(/token=[^&]*(&?)/, '');
          window.location.replace(urlSansToken);
        }
      }
    }
    if (
      window.location.pathname.includes('calendrier') ||
      window.location.pathname.includes('album') ||
      window.location.pathname.includes('carte') ||
      window.location.pathname.includes('admin') // Route type is inferred later
    ) {
      return;
    }

    // it is here to prevent multiple creation, but the useEffect should be transformed in the future so it is not triggered so often
    if (userInfo === null) {
      return;
    }

    if (!id) {
      fetchDispatchOther(); // Last creation
      return;
    }
    if (id && id !== 'nouveau') {
      fetchDispatchOther(id);
      return;
    }
    createOtherFn();
  }, [userInfo, isReady, searchParams]);
};
