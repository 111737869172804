import { styled } from 'styled-components';
import Button, { buttonType } from '../../general/Button';
import colors from '../../../constants/colors';
import { ReactSVG } from 'react-svg';
import Loader from '../../general/Loader';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTheme from '../../../hook/useTheme';
import styleConstants from '../../../constants/styleConstants';

const CheckedElement = () => {
  return <ReactSVG src="/svg/checked_circle.svg" />;
};

const UncheckedElement = () => {
  return (
    <div
      style={{
        minWidth: '20px',
        minHeight: '20px',
        backgroundColor: colors.white,
        border: 'solid 1px ' + colors.gray300,
        borderRadius: '6px',
      }}
    ></div>
  );
};

const CheckListElement = ({
  message,
  checked,
  canChange,
  changeTotal,
}: {
  message: string;
  checked: boolean;
  canChange: boolean;
  changeTotal: (newChecked: number) => void;
}) => {
  const [isChecked, setIsChecked] = useState(checked);
  return (
    <ElementContainer
      onClick={() => {
        if (canChange) {
          changeTotal(isChecked ? -1 : 1);
          setIsChecked(!isChecked);
        }
      }}
      $canChange={canChange}
    >
      {isChecked ? <CheckedElement /> : <UncheckedElement />}
      <Text>{message}</Text>
    </ElementContainer>
  );
};

const ElementContainer = styled.div<{ $canChange: boolean }>`
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: left;

  gap: 12px;

  ${(props) => (props.$canChange ? 'cursor: pointer;' : '')}
`;

interface ModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleValidate: () => void;
  isLoading: boolean;
}

const LastVerificationModal: React.FC<ModalProps> = ({
  visible,
  setVisible,
  handleValidate,
  isLoading,
}) => {
  const [totalChecked, setTotalChecked] = useState(0);

  const { t } = useTranslation(['validationCheckList']);
  const { color, serenityDisplayed } = useTheme();

  const changeTotal = (newChecked: number) => {
    setTotalChecked(totalChecked + newChecked);
  };

  return (
    <Container $show={visible}>
      <FormTopContainer>
        <Title>{t('lastChecksBeforeValidation')}</Title>
      </FormTopContainer>
      <FormContainer>
        <CheckListElement
          checked={false}
          message={t('checkAccuracyOfYourPersonalData')}
          canChange={true}
          changeTotal={changeTotal}
        />
        <CheckListElement
          checked={false}
          message={t('carefullyReviewAllPagesOfYourCreation')}
          canChange={true}
          changeTotal={changeTotal}
        />
        <CheckListElement
          checked={false}
          message={t('checkSpellingOfTexts')}
          canChange={true}
          changeTotal={changeTotal}
        />
        <CheckListElement
          checked={false}
          message={t('ensureYourPhotosAreOfGoodQuality')}
          canChange={true}
          changeTotal={changeTotal}
        />
        {serenityDisplayed && (
          <>
            <SubTitle>{t('haveAnyDoubts')}</SubTitle>
            <Text>
              {`${t('addSerenityOptionToYourCart')} ${t(
                'orderCheckBeforePrintingByOurTeam',
              )}`}
            </Text>
            <CheckListElement
              checked={true}
              message={t('textAndSpellingCorrection')}
              canChange={false}
              changeTotal={changeTotal}
            />
            <CheckListElement
              checked={true}
              message={t('layoutCorrection')}
              canChange={false}
              changeTotal={changeTotal}
            />
          </>
        )}
        <ButtonContainer>
          <Button
            $type={buttonType.white}
            onClick={() => setVisible(false)}
            style={{ width: 'calc(50% - 8px)' }}
          >
            {t('modifyMyCreation')}
          </Button>
          <Button
            $type={buttonType.themed}
            onClick={() => handleValidate()}
            style={{ width: 'calc(50% - 8px)' }}
            disabled={isLoading || totalChecked !== 4}
            $themeColor={color}
          >
            {isLoading ? <Loader /> : t('validate')}
          </Button>
        </ButtonContainer>
      </FormContainer>
    </Container>
  );
};

const Container = styled.div<{ $show: boolean }>`
  width: 100%;
  height: 100%;

  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  background-color: rgba(52, 64, 84, 0.7);
`;

const FormTopContainer = styled.div`
  width: 462px;
  height: 84px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 0 0 0 18px;

  box-sizing: border-box;

  position: relative;

  background-color: ${colors.white};
  color: ${colors.black};

  border-radius: 8px 8px 0 0;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const FormContainer = styled.div`
  width: 462px;

  display: flex;
  flex-direction: column;

  background-color: ${colors.gray100};
  color: ${colors.black};

  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  padding: 24px;

  gap: 8px;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const Title = styled.div`
  font-family: 'Playfair Display';
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;

  color: ${colors.black};
`;

const SubTitle = styled.div`
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`;

const Text = styled.div`
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 500;
  text-align: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-around;

  gap: 16px;
`;

export default LastVerificationModal;
