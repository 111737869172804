import axios from 'axios';

export const getCardByIDorSKU = async (
  id: string,
  traduction: 'FR' | 'DE' | 'IT' | null,
) => {
  return axios
    .get<any>(`/creator/${id}${traduction ? '/' + traduction : ''}`)
    .then((res) => res.data);
};

export const createCardByAdmin = async (
  newSKU: string,
  originalModel?: string,
) => {
  return axios
    .post<any>(`/creator/${newSKU}`, {
      originalModel: originalModel ?? null,
    })
    .then((res) => res.data);
};

export const getLastSKUTaken = async () => {
  return axios.get<any>(`/creator/lastTaken`).then((res) => res.data);
};
