import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators as UndoActionCreators } from 'redux-undo';
import { RootState } from '../../constants/initialStore';
import Button, { buttonType } from '../general/Button';
import { ReactSVG } from 'react-svg';
import colors from '../../constants/colors';
import { useTranslation } from 'react-i18next';
import useLittleScreen from '../../hook/useLittleScreen';
import Hotjar from '@hotjar/browser';

const UndoButton: React.FC = () => {
  const dispatch = useDispatch();
  const creationState = useSelector((state: RootState) => state.creation);

  const { t } = useTranslation(['common']);

  const littleScreen = useLittleScreen();

  const handleUndo = () => {
    if (creationState.past.length > 0) {
      Hotjar.event('Appuie sur le bouton "Annuler"');
      dispatch(UndoActionCreators.undo()); // Dispatch de l'action Undo
    }
  };

  return (
    <Button
      $type={buttonType['white-noborder']}
      onClick={handleUndo}
      disabled={creationState.past.length === 0}
      style={{
        flexDirection: 'column',
        padding: littleScreen ? '5px' : '5px 18px',
        gap: '5px',
        color: creationState.past.length === 0 ? colors.gray500 : colors.black,
      }}
    >
      <ReactSVG
        src={
          creationState.past.length === 0
            ? '/svg/undo_icon_gray.svg'
            : '/svg/undo_icon_black.svg'
        }
      />
      {!littleScreen && t('general.undo')}
    </Button>
  );
};

export default UndoButton;
