import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const squareLayouts1Photo: LayoutElement[][] = [
  [
    {
      type: ElementType.PHOTO,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.82,
      height: 0.76,
      top: 0.12,
      left: 0.09,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.42,
      height: 0.57,
      top: 0.2,
      left: 0.29,
    },
    {
      type: ElementType.TEXT,
      width: 0.54,
      height: 0.1,
      top: 0.81,
      left: 0.23,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.62,
      height: 0.56,
      top: 0.22,
      left: 0.19,
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.1,
      top: 0.82,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 1,
      height: 0.8,
      top: 0,
      left: 0,
    },
    {
      type: ElementType.TEXT,
      width: 0.54,
      height: 0.09,
      top: 0.85,
      left: 0.23,
    },
  ],

  [
    {
      type: ElementType.PHOTO,
      width: 0.66,
      height: 0.45,
      top: 0.21,
      left: 0.17,
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.1,
      top: 0.75,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.42,
      height: 0.76,
      top: 0.12,
      left: 0.29,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.5,
      height: 0.5,
      top: 0.2,
      left: 0.25,
      style: {
        shape: 'diamond',
        rotation: -45,
        rotationFrame: 45,
      },
    },
    {
      type: ElementType.TEXT,
      width: 0.54,
      height: 0.09,
      top: 0.84,
      left: 0.23,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.64,
      height: 0.64,
      top: 0.12,
      left: 0.18,
      style: {
        shape: 'circle',
        rotation: 0,
        rotationFrame: 0,
      },
    },
    {
      type: ElementType.TEXT,
      width: 0.54,
      height: 0.09,
      top: 0.84,
      left: 0.23,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.7,
      height: 0.7,
      top: 0.08,
      left: 0.15,
      style: {
        shape: 'arch',
        rotation: 0,
        rotationFrame: 0,
      },
    },
    {
      type: ElementType.TEXT,
      width: 0.54,
      height: 0.09,
      top: 0.84,
      left: 0.23,
    },
  ],
];
