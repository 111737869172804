import { styled } from 'styled-components';
import { ReactSVG } from 'react-svg';
import Button, { buttonType } from '../../components/general/Button';
import { resetPassword } from '../../api/auth';
import colors from '../../constants/colors';
import { useMemo, useState } from 'react';
import { useAuth } from '../../hook/useAuth';
import { useTranslation } from 'react-i18next';
import styleConstants from '../../constants/styleConstants';
import useTheme from '../../hook/useTheme';

interface ModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ForgottenPasswordPage: React.FC<ModalProps> = ({
  visible,
  setVisible,
}) => {
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  const { origin } = useTheme();

  const { asklogin } = useAuth();

  const canSubmit = useMemo(() => !!email && email.includes('@'), [email]);

  const { t } = useTranslation(['common']);

  const handleResetPassword = async () => {
    const res = await resetPassword(email, origin);
    if (res) {
      setMessage(
        'Si cette adresse e-mail est associée à un compte, un e-mail de réinitialisation de mot de passe a été envoyé.',
      );
    }
  };

  const handleBackToLogIn = () => {
    setMessage('');
    asklogin(true);
  };

  return (
    <Container $show={visible}>
      <FormTopContainer>
        <Title>{t('auth.forgotPassword')}</Title>
        <CloseIcon src="/svg/cross.svg" onClick={() => setVisible(false)} />
      </FormTopContainer>
      <FormContainer>
        <Text>{t('auth.resetLinkSent')}</Text>
        <Label>{t('auth.email')}</Label>
        <CustomInput
          type="text"
          placeholder={t('auth.enterEmail')}
          autoComplete="email"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />
        {message && <Message>{message}</Message>}
        <Button
          $type={buttonType.black}
          onClick={message === '' ? handleResetPassword : handleBackToLogIn}
          disabled={!canSubmit}
        >
          {message === '' ? t('auth.reset') : 'Retour'}
        </Button>
      </FormContainer>
    </Container>
  );
};

const Container = styled.div<{ $show: boolean }>`
  width: 100%;
  height: 100%;

  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;

  background-color: rgba(52, 64, 84, 0.7);
`;

const FormTopContainer = styled.div`
  width: 450px;
  height: 84px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;

  background-color: ${colors.white};
  color: ${colors.black};

  border-radius: 8px 8px 0 0;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const FormContainer = styled.form`
  width: 450px;

  display: flex;
  flex-direction: column;

  background-color: ${colors.gray100};
  color: ${colors.black};

  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  padding: 24px;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const Title = styled.div`
  font-family: 'Playfair Display';
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
`;

const CloseIcon = styled(ReactSVG)`
  position: absolute;

  top: 24px;
  right: 24px;

  cursor: pointer;

  @media (max-width: ${styleConstants.breakpointLg}) {
    top: 12px;
    right: 12px;
  }
`;

const Text = styled.div`
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin: 0 0 20px 0;
`;

const Label = styled.div`
  margin: 0 0 6px 0;

  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`;

const CustomInput = styled.input`
  width: 100%;

  color: ${colors.gray500};
  background-color: ${colors.white};

  box-sizing: border-box;
  margin: 0 0 16px 0;
  border: solid 1px ${colors.gray300};
  padding: 14px 10px;

  font-family: 'DM Sans';
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
`;

// const Link = styled.div`
//   width: 100%;

//   font-family: 'DM Sans';
//   font-size: 14px;
//   font-weight: 700;
//   text-align: left;

//   cursor: pointer;
// `;

// const TextWithLine = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 8px;

//   margin: 20px 0;

//   font-family: 'DM Sans';
//   font-size: 14px;
//   font-weight: 500;
//   text-align: center;
// `;

// const Line = styled.div`
//   height: 1px;
//   flex: 1;

//   background-color: ${colors.gray200};
// `;

const Message = styled.div`
  color: ${colors.gray700};
  text-align: center;
  margin-bottom: 6px;
`;

export default ForgottenPasswordPage;
