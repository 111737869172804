import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { styled } from 'styled-components';
import colors from '../../../constants/colors';
import styleConstants from '../../../constants/styleConstants';
import { PhotoFolder, PhotoType } from '../../../types/models/Photos';
import Button, { buttonType } from '../../general/Button';
import { Dimensions } from '../PhotoMenu';
import PhotoImporter from '../PhotoImporter';

interface ModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleValidate: (id: number, photosDimension: Dimensions) => void;
  photos: PhotoType[];
  folders: PhotoFolder[];
  photosFiles: { [key: string]: File };
  openImport: React.Dispatch<React.SetStateAction<boolean>>;
  loadEnd: (id: string, newPhoto: PhotoType) => Promise<void>;
}

const ReplacePhotoModal: React.FC<ModalProps> = ({
  visible,
  setVisible,
  handleValidate,
  photos,
  folders,
  photosFiles,
  openImport,
  loadEnd,
}) => {
  const [originalSize, setOriginalSize] = useState({ width: 0, height: 0 });
  const [selectedPhoto, setSelectedPhoto] = useState<number>(-1);
  const [selectedFolder, setSelectedFolder] = useState<number>(-1);

  const { t } = useTranslation(['common']);

  const onLoadHandler: React.ReactEventHandler<HTMLImageElement> = (event) => {
    setOriginalSize({
      height: event.currentTarget.naturalHeight,
      width: event.currentTarget.naturalWidth,
    });
  };

  const openImportPhoto = () => {
    openImport(true);
  };

  const validatePhoto = (index: number) => {
    handleValidate(index, originalSize);
    setVisible(false);
  };

  useEffect(() => {
    setSelectedFolder(-1);
    setSelectedPhoto(-1);
  }, [visible]);

  return createPortal(
    <Container $show={visible} id="replaceModal">
      <FormTopContainer>
        <Button
          $type={buttonType['white-noborder']}
          onClick={() => setVisible(false)}
        >
          <ReactSVG src="/svg/cross.svg" />
          {t('general.close')}
        </Button>
        <Button $type={buttonType.black} onClick={() => openImportPhoto()}>
          {t('photos.import')}
        </Button>
      </FormTopContainer>
      <FormContainer>
        {selectedFolder === -1 && (
          <SubTitle>{t('photos.photoFolders')}</SubTitle>
        )}
        {selectedFolder === -1 && (
          <PhotoList>
            {folders.map((folder, index) => {
              return (
                <FolderDisplayer
                  key={folder.id}
                  onClick={() => setSelectedFolder(index)}
                >
                  <FolderPicture>
                    <ReactSVG src={'/svg/folder.svg'} />
                  </FolderPicture>
                  <FolderName>{folder.name}</FolderName>
                  <PhotoNumber>
                    {
                      photos.filter((photo) => photo.folderID === folder.id)
                        .length
                    }{' '}
                    {t('general.photos')}
                  </PhotoNumber>
                </FolderDisplayer>
              );
            })}
          </PhotoList>
        )}
        {selectedFolder !== -1 && (
          <BackButton
            onClick={() => {
              setSelectedFolder(-1);
            }}
          >
            <ReactSVG
              src="/svg/left_chevron.svg"
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 10px; height: 20px;');
              }}
            />
            <SubTitle>Retour</SubTitle>
          </BackButton>
        )}
        <SubTitle>Vos photos</SubTitle>
        <PhotoList>
          {photos.map((photo, index) => {
            if (
              selectedFolder === -1
                ? photo.folderID === undefined || photo.folderID === null
                : photo.folderID === folders[selectedFolder].id
            )
              return (
                <PhotoDisplayer
                  key={photo.id}
                  onClick={() => validatePhoto(index)}
                  onLoad={onLoadHandler}
                  src={photo.pictureURL}
                  selected={index === selectedPhoto}
                />
              );
          })}
          {Object.keys(photosFiles).map((key, index) => {
            const photo = photosFiles[key];
            if (photo) {
              return (
                <PhotoImporter
                  key={index}
                  file={photo}
                  id={key}
                  folderID={
                    selectedFolder === -1 ? '' : folders[selectedFolder].id
                  }
                  loadEnd={loadEnd}
                />
              );
            }
          })}
        </PhotoList>
      </FormContainer>
    </Container>,
    document.body,
  );
};

const Container = styled.div<{ $show: boolean }>`
  width: 100%;
  height: 100%;

  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  background-color: rgba(52, 64, 84, 0.7);
`;

const FormTopContainer = styled.div`
  width: 600px;
  height: 84px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  padding: 0 12px 0 18px;

  box-sizing: border-box;

  position: relative;

  background-color: ${colors.white};
  color: ${colors.black};

  border-radius: 8px 8px 0 0;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
    flex-wrap: wrap;
  }
`;

const FormContainer = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${colors.gray100};
  color: ${colors.black};

  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  padding: 24px;
  gap: 12px;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const Title = styled.div`
  font-family: 'DM Sans';
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;

  color: ${colors.gray900};
`;

const SubTitle = styled.div`
  width: 100%;
  font-family: 'DM Sans';
  font-size: 16px;
  font-weight: 500;
  text-align: left;

  color: ${colors.gray900};
`;

const PhotoList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
  padding: 12px 8px;
  overflow-x: scroll;
`;

const PhotoDisplayer = styled.img<{ selected: boolean }>`
  width: 128px;
  height: 112px;
  padding: 8px;
  border: solid 2px ${(props) => (props.selected ? colors.green : colors.white)};
  object-fit: contain;
  background-color: ${colors.white};
`;

const FolderDisplayer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
`;

const FolderPicture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 96px;
  position: relative;
  background-color: ${colors.gray100};
`;

const FolderName = styled.div`
  width: 112px;
  display: flex;
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 500;
  text-align: left;
  color: ${colors.black};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PhotoNumber = styled.div`
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  color: ${colors.gray700};
`;

const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: 'center';
  position: relative;
  width: 100%;
  gap: 8px;
  cursor: pointer;
`;

export default ReplacePhotoModal;
