import { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { styled } from 'styled-components';
import colors from '../../constants/colors';
import {
  RootState,
  addElement,
  modifyElement,
  resetFocus,
} from '../../constants/initialStore';
import useCreationType from '../../hook/useCreationType';
import useLittleScreen from '../../hook/useLittleScreen';
import { Element } from '../../types/models/Elements';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import { importPhotos } from '../../api/photos';
import { PhotoType } from '../../types/models/Photos';
import useEffectSkipFirstRender from '../../hook/useEffectSkipFirstRender';
import Loader from '../general/Loader';

interface PhotoProps {
  file: File;
  id: string;
  folderID: string;
  loadEnd: (id: string, newPhoto: PhotoType) => Promise<void>;
}

const PhotoImporter: React.FC<PhotoProps> = ({
  file,
  id,
  folderID,
  loadEnd,
}) => {
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [hasStarted, setHasStarted] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  useEffectSkipFirstRender(() => {
    const importPhoto = async () => {
      const formData = new FormData();
      formData.append('photo0', file);
      const result = await importPhotos(folderID ?? '', formData);
      if (result.data) {
        loadEnd(id, result.data[0]);
        setHasLoaded(true);
      }
    };

    try {
      if (!hasLoaded && file && hasStarted) {
        importPhoto();
      }
    } catch (e) {
      console.log(e);
    }
  }, [hasStarted, hasLoaded]);

  useEffect(() => {
    if (file && !hasStarted) {
      setHasStarted(true);
    }
  }, [file, hasStarted]);

  if (hasLoaded) return null;

  return (
    <Container>
      {imageSrc && <Image src={imageSrc} alt="uploaded" />}
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 82px;
  width: 82px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Image = styled.img`
  width: 82px;
  height: 82px;

  filter: blur(3px);

  object-fit: cover;
`;

const RoundDiv = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  border-radius: 50%;
  position: absolute;
  top: 4px;
  right: 4px;
`;

const LoaderContainer = styled.div`
  height: 82px;
  width: 82px;
  display: flex;
  position: absolute;
  background-color: transparent;
`;

export default PhotoImporter;
