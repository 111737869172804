const colors = {
  white: '#FFFFFF',
  black: '#1F1F1F',
  error50: '#FEF3F2',
  error200: '#FECDCA',
  error600: '#D92D20',
  error700: '#B42318',
  gray100: '#F2F4F7',
  gray200: '#EAECF0',
  gray300: '#D0D5DD',
  gray400: '#98A2B3',
  gray500: '#667085',
  gray600: '#43566A',
  gray700: '#344054',
  gray900: '#101828',
  green: '#2E7C81',
  dark_green: '#527755',
  red: '#C5373B',
  success: '#D1E7DD',
  success50: '#ECFDF3',
  success200: '#ABEFC6',
  success700: '#067647',
  warning25: '#FFFCF5',
  warning300: '#F2C84B',
  warning700: '#B54708',
  yellow: '#F7C143',
  blue: '#504bd1 ',
};

export default colors;
