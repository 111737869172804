/* eslint-disable @typescript-eslint/no-empty-function */
import axios from 'axios';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { UserCreation, UserCredentials } from '../types/models/Auth';
import { LogIn, Register, getMe, getOwnership } from '../api/auth';
import LogInPage from '../pages/auth/LogInPage';
import RegisterPage from '../pages/auth/RegisterPage';
import ForgottenPasswordPage from '../pages/auth/ForgottenPasswordPage';
import { useParams, useSearchParams } from 'react-router-dom';
import { getInitialProps } from 'react-i18next';
import { getCardOwnership } from '../api/cards';
import useTheme from './useTheme';

type UserInfo =
  | {
      state: LoginState.LOGGED_OUT;
    }
  | {
      state: LoginState.LOGGED_IN;
      token: string;
      originWebSite: 'PP' | 'PC';
      isAdmin: boolean;
    };

interface IAuthContext {
  userInfo: UserInfo | null;
  login: (user: UserCredentials) => Promise<boolean>;
  logout: () => Promise<void>;
  register: (userData: UserCreation) => Promise<boolean>;
  asklogin: (fromModal: boolean, email?: string) => void;
  askregister: () => void;
  askresetpassword: () => void;
  isReady: boolean;
  useNewAccessToken: (token: string) => boolean;
  setUUIDForCookie: (uuid: string) => void;
}

export enum AuthStatus {
  OK,
  WRONG_CREDENTIALS,
  INVALID_FORMAT,
  EMAIL_UNAVAILABLE,
  ERROR,
  EMAIL_NOT_VALIDATED,
}

export enum LoginState {
  LOGGED_IN,
  LOGGED_OUT,
}

const AuthContext: React.Context<any> = createContext<IAuthContext>({
  userInfo: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login: async (user: UserCredentials) => false,
  logout: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  register: async (userData: UserCreation) => false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  asklogin: (fromModal: boolean, email?: string) => {},
  askregister: () => {},
  askresetpassword: () => {},
  isReady: false,
  useNewAccessToken: (token: string) => false,
  setUUIDForCookie: (uuid: string) => {},
});

export const AuthProvider = ({ children }: any) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [logInModalVisible, setLogInModalVisible] = useState<boolean>(false);
  const [registerModalVisible, setRegisterModalVisible] =
    useState<boolean>(false);
  const [resetPasswordModalVisible, setResetPasswordModalVisible] =
    useState<boolean>(false);
  const [initialEmail, setInitialEmail] = useState<string>('');
  const [uuidInCookie, setUUIDInCookie] = useState<string>('');

  const { initialLanguage } = getInitialProps();

  const { origin } = useTheme();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      setAccessToken(token);
      setIsReady(true);
    } else if (uuidInCookie && uuidInCookie !== '') {
      setIsReady(true);
    }
  }, [uuidInCookie]);

  useEffect(() => {
    const askOwnership = async () => {
      await getOwnership(uuidInCookie);
    };

    if (accessToken && accessToken !== '') {
      localStorage.setItem('accessToken', accessToken);

      const interceptor = axios.interceptors.request.use((config) => {
        if (uuidInCookie && uuidInCookie !== '') {
          if (config?.headers) {
            config.headers.Authorization = `Bearer ${accessToken}`;
            config.headers['X-cookie'] = `${uuidInCookie}`;
          }
          return config;
        } else {
          if (config?.headers) {
            config.headers.Authorization = `Bearer ${accessToken}`;
          }
          return config;
        }
      });
      updateUserInfo();

      if (uuidInCookie) {
        askOwnership();
      }

      setIsReady(true);

      return () => axios.interceptors.request.eject(interceptor);
    } else if (uuidInCookie && uuidInCookie !== '') {
      const interceptor = axios.interceptors.request.use((config) => {
        if (config?.headers) {
          config.headers['X-cookie'] = `${uuidInCookie}`;
        }
        return config;
      });

      setIsReady(true);
    }
    updateUserInfo();
  }, [accessToken, uuidInCookie, isReady]);

  const updateUserInfo = useCallback(async () => {
    const noAuth = !!new URLSearchParams(window.location.search).get(
      'signature',
    );
    if (!isReady || noAuth) {
      return;
    }
    if (!accessToken || accessToken === '') {
      setUserInfo({ state: LoginState.LOGGED_OUT });
      return;
    }

    try {
      const info = await getMe();
      if (info.data) {
        setUserInfo({
          state: LoginState.LOGGED_IN,
          ...info.data,
          originWebSite: info.data.origin,
        });
      } else {
        setUserInfo({ state: LoginState.LOGGED_OUT });
      }
    } catch (e) {
      setUserInfo({ state: LoginState.LOGGED_OUT });
    }
  }, [accessToken, isReady]);

  const planetCardsUrl = import.meta.env.VITE_SHOPIFY_URL_PC;
  const planetPhotoUrl = import.meta.env.VITE_SHOPIFY_URL_PP;
  const login = useCallback(async (user: UserCredentials): Promise<boolean> => {
    const originWebSite = window.location.href.includes('planet-photo')
      ? 'PP'
      : 'PC';


    try {
      const res = await LogIn(user, originWebSite);
      setAccessToken(res.data.access_token);
      setLogInModalVisible(false);
      localStorage.setItem('loggedInFromPersoApp', 'True');
      // Voici où changer les URLs si besoin !
      const initialURL =
        res.data.origin === 'PC'
          ? planetCardsUrl
          : planetPhotoUrl
      const multipass_url = initialURL + res.data.multipass_url;
      window.location.replace(multipass_url);
      return true;
    } catch {
      return false;
    }
  }, []);

  const logout = useCallback(async () => {
    setAccessToken(null);
    localStorage.setItem('accessToken', '');
  }, []);

  const register = useCallback(async (user: UserCreation): Promise<boolean> => {
    try {
      const res = await Register(user, origin);
      setAccessToken(res.data.access_token);
      setRegisterModalVisible(false);
      return true;
    } catch {
      return false;
    }
  }, []);

  const asklogin = useCallback(
    async (fromModal: boolean, email?: string): Promise<void> => {
      if (email) {
        setInitialEmail(email);
      }
      if (fromModal) {
        setRegisterModalVisible(false);
        setResetPasswordModalVisible(false);
        setLogInModalVisible(true);
      } else {
        if (!registerModalVisible && !resetPasswordModalVisible) {
          setLogInModalVisible(true);
        }
      }
    },
    [],
  );

  const askregister = useCallback(async (): Promise<void> => {
    setLogInModalVisible(false);
    setResetPasswordModalVisible(false);
    setRegisterModalVisible(true);
  }, []);

  const askresetpassword = useCallback(async (): Promise<void> => {
    setLogInModalVisible(false);
    setRegisterModalVisible(false);
    setResetPasswordModalVisible(true);
  }, []);

  const useNewAccessToken = (token: string) => {
    setAccessToken(token);
    return true;
  };

  const setUUIDForCookie = (uuid: string) => {
    setUUIDInCookie(uuid);
  };

  return (
    <AuthContext.Provider
      value={{
        userInfo,
        login,
        logout,
        register,
        asklogin,
        askregister,
        askresetpassword,
        isReady,
        useNewAccessToken,
        setUUIDForCookie,
      }}
    >
      <LogInPage
        visible={logInModalVisible}
        setVisible={setLogInModalVisible}
        initialEmail={initialEmail}
      />
      <RegisterPage
        visible={registerModalVisible}
        setVisible={setRegisterModalVisible}
      />
      <ForgottenPasswordPage
        visible={resetPasswordModalVisible}
        setVisible={setResetPasswordModalVisible}
      />
      {children}
    </AuthContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAuth = () => useContext<IAuthContext>(AuthContext);
