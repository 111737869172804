import { useLayoutEffect, useState } from 'react';
import { styleConstantsNumbers } from '../constants/styleConstants';

const useLittleScreen = () => {
  const [littleScreen, setLittleScreen] = useState<boolean>(
    window.innerWidth <= styleConstantsNumbers.breakpointLg,
  );

  useLayoutEffect(() => {
    function updateScreenSizeState() {
      setLittleScreen(window.innerWidth <= styleConstantsNumbers.breakpointLg);
    }
    window.addEventListener('resize', updateScreenSizeState);
    updateScreenSizeState();
    return () => window.removeEventListener('resize', updateScreenSizeState);
  }, []);

  return littleScreen;
};

export default useLittleScreen;
