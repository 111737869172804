import { Other, OtherToSend } from '../types/models/Other';
import axios from 'axios';

export const getOngoingOther = async (id?: string) => {
  return axios.get<Other>(`/others/${id ? id : ''}`).then((res) => res.data);
};

export const createOther = async (payload: OtherToSend, locale: string) => {
  return axios
    .post<Other>(`/others`, { creation: payload, locale })
    .then((res) => res.data);
};

export const saveOther = async (payload: OtherToSend) => {
  return axios
    .put<Other>(`/others/${payload.id}`, payload)
    .then((res) => res.data);
};

export const saveNameOther = async (id: string, newName: string) => {
  return axios.post<any>(`/others/name/${id}`, { name: newName });
};

export const endPersonnalisationOther = async (
  otherID: string,
  locale: string,
  fromCart: boolean,
) => {
  return axios.post<string>(`/others/end/${otherID}`, { locale, fromCart });
};
