import { ReactSVG } from 'react-svg';
import { styled } from 'styled-components';
import colors from '../../constants/colors';

interface IconButtonProps {
  title: string;
  src: string;
  disabled?: boolean;
  active?: boolean;
  onClick?: () => void;
  style?: any;
  isDeletion?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({
  title,
  src,
  disabled = false,
  active = false,
  onClick,
  style,
  isDeletion = false,
}) => {
  const handleClick = () => {
    if (onClick && !disabled) {
      onClick();
    }
  };

  return (
    <Container disabled={disabled} onClick={handleClick} style={style}>
      <Icon disabled={disabled} $active={active}>
        <ReactSVG
          src={src}
          beforeInjection={(svg) => {
            isDeletion &&
              svg.setAttribute('style', 'width: 32px; height: 32px;');
          }}
        />
      </Icon>
      <Title style={isDeletion ? { color: colors.red } : {}}>{title}</Title>
    </Container>
  );
};

const Container = styled.button<{ disabled: boolean }>`
  all: unset;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;

  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

const Icon = styled.div<{ disabled: boolean; $active: boolean }>`
  width: 48px;
  height: 48px;
  display: flex;

  justify-content: center;
  align-items: center;

  border: solid 1px ${colors.gray300};
  border-radius: 8px;

  background-color: ${(props) =>
    props.disabled
      ? colors.gray200
      : props.$active
      ? colors.black
      : colors.white};

  box-sizing: border-box;
`;

const Title = styled.div`
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  color: ${colors.black};
`;

export default IconButton;
