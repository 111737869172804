import axios from 'axios';
import { UserCreation, UserCredentials } from '../types/models/Auth';

const searchParams = new URLSearchParams(window.location.search);
const signature = searchParams.get('signature');
if (signature)
  axios.defaults.headers.common['x-puppeteer-signature'] = signature;

export const LogIn = async (
  credentials: UserCredentials,
  origin: 'PC' | 'PP' | 'STAGGING',
) => {
  return axios.post(`/auth/login`, {
    ...credentials,
    originWebSite: origin,
    returnUrl: window.location.href,
  });
};

export const Register = async (data: UserCreation, origin: 'PC' | 'PP' | 'STAGGING') => {
  return axios.post(`/auth/register`, { info: data, origin });
};

export const resetPassword = async (email: string, origin: 'PC' | 'PP' | 'STAGGING') => {
  return axios.post(`/auth/resetPassword`, { email, origin });
};

export const getMe = async () => {
  return axios.get(`/auth/me`);
};

export const getOwnership = async (oldUUID: string) => {
  return axios.post(`/users/newOwner`, { oldUUID: oldUUID });
};
