import { ElementType, LayoutElement } from '../../../types/models/Elements';
import { panoramicLayouts1Photo } from '../panoramic/1photo';

export const mugLayouts1Photo: LayoutElement[][] = [
  [
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.7,
      top: 0.15,
      left: 0.1,
    },
    {
      type: ElementType.TEXT,
      width: 0.3,
      height: 0.7,
      top: 0.15,
      left: 0.6,
    },
  ],
  ...panoramicLayouts1Photo,
];
