import { styled } from 'styled-components';
import { ReactZoomPanPinchContentRef } from 'react-zoom-pan-pinch';
import colors from '../../constants/colors';
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GridOverlay from '../../components/functionnalities/GridOverlay';
import {
  RootState,
  changeFace,
  changeRatio,
  closeMenu,
  resetFocus,
} from '../../constants/initialStore';

import DrawZone from '../../components/cardElements/DrawZone';
import SavingPopup from '../../components/functionnalities/SavingPopup';
import TransformZoom from '../../components/general/TransformZoom';
import { useAutoSaveCard } from '../../hook/useAutoSaveCard';
import FacesDisplayer from '../../components/cardElements/FacesDisplayer';
import useCardZoneSize from '../../hook/useCardZoneSize';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import styleConstants from '../../constants/styleConstants';
import CutZoneOverlay from '../../components/functionnalities/CutZoneOverlay';
import CleanOverlay from '../../components/functionnalities/CleanOverlay';
import { useFetchOrCreateCard } from '../../hook/useFetchOrCreateCard';

const MainCardZone: React.FC = () => {
  const maxZoneRef = useRef<HTMLDivElement>(null);

  const gridDisplay = useSelector((state: RootState) => state.grid.value);
  const cutZoneDisplay = useSelector((state: RootState) => state.cutZone.value);
  const currentFace = useSelector((state: RootState) => state.face.value);
  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );

  const menuOpen = useSelector((state: RootState) => state.menu.value !== 0);
  const zoomRef = useRef<ReactZoomPanPinchContentRef>(null);
  useAutoSaveCard();

  const dispatch = useDispatch();

  const [maxHeight, maxWidth, displayHeight, displayWidth] = useCardZoneSize(
    changeRatio,
    maxZoneRef,
    64,
    0,
  );

  useEffect(() => {
    dispatch(changeFace(0));
  }, [card.facesSizes.length]);

  useEffect(() => {
    zoomRef.current?.setTransform(
      (maxWidth - displayWidth) / 2,
      (maxHeight - displayHeight) / 2,
      1,
    );
  }, [maxWidth, maxHeight, displayWidth, displayHeight]);

  const handleClick = () => {
    // dispatch(closeMenu());
    dispatch(resetFocus());
  };

  return (
    <Container $menuOpen={menuOpen}>
      <MaxZone ref={maxZoneRef} onClick={handleClick}>
        <SavingPopup />

        <FacesDisplayer />
        <TransformZoom ref={zoomRef}>
          {gridDisplay && <GridOverlay />}
          {cutZoneDisplay && (
            <CutZoneOverlay
              $radius={card.facesSizes[currentFace].radius.map((radius) =>
                card.facesSizes[currentFace].width >
                card.facesSizes[currentFace].height
                  ? (radius / card.facesSizes[currentFace].width) * 100
                  : (radius / card.facesSizes[currentFace].height) * 100,
              )}
            />
          )}
          <CleanOverlay
            $radius={card.facesSizes[currentFace].radius.map((radius) =>
              card.facesSizes[currentFace].width >
              card.facesSizes[currentFace].height
                ? (radius / card.facesSizes[currentFace].width) * 100
                : (radius / card.facesSizes[currentFace].height) * 100,
            )}
          />
          <DrawZone displayHeight={displayHeight} displayWidth={displayWidth} />
        </TransformZoom>
      </MaxZone>
    </Container>
  );
};

const Container = styled.div<{ $menuOpen: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  overflow: visible;

  justify-content: center;
  align-items: center;

  background-color: ${colors.gray100};

  padding: 40px ${(props) => (props.$menuOpen ? '0' : '155')}px;

  @media (max-width: ${styleConstants.breakpointLg}) {
    height: calc(100% - 200px);
    justify-content: flex-start;
    padding: 12px 12px 0px 12px;
  }
`;

const MaxZone = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  position: relative;

  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export default MainCardZone;
