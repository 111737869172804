import { useEffect, useState } from 'react';
import { LoginState, useAuth } from './useAuth';
import colors from '../constants/colors';

const useTheme = () => {
  const [logoFull, setLogoFull] = useState<string>('');
  const [color, setColor] = useState<string>('');
  const [serenityDisplayed, setSerenityDisplayed] = useState<boolean>(true);
  const [origin, setOrigin] = useState<'PC' | 'PP'>('PC');
  const { userInfo } = useAuth();

  useEffect(() => {
    if (
      (userInfo?.state === LoginState.LOGGED_IN &&
        userInfo.originWebSite === 'PP') ||
      window.location.href.includes('planet-photo')
    ) {
      setLogoFull('/logo_pp.svg');
      setColor(colors.blue);
      setSerenityDisplayed(false);
      setOrigin('PP');
    } else {
      setLogoFull('/svg/logo.svg');
      setColor(colors.green);
      setSerenityDisplayed(false);
      setOrigin('PC');
    }
  }, [userInfo]);

  return { color, logoFull, serenityDisplayed, origin };
};

export default useTheme;
