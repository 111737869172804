import { AlbumFormat, AlbumType } from '../constants/albumFormats';
import { CalendarFormat } from '../constants/calendarFormats';
import { CalendarType, CalendarTypes } from '../types/models/Calendar';
import { ConvertMMToPixel } from './convertCMToPixel';

export const getFreeSpaceHeightCalendar = (
  type: CalendarType,
  format: CalendarFormat,
  isCover?: boolean,
) => {
  const formatPxHeight = ConvertMMToPixel(format.height);
  if (isCover) return formatPxHeight;
  switch (type) {
    case CalendarTypes.GRID:
      return (format.isDouble ? 1 : 0.5) * formatPxHeight;
    case CalendarTypes.LINE:
      return (
        formatPxHeight -
        ConvertMMToPixel(
          format.forbiddenZones !== undefined
            ? format.forbiddenZones[0].height + 22
            : 30,
        )
      );
    case CalendarTypes.SQUARE:
      return formatPxHeight - ConvertMMToPixel(80);
    case CalendarTypes.LONG:
      return formatPxHeight * 0.5;
    default:
      return formatPxHeight;
  }
};

export const getFreeSpaceWidthCalendar = (
  type: CalendarType,
  format: CalendarFormat,
  isCover?: boolean,
) => {
  const formatPxWidth = ConvertMMToPixel(format.width);
  if (isCover) return formatPxWidth;
  switch (type) {
    case CalendarTypes.CORNER_SQUARE:
      return formatPxWidth - 885;
    default:
      return formatPxWidth;
  }
};

export const getFreeSpaceHeightAlbum = (format: AlbumFormat) => {
  const formatPxHeight = ConvertMMToPixel(format.height);
  return formatPxHeight;
};

export const getFreeSpaceWidthAlbum = (
  type: AlbumType,
  format: AlbumFormat,
  isCover?: boolean,
) => {
  const formatPxWidth = ConvertMMToPixel(format.width);
  if (isCover) return formatPxWidth;
  if (type === AlbumType.PLAT) {
    return formatPxWidth * 2;
  } else {
    return formatPxWidth;
  }
};

// TODO : Add GetFreeSpace Album and Others
