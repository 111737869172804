import styled from 'styled-components';
import {
  CloseButton,
  ColorContainer,
  Container,
  Item,
  Line,
  SubTitle,
  Title,
  TitleContainer,
  TitleItem,
} from './MenuStyles';
import colors from '../../constants/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  changeCalendar,
  closeMenu,
  loadElements,
  store,
} from '../../constants/initialStore';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { ButtonLine, InlinedButton, VLine } from '../general/ButtonLine';
import { layouts as predefinedLayouts } from '../../constants/layouts/availableLayouts';
import {
  BlockElementStyle,
  Element,
  ElementType,
  LayoutElement as LayoutElementType,
  PhotoElementStyle,
} from '../../types/models/Elements';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import { v4 as uuid } from 'uuid';
import {
  CalendarFormat,
  calendarFormats,
} from '../../constants/calendarFormats';
import {
  Calendar,
  CalendarType,
  CalendarTypes,
} from '../../types/models/Calendar';
import CalendarInput from '../general/CalendarInput';
import { onMonthStartChange } from '../../pages/calendars/calendarFns';
import dayjs from 'dayjs';
import {
  AlbumFormat,
  AlbumType,
  albumFormats,
} from '../../constants/albumFormats';
import useCreationType from '../../hook/useCreationType';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import i18next from '../../i18n';
import {
  OtherFormat,
  OtherType,
  otherFormats,
} from '../../constants/otherFormats';
import CloseMenuButton from '../layout/CloseMenuButton';
import { Format } from '../../types/models/Cards';
import { formats } from '../../constants/formats';
import flattenBlockIntoElements from '../../utils/flattenBlockIntoElements';
import {
  getFreeSpaceHeightCalendar,
  getFreeSpaceWidthCalendar,
} from '../../utils/getFreeSpace';

const getFreeSpaceHeightAlbum = (
  type: AlbumType,
  format: AlbumFormat,
  isCover?: boolean,
) => {
  const formatPxHeight = ConvertMMToPixel(format.height);
  return formatPxHeight;
};

const getFreeSpaceWidthAlbum = (
  type: AlbumType,
  format: AlbumFormat,
  isCover?: boolean,
) => {
  const formatPxWidth = ConvertMMToPixel(format.width);
  if (isCover) return formatPxWidth;
  if (type === AlbumType.PLAT) {
    return formatPxWidth * 2;
  } else {
    return formatPxWidth;
  }
};

const setElementsToLayoutCard = (
  elements: LayoutElementType[],
  freeSpaceWidth: number,
  freeSpaceHeight: number,
) => {
  const { creation, face } = store.getState();

  const [focusElements, otherElements] = creation.present.elements.value.reduce(
    ([focusElements, otherElements], element) => {
      if (
        element.face === face.value &&
        [ElementType.PHOTO, ElementType.TEXT].includes(element.type)
      ) {
        return [[...focusElements, element], otherElements];
      }
      return [focusElements, [...otherElements, element]];
    },
    [[], []] as (typeof creation.present.elements.value)[],
  );

  const currentPhotoElements = focusElements.filter(
    (e) => e.type === ElementType.PHOTO,
  );

  const currentTextElements = focusElements.filter(
    (e) => e.type === ElementType.TEXT,
  );

  const flattenElements = flattenBlockIntoElements(
    elements,
    freeSpaceWidth,
    freeSpaceHeight,
  );

  const [photoElements, textElements] = flattenElements.reduce(
    ([photoElements, textElements], element) => {
      if (element.type === ElementType.PHOTO) {
        return [[...photoElements, element], textElements];
      }
      return [photoElements, [...textElements, element]];
    },
    [[], []] as (typeof elements)[],
  );

  const newPhotosElements = photoElements.map(
    ({ width, height, top, left, style }, index) => {
      let photo: Partial<Element> | undefined = currentPhotoElements[index]
        ? { ...currentPhotoElements[index] }
        : undefined;
      if (!photo) {
        photo = {
          type: ElementType.PHOTO,
          id: uuid(),
          face: face.value,
          content: '',
          editable: false,
          contentEditable: true,
          style: {
            brightness: 1,
            saturation: 100,
            opacity: 1,
            zoom: 100,
            rotation: 0,
            grayscale: false,
            sepia: false,
            mirrored: false,
            shadow: false,
            shape: 'rectangle',
            translateX: 0,
            translateY: 0,
            rotationFrame: 0,
          },
        };
      }
      photo.width = width * freeSpaceWidth;
      photo.height = height * freeSpaceHeight;

      const styleAsPhotoElementStyle = style as PhotoElementStyle;

      if (
        styleAsPhotoElementStyle &&
        (styleAsPhotoElementStyle.shape === 'arch' ||
          styleAsPhotoElementStyle.shape === 'circle' ||
          styleAsPhotoElementStyle.rotationFrame)
      ) {
        if (freeSpaceWidth < freeSpaceHeight) {
          photo.height = width * freeSpaceWidth;
        } else {
          photo.width = height * freeSpaceHeight;
        }
      }
      photo.top = top * freeSpaceHeight;
      photo.left = left * freeSpaceWidth;
      photo.style = {
        ...photo.style,
        ...((style as PhotoElementStyle) ?? {
          shape: 'rectangle',
          rotation: 0,
          rotationFrame: 0,
        }),
      };
      return photo;
    },
  );

  const newTextElements = textElements.map(
    ({ width, height, top, left }, index) => {
      let text: Partial<Element> | undefined = currentTextElements[index]
        ? { ...currentTextElements[index] }
        : undefined;
      if (!text) {
        text = {
          type: ElementType.TEXT,
          id: uuid(),
          face: face.value,
          content: '',
          editable: true,
          zIndex: 20,
          contentEditable: true,
          style: {
            bold: false,
            italic: false,
            underlined: false,
            size: 12,
            letterSpacing: 0,
            interline: 1,
            alignement: 'center',
            font: 'DM Sans',
            color: colors.black,
            transform: 'none',
            rotation: 0,
          },
        };
      }
      text.width = width * freeSpaceWidth;
      text.height = height * freeSpaceHeight;
      text.top = top * freeSpaceHeight;
      text.left = left * freeSpaceWidth;
      return text;
    },
  );

  store.dispatch(
    loadElements([...otherElements, ...newPhotosElements, ...newTextElements]),
  );
};

const setElementsToLayoutCalendrier = (
  elements: LayoutElementType[],
  freeSpaceWidth: number,
  freeSpaceHeight: number,
) => {
  const { creation, focus } = store.getState();

  const elementFocused = creation.present.elements.value.find(
    (element) => element.id === focus.value,
  );

  let currentFocusIndex = creation.present.calendar!.calendarMonths.findIndex(
    ({ id }) =>
      elementFocused
        ? id === elementFocused.calendarMonthID
        : id === focus.value,
  );
  if (currentFocusIndex === -1) currentFocusIndex = 0;
  if (currentFocusIndex == 0) return;
  const currentFocus =
    creation.present.calendar!.calendarMonths[currentFocusIndex];

  const [focusElements, otherElements] = creation.present.elements.value.reduce(
    ([focusElements, otherElements], element) => {
      if (
        element.calendarMonthID === currentFocus!.id &&
        [ElementType.PHOTO, ElementType.TEXT].includes(element.type)
      ) {
        return [[...focusElements, element], otherElements];
      }
      return [focusElements, [...otherElements, element]];
    },
    [[], []] as (typeof creation.present.elements.value)[],
  );

  const currentPhotoElements = focusElements.filter(
    (e) => e.type === ElementType.PHOTO,
  );

  const currentTextElements = focusElements.filter(
    (e) => e.type === ElementType.TEXT,
  );

  const flattenElements = flattenBlockIntoElements(
    elements,
    freeSpaceWidth,
    freeSpaceHeight,
  );

  const [photoElements, textElements] = flattenElements.reduce(
    ([photoElements, textElements], element) => {
      if (element.type === ElementType.PHOTO) {
        return [[...photoElements, element], textElements];
      }
      return [photoElements, [...textElements, element]];
    },
    [[], []] as (typeof elements)[],
  );

  const newPhotosElements = photoElements.map(
    ({ width, height, top, left, style }, index) => {
      let photo: Partial<Element> | undefined = currentPhotoElements[index]
        ? { ...currentPhotoElements[index] }
        : undefined;
      if (!photo) {
        photo = {
          type: ElementType.PHOTO,
          id: uuid(),
          calendarMonthID: currentFocus!.id,
          content: '',
          editable: false,
          contentEditable: true,
          style: {
            brightness: 1,
            saturation: 100,
            opacity: 1,
            zoom: 100,
            rotation: 0,
            grayscale: false,
            sepia: false,
            mirrored: false,
            shadow: false,
            shape: 'rectangle',
            translateX: 0,
            translateY: 0,
            rotationFrame: 0,
          },
        };
      }

      photo.width = width * freeSpaceWidth;
      photo.height = height * freeSpaceHeight;

      const styleAsPhotoElementStyle = style as PhotoElementStyle;

      if (
        styleAsPhotoElementStyle &&
        (styleAsPhotoElementStyle.shape === 'arch' ||
          styleAsPhotoElementStyle.shape === 'circle' ||
          styleAsPhotoElementStyle.rotationFrame)
      ) {
        if (creation.present.calendar?.format === 'CALCAR30') {
          photo.height = width * freeSpaceWidth;
        } else if (freeSpaceWidth < freeSpaceHeight) {
          photo.height = width * freeSpaceWidth;
        } else {
          photo.width = height * freeSpaceHeight;
        }
      }
      photo.top = top * freeSpaceHeight;
      photo.left = left * freeSpaceWidth;
      photo.style = {
        ...photo.style,
        ...((style as PhotoElementStyle) ?? {
          shape: 'rectangle',
          rotation: 0,
          rotationFrame: 0,
        }),
      };
      return photo;
    },
  );

  const newTextElements = textElements.map(
    ({ width, height, top, left }, index) => {
      let text: Partial<Element> | undefined = currentTextElements[index]
        ? { ...currentTextElements[index] }
        : undefined;
      if (!text) {
        text = {
          type: ElementType.TEXT,
          id: uuid(),
          calendarMonthID: currentFocus!.id!,
          content: '',
          editable: true,
          contentEditable: true,
          style: {
            bold: false,
            italic: false,
            underlined: false,
            size: 12,
            letterSpacing: 0,
            interline: 1,
            alignement: 'center',
            font: 'DM Sans',
            color: colors.black,
            transform: 'none',
            rotation: 0,
          },
        };
      }
      text.width = width * freeSpaceWidth;
      text.height = height * freeSpaceHeight;
      if (
        creation.present.calendar?.format === 'CALCAR30' &&
        photoElements.findIndex((photo) => {
          const styleAsPhotoElementStyle = photo.style as PhotoElementStyle;
          return (
            styleAsPhotoElementStyle &&
            (styleAsPhotoElementStyle.shape === 'arch' ||
              styleAsPhotoElementStyle.shape === 'circle' ||
              styleAsPhotoElementStyle.shape === 'diamond')
          );
        }) !== -1
      ) {
        text.top = top * freeSpaceWidth;
      } else {
        text.top = top * freeSpaceHeight;
      }
      text.left = left * freeSpaceWidth;
      return text;
    },
  );

  store.dispatch(
    loadElements([...otherElements, ...newPhotosElements, ...newTextElements]),
  );
};

const setElementsToLayoutAlbum = (
  elements: LayoutElementType[],
  freeSpaceWidth: number,
  freeSpaceHeight: number,
) => {
  const { creation, focus } = store.getState();
  const elementFocused = creation.present.elements.value.find(
    (element) => element.id === focus.value,
  );
  let currentPageFocusIndex = creation.present.album?.albumPages.findIndex(
    ({ id }) =>
      elementFocused ? id === elementFocused.pageID : id === focus.value,
  );

  const currentFocus =
    currentPageFocusIndex === undefined || currentPageFocusIndex === -1
      ? creation.present.album!.albumBackCover.id === focus.value
        ? creation.present.album!.albumBackCover
        : creation.present.album!.albumCover
      : creation.present.album!.albumPages[currentPageFocusIndex];

  const [focusElements, otherElements] = creation.present.elements.value.reduce(
    ([focusElements, otherElements], element) => {
      if (
        element.pageID === currentFocus!.id &&
        [ElementType.PHOTO, ElementType.TEXT, ElementType.MOTIF].includes(
          element.type,
        )
      ) {
        return [[...focusElements, element], otherElements];
      }
      return [focusElements, [...otherElements, element]];
    },
    [[], []] as (typeof creation.present.elements.value)[],
  );

  const currentPhotoElements = focusElements.filter(
    (e) => e.type === ElementType.PHOTO,
  );

  const currentTextElements = focusElements.filter(
    (e) => e.type === ElementType.TEXT,
  );

  // filter the sliceTextElements and remove them from the currentTextElements
  const forSliceTextElements = currentTextElements.filter(element => element.isForSlice);
  const nonSliceTextElements = currentTextElements.filter(element => !element.isForSlice);


  const flattenElements = flattenBlockIntoElements(
    elements,
    freeSpaceWidth,
    freeSpaceHeight,
  );

  const [photoElements, textElements] = flattenElements.reduce(
    ([photoElements, textElements], element) => {
      if (element.type === ElementType.PHOTO) {
        return [[...photoElements, element], textElements];
      }
      return [photoElements, [...textElements, element]];
    },
    [[], []] as (typeof elements)[],
  );

  const newPhotosElements = photoElements.map(
    ({ width, height, top, left, style }, index) => {
      let photo: Partial<Element> | undefined = currentPhotoElements[index]
        ? { ...currentPhotoElements[index] }
        : undefined;
      if (!photo) {
        photo = {
          type: ElementType.PHOTO,
          id: uuid(),
          pageID: currentFocus!.id,
          content: '',
          editable: false,
          contentEditable: true,
          style: {
            brightness: 1,
            saturation: 100,
            opacity: 1,
            zoom: 100,
            rotation: 0,
            grayscale: false,
            sepia: false,
            mirrored: false,
            shadow: false,
            shape: 'rectangle',
            translateX: 0,
            translateY: 0,
            rotationFrame: 0,
          },
        };
      }
      photo.width = width * freeSpaceWidth;
      photo.height = height * freeSpaceHeight;

      const styleAsPhotoElementStyle = style as PhotoElementStyle;

      if (
        styleAsPhotoElementStyle &&
        (styleAsPhotoElementStyle.shape === 'arch' ||
          styleAsPhotoElementStyle.shape === 'circle' ||
          styleAsPhotoElementStyle.rotationFrame)
      ) {
        if (freeSpaceWidth < freeSpaceHeight) {
          photo.height = width * freeSpaceWidth;
        } else {
          photo.width = height * freeSpaceHeight;
        }
      }
      photo.top = top * freeSpaceHeight;
      photo.left = left * freeSpaceWidth;
      photo.style = {
        ...photo.style,
        ...((style as PhotoElementStyle) ?? {
          shape: 'rectangle',
          rotation: 0,
          rotationFrame: 0,
        }),
      };
      return photo;
    },
  );

  const newTextElements = textElements.map(
    ({ width, height, top, left }, index) => {
      let text: Partial<Element> | undefined = nonSliceTextElements[index]
        ? { ...nonSliceTextElements[index] }
        : undefined;
      if (!text) {
        text = {
          type: ElementType.TEXT,
          id: uuid(),
          pageID: currentFocus!.id!,
          content: '',
          editable: true,
          contentEditable: true,
          style: {
            bold: false,
            italic: false,
            underlined: false,
            size: 12,
            letterSpacing: 0,
            interline: 1,
            alignement: 'center',
            font: 'DM Sans',
            color: colors.black,
            transform: 'none',
            rotation: 0,
          },
        };
      }

      text.width = width * freeSpaceWidth;
      text.height = height * freeSpaceHeight;
      text.top = top * freeSpaceHeight;
      text.left = left * freeSpaceWidth;
      return text;
    },
  );

  store.dispatch(
    loadElements([...otherElements, ...newPhotosElements, ...newTextElements, ...forSliceTextElements]),
  );
};

const setElementsToLayoutOther = (
  elements: LayoutElementType[],
  freeSpaceWidth: number,
  freeSpaceHeight: number,
  widthShift: number,
  heightShift: number,
) => {
  const { creation } = store.getState();

  const currentPhotoElements = creation.present.elements.value.filter(
    (e) => e.type === ElementType.PHOTO,
  );

  const currentTextElements = creation.present.elements.value.filter(
    (e) => e.type === ElementType.TEXT,
  );

  const currentMotifElements = creation.present.elements.value.filter(
    (e) => e.type === ElementType.MOTIF,
  );

  const flattenElements = flattenBlockIntoElements(
    elements,
    freeSpaceWidth,
    freeSpaceHeight,
  );

  const [photoElements, textElements] = flattenElements.reduce(
    ([photoElements, textElements], element) => {
      if (element.type === ElementType.PHOTO) {
        return [[...photoElements, element], textElements];
      }
      return [photoElements, [...textElements, element]];
    },
    [[], []] as (typeof elements)[],
  );

  const newPhotosElements = photoElements.map(
    ({ width, height, top, left, style }, index) => {
      let photo: Partial<Element> | undefined = currentPhotoElements[index]
        ? { ...currentPhotoElements[index] }
        : undefined;
      if (!photo) {
        photo = {
          type: ElementType.PHOTO,
          id: uuid(),
          content: '',
          pageID: undefined,
          editable: false,
          contentEditable: true,
          style: {
            brightness: 1,
            saturation: 100,
            opacity: 1,
            zoom: 100,
            rotation: 0,
            grayscale: false,
            sepia: false,
            mirrored: false,
            shadow: false,
            shape: 'rectangle',
            translateX: 0,
            translateY: 0,
            rotationFrame: 0,
          },
        };
      }
      photo.width = width * freeSpaceWidth;
      photo.height = height * freeSpaceHeight;

      const styleAsPhotoElementStyle = style as PhotoElementStyle;

      if (
        styleAsPhotoElementStyle &&
        (styleAsPhotoElementStyle.shape === 'arch' ||
          styleAsPhotoElementStyle.shape === 'circle' ||
          styleAsPhotoElementStyle.rotationFrame)
      ) {
        if (freeSpaceWidth < freeSpaceHeight) {
          photo.height = width * freeSpaceWidth;
        } else {
          photo.width = height * freeSpaceHeight;
        }
      }
      photo.top = top * freeSpaceHeight + heightShift;
      photo.left = left * freeSpaceWidth + widthShift;
      photo.style = {
        ...photo.style,
        ...((style as PhotoElementStyle) ?? {
          shape: 'rectangle',
          rotation: 0,
          rotationFrame: 0,
        }),
      };
      return photo;
    },
  );

  const newTextElements = textElements.map(
    ({ width, height, top, left }, index) => {
      let text: Partial<Element> | undefined = currentTextElements[index]
        ? { ...currentTextElements[index] }
        : undefined;
      if (!text) {
        text = {
          type: ElementType.TEXT,
          id: uuid(),
          content: '',
          editable: true,
          contentEditable: true,
          style: {
            bold: false,
            italic: false,
            underlined: false,
            size: 12,
            letterSpacing: 0,
            interline: 1,
            alignement: 'center',
            font: 'DM Sans',
            color: colors.black,
            transform: 'none',
            rotation: 0,
          },
        };
      }
      text.width = width * freeSpaceWidth;
      text.height = height * freeSpaceHeight;
      text.top = top * freeSpaceHeight + heightShift;
      text.left = left * freeSpaceWidth + widthShift;
      return text;
    },
  );

  store.dispatch(
    loadElements([
      ...newPhotosElements,
      ...newTextElements,
      ...currentMotifElements,
    ]),
  );
};

const LayoutItem = styled.button<{ $isSelected: boolean }>`
  all: unset;
  position: relative;
  cursor: ${({ $isSelected }) => ($isSelected ? 'default' : 'pointer')};
  border: 1px solid ${colors.gray200};
  border-color: ${({ $isSelected }) =>
    $isSelected ? colors.black : colors.gray200};
  box-sizing: border-box;

  &:hover {
    border-color: ${({ $isSelected }) =>
      $isSelected ? colors.black : colors.gray300};
  }
`;

const PhotoLayoutElement = styled.div<{ $style?: PhotoElementStyle }>`
  position: absolute;
  background-color: ${colors.gray200};
  border: solid 1px ${colors.gray700};

  border-radius: ${({ $style }) =>
    $style && $style.shape === 'circle'
      ? '50%'
      : $style && $style.shape === 'arch'
      ? '50% 50% 0 0'
      : '0'};
  transform: ${({ $style }) =>
    $style && $style.rotationFrame
      ? 'rotate(' + $style.rotationFrame + 'deg) '
      : ''};
`;

const TextLayoutElement = styled.div`
  position: absolute;
  border: dashed 1px ${colors.gray700};
`;

const getCalendarTypeLabel = (type: CalendarType) => {
  switch (type) {
    case CalendarTypes.GRID:
      return i18next.t('calendar.grid', { ns: ['common'] });
    case CalendarTypes.LINE:
      return i18next.t('calendar.line', { ns: ['common'] });
    case CalendarTypes.SQUARE:
      return 'Carré';
    case CalendarTypes.LONG:
      return 'Long';
    case CalendarTypes.CORNER_SQUARE:
      return 'Carré coin';
  }
};

const LayoutMenu: React.FC = () => {
  const dispatch = useDispatch();
  const type = useCreationType();

  const [format, setFormat] = useState<
    AlbumFormat | CalendarFormat | OtherFormat | null
  >(null);
  const [creationType, setCreationType] = useState<
    AlbumType | CalendarType | null
  >(null);
  const [typesAvailable, setTypesAvailable] = useState<CalendarType[]>([]);
  const [isCover, setIsCover] = useState<boolean>(false);
  const [freeSpaceHeight, setFreeSpaceHeight] = useState<number>(0);
  const [freeSpaceWidth, setFreeSpaceWidth] = useState<number>(0);

  const [heightShift, setHeightShift] = useState<number>(0);
  const [widthShift, setWidthShift] = useState<number>(0);

  const [monthStart, setMonthStart] = useState<string>('');

  const [isPortrait, setIsPortrait] = useState(false);
  const [isSquare, setIsSquare] = useState(false);
  const [isMug, setIsMug] = useState(false);
  const [isLandScape, setIsLandScape] = useState(true);
  const [isPanoramic, setIsPanoramic] = useState(false);
  const [representationHeight, setRepresentationHeight] = useState(80);
  const [representationWidth, setRepresentationWidth] = useState(80);

  const [layouts, setLayouts] = useState<LayoutElementType[][]>([]);

  const [nbPhotos, setNbPhotos] = useState(() => {
    const state = store.getState();
    let focusId: string | null = state.focus.value;
    if (!focusId) {
      if (type === 'calendrier') {
        focusId = state.creation.present.calendar!.calendarMonths[0].id!;
      } else if (type === 'album') {
        focusId = state.creation.present.album!.albumCover.id;
      }
    }
    const elements = state.creation.present.elements.value.filter(
      (element) =>
        element.calendarMonthID === focusId &&
        element.type === ElementType.PHOTO,
    );
    return Math.min(Math.max(elements.length, 1), 5);
  });

  const calendar = useSelector(
    (state: RootState) => state.creation.present.calendar,
  );
  const album = useSelector((state: RootState) => state.creation.present.album);
  const other = useSelector((state: RootState) => state.creation.present.other);
  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );

  const focus = useSelector((state: RootState) => state.focus);

  useEffect(() => {
    if (type === 'album' && album) {
      setFormat(albumFormats[album.format]);
      setCreationType(albumFormats[album.format].type);
      setTypesAvailable([]);
      setIsCover(
        focus.value === album.albumCover.id ||
          focus.value === album.albumBackCover.id,
      );
      const spaceHeight = getFreeSpaceHeightAlbum(
        albumFormats[album.format].type as AlbumType,
        albumFormats[album.format] as AlbumFormat,
        focus.value === album.albumCover.id ||
          focus.value === album.albumBackCover.id,
      );
      const spaceWidth = getFreeSpaceWidthAlbum(
        albumFormats[album.format].type as AlbumType,
        albumFormats[album.format] as AlbumFormat,
        focus.value === album.albumCover.id ||
          focus.value === album.albumBackCover.id,
      );
      setFreeSpaceHeight(spaceHeight);
      setFreeSpaceWidth(spaceWidth);
      setIsPortrait(spaceHeight > spaceWidth);
      setIsSquare(spaceWidth === spaceHeight);
      setIsLandScape(spaceHeight < spaceWidth);
      setRepresentationHeight((spaceHeight > spaceWidth && 110) || 80);
      setRepresentationWidth((spaceHeight < spaceWidth && 110) || 80);
    } else if (type === 'calendrier' && calendar) {
      const formatName = calendar.format;
      setFormat(calendarFormats[formatName]);
      setCreationType(calendar.type);
      setTypesAvailable(calendarFormats[formatName].types);
      setIsCover(focus.value === calendar.calendarMonths[0].id);
      const spaceHeight = getFreeSpaceHeightCalendar(
        calendar.type as CalendarType,
        calendarFormats[formatName] as CalendarFormat,
        focus.value === calendar.calendarMonths[0].id,
      );
      const spaceWidth = getFreeSpaceWidthCalendar(
        calendar.type as CalendarType,
        calendarFormats[formatName] as CalendarFormat,
        focus.value === calendar.calendarMonths[0].id,
      );
      setFreeSpaceHeight(spaceHeight);
      setFreeSpaceWidth(spaceWidth);
      setMonthStart(calendar.monthStart);
      setIsPortrait(spaceHeight > spaceWidth);
      setIsSquare(
        formatName === 'CALCAR30' ||
          spaceWidth === calendarFormats[formatName]!.height,
      );
      setIsLandScape(spaceHeight < spaceWidth);
      setIsPanoramic(
        spaceWidth / spaceHeight > 2.3 || spaceHeight / spaceWidth > 2.3,
      );
      setRepresentationHeight(
        (spaceHeight > spaceWidth && formatName !== 'CALCAR30' && 110) || 80,
      );
      setRepresentationWidth(
        (spaceHeight < spaceWidth && formatName !== 'CALCAR30' && 110) || 80,
      );
    } else if (type === 'autre' && other) {
      const formatName = other.format;
      setFormat(otherFormats[formatName]);
      setCreationType(null);
      setTypesAvailable([]);
      setIsCover(false);
      const spaceHeight = ConvertMMToPixel(otherFormats[formatName].height);
      const spaceWidth = ConvertMMToPixel(otherFormats[formatName].width);
      setFreeSpaceHeight(spaceHeight);
      setFreeSpaceWidth(spaceWidth);
      setIsPortrait(spaceHeight > spaceWidth);
      setIsSquare(spaceWidth === spaceHeight);
      setIsLandScape(spaceHeight < spaceWidth);
      setIsPanoramic(
        spaceWidth / spaceHeight > 2.3 || spaceHeight / spaceWidth > 2.3,
      );
      setIsMug(
        otherFormats[formatName].type === OtherType.Mug ||
          otherFormats[formatName].type === OtherType.Gourde,
      );
      setRepresentationHeight((spaceHeight > spaceWidth && 110) || 80);
      setRepresentationWidth((spaceHeight < spaceWidth && 110) || 80);
      setWidthShift(ConvertMMToPixel(0));
      setHeightShift(ConvertMMToPixel(0));
    } else if (type === 'carte' && card) {
      const formatName = card.format;
      setFormat(otherFormats[formatName]);
      setCreationType(null);
      setTypesAvailable([]);
      setIsCover(false);
      const spaceHeight = ConvertMMToPixel(formats[formatName].height);
      const spaceWidth = ConvertMMToPixel(formats[formatName].width);
      setFreeSpaceHeight(spaceHeight);
      setFreeSpaceWidth(spaceWidth);
      setIsPortrait(spaceHeight > spaceWidth);
      setIsSquare(spaceWidth === spaceHeight);
      setIsLandScape(spaceHeight < spaceWidth);
      setIsPanoramic(
        spaceWidth / spaceHeight > 2.3 || spaceHeight / spaceWidth > 2.3,
      );
      setRepresentationHeight((spaceHeight > spaceWidth && 110) || 80);
      setRepresentationWidth((spaceHeight < spaceWidth && 110) || 80);
      setWidthShift(ConvertMMToPixel(0));
      setHeightShift(ConvertMMToPixel(0));
    }
  }, [type, album, calendar, other, card, focus]);

  useEffect(() => {
    setLayouts(
      (
        predefinedLayouts[
          isMug
            ? 'mug'
            : isPortrait
            ? isPanoramic
              ? 'panoramicV'
              : 'portrait'
            : isPanoramic
            ? 'panoramic'
            : isSquare
            ? 'square'
            : 'landscape'
        ] as any
      )[nbPhotos == 0 ? 'texte' : nbPhotos] as LayoutElementType[][],
    );
  }, [isPortrait, isPanoramic, nbPhotos, isSquare, isMug]);

  const { t } = useTranslation(['common']);

  const createHandleNbPhotos = (nb: number) => () => setNbPhotos(nb);

  const onLayoutClick = (index: number) => () => {
    const elements = layouts[index];
    if (type === 'album') {
      setElementsToLayoutAlbum(elements, freeSpaceWidth, freeSpaceHeight);
    } else if (type === 'calendrier') {
      setElementsToLayoutCalendrier(elements, freeSpaceWidth, freeSpaceHeight);
    } else if (type === 'autre') {
      setElementsToLayoutOther(
        elements,
        freeSpaceWidth,
        freeSpaceHeight,
        widthShift,
        heightShift,
      );
    } else if (type === 'carte') {
      setElementsToLayoutCard(elements, freeSpaceWidth, freeSpaceHeight);
    }
  };

  const createHandleCalendarTypeChange = (type: CalendarType) => () => {
    dispatch(changeCalendar((prev) => ({ ...(prev as Calendar), type })));
  };

  const onCalendarInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onMonthStartChange(value);
  };

  return (
    <Container>
      <CloseMenuButton />
      {monthStart !== '' && (
        <Item>
          <SubTitle>{t('calendar.startMonth')}</SubTitle>
          <CalendarInput
            type="month"
            lang="it"
            value={dayjs.utc(monthStart).format('YYYY-MM')}
            onChange={onCalendarInputChange}
          />
        </Item>
      )}
      {typesAvailable.length > 1 && (
        <Item>
          <SubTitle>{t('calendar.calendarType')}</SubTitle>
          <ButtonLine>
            {typesAvailable.map((type, index) => (
              <Fragment key={index}>
                <InlinedButton
                  $active={type === creationType}
                  onClick={createHandleCalendarTypeChange(type)}
                >
                  {getCalendarTypeLabel(type)}
                </InlinedButton>
                {index < typesAvailable.length - 1 && <VLine />}
              </Fragment>
            ))}
          </ButtonLine>
        </Item>
      )}
      {/* {album && (
        <Item>
          <SubTitle>
            {t('layout.pagesCount')} {album.albumPages.length}
          </SubTitle>
        </Item>
      )} */}
      <Item>
        <SubTitle>{t('layout.photosCount')}</SubTitle>
        <ButtonLine>
          {Array.from(
            {
              length: Object.keys(
                predefinedLayouts[
                  isMug
                    ? 'mug'
                    : isPortrait
                    ? isPanoramic
                      ? 'panoramicV'
                      : 'portrait'
                    : isPanoramic
                    ? 'panoramic'
                    : isSquare
                    ? 'square'
                    : 'landscape'
                ],
              ).length,
            },
            (_, index) => (
              <Fragment key={index}>
                <InlinedButton
                  $active={nbPhotos === (index + 1) % 6}
                  onClick={createHandleNbPhotos((index + 1) % 6)}
                >
                  {index >= 0 && index < 5 && index + 1}
                  {index === 4 && '+'}
                  {index === 5 && t('general.text')}
                </InlinedButton>
                {index < 5 && <VLine />}
              </Fragment>
            ),
          )}
        </ButtonLine>
        <ColorContainer>
          {layouts.map((elements, index) => (
            <LayoutItem
              key={index}
              $isSelected={false}
              onClick={onLayoutClick(index)}
              style={{
                height: representationHeight + 2,
                width: representationWidth + 2,
              }}
            >
              {flattenBlockIntoElements(
                elements,
                representationWidth,
                representationHeight,
                true,
              ).map(({ type, style: $style, ...element }, index) => {
                const styleAsPhotoElementStyle = $style as
                  | PhotoElementStyle
                  | undefined;

                let localRepresentationHeight: number = representationHeight;
                let localRepresentationWidth: number = representationWidth;

                if (
                  !!styleAsPhotoElementStyle &&
                  (styleAsPhotoElementStyle.shape === 'diamond' ||
                    styleAsPhotoElementStyle.shape === 'circle')
                ) {
                  if (representationHeight < representationWidth) {
                    localRepresentationWidth = representationHeight;
                  } else {
                    localRepresentationHeight = representationWidth;
                  }
                }

                const style = {
                  width: element.width * localRepresentationWidth,
                  height: element.height * localRepresentationHeight,
                  top: element.top * representationHeight,
                  left: element.left * representationWidth,
                };
                return type === ElementType.PHOTO ? (
                  <PhotoLayoutElement
                    key={index}
                    style={style}
                    $style={$style as PhotoElementStyle}
                  />
                ) : (
                  <TextLayoutElement key={index} style={style} />
                );
              })}
            </LayoutItem>
          ))}
        </ColorContainer>
      </Item>
    </Container>
  );
};

export default LayoutMenu;
