import styled from 'styled-components';
import { MotifElementStyle } from '../../../types/models/Elements';

export const MotifDisplayer = styled.img<{
  $elementstyle: MotifElementStyle | null;
}>`
  transform: ${(props) =>
    props.$elementstyle?.rotation
      ? 'rotate(' + props.$elementstyle.rotation + 'deg) '
      : ''};
`;
