import { useDispatch, useSelector } from 'react-redux';
import { RootState, activatePopup } from '../constants/initialStore';
import { LoginState, useAuth } from './useAuth';
import { useEffect, useRef } from 'react';
import { saveCalendar } from '../api/calendars';
import { Calendar } from '../types/models/Calendar';
import { PhotoElementStyle, TextElementStyle } from '../types/models/Elements';
import useCreationType from './useCreationType';

export const useAutoSaveCalendar = () => {
  const dispatch = useDispatch();
  const { userInfo, asklogin, isReady } = useAuth();
  const calendar = useSelector(
    (state: RootState) => state.creation.present.calendar,
  );

  const type = useCreationType();

  const calendarElements = useSelector(
    (state: RootState) => state.creation.present.elements.value,
  );
  const saveTimeoutRef = useRef<any | null>(null);

  const save = async () => {
    if (type !== 'calendrier') {
      return;
    }
    // if (userInfo?.state !== LoginState.LOGGED_IN) {
    //   // await asklogin(false);
    //   return;
    // }
    const { calendarElements: _, ...restCalendar } = calendar as Calendar;
    const payload: Calendar = {
      ...(restCalendar as Calendar),
      calendarMonths: calendar!.calendarMonths.map((month) => ({
        ...month,
        calendarElements: calendarElements
          .filter(({ calendarMonthID }) => calendarMonthID === month.id)
          .map(({ calendarMonthID, style, locked, ...rest }) => ({
            style: JSON.stringify(style) as unknown as
              | TextElementStyle
              | PhotoElementStyle,
            ...rest,
          })),
      })),
    };
    await saveCalendar(payload);
    dispatch(activatePopup());
    saveTimeoutRef.current = null;
  };

  useEffect(() => {
    if (!isReady) return;
    if (userInfo?.state === LoginState.LOGGED_IN && userInfo.isAdmin) return;
    if (saveTimeoutRef.current !== null) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(
      save,
      userInfo?.state === LoginState.LOGGED_IN ? 5000 : 5000,
    );
  }, [calendar, calendarElements]);

  return save;
};
