export enum AlbumType {
  'RIGIDE',
  'SOUPLE',
  'PLAT',
  'SPIRALE',
}

export type AlbumFormat = {
  name: string;
  width: number;
  height: number;
  type: AlbumType;
  maxPages: number;
  minPages: number;
  defaultCoverPage?: string;
  canEditBackCover?: boolean;
};

type AlbumFormats = Record<string, AlbumFormat>;

export const albumFormats: AlbumFormats = {
  SOUCAR30: {
    name: 'Livre photo carré souple 30x30cm',
    width: 300,
    height: 300,
    type: AlbumType.SOUPLE,
    maxPages: 92,
    minPages: 24,
  },
  SOUCAR20: {
    name: 'Livre photo carré souple 20x20cm',
    width: 200,
    height: 200,
    type: AlbumType.SOUPLE,
    maxPages: 92,
    minPages: 24,
  },
  SOUCAR14: {
    name: 'Livre photo carré souple 14x14cm',
    width: 140,
    height: 140,
    type: AlbumType.SOUPLE,
    maxPages: 48,
    minPages: 24,
  },
  SOUA4PAY: {
    name: 'Livre photo paysage souple 30x20cm',
    width: 300,
    height: 200,
    type: AlbumType.SOUPLE,
    maxPages: 92,
    minPages: 24,
  },
  SOUA5PAY: {
    name: 'Livre photo paysage souple 21x15cm',
    width: 210,
    height: 150,
    type: AlbumType.SOUPLE,
    maxPages: 92,
    minPages: 24,
  },
  SOUA4POR: {
    name: 'Livre photo portrait souple 20x30cm',
    width: 200,
    height: 300,
    type: AlbumType.SOUPLE,
    maxPages: 92,
    minPages: 24,
  },
  SPICAR20: {
    name: 'Livre photo carré spirale 20x20cm',
    width: 200,
    height: 200,
    type: AlbumType.SPIRALE,
    maxPages: 92,
    minPages: 24,
  },
  RIGCAR30: {
    name: 'Livre photo carré rigide 30x30cm',
    width: 300,
    height: 300,
    type: AlbumType.RIGIDE,
    maxPages: 92,
    minPages: 24,
  },
  RIGCAR21: {
    name: 'Livre photo carré rigide 21x21cm',
    width: 210,
    height: 210,
    type: AlbumType.RIGIDE,
    maxPages: 92,
    minPages: 24,
  },
  RIGA4PAY: {
    name: 'Livre photo paysage rigide A4',
    width: 297,
    height: 210,
    type: AlbumType.RIGIDE,
    maxPages: 92,
    minPages: 24,
  },
  RIGA5PAY: {
    name: 'Livre photo paysage rigide A5',
    width: 210,
    height: 148,
    type: AlbumType.RIGIDE,
    maxPages: 92,
    minPages: 24,
  },
  RIGA4POR: {
    name: 'Livre photo portrait rigide A4',
    width: 210,
    height: 297,
    type: AlbumType.RIGIDE,
    maxPages: 92,
    minPages: 24,
  },
  LAYFLATA4: {
    name: 'Livre ouverture à plat A4',
    width: 297,
    height: 210,
    type: AlbumType.PLAT,
    maxPages: 46,
    minPages: 12,
  },
  LAYFLAT30: {
    name: 'Livre ouverture à plat 30x30cm',
    width: 300,
    height: 300,
    type: AlbumType.PLAT,
    maxPages: 46,
    minPages: 12,
  },
  CARNSOU10: {
    name: 'Carnet souple 10x14cm',
    width: 100,
    height: 140,
    type: AlbumType.SOUPLE,
    defaultCoverPage: 'windowPhotoAndText',
    minPages: 0,
    maxPages: 0,
    canEditBackCover: true,
  },
  CARNSOU15: {
    name: 'Carnet souple 15x21cm',
    width: 150,
    height: 210,
    type: AlbumType.SOUPLE,
    defaultCoverPage: 'windowPhotoAndText',
    minPages: 0,
    maxPages: 0,
    canEditBackCover: true,
  },
  CARNSPI10: {
    name: 'Carnet spirale 10x14cm',
    width: 100,
    height: 140,
    type: AlbumType.SPIRALE,
    defaultCoverPage: 'windowPhotoAndText',
    minPages: 0,
    maxPages: 0,
    canEditBackCover: true,
  },
  CARNSPI15: {
    name: 'Carnet spirale 15x21cm',
    width: 150,
    height: 210,
    type: AlbumType.SPIRALE,
    defaultCoverPage: 'windowPhotoAndText',
    minPages: 0,
    maxPages: 0,
    canEditBackCover: true,
  },
};
