import axios from 'axios';
import { BrowserRouter, useLocation } from 'react-router-dom';
import AppRoutes from './routing/AppRoutes';
import { AuthProvider } from './hook/useAuth';
import { Provider } from 'react-redux';
import { store } from './constants/initialStore';
import './i18n';
import { useEffect, useState } from 'react';
import { initGA, logPageView } from './analytics';
import colors from './constants/colors';
import { useTranslation } from 'react-i18next';

const measurementID = 'G-3BYMN9F3TP'; // Replace with your measurement ID

const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);
};

function App() {
  axios.defaults.baseURL = import.meta.env.VITE_API_URL;
  const [error, setError] = useState('');
  const { i18n, t } = useTranslation(['common']);

  useEffect(() => {
    const validLanguages = ['fr', 'de', 'it'];
    if (!validLanguages.includes(i18n.language)) {
      i18n.changeLanguage('fr');
    }


    // Tester la connexion
    axios
      .get(`/clicAdmin/testconnexion`)
      .then((response) => {
        setError(''); // Clear previous error
      })
      .catch((error) => {
        console.error('Error:', error);
        if (error.response?.status === 404) {
          setError(t('error.not_found')); // Utiliser la traduction
        } else if (error.message.includes('Network Error')) {
          setError(t('error.network_error'));
        } else {
          setError(t('error.unknown_error'));
        }
      });
  }, [i18n.language]);

  const goBack = () => {
    window.history.back();
  };

  return (
    <AuthProvider>
      <Provider store={store}>
        <BrowserRouter>
          <PageViews />
          {error && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'rgba(0, 0, 0, 0.8)',
                color: 'white',
                padding: '20px',
                textAlign: 'center',
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  backgroundColor: '#222',
                  borderRadius: '8px',
                  padding: '20px',
                  maxWidth: '400px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                }}
              >
                <h2 style={{ margin: '0 0 15px', fontSize: '1.5rem' }}>
                  {t('error.title')}
                </h2>
                <p style={{ margin: '0 0 10px' }}>{error}</p>
                <a
                  href="mailto:help@planet-cards.com"
                  style={{
                    color: '#1e90ff',
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                  }}
                >
                  help@planet-cards.com
                </a>
                <button
                  onClick={goBack}
                  style={{
                    marginTop: '20px',
                    padding: '10px 15px',
                    fontSize: '1rem',
                    backgroundColor: colors.blue,
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  {t('error.go_back')}
                </button>
              </div>
            </div>
          )}
          <AppRoutes />
        </BrowserRouter>
      </Provider>
    </AuthProvider>
  );
}

const PageViews: React.FC = () => {
  usePageViews();
  return null;
};

export default App;
