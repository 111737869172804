import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactNode } from 'react';
import ImmutableText from '../../../cardElements/textElements/ImmutableText';
import { ImmutableMotif } from '../../../cardElements/motifElements/ImmutableMotif';
import {
  ElementType,
  MotifElementStyle,
} from '../../../../types/models/Elements';
import { AlbumPage } from '../../../../types/models/Album';
import { RootState } from '../../../../constants/initialStore';
import { albumFormats } from '../../../../constants/albumFormats';
import { ConvertMMToPixel } from '../../../../utils/convertCMToPixel';
import {
  AlbumTitle,
  PageContainer,
  SliceContainer,
  Title,
} from '../../../../pages/albums/AlbumPageStyles';
import ImmutablePhoto from '../../../cardElements/photoElements/ImmutablePhoto';
import { useTranslation } from 'react-i18next';
import TextElement from '../../../cardElements/textElements/TextElement';

const DEFAULT_SLICE_WIDTH_MMT = 20;
const PAGE_WIDTH_MMT = 0.4;

export const Slice = ({
  backgroundColor,
  backgroundTheme,
  name,
  numberOfPages,
  isGeneration
}: Pick<AlbumPage, 'id' | 'backgroundColor' | 'backgroundTheme'> & {
  children?: ReactNode;
  name: string;
  numberOfPages?: number;
  isGeneration?: boolean;
}) => {
  const format = useSelector(
    (state: RootState) => state.creation.present.album!.format,
  );
  const ratio = useSelector((state: RootState) => state.previewRatio!.value);
  const { height } = albumFormats[format];

  const displayWidth = !!numberOfPages
    ? ConvertMMToPixel(PAGE_WIDTH_MMT) * numberOfPages * ratio
    : ConvertMMToPixel(DEFAULT_SLICE_WIDTH_MMT) * ratio;
  const displayHeight = ConvertMMToPixel(height) * ratio;

  const element = useSelector((state: RootState) =>
    state.creation.present.elements.value.find(
      (elmt) => elmt.isForSlice === true,
    ),
  );

  return (
    <SliceContainer
      $width={displayWidth}
      $height={displayHeight}
      $backgroundColor={backgroundColor ?? ''}
      $backgroundTheme={backgroundTheme}
      $ratio={ratio}
      $isGeneration={isGeneration}
    >
      {element && element.content !== '' && (
        <SliceTextContainer $displayWidth={displayWidth}>
          <TextElement key={element.id} element={element} index={0} />
        </SliceTextContainer>
      )}
    </SliceContainer>
  );
};

const Cover = ({
  id,
  backgroundColor,
  backgroundTheme,
  title,
}: Pick<AlbumPage, 'id' | 'backgroundColor' | 'backgroundTheme'> & {
  children?: ReactNode;
  title: string;
}) => {
  const format = useSelector(
    (state: RootState) => state.creation.present.album!.format,
  );
  const ratio = useSelector((state: RootState) => state.previewRatio!.value);
  const { width, height } = albumFormats[format];
  const displayWidth = ConvertMMToPixel(width) * ratio;

  const pageElements = useSelector(
    (state: RootState) =>
      state.creation.present.elements.value.filter((el) => el.pageID === id),
    { equalityFn: shallowEqual },
  );

  return (
    <CoverContainer>
      <Title>{title}</Title>
      <PreviewPageContainer
        $width={displayWidth}
        $height={ConvertMMToPixel(height) * ratio}
        $backgroundColor={backgroundColor ?? ''}
        $backgroundTheme={backgroundTheme}
        $ratio={ratio}
        $displaycutzone={false}
      >
        <Watermark src={'/watermark.png'} />
        {pageElements.map((element) => {
          // if (element.noPrint) {
          //   return null;
          // }
          switch (element.type) {
            case ElementType.TEXT:
              return (
                <ImmutableText
                  key={element.id}
                  element={element}
                  ratio={ratio}
                />
              );
            case ElementType.PHOTO:
              return (
                <ImmutablePhoto
                  key={element.id}
                  src={element.content}
                  element={element}
                  elementstyle={
                    element.style && 'opacity' in element.style
                      ? element.style
                      : null
                  }
                  ratio={ratio}
                />
              );
            case ElementType.MOTIF:
              return (
                <ImmutableMotif
                  src={element.content}
                  key={element.id}
                  $element={element}
                  $elementstyle={element.style as MotifElementStyle}
                  $ratio={ratio}
                  $index={element.zIndex}
                />
              );
          }
        })}
      </PreviewPageContainer>
    </CoverContainer>
  );
};

const CoverPreview = ({
  cover,
  backCover,
}: {
  cover: AlbumPage;
  backCover: AlbumPage;
}) => {
  const name = useSelector(
    (state: RootState) => state.creation.present.album!.name,
  );
  const { t } = useTranslation(['common']);
  return (
    <AlbumPageContainer>
      <Cover {...backCover} title={t('album.backCover')}></Cover>
      <Slice {...cover} name={name ?? ''} />
      <Cover {...cover} title={t('album.cover')}></Cover>
    </AlbumPageContainer>
  );
};

const AlbumPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

const CoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const PreviewPageContainer = styled(PageContainer)`
  border: none;
`;

const Watermark = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 1000;
`;

const SliceTextContainer = styled.div<{ $displayWidth: number }>`
  transform: translateY(${(props) => props.$displayWidth}px) rotate(90deg);
  transform-origin: 100% 0%;
  & > div {
    & > div {
      align-content: center;
    }
  }
`;

export default CoverPreview;
