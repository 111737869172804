import { styled } from 'styled-components';

import useCreationType from '../../../hook/useCreationType';
import colors from '../../../constants/colors';
import CardPreview from './CardPreview';
import AlbumPreview from './albumPreview/AlbumPreview';
import CalendarPreview from './calendarPreview/CalendarPreview';
import OtherPreview from './OtherPreview';
import { ReactSVG } from 'react-svg';
import useLittleScreen from '../../../hook/useLittleScreen';

interface CreationPreviewProps {
  setShowCreationPreview: React.Dispatch<boolean>;
}

const CreationPreview: React.FC<CreationPreviewProps> = ({
  setShowCreationPreview,
}) => {
  const type = useCreationType();
  const littleScreen = useLittleScreen();

  return (
    <Container style={littleScreen ? { padding: '0px' } : {}}>
      <PreviewZone>
        <CloseButton onClick={() => setShowCreationPreview(false)}>
          <ReactSVG
            src="/svg/close.svg"
            width={'24px'}
            height={'24px'}
            wrapper="svg"
          />
        </CloseButton>
        {type === 'carte' ? (
          <CardPreview />
        ) : type === 'album' ? (
          <AlbumPreview />
        ) : type === 'calendrier' ? (
          <CalendarPreview />
        ) : (
          type === 'autre' && <OtherPreview />
        )}
      </PreviewZone>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  background-color: rgba(52, 64, 84, 0.7);
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 90px 135px;
`;

const PreviewZone = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  background-color: ${colors.gray100};
  padding: 72px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: white;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  cursor: pointer;
`;
export default CreationPreview;
