import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const panoramicVLayouts1Photo: LayoutElement[][] = [
  [
    {
      type: ElementType.PHOTO,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.8,
      height: 0.95,
      top: 0.025,
      left: 0.1,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.8,
      height: 0.85,
      top: 0.025,
      left: 0.1,
    },
    {
      type: ElementType.TEXT,
      width: 0.8,
      height: 0.05,
      top: 0.9,
      left: 0.1,
    },
  ],
];
