import { styled } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import {
  RootState,
  changePreviewRatio,
} from '../../../../constants/initialStore';
import AlbumPageDisplayerPreview from './PagePreview';
import { useEffect, useRef } from 'react';
import { albumFormats } from '../../../../constants/albumFormats';
import { ConvertMMToPixel } from '../../../../utils/convertCMToPixel';
import CoverPreview from './CoverPreview';
import colors from '../../../../constants/colors';

const AlbumPreview: React.FC = () => {
  const INITIAL_PADDING_X = 170;

  const album = useSelector((state: RootState) => state.creation.present.album);
  const format = album?.format;
  const covers = [album?.albumCover, album?.albumBackCover];
  const pages = album?.albumPages;
  const elRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!format) return;
    const { width, height } = albumFormats[format];

    const { clientHeight: zoneHeight, clientWidth: zoneWidth } = elRef.current!;

    let ratio = Math.min(
      (zoneWidth - 2 * INITIAL_PADDING_X) / ConvertMMToPixel(width),
      (zoneHeight - 260) / ConvertMMToPixel(height),
    );
    ratio = Math.max(ratio, 0.01);
    dispatch(changePreviewRatio(ratio));
  }, [format]);

  return (
    <MainContainer ref={elRef}>
      <Title>{album?.name}</Title>
      {covers && covers[0] !== undefined && covers[1] !== undefined && (
        <CoverPreview cover={covers[0]} backCover={covers[1]} />
      )}
      {pages && pages.length > 0 && (
        <AlbumPages>
          {[...pages]
            .sort((pageA, pageB) => pageA.position - pageB.position)
            .map((page, index) => {
              return <AlbumPageDisplayerPreview key={index} {...page} />;
            })}
        </AlbumPages>
      )}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 40px;
  overflow-y: scroll;

  scrollbar-color: #c1c1c1 white;

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
  }
`;

const Title = styled.div`
  font-family: Playfair Display;
  font-size: 24px;
  line-height: 24px;
  color: ${colors.black};
`;

const AlbumPages = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-columns: repeat(auto-fit, minmax(100px, fit-content));
  gap: 48px 24px;
  margin-bottom: 36px;
`;

export default AlbumPreview;
