import { useDrop } from 'react-dnd';
import { RootState, addElement } from '../constants/initialStore';
import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { ConvertMMToPixel } from '../utils/convertCMToPixel';
import useLittleScreen from './useLittleScreen';

const DEFAULT_MOTIF_SIZES = ConvertMMToPixel(100);
const DEFAULT_PHOTOS_SIZES = ConvertMMToPixel(100);

const useElementDrop = (elementValues: any) => {
  const dispatch = useDispatch();
  const ratio = useSelector((state: RootState) => state.ratio.value);
  const dropZoneRef = useRef<HTMLDivElement | null>(null);

  const littleScreen = useLittleScreen();

  const [, drop] = useDrop({
    accept: ['MotifItem', 'PhotoItem'],
    drop: (
      item:
        | {
            name: string;
            src: string;
            group: string;
            width: number;
            height: number;
            type: 'MOTIF';
            index: number;
          }
        | {
            src: string;
            id: string;
            type: 'PHOTO';
            index: number;
          },
      monitor,
    ) => {
      if (monitor.didDrop()) {
        return;
      }
      const dropElement = dropZoneRef.current; // Get the DOM node of the drop target
      const dropRect = dropElement?.getBoundingClientRect(); // Get the bounding rectangle of the drop target
      const clientOffset = monitor.getClientOffset(); // Get client offset of the dragged item

      if (dropRect && clientOffset) {
        const x = clientOffset.x - dropRect.left; // Calculate relative x coordinate
        const y = clientOffset.y - dropRect.top;
        if (item.type === 'MOTIF') {
          dispatch(
            addElement({
              element: {
                type: 'MOTIF',
                top: y / ratio,
                left: x / ratio,
                width: DEFAULT_MOTIF_SIZES,
                height: DEFAULT_MOTIF_SIZES,
                content: item.src,
                style: {
                  rotation: 0,
                },
                zIndex: item.index,
                ...elementValues,
                editable: true,
                contentEditable: true,
              },
            }),
          );
        } else if (item.type === 'PHOTO') {
          dispatch(
            addElement({
              element: {
                type: 'PHOTO',
                top: y / ratio,
                left: x / ratio,
                width: DEFAULT_PHOTOS_SIZES,
                height: DEFAULT_PHOTOS_SIZES,
                content: item.src,
                ...elementValues,
                style: {
                  brightness: 1,
                  saturation: 100,
                  opacity: 1,
                  zoom: 100,
                  rotation: 0,
                  grayscale: false,
                  sepia: false,
                  mirrored: false,
                  shadow: false,
                  shape: 'rectangle',
                  translateX: 0,
                  translateY: 0,
                  rotationFrame: 0,
                },
                zIndex: item.index,
                editable: true,
                contentEditable: true,
              },
            }),
          );
        }
      }
    },
  });

  return mergeRefs([dropZoneRef, drop]);
};

export default useElementDrop;
