import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const portraitLayouts5MorePhoto: LayoutElement[][] = [
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.25,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.25,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.8,
      height: 0.8,
      top: 0.1,
      left: 0.1,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.25,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.25,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.BLOCK,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'column',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 1,
                  height: 0.5,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 1,
                  height: 0.5,
                  top: 0,
                  left: 0,
                },
              ],
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.BLOCK,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'column',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 1,
                  height: 0.5,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 1,
                  height: 0.5,
                  top: 0,
                  left: 0,
                },
              ],
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.8,
      height: 0.8,
      top: 0.1,
      left: 0.1,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.BLOCK,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'column',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 1,
                  height: 0.5,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 1,
                  height: 0.5,
                  top: 0,
                  left: 0,
                },
              ],
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.BLOCK,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'column',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 1,
                  height: 0.5,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 1,
                  height: 0.5,
                  top: 0,
                  left: 0,
                },
              ],
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.66,
      height: 0.76,
      top: 0.12,
      left: 0.17,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 0.48,
      top: 0.28,
      left: 0,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.72,
      height: 0.08,
      top: 0.83,
      left: 0.14,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.82,
      height: 0.61,
      top: 0.19,
      left: 0.09,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.72,
      height: 0.08,
      top: 0.84,
      left: 0.14,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.8,
      height: 0.7,
      top: 0.15,
      left: 0.1,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.35,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.35,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.82,
      height: 0.61,
      top: 0.19,
      left: 0.09,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.72,
      height: 0.08,
      top: 0.84,
      left: 0.14,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.78,
      height: 0.64,
      top: 0.16,
      left: 0.11,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.72,
      height: 0.08,
      top: 0.84,
      left: 0.14,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.8,
      height: 0.7,
      top: 0.15,
      left: 0.1,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.35,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.4,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.6,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.35,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.6,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.4,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.35,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.4,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.6,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.82,
      height: 0.82,
      top: 0.09,
      left: 0.09,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.25,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.25,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.25,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.25,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
];
