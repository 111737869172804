import dayjs from 'dayjs';
import {
  changeCalendar,
  modifyElement,
  store,
} from '../../constants/initialStore';
import { Calendar } from '../../types/models/Calendar';
import { ElementType } from '../../types/models/Elements';

export const onMonthStartChange = (value: string) => {
  const startOfYear = dayjs.utc().startOf('year').add(1, 'year');

  const monthStartDayjs = value ? dayjs.utc(value) : startOfYear;

  const storeState = store.getState();

  // Update cover year text
  const firstMonth = dayjs.utc(
    storeState.creation.present.calendar!.monthStart,
  );
  const lastMonth = dayjs.utc(
    storeState.creation.present.calendar!.calendarMonths[12].month,
  );

  const isPreviousOneYear = firstMonth.year() === lastMonth.year();
  const previousYearString = `${firstMonth.format('YYYY')}${
    isPreviousOneYear ? '' : ` - ${lastMonth.format('YYYY')}`
  }`;

  const isNewOneYear =
    dayjs.utc(monthStartDayjs).year() ===
    dayjs.utc(monthStartDayjs.add(1, 'year')).year();

  const newYearString = `${monthStartDayjs.format('YYYY')}${
    isNewOneYear ? '' : ` - ${monthStartDayjs.add(1, 'year').format('YYYY')}`
  }`;

  const elementTextYearId = storeState.creation.present.elements.value.find(
    (e) =>
      e.content === previousYearString &&
      e.type === ElementType.TEXT &&
      e.calendarMonthID ===
        storeState.creation.present.calendar!.calendarMonths[0].id,
  )?.id;

  if (elementTextYearId) {
    store.dispatch(
      modifyElement({
        id: elementTextYearId,
        modification: { content: newYearString },
      }),
    );
  }

  // Update months
  store.dispatch(
    changeCalendar((prev) => ({
      ...(prev as Calendar),
      monthStart: monthStartDayjs.toISOString(),
      calendarMonths: (prev as Calendar).calendarMonths.map((month, index) => ({
        ...month,
        month: monthStartDayjs.add(index - 1, 'months').toISOString(),
      })),
    })),
  );
};
