import { styled } from 'styled-components';
import { ReactSVG } from 'react-svg';
import { useEffect, useState } from 'react';
import Button, { buttonType } from '../../general/Button';
import colors from '../../../constants/colors';
import { useTranslation } from 'react-i18next';
import styleConstants from '../../../constants/styleConstants';
import { createPortal } from 'react-dom';

interface ModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleValidate: (value: string) => void;
}

const CreateFolderModal: React.FC<ModalProps> = ({
  visible,
  setVisible,
  handleValidate,
}) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue('');
  }, [visible]);

  const { t } = useTranslation(['common']);

  return createPortal(
    <Container $show={visible}>
      <FormTopContainer>
        <ReactSVG src="/svg/rename_folder_icon.svg" />
        <CloseIcon src="/svg/cross.svg" onClick={() => setVisible(false)} />
      </FormTopContainer>
      <FormContainer>
        <Title>{t('photos.createFolder')}</Title>
        <Text>{t('photos.folderName')}</Text>
        <TextInput
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
        />
        <ButtonContainer>
          <Button $type={buttonType.white} onClick={() => setVisible(false)}>
            {t('general.undo')}
          </Button>
          <Button
            $type={buttonType.black}
            onClick={() => handleValidate(value)}
          >
            {t('photos.validate')}
          </Button>
        </ButtonContainer>
      </FormContainer>
    </Container>,
    document.body,
  );
};

const Container = styled.div<{ $show: boolean }>`
  width: 100%;
  height: 100%;

  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  background-color: rgba(52, 64, 84, 0.7);
`;

const FormTopContainer = styled.div`
  width: 400px;
  height: 84px;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 0 0 18px;

  box-sizing: border-box;

  position: relative;

  background-color: ${colors.white};
  color: ${colors.black};

  border-radius: 8px 8px 0 0;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const FormContainer = styled.div`
  width: 400px;

  display: flex;
  flex-direction: column;

  background-color: ${colors.white};
  color: ${colors.black};

  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  padding: 24px;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const Title = styled.div`
  font-family: 'DM Sans';
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;

  color: ${colors.gray900};
`;

const CloseIcon = styled(ReactSVG)`
  position: absolute;

  top: 24px;
  right: 24px;

  cursor: pointer;

  @media (max-width: ${styleConstants.breakpointLg}) {
    top: 12px;
    right: 12px;
  }
`;

const TextInput = styled.input`
  width: 100%;
  padding: 12px 14px;
  border: solid 1px ${colors.gray300};
  border-radius: 8px;

  box-sizing: border-box;

  margin: 0 0 20px;

  font-family: 'DM Sans';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-around;
`;

const Text = styled.div`
  display: flex;
  margin: 20px 0 0;

  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`;

export default CreateFolderModal;
