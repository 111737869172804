import { useDispatch, useSelector } from 'react-redux';
import { RootState, activatePopup } from '../constants/initialStore';
import { LoginState, useAuth } from './useAuth';
import { getCardOwnership, saveCard } from '../api/cards';
import { useEffect, useRef } from 'react';
import useCreationType from './useCreationType';

export const useAutoSaveCard = () => {
  const dispatch = useDispatch();
  const { userInfo, asklogin, isReady } = useAuth();
  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );
  const cardElements = useSelector(
    (state: RootState) => state.creation.present.elements.value,
  );
  const saveTimeoutRef = useRef<any | null>(null);

  const type = useCreationType();

  const save = async () => {
    if (type !== 'carte') {
      return;
    }
    // if (userInfo?.state !== LoginState.LOGGED_IN) {
    //   const payload = {
    //     id: card.id,
    //     format: card.format,
    //     paperId: card.paperId,
    //     stringId: card.stringId,
    //     cutId: card.cutId,
    //     quantityAsked: card.quantityAsked,
    //     enveloppeOption: card.enveloppeOption,
    //     faces: card.facesSizes.map((face, index) => {
    //       return {
    //         id: face.id,
    //         locked: face.locked,
    //         backgroundColor: face.bgColor,
    //         backgroundTheme: face.bgTheme,
    //         elements: cardElements
    //           .filter((element) => element.face === index)
    //           .map((element) => {
    //             return {
    //               ...element,
    //               style: element.style ? JSON.stringify(element.style) : '',
    //               face: undefined,
    //             };
    //           }),
    //       };
    //     }),
    //   };
    //   await saveCardPublic(payload);
    //   dispatch(activatePopup());
    //   saveTimeoutRef.current = null;
    // } else {
    const payload = {
      id: card.id,
      format: card.format,
      paperId: card.paperId,
      stringId: card.stringId,
      cutId: card.cutId,
      quantityAsked: card.quantityAsked,
      enveloppeOption: card.enveloppeOption,
      faces: card.facesSizes.map((face, index) => {
        return {
          id: face.id,
          locked: face.locked,
          backgroundColor: face.bgColor,
          backgroundTheme: face.bgTheme,
          elements: cardElements
            .filter((element) => element.face === index)
            .map((element) => {
              return {
                ...element,
                style: element.style ? JSON.stringify(element.style) : '',
                face: undefined,
              };
            }),
        };
      }),
    };
    await saveCard(payload);
    dispatch(activatePopup());
    saveTimeoutRef.current = null;
    // }
  };

  useEffect(() => {
    if (!isReady) return;
    if (userInfo?.state === LoginState.LOGGED_IN && userInfo.isAdmin) return;
    if (saveTimeoutRef.current !== null) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(
      save,
      userInfo?.state === LoginState.LOGGED_IN ? 5000 : 5000,
    );
  }, [
    cardElements,
    card.facesSizes,
    userInfo?.state,
    card.cutId,
    card.paperId,
    card.stringId,
  ]);

  useEffect(() => {
    const getOwnership = async () => {
      if (userInfo?.state === LoginState.LOGGED_IN && card.id) {
        await getCardOwnership(card.id);
      }
    };
    getOwnership();
  }, [userInfo?.state, card.ownerID, card.id]);

  return save;
};
