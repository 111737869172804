import NumberChooser from './NumberChooser';
import ButtonText from './ButtonText';
import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface ManagePixeProps {
  pixelValue: number;
  setPixelValue: React.Dispatch<React.SetStateAction<number>>;
  maxSize: number;
  sizeElement: number;
  translateKey: string;
}
const ManagePixel = ({
  translateKey,
  pixelValue,
  setPixelValue,
  maxSize,
  sizeElement,
}: ManagePixeProps) => {
  return (
    <>
      <RowTextButton>
        <NumberChooser
          title={translateKey}
          number={pixelValue}
          setNumber={setPixelValue}
          step={1}
        />
      </RowTextButton>
      <RowTextButton>
        <ButtonText
          title={'['}
          disabled={focus === null}
          onClick={() => setPixelValue(0)}
        />
        <ButtonText
          title={'1/4'}
          disabled={focus === null}
          onClick={() => setPixelValue((maxSize - sizeElement) / 4)}
        />
        <ButtonText
          title={'1/2'}
          disabled={focus === null}
          onClick={() => setPixelValue((maxSize - sizeElement) / 2)}
        />
        <ButtonText
          title={'3/4'}
          disabled={focus === null}
          onClick={() => setPixelValue((3 * (maxSize - sizeElement)) / 4)}
        />
        <ButtonText
          title={']'}
          disabled={focus === null}
          onClick={() => setPixelValue(maxSize - sizeElement)}
        />
      </RowTextButton>
    </>
  );
};

export default ManagePixel;

const RowTextButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: flex-end;
`;
