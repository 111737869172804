import React from 'react';
import { createPortal } from 'react-dom';
import { CloseButton } from '../functionnalities/MenuStyles';
import { useDispatch } from 'react-redux';
import { closeMenu } from '../../constants/initialStore';
import { ReactSVG } from 'react-svg';
import useLittleScreen from '../../hook/useLittleScreen';

interface CloseMenuButton {
  handleValidate: () => void;
}

const ValidateMenuButton: React.FC<CloseMenuButton> = ({ handleValidate }) => {
  const littleScreen = useLittleScreen();

  return littleScreen ? (
    <CloseButton onClick={handleValidate} $isSmall={littleScreen}>
      <ReactSVG src="/svg/check_new.svg" width={'24px'} height={'24px'} />
    </CloseButton>
  ) : (
    createPortal(
      <CloseButton onClick={handleValidate} $isSmall={littleScreen}>
        <ReactSVG src="/svg/check_new.svg" width={'24px'} height={'24px'} />
      </CloseButton>,
      document.body,
    )
  );
};

export default ValidateMenuButton;
