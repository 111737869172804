import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const portraitLayouts1Photo: LayoutElement[][] = [
  [
    {
      type: ElementType.PHOTO,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.725,
      height: 0.8,
      top: 0.1,
      left: 0.1375,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 1,
      height: 0.6,
      top: 0.2,
      left: 0,
    },
    {
      type: ElementType.TEXT,
      width: 0.8,
      height: 0.05,
      top: 0.85,
      left: 0.1,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 1,
      height: 0.8,
      top: 0,
      left: 0,
    },
    {
      type: ElementType.TEXT,
      width: 0.8,
      height: 0.05,
      top: 0.85,
      left: 0.1,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.8,
      height: 0.6,
      top: 0.2,
      left: 0.1,
    },
    {
      type: ElementType.TEXT,
      width: 0.8,
      height: 0.05,
      top: 0.85,
      left: 0.1,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.6,
      height: 0.7,
      top: 0.12,
      left: 0.2,
    },
    {
      type: ElementType.TEXT,
      width: 0.8,
      height: 0.05,
      top: 0.85,
      left: 0.1,
    },
  ],
  [
    {
      type: ElementType.PHOTO, // TO-DO rotation du cardre.
      width: 0.6,
      height: 0.6,
      top: 0.3,
      left: 0.2,
      style: {
        shape: 'diamond',
        rotation: -45,
        rotationFrame: 45,
      },
    },
    {
      type: ElementType.TEXT,
      width: 0.8,
      height: 0.05,
      top: 0.85,
      left: 0.1,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.8,
      height: 0.4,
      top: 0.3,
      left: 0.1,
    },
    {
      type: ElementType.TEXT,
      width: 0.8,
      height: 0.05,
      top: 0.75,
      left: 0.1,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.6,
      height: 0.4,
      top: 0.3,
      left: 0.2,
    },
    {
      type: ElementType.TEXT,
      width: 0.8,
      height: 0.05,
      top: 0.75,
      left: 0.1,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.8,
      height: 0.8,
      top: 0.24,
      left: 0.1,
      style: {
        shape: 'circle',
        rotation: 0,
        rotationFrame: 0,
      },
    },
    {
      type: ElementType.TEXT,
      width: 0.8,
      height: 0.05,
      top: 0.85,
      left: 0.1,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.7,
      height: 0.56,
      top: 0.26,
      left: 0.15,
      style: {
        shape: 'arch',
        rotation: 0,
        rotationFrame: 0,
      },
    },
    {
      type: ElementType.TEXT,
      width: 0.8,
      height: 0.05,
      top: 0.83,
      left: 0.1,
    },
  ],
];
