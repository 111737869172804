import React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { ReactSVG } from 'react-svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../constants/initialStore';
import { otherFormats } from '../../constants/otherFormats';
import {
  ConvertMMToPixel,
  ConvertPixelToMM,
} from '../../utils/convertCMToPixel';

interface PhotoQualityIndicatorProps {
  isgoodquality: boolean;
}

const PhotoQualityIndicator: React.FC<PhotoQualityIndicatorProps> = ({
  isgoodquality,
}) => {
  const zoom = useSelector((state: RootState) => state.zoom.value);
  const format = useSelector((state: RootState) =>
    state.creation.present.other
      ? state.creation.present.other.format
      : undefined,
  );
  const elementFocused = useSelector(
    (state: RootState) =>
      state.creation.present.elements.value.find(
        (elmt) => elmt.id === state.focus.value,
      ) ?? undefined,
  );

  return (
    <Indicator
      $isgoodquality={isgoodquality ? true : undefined}
      $zoom={zoom}
      $isBottomToile={
        format !== undefined &&
        elementFocused !== undefined &&
        format.includes('TOI') &&
        elementFocused.height + elementFocused.top >
          ConvertMMToPixel(otherFormats[format].effectiveHeight ?? 999999)
      }
      $isLeftToile={
        format !== undefined &&
        elementFocused !== undefined &&
        format.includes('TOI') &&
        elementFocused.left <
          ConvertMMToPixel(
            (otherFormats[format].width -
              (otherFormats[format].effectiveWidth ?? 0)) /
              2 ?? 999999,
          )
      }
    >
      <Badge
        src={isgoodquality ? '/svg/badge_happy.svg' : '/svg/badge_sad.svg'}
      />
      <Text>
        {isgoodquality
          ? ' Photo de bonne qualité'
          : ' Photo de mauvaise qualité'}
      </Text>
    </Indicator>
  );
};

const Badge = styled(ReactSVG)`
  width: 24px !important;
  height: 24px;
`;

const Text = styled.div`
  max-width: 0;
  overflow: hidden;
  transition: max-width 1s ease-out;
  white-space: nowrap;
`;

const Indicator = styled.div<{
  $isgoodquality?: boolean;
  $zoom: number;
  $isBottomToile?: boolean;
  $isLeftToile?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 32px;
  position: absolute;
  bottom: ${(props) => (props.$isBottomToile ? '30px' : '6px')};
  left: ${(props) => (props.$isLeftToile ? '30px' : '6px')};
  transform: scale(${({ $zoom }) => Math.min(1 / $zoom, 1)});
  transform-origin: bottom;
  border-radius: 16px;
  padding: 4px;
  background-color: ${({ $isgoodquality }) =>
    $isgoodquality ? colors.success50 : colors.error50};
  border: 2px solid
    ${({ $isgoodquality }) =>
      $isgoodquality ? colors.success200 : colors.error200};
  color: ${({ $isgoodquality }) =>
    $isgoodquality ? colors.success700 : colors.error700};
  box-sizing: border-box;

  z-index: 200;
  cursor: pointer;

  &:hover ${Text} {
    max-width: 240px;
  }
`;

export default PhotoQualityIndicator;
