import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const panoramicVLayouts2Photos: LayoutElement[][] = [
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.8,
      height: 0.94,
      top: 0.03,
      left: 0.1,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.8,
      height: 0.82,
      top: 0.03,
      left: 0.1,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.8,
      height: 0.05,
      top: 0.9,
      left: 0.1,
    },
  ],
];
