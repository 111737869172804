import { styled } from 'styled-components';
import colors from '../../constants/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  addElement,
  closeMenu,
  deleteElement,
  modifyElement,
} from '../../constants/initialStore';
import React, { useEffect, useState } from 'react';
import Button, { buttonType } from '../general/Button';
import IconButton from '../general/IconButton';
import ReactSelect from 'react-select';
import { ReactSVG } from 'react-svg';
import { ElementType } from '../../types/models/Elements';
import NumberChooser from '../general/NumberChooser';
import RemoveElementModal from './modals/RemoveElementModal';
import ColorPicker from '../cardElements/ColorPicker';
import ColorItem from '../cardElements/ColorItem';
import { availableColors } from '../../constants/availableColors';
import {
  Font,
  availableFonts,
  fontsGroups,
} from '../../constants/availableFonts';
import useCreationType from '../../hook/useCreationType';
import { ButtonLine, InlinedButton, VLine } from '../general/ButtonLine';
import { useTranslation } from 'react-i18next';
import { CloseButton, TitleContainer, TitleItem } from './MenuStyles';
import RangeSlider from './Slider';
import { LoginState, useAuth } from '../../hook/useAuth';
import ManagePixel from '../general/ManagePixel';
import {
  ConvertMMToPixel,
  ConvertPt300DPIToPixel,
} from '../../utils/convertCMToPixel';
import ManageElementSize from '../general/ManageElementSize';
import CloseMenuButton from '../layout/CloseMenuButton';
import useLittleScreen from '../../hook/useLittleScreen';

const mapTransformToCasse = ['none', 'uppercase', 'lowercase', 'capitalize'];

const mapAlignement = ['left', 'center', 'right', 'justify'];

const TextMenu: React.FC = () => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const [initialTop, setInitialTop] = useState<number>(100);
  const [initialLeft, setInitialLeft] = useState<number>(100);
  const [activeCasse, setActiveCasse] = useState<number>(0);
  const [activeAlignement, setActiveAlignement] = useState<number>(0);
  const [isBoldActive, setIsBoldActive] = useState<boolean>(false);
  const [isItalicActive, setIsItalicActive] = useState<boolean>(false);
  const [isUnderLineActive, setIsUnderLineActive] = useState<boolean>(false);
  const [selectedFont, setSelectedFont] = useState<string>('DM Sans');
  const [fontSize, setFontSize] = useState<number>(14);
  const [interLine, setInterLine] = useState<number>(0);
  const [letterSpace, setLetterSpace] = useState<number>(1);
  const [isSupExpoActive, setIsSupExpoActive] = useState<boolean>(false);
  const [colorChosen, setColorChosen] = useState<{
    color: string;
    name: string;
  }>(availableColors[0]);
  const [rotationValue, setRotationValue] = useState<number>(0);
  const [pixelHorizontalPerfect, setPixelHorizontalPerfect] =
    useState<number>(0);
  const [pixelVerticalPerfect, setPixelVerticalPerfect] = useState<number>(0);
  const [planValue, setPlanValue] = useState<number>(0);
  const [removeModalVisible, setRemoveModalVisible] = useState<boolean>(false);
  const [colorPickerOpen, setColorPickerOpen] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const [isLabel, setIsLabel] = useState<boolean>(false);

  const [font, setFont] = useState<Font | undefined>(availableFonts[0]);
  const [fontValue, setFontValue] = useState<
    | {
        value: string;
        label: string;
      }
    | undefined
  >({ value: `'${availableFonts[0].name}'`, label: availableFonts[0].name });

  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );

  const currentFace = useSelector((state: RootState) => state.face.value);

  const [maxWidth, setMaxWidth] = useState<number>(0);
  const [maxHeight, setMaxHeight] = useState<number>(0);

  const littleScreen = useLittleScreen();

  const lockUnlockElement = async () => {
    if (elementFocused) {
      dispatch(
        modifyElement({
          id: elementFocused.id,
          element: {
            ...elementFocused,
            editable: !elementFocused.editable,
          },
        }),
      );
    }
  };

  const lockUnlockElementContent = async () => {
    if (elementFocused) {
      dispatch(
        modifyElement({
          id: elementFocused.id,
          element: {
            ...elementFocused,
            contentEditable: !elementFocused.contentEditable,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (card) {
      setMaxWidth(
        parseInt(
          ConvertMMToPixel(card.facesSizes[currentFace].width).toFixed(0),
        ),
      );
      setMaxHeight(
        parseInt(
          ConvertMMToPixel(card.facesSizes[currentFace].height).toFixed(0),
        ),
      );
    }
    if (
      card.facesSizes[currentFace].width ===
        card.facesSizes[currentFace].height &&
      (card.facesSizes[currentFace].width === 43 ||
        card.facesSizes[currentFace].width === 40)
    ) {
      setIsLabel(true);
    }
  }, [card, currentFace]);

  const focus = useSelector((state: RootState) => state.focus.value);
  const elementFocused = useSelector((state: RootState) =>
    state.creation.present.elements.value.find(
      ({ id, type }: { id?: string; type: ElementType }) =>
        id === focus && type === ElementType.TEXT,
    ),
  );
  const elementMaxZIndex = useSelector(
    (state: RootState) => state.creation.present.elements.value.length,
  );
  const type = useCreationType();

  const dispatch = useDispatch();

  const { t } = useTranslation(['common']);

  const DeleteText = () => {
    dispatch(deleteElement({ id: focus }));
    setRemoveModalVisible(false);
  };

  const AddText = () => {
    dispatch(
      addElement({
        element: {
          type: 'TEXT',
          top: maxHeight / 2 - (isLabel ? 118 : 150), // centered
          left: maxWidth / 2 - (isLabel ? 86 : 350), // centered
          width: isLabel ? 172 : 700,
          height: isLabel ? 236 : 300,
          face: type === 'carte' ? currentFace : undefined,
          calendarMonthID: type === 'calendrier' ? focus : undefined,
          pageID: type === 'album' ? focus : undefined,
          content: '',
          zIndex: elementMaxZIndex,
          style: {
            bold: isBoldActive,
            italic: isItalicActive,
            underlined: isUnderLineActive,
            transform: mapTransformToCasse[activeCasse],
            alignement: mapAlignement[activeAlignement],
            font: selectedFont,
            size: fontSize,
            letterSpacing: letterSpace,
            interline: interLine,
            color: colorChosen.color,
            rotation: rotationValue,
          },
          editable: true,
          contentEditable: true,
        },
      }),
    );
    setInitialTop((prevValue) => (prevValue >= 200 ? 100 : prevValue + 20));
    setInitialLeft((prevValue) => prevValue + 20);
  };

  const DuplicateText = () => {
    if (elementFocused) {
      const elementToDuplicate = {
        ...elementFocused,
        top: elementFocused.top + 20,
        left: elementFocused.left + 20,
      };
      delete elementToDuplicate.id;
      dispatch(addElement({ element: elementToDuplicate }));
    }
  };

  const handleOpenColorPicker = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setColorPickerOpen(!colorPickerOpen);
  };

  const changeColor = (color: { name: string; color: string }) => {
    setColorChosen(color);
    setColorPickerOpen(false);
  };

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const user = useAuth();

  useEffect(() => {
    if (
      user.userInfo?.state === LoginState.LOGGED_IN &&
      user.userInfo.isAdmin
    ) {
      setIsAdmin(true);
    }
  }, [user]);

  useEffect(() => {
    if (elementFocused) {
      setWidth(parseInt(String(elementFocused.width)));
      setHeight(parseInt(String(elementFocused.height)));
      setPlanValue(elementFocused.zIndex || 0);
      if ('bold' in elementFocused.style) {
        setIsBoldActive(elementFocused.style.bold);
        setIsItalicActive(elementFocused.style.italic);
        setIsUnderLineActive(elementFocused.style.underlined);
        setActiveAlignement(
          mapAlignement.findIndex((alignement) =>
            'alignement' in elementFocused.style
              ? alignement === elementFocused.style.alignement
              : false,
          ),
        );
        setActiveCasse(
          mapTransformToCasse.findIndex((casse) =>
            'transform' in elementFocused.style
              ? casse === elementFocused.style.transform
              : false,
          ),
        );
        setSelectedFont(elementFocused.style.font);
        setInterLine(elementFocused.style.interline as number);
        setFontSize(elementFocused.style.size as number);
        setLetterSpace(elementFocused.style.letterSpacing as number);
        setColorChosen(
          availableColors.find((color) =>
            'transform' in elementFocused.style
              ? color.color === elementFocused.style.color.toLowerCase()
              : color.color === '',
          ) ?? availableColors[0],
        );
        setRotationValue(elementFocused.style.rotation as number);
        setIsReady(true);
        setPixelHorizontalPerfect(parseInt(String(elementFocused.left)));
        setPixelVerticalPerfect(parseInt(String(elementFocused.top)));
      }
    } else {
      setIsBoldActive(false);
      setIsItalicActive(false);
      setIsUnderLineActive(false);
      setActiveAlignement(0);
      setActiveCasse(0);
      setSelectedFont('DM Sans');
      setInterLine(0);
      setFontSize(14);
      setLetterSpace(0);
      setColorChosen(availableColors[0]);
      setRotationValue(0);
      setIsReady(true);
    }
  }, [focus, elementFocused]);

  useEffect(() => {
    if (elementFocused && isReady) {
      dispatch(
        modifyElement({
          id: elementFocused.id,
          element: {
            ...elementFocused,
            width,
            height,
            content: document.getElementById('textInput-' + elementFocused?.id)!
              .innerHTML,
            top: pixelVerticalPerfect,
            left: pixelHorizontalPerfect,
            zIndex: planValue,
            style: {
              bold: isBoldActive,
              italic: isItalicActive,
              underlined: isUnderLineActive,
              transform: mapTransformToCasse[activeCasse],
              alignement: mapAlignement[activeAlignement],
              font: selectedFont,
              size: fontSize,
              letterSpacing: letterSpace,
              interline: interLine,
              color: colorChosen.color,
              rotation: rotationValue,
            },
          },
        }),
      );
    }
  }, [
    isReady,
    isBoldActive,
    isItalicActive,
    isUnderLineActive,
    activeCasse,
    activeAlignement,
    selectedFont,
    fontSize,
    letterSpace,
    interLine,
    colorChosen,
    rotationValue,
    pixelHorizontalPerfect,
    pixelVerticalPerfect,
    width,
    height,
    planValue,
    isSupExpoActive,
  ]);

  useEffect(() => {
    const chosenFont = availableFonts.find(
      ({ name }) => `'${name}'` === selectedFont,
    );
    setFont(chosenFont);
    setFontValue(
      chosenFont
        ? {
            value: `'${chosenFont.name}'`,
            label: chosenFont.name,
          }
        : undefined,
    );
  }, [selectedFont]);

  const onSetInterline = (value: any) => setInterLine(value);

  if (elementFocused && !elementFocused.editable && !isAdmin) {
    return (
      <Container>
        <CloseMenuButton />
        <Item>
          <SubTitle>
            {"L'élément a été verrouillé par l'administrateur"}
          </SubTitle>
        </Item>
      </Container>
    );
  }

  const handleSupExpo = () => {
    const selection = window.getSelection();
    const selectedText = selection?.toString();

    if (selectedText && selectedText.length > 0 && selection) {
      const range = selection.getRangeAt(0);
      const containerElement = range.commonAncestorContainer.parentElement;

      if (containerElement && containerElement.tagName === 'SUP') {
        const docFrag = document.createDocumentFragment();
        while (containerElement.firstChild) {
          const child = containerElement.removeChild(
            containerElement.firstChild,
          );
          docFrag.appendChild(child);
        }

        if (containerElement.parentNode) {
          containerElement.parentNode.replaceChild(docFrag, containerElement);
          setIsSupExpoActive(false);
        }
      } else {
        const sup = document.createElement('sup');
        range.surroundContents(sup);
        setIsSupExpoActive(true);
      }

      selection.removeAllRanges();
    }
  };

  return (
    <Container>
      <RemoveElementModal
        visible={removeModalVisible}
        setVisible={setRemoveModalVisible}
        handleValidate={DeleteText}
      />
      <CloseMenuButton />

      <Button $type={buttonType.black} onClick={AddText}>
        {t('text.addText')}
      </Button>
      <Item>
        <SubTitle>{t('text.font')}</SubTitle>
        <ReactSelect
          options={fontsGroups}
          // formatGroupLabel={formatGroupLabel}
          isSearchable={!littleScreen}
          styles={{
            option: (styles, { data, isSelected, isFocused }) => {
              return {
                ...styles,
                fontFamily: data.value,
                color: colors.black,
                backgroundColor: isSelected
                  ? colors.gray300
                  : isFocused
                  ? colors.gray100
                  : colors.white,
              };
            },
            singleValue: (styles, { data }) => {
              return { ...styles, fontFamily: data.value, color: colors.black };
            },
            groupHeading: (base, props) => {
              return {
                ...base,
                backgroundColor: colors.gray400,
                textDecoration: 'underline',
                color: colors.white,
                padding: '6px',
              };
            },
          }}
          value={fontValue}
          onChange={(newValue) => {
            if (newValue) {
              setSelectedFont(newValue.value);
            } else {
              setSelectedFont('');
            }
          }}
        />
      </Item>

      <HorizontalItem>
        <SubTitle>{t('text.textColor')}</SubTitle>
        <SmallContainer onClick={handleOpenColorPicker}>
          <ColorItem color={colorChosen} />
          <IconSVG src="/svg/right_chevron.svg" />
        </SmallContainer>
        {colorPickerOpen && (
          <ColorPicker
            visible={colorPickerOpen}
            setVisible={setColorPickerOpen}
            update={changeColor}
            chosenColor={colorChosen}
          />
        )}
      </HorizontalItem>

      <Item>
        <SubTitle>{t('text.style')}</SubTitle>
        <IconButtonLine>
          <IconButton
            title={t('text.bold')}
            src={isBoldActive ? '/svg/bold_white.svg' : '/svg/bold_black.svg'}
            disabled={font && font.bold === false}
            active={isBoldActive}
            onClick={() => setIsBoldActive(!isBoldActive)}
          />
          <IconButton
            title={t('text.italic')}
            src={
              isItalicActive ? '/svg/italic_white.svg' : '/svg/italic_black.svg'
            }
            disabled={font && font.italic === false}
            active={isItalicActive}
            onClick={() => setIsItalicActive(!isItalicActive)}
          />
          <IconButton
            title={t('text.underline')}
            src={
              isUnderLineActive
                ? '/svg/underline_white.svg'
                : '/svg/underline_black.svg'
            }
            disabled={false}
            active={isUnderLineActive}
            onClick={() => setIsUnderLineActive(!isUnderLineActive)}
          />
          <IconButton
            title={t('text.exposant')}
            src={'/svg/exposant.svg'}
            disabled={false}
            onClick={() => handleSupExpo()}
          />
        </IconButtonLine>
        <ButtonLine>
          <InlinedButton
            $active={activeCasse === 0}
            onClick={() => setActiveCasse(0)}
          >
            <ReactSVG
              src={
                activeCasse === 0
                  ? '/svg/minus_white.svg'
                  : '/svg/minus_black.svg'
              }
            />
          </InlinedButton>
          <VLine />
          <InlinedButton
            $active={activeCasse === 1}
            onClick={() => setActiveCasse(1)}
          >
            AG
          </InlinedButton>
          <VLine />
          <InlinedButton
            $active={activeCasse === 2}
            onClick={() => setActiveCasse(2)}
          >
            ag
          </InlinedButton>
          <VLine />
          <InlinedButton
            $active={activeCasse === 3}
            onClick={() => setActiveCasse(3)}
          >
            Ag
          </InlinedButton>
        </ButtonLine>
      </Item>

      <Row>
        <NumberChooser
          title={t('text.size')}
          number={fontSize}
          setNumber={setFontSize}
          step={1}
        />
        <NumberChooser
          title={t('text.letterSpacing')}
          number={letterSpace}
          setNumber={setLetterSpace}
          step={0.5}
        />
        <NumberChooser
          title={t('text.lineHeight')}
          number={interLine}
          setNumber={onSetInterline}
          step={0.5}
        />
      </Row>

      <Item>
        <SubTitle>{t('text.alignment')}</SubTitle>
        <ButtonLine>
          <InlinedButton
            $active={activeAlignement === 0}
            onClick={() => setActiveAlignement(0)}
          >
            <ReactSVG
              src={
                activeAlignement === 0
                  ? '/svg/align_left_white.svg'
                  : '/svg/align_left_black.svg'
              }
            />
          </InlinedButton>
          <VLine />
          <InlinedButton
            $active={activeAlignement === 1}
            onClick={() => setActiveAlignement(1)}
          >
            <ReactSVG
              src={
                activeAlignement === 1
                  ? '/svg/align_center_white.svg'
                  : '/svg/align_center_black.svg'
              }
            />
          </InlinedButton>
          <VLine />
          <InlinedButton
            $active={activeAlignement === 2}
            onClick={() => setActiveAlignement(2)}
          >
            <ReactSVG
              src={
                activeAlignement === 2
                  ? '/svg/align_right_white.svg'
                  : '/svg/align_right_black.svg'
              }
            />
          </InlinedButton>
          <VLine />
          <InlinedButton
            $active={activeAlignement === 3}
            onClick={() => setActiveAlignement(3)}
          >
            <ReactSVG
              src={
                activeAlignement === 3
                  ? '/svg/align_justify_white.svg'
                  : '/svg/align_justify_black.svg'
              }
            />
          </InlinedButton>
        </ButtonLine>
      </Item>

      <Item>
        <SliderLabel>{t('text.rotateText')}</SliderLabel>
        <RangeSlider
          value={rotationValue}
          onChange={(newValue) => {
            setRotationValue(newValue);
          }}
          max={180}
          min={-180}
        />
      </Item>

      {isAdmin && (
        <Button
          $type={buttonType.white}
          onClick={() => setPixelHorizontalPerfect((maxWidth - width) / 2)}
        >
          {t('photos.centerH')}
        </Button>
      )}
      {isAdmin && (
        <Button
          $type={buttonType.white}
          onClick={() => setPixelVerticalPerfect((maxHeight - height) / 2)}
        >
          {t('photos.centerV')}
        </Button>
      )}

      {isAdmin && (
        <Item>
          <ManagePixel
            translateKey={t('photos.pixelHorizontal')}
            pixelValue={pixelHorizontalPerfect}
            setPixelValue={setPixelHorizontalPerfect}
            maxSize={maxWidth}
            sizeElement={width}
          />
          <ManagePixel
            translateKey={t('photos.pixelVertical')}
            pixelValue={pixelVerticalPerfect}
            setPixelValue={setPixelVerticalPerfect}
            maxSize={maxHeight}
            sizeElement={height}
          />
        </Item>
      )}
      {isAdmin && (
        <Item>
          <ManageElementSize
            translateKey={t('photos.sizeWidthElement')}
            value={width}
            setValue={setWidth}
            maxSize={maxWidth}
          />
          <ManageElementSize
            translateKey={t('photos.sizeHeightElement')}
            value={height}
            setValue={setHeight}
            maxSize={maxHeight}
          />
        </Item>
      )}
      {isAdmin && (
        <Item>
          <Row>
            <NumberChooser
              title={t('photos.plan')}
              number={planValue}
              setNumber={setPlanValue}
              step={1}
            />
          </Row>
        </Item>
      )}
      <Item>
        {isAdmin && !elementFocused?.editable && (
          <SubTitle>L'élement a été verrouillé pour les utilisateurs</SubTitle>
        )}
        {isAdmin && (
          <Button $type={buttonType.white} onClick={lockUnlockElement}>
            {elementFocused?.editable ? 'Verrouiller ' : 'Déverrouiller '}
            le texte
          </Button>
        )}
      </Item>
      <Item>
        {isAdmin && !elementFocused?.contentEditable && (
          <SubTitle>L'élement a été verrouillé pour les utilisateurs</SubTitle>
        )}
        {isAdmin && (
          <Button $type={buttonType.white} onClick={lockUnlockElementContent}>
            {elementFocused?.contentEditable
              ? 'Verrouiller '
              : 'Déverrouiller '}
            le contenu du texte
          </Button>
        )}
      </Item>
      <IconButtonLine>
        <IconButton
          title={t('text.duplicate')}
          src="/svg/duplicate.svg"
          disabled={focus === null}
          onClick={DuplicateText}
        />
        <IconButton
          title={t('photos.delete')}
          src="/svg/trash.svg"
          disabled={focus === null}
          onClick={() => setRemoveModalVisible(true)}
          isDeletion={true}
        />
      </IconButtonLine>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: ${colors.white};
  padding-top: 24px;

  box-sizing: border-box;
  gap: 24px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 8px;

  color: ${colors.black};
`;

const HorizontalItem = styled(Item)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  font-family: 'Playfair Display';
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  color: ${colors.black};
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 2px;
  background-color: ${colors.gray100};

  margin-top: 8px;
`;

const SubTitle = styled.div`
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: ${colors.black};
`;

const IconButtonLine = styled.div`
  display: flex;
  flex-direction: row;

  padding: 0 8px;

  justify-content: flex-start;

  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

const SmallContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  cursor: pointer;
`;

const IconSVG = styled(ReactSVG)`
  transform: rotate(90deg) scale(0.4);

  height: 20px;
  margin-left: 10px;

  cursor: pointer;
`;

const SliderLabel = styled.div`
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`;

export default TextMenu;
