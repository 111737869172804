import styled from 'styled-components';
import colors from '../../../constants/colors';
import { useRef, useState, MouseEvent } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import DayModal from './DayModal';
import { CalendarMonth } from '../../../types/models/Calendar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../constants/initialStore';
import MonthDay from './MonthDay';
import { calendarFormats } from '../../../constants/calendarFormats';
import CalendarText, { getCalendarFontProps } from './CalendarText';
import { ConvertMMToPixel } from '../../../utils/convertCMToPixel';

const CALENDAR_DESIGN_HEIGHT = 503;

const MonthTitle = styled(CalendarText)<{ $ratio: number }>`
  grid-column: 1 / -1;
  justify-self: flex-end;
  color: ${colors.black};
  text-transform: capitalize;
`;

const Grid = styled.div<{ $ratio: number; $paddingTop: number }>`
  height: 100%;
  width: 100%;
  padding: 3.49854227405%;
  padding-top: ${({ $paddingTop }) => $paddingTop}px;

  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 9.479717813% 5.95238095238%;
  grid-auto-rows: 1fr;
`;

type DayLabelProps = {
  $ratio: number;
  $fontSize: number;
  $fontFamily: string;
  $bold: boolean;
};

const DayLabel = styled.span<DayLabelProps>`
  display: grid;
  place-content: center;
  color: ${colors.black};
  font: ${({ $bold }) => ($bold ? 700 : 400)} ${({ $fontSize }) => $fontSize}px /
    1 ${({ $fontFamily }) => $fontFamily};
  text-transform: capitalize;
`;

type CalendarGridProps = Pick<
  CalendarMonth,
  'backgroundColor' | 'month' | 'calendarDays'
> & {
  days: Dayjs[];
  ratio: number;
};

const CalendarGrid = ({
  days,
  calendarDays,
  backgroundColor,
  month,
  ratio,
}: CalendarGridProps) => {
  const format = useSelector(
    (state: RootState) => state.creation.present.calendar!.format,
  );
  const { fonts: fontsByDisplay, height, isDouble } = calendarFormats[format];
  const fonts = fontsByDisplay['GRID']!;

  const [dayModalIndex, setDayModalIndex] = useState<number | null>(null);

  const emptyDayBeforeLength = (days[0].day() + 6) % 7;
  const emptyDayAfterLength = (7 - days[days.length - 1].day()) % 7;

  const daysElementRef = useRef<(HTMLDivElement | null)[]>(
    Array.from({ length: days.length }, () => {
      return null;
    }),
  );

  const paddingTop =
    ((isDouble ? 1 : 0.5) + 34 / CALENDAR_DESIGN_HEIGHT) *
    ConvertMMToPixel(height) *
    ratio;

  return (
    <Grid $ratio={ratio} $paddingTop={paddingTop}>
      <MonthTitle {...getCalendarFontProps(fonts.title, ratio)} $ratio={ratio}>
        {days[0].format('MMMM YYYYY').slice(0, -5)}
      </MonthTitle>
      {Array.from({ length: 7 }, (_, index) => (
        <DayLabel
          key={index}
          $ratio={ratio}
          {...getCalendarFontProps(fonts.days, ratio)}
        >
          {dayjs
            .utc()
            .day(index + 1)
            .format('dddd')}
        </DayLabel>
      ))}
      {Array.from({ length: emptyDayBeforeLength }, (_, index) => (
        <MonthDay key={index} ratio={ratio} />
      ))}
      {days.map((d, index) => {
        const dayElement = calendarDays.find((cd) => cd.day === d.date());
        const { photo, text } = dayElement ?? {};
        const props = { date: d, photo, text };
        return (
          <MonthDay
            key={d.date()}
            ref={(el) => (daysElementRef.current[index] = el)}
            {...props}
            onClickCapture={(e: MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              setDayModalIndex(index);
            }}
            ratio={ratio}
          />
        );
      })}
      {Array.from({ length: emptyDayAfterLength }, (_, index) => (
        <MonthDay key={index} ratio={ratio} />
      ))}
      {dayModalIndex !== null && (
        <DayModal
          month={month}
          index={dayModalIndex}
          setIndex={setDayModalIndex}
          backgroundColor={backgroundColor}
          days={days}
          daysElementRef={daysElementRef}
        />
      )}
    </Grid>
  );
};

export default CalendarGrid;
