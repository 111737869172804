import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

const renderCountToSkip = import.meta.env.DEV ? 2 : 1; // React strict mode will cause a seconde render to be triggered

const useEffectSkipFirstRender = (
  effect: EffectCallback,
  deps?: DependencyList,
) => {
  const renderCount = useRef(0);

  useEffect(() => {
    renderCount.current += 1;
    if (renderCount.current <= renderCountToSkip) return;
    effect();
  }, deps);
};

export default useEffectSkipFirstRender;
