import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactNode } from 'react';
import ImmutableText from '../../../cardElements/textElements/ImmutableText';
import { ImmutableMotif } from '../../../cardElements/motifElements/ImmutableMotif';
import {
  ElementType,
  MotifElementStyle,
} from '../../../../types/models/Elements';
import { RootState } from '../../../../constants/initialStore';
import { AlbumType, albumFormats } from '../../../../constants/albumFormats';
import { ConvertMMToPixel } from '../../../../utils/convertCMToPixel';
import { AlbumPage } from '../../../../types/models/Album';
import {
  AlbumPageContainer,
  DoubleSeparator,
  PageContainer,
  Title,
} from '../../../../pages/albums/AlbumPageStyles';
import ImmutablePhoto from '../../../cardElements/photoElements/ImmutablePhoto';

export const PagePreview = ({
  id,
  backgroundColor,
  backgroundTheme,
}: Pick<
  AlbumPage,
  'id' | 'backgroundColor' | 'backgroundTheme' | 'position'
> & {
  children?: ReactNode;
  isCover?: boolean;
}) => {
  const format = useSelector(
    (state: RootState) => state.creation.present.album!.format,
  );
  const ratio = useSelector((state: RootState) => state.previewRatio!.value);
  const { width, height } = albumFormats[format];
  const isDouble = albumFormats[format].type === AlbumType.PLAT;
  const displayWidth = (isDouble ? 2 : 1) * ConvertMMToPixel(width) * ratio;

  const pageElements = useSelector(
    (state: RootState) =>
      state.creation.present.elements.value.filter((el) => el.pageID === id),
    { equalityFn: shallowEqual },
  );

  return (
    <PreviewPageContainer
      $width={displayWidth}
      $height={ConvertMMToPixel(height) * ratio}
      $backgroundColor={backgroundColor ?? ''}
      $backgroundTheme={backgroundTheme}
      $ratio={ratio}
      $displaycutzone={false}
    >
      <Watermark src={'/watermark.png'} />
      {pageElements.map((element) => {
        switch (element.type) {
          case ElementType.TEXT:
            return (
              <ImmutableText key={element.id} element={element} ratio={ratio} />
            );
          case ElementType.PHOTO:
            return (
              <ImmutablePhoto
                key={element.id}
                src={element.content}
                element={element}
                elementstyle={
                  element.style && 'opacity' in element.style
                    ? element.style
                    : null
                }
                ratio={ratio}
              />
            );
          case ElementType.MOTIF:
            return (
              <ImmutableMotif
                src={element.content}
                key={element.id}
                $element={element}
                $elementstyle={element.style as MotifElementStyle}
                $ratio={ratio}
                $index={element.zIndex}
              />
            );
        }
      })}
    </PreviewPageContainer>
  );
};

const AlbumPageDisplayerPreview = (props: AlbumPage) => {
  const format = useSelector(
    (state: RootState) => state.creation.present.album!.format,
  );

  const isDouble = albumFormats[format].type === AlbumType.PLAT;

  return (
    <AlbumPageContainer>
      <Title>
        {props.position + 1}
        {props.position > 1 ? 'e' : props.position > 0 ? 'eme' : 'ere'} page
      </Title>
      <PagePreview {...props}>{isDouble && <DoubleSeparator />}</PagePreview>
    </AlbumPageContainer>
  );
};

const PreviewPageContainer = styled(PageContainer)`
  border: none;
`;

const Watermark = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 1000;
`;

export default AlbumPageDisplayerPreview;
