import { styled } from 'styled-components';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  changePreviewRatio,
} from '../../../../constants/initialStore';
import { calendarFormats } from '../../../../constants/calendarFormats';
import { ConvertMMToPixel } from '../../../../utils/convertCMToPixel';
import CalendarMonthPreview, {
  CalendarCoverPreview,
} from './CalendarMonthPreview';
import colors from '../../../../constants/colors';

const INITIAL_PADDING_X = 170;

const CalendarPreview: React.FC = () => {
  const dispatch = useDispatch();
  const calendar = useSelector(
    (state: RootState) => state.creation.present.calendar,
  );
  const elRef = useRef<HTMLDivElement | null>(null);
  const format = calendar?.format;
  const formatObj = format && calendarFormats[format];
  const noCover = formatObj && formatObj.noCover;

  const months = calendar?.calendarMonths;

  useEffect(() => {
    if (!formatObj) return;
    const { width, height } = formatObj;

    const { clientHeight: zoneHeight, clientWidth: zoneWidth } = elRef.current!;

    let ratio = Math.min(
      (zoneWidth - 2 * INITIAL_PADDING_X - 24) / 2 / ConvertMMToPixel(width),
      (zoneHeight - 260) / ConvertMMToPixel(height),
    );
    ratio = Math.max(ratio, 0.01);
    dispatch(changePreviewRatio(ratio));
  }, [formatObj]);

  return (
    <Container ref={elRef}>
      <Title>{calendar?.name}</Title>
      {months && (
        <CalendarMonths>
          {!noCover && <CalendarCoverPreview {...months[0]} />}
          {months.slice(1).map((month, index) => (
            <CalendarMonthPreview key={index} {...month} />
          ))}
        </CalendarMonths>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  scrollbar-color: #c1c1c1 white;

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
  }
`;

const Title = styled.div`
  font-family: Playfair Display;
  font-size: 24px;
  line-height: 24px;
  color: ${colors.black};
`;

const CalendarMonths = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 24px;
  margin-top: 36px;
  margin-bottom: 36px;
`;

export default CalendarPreview;
