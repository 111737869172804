import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const landscapeLayouts4Photo: LayoutElement[][] = [
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.86,
      height: 0.86,
      top: 0.07,
      left: 0.07,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.86,
      height: 0.86,
      top: 0.07,
      left: 0.07,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.35,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.65,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.65,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.35,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.86,
      height: 0.86,
      top: 0.07,
      left: 0.07,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 0.35,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 0.3,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 0.35,
          height: 1,
          top: 0,
          left: 0,
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.72,
      height: 1,
      top: 0,
      left: 0.14,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.56,
      height: 0.8,
      top: 0.1,
      left: 0.22,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.58,
      height: 0.71,
      top: 0.19,
      left: 0.21,
      style: {
        gap: 80,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.86,
          top: 0.07,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.BLOCK,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.35,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.65,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
            {
              type: ElementType.BLOCK,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.65,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.35,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
          ],
        },
        {
          type: ElementType.TEXT,
          width: 0.9,
          height: 0.14,
          top: 0,
          left: 0.03,
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.58,
      height: 0.71,
      top: 0.19,
      left: 0.21,
      style: {
        gap: 80,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.86,
          top: 0.07,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.BLOCK,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.25,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.75,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
            {
              type: ElementType.BLOCK,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.75,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.25,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
          ],
        },
        {
          type: ElementType.TEXT,
          width: 0.9,
          height: 0.14,
          top: 0,
          left: 0.03,
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.86,
      height: 0.3,
      top: 0.35,
      left: 0.07,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.68,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.56,
      height: 0.69,
      top: 0.2,
      left: 0.22,
      style: {
        gap: 80,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.86,
          top: 0.07,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.BLOCK,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
            {
              type: ElementType.BLOCK,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
          ],
        },
        {
          type: ElementType.TEXT,
          width: 0.9,
          height: 0.14,
          top: 0,
          left: 0.03,
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 0.63,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 0.37,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 1 / 3,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 1 / 3,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 1 / 3,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.68,
      height: 0.62,
      top: 0.19,
      left: 0.16,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 0.63,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 0.37,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 1 / 3,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 1 / 3,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 1 / 3,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
];
