import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import commonFrench from './locales/fr/common.json';
import commonGerman from './locales/de/common.json';
import commonItalian from './locales/it/common.json';

import colorsFrench from './locales/fr/colors.json';
import colorsGerman from './locales/de/colors.json';
import colorsItalian from './locales/it/colors.json';

import validationCheckListFrench from './locales/fr/validationCheckList.json';
import validationCheckListGerman from './locales/de/validationCheckList.json';
import validationCheckListItalian from './locales/it/validationCheckList.json';

import helpFrench from './locales/fr/help.json';
import helpGerman from './locales/de/help.json';
import helpItalian from './locales/it/help.json';

import productsNameFrench from './locales/fr/productsName.json';
import productsNameGerman from './locales/de/productsName.json';
import productsNameItalian from './locales/it/productsName.json';

const resources = {
  fr: {
    common: commonFrench,
    colors: colorsFrench,
    validationCheckList: validationCheckListFrench,
    help: helpFrench,
    productsName: productsNameFrench,
  },
  de: {
    common: commonGerman,
    colors: colorsGerman,
    validationCheckList: validationCheckListGerman,
    help: helpGerman,
    productsName: productsNameGerman,
  },
  it: {
    common: commonItalian,
    colors: colorsItalian,
    validationCheckList: validationCheckListItalian,
    help: helpItalian,
    productsName: productsNameItalian,
  },
};

i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  fallbackLng: 'fr',
  debug: true,
});

export default i18n;
