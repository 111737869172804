import styled from 'styled-components';
import { PhotoElementStyle, Element } from '../../../types/models/Elements';
import { PhotoDisplayer } from './PhotoDisplayer';
import colors from '../../../constants/colors';

interface ImmutablePhotoProps extends React.HTMLProps<HTMLImageElement> {
  elementstyle: PhotoElementStyle | null;
  element: Element;
  ratio: number;
}

const ImmutablePhoto: React.FC<ImmutablePhotoProps> = ({
  elementstyle,
  element,
  ratio,
  src,
}) => {
  return (
    <PhotoContainer
      $elementstyle={elementstyle}
      $width={element.width}
      $height={element.height}
      $top={element.top}
      $left={element.left}
      $ratio={ratio}
      $index={element.zIndex}
    >
      <PhotoContent
        src={src}
        $elementstyle={elementstyle}
        $mustCover={element.mustCover ?? false}
        $ratio={ratio}
      />
    </PhotoContainer>
  );
};

const PhotoContainer = styled.div<{
  $elementstyle: PhotoElementStyle | null;
  $width: number;
  $height: number;
  $top: number;
  $left: number;
  $ratio: number;
  $index: number;
}>`
  display: flex;
  position: absolute;
  width: ${(props) => `${props.$width * props.$ratio}px`};
  height: ${(props) => `${props.$height * props.$ratio}px`};
  left: ${(props) => `${props.$left * props.$ratio}px`};
  top: ${(props) => `${props.$top * props.$ratio}px`};

  z-index: ${(props) => props.$index};

  overflow: hidden;
  box-shadow: ${(props) =>
    props.$elementstyle?.shadow ? '5px 5px 5px ' + colors.gray700 : 'none'};
  border-radius: ${(props) =>
    props.$elementstyle?.shape === 'circle'
      ? '50%'
      : props.$elementstyle?.shape === 'arch'
      ? '50% 50% 0 0'
      : '0'};
  transform: ${(props) =>
    props.$elementstyle?.rotationFrame
      ? 'rotate(' + props.$elementstyle.rotationFrame + 'deg) '
      : ''};
`;

const PhotoContent = styled(PhotoDisplayer)`
  width: 100%;
  height: 100%;
`;

export default ImmutablePhoto;
