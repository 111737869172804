import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import colors from '../../constants/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  addElement,
  closeMenu,
  deleteElement,
  modifyElement,
} from '../../constants/initialStore';
import { availableMotifs } from '../../constants/availableMotifs';
import RemoveElementModal from './modals/RemoveElementModal';
import IconButton from '../general/IconButton';
import MotifItem from '../cardElements/MotifItem';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { CloseButton, SubTitle, TitleContainer, TitleItem } from './MenuStyles';
import RangeSlider from './Slider';
import { ElementType } from '../../types/models/Elements';
import NumberChooser from '../general/NumberChooser';
import { LoginState, useAuth } from '../../hook/useAuth';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import ManagePixel from '../general/ManagePixel';
import ManageElementSize from '../general/ManageElementSize';
import Button, { buttonType } from '../general/Button';
import CloseMenuButton from '../layout/CloseMenuButton';

const MotifMenu: React.FC = () => {
  const ratio = useSelector((state: RootState) => state.ratio.value);

  const [rotationValue, setRotationValue] = useState<number>(0);
  const [removeModalVisible, setRemoveModalVisible] = useState<boolean>(false);
  const [pixelHorizontalPerfect, setPixelHorizontalPerfect] =
    useState<number>(0);
  const [pixelVerticalPerfect, setPixelVerticalPerfect] = useState<number>(0);
  const [categoryOpened, setCategoryOpened] = useState<number>(-1);
  const [planValue, setPlanValue] = useState<number>(0);
  const [isMotif, setIsMotif] = useState<boolean>(false);

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );

  const [maxWidth, setMaxWidth] = useState<number>(0);
  const [maxHeight, setMaxHeight] = useState<number>(0);

  const [isEditable, setIsEditable] = useState<boolean>(false);

  useEffect(() => {
    if (card) {
      setMaxWidth(
        parseInt(
          ConvertMMToPixel(card.facesSizes[currentFace].width).toFixed(0),
        ),
      );
      setMaxHeight(
        parseInt(
          ConvertMMToPixel(card.facesSizes[currentFace].height).toFixed(0),
        ),
      );
    }
  }, [card]);

  const focus = useSelector((state: RootState) => state.focus.value);
  const elements = useSelector(
    (state: RootState) => state.creation.present.elements,
  );

  const currentFace = useSelector((state: RootState) => state.face.value);
  const dispatch = useDispatch();

  const { t } = useTranslation(['common']);

  const deleteIcon = () => {
    dispatch(deleteElement({ id: focus }));
    setRemoveModalVisible(false);
  };

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const user = useAuth();

  useEffect(() => {
    if (
      user.userInfo?.state === LoginState.LOGGED_IN &&
      user.userInfo.isAdmin
    ) {
      setIsAdmin(true);
    }
  }, [user]);

  const lockUnlockElement = async () => {
    const element = elements.value.find(({ id }) => id === focus);
    if (element) {
      setIsEditable(!element.editable);
      dispatch(
        modifyElement({
          id: focus,
          element: {
            ...element,
            editable: !element.editable,
          },
        }),
      );
    }
  };

  const lockUnlockElementContent = async () => {
    const element = elements.value.find(({ id }) => id === focus);
    if (element) {
      dispatch(
        modifyElement({
          id: focus,
          element: {
            ...element,
            contentEditable: !element.contentEditable,
          },
        }),
      );
    }
  };

  const downloadImage = () => {
    const element = elements.value.find(({ id }) => id === focus);
    if (element) {
      const imageUrl = element.content;
      const link = document.createElement('a');
      link.href = imageUrl;
      link.download = element.id + '.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getElementFocused = () => {
    return elements.value.find(({ id }) => id === focus);
  };

  useEffect(() => {
    if (focus !== null) {
      const element = elements.value.find(({ id }) => id === focus);
      if (element && element.type === ElementType.MOTIF) {
        setRotationValue(element.style.rotation);
        setPixelVerticalPerfect(parseInt(String(element.top)));
        setPixelHorizontalPerfect(parseInt(String(element.left)));
        setWidth(parseInt(String(element.width)));
        setHeight(parseInt(String(element.height)));
        setPlanValue(element.zIndex);
        setIsEditable(element.editable);
        setIsMotif(true);
      } else {
        setIsMotif(false);
        setRotationValue(0);
      }
    } else {
      setIsMotif(false);
      setRotationValue(0);
    }
  }, [focus]);

  useEffect(() => {
    if (!focus || getElementFocused()?.type !== ElementType.MOTIF) return;

    if (isAdmin) {
      dispatch(
        modifyElement({
          id: focus,
          modification: {
            top: pixelVerticalPerfect,
            left: pixelHorizontalPerfect,
            width: width,
            height: height,
            style: {
              rotation: rotationValue,
            },
            zIndex: planValue,
          },
        }),
      );
    } else {
      dispatch(
        modifyElement({
          id: focus,
          modification: {
            style: {
              rotation: rotationValue,
            },
          },
        }),
      );
    }
  }, [
    rotationValue,
    pixelHorizontalPerfect,
    pixelVerticalPerfect,
    height,
    width,
    planValue,
  ]);

  if (focus && !isEditable && !isAdmin && isMotif) {
    return (
      <Container>
        <TitleItem>
          <TitleContainer>
            <Title>{t('photos.modifyPhoto')}</Title>
            <CloseMenuButton />
          </TitleContainer>
          <Line />
        </TitleItem>
        <Item>
          <SubTitle>
            {"L'élément a été verrouillé par l'administrateur"}
          </SubTitle>
        </Item>
      </Container>
    );
  }

  if (focus && isMotif) {
    return (
      <Container>
        <RemoveElementModal
          visible={removeModalVisible}
          setVisible={setRemoveModalVisible}
          handleValidate={deleteIcon}
        />
        <CloseMenuButton />
        <IconButtonLine>
          <IconButton
            title={t('photos.delete')}
            src="/svg/trash.svg"
            onClick={() => setRemoveModalVisible(true)}
            isDeletion={true}
          />
        </IconButtonLine>

        <Item>
          <SliderLabel>{t('photos.rotateImage')}</SliderLabel>
          <RangeSlider
            value={rotationValue}
            onChange={(newValue) => {
              setRotationValue(newValue);
            }}
            max={180}
            min={-180}
          />
        </Item>

        {isAdmin && (
          <Item>
            <ManagePixel
              translateKey={t('photos.pixelHorizontal')}
              pixelValue={pixelHorizontalPerfect}
              setPixelValue={setPixelHorizontalPerfect}
              maxSize={maxWidth}
              sizeElement={width}
            />
            <ManagePixel
              translateKey={t('photos.pixelVertical')}
              pixelValue={pixelVerticalPerfect}
              setPixelValue={setPixelVerticalPerfect}
              maxSize={maxHeight}
              sizeElement={height}
            />
          </Item>
        )}
        {isAdmin && (
          <Item>
            <ManageElementSize
              translateKey={t('photos.sizeWidthElement')}
              value={width}
              setValue={(e) => setWidth((e as number) * ratio)}
              maxSize={maxWidth}
            />
            <ManageElementSize
              translateKey={t('photos.sizeHeightElement')}
              value={height}
              setValue={(e) => setHeight((e as number) * ratio)}
              maxSize={maxHeight}
            />
          </Item>
        )}
        {isAdmin && (
          <Item>
            <Row>
              <NumberChooser
                title={t('photos.plan')}
                number={planValue}
                setNumber={setPlanValue}
                step={1}
              />
            </Row>
          </Item>
        )}
        <Item>
          {isAdmin && !getElementFocused()?.editable && (
            <SubTitle>
              L'élement a été verrouillé pour les utilisateurs
            </SubTitle>
          )}
          {isAdmin && (
            <Button $type={buttonType.white} onClick={lockUnlockElement}>
              {getElementFocused()?.editable
                ? 'Verrouiller '
                : 'Déverrouiller '}
              le motif
            </Button>
          )}
        </Item>
        <Item>
          {isAdmin && !getElementFocused()?.contentEditable && (
            <SubTitle>
              L'élement a été verrouillé pour les utilisateurs
            </SubTitle>
          )}
          {isAdmin && (
            <Button $type={buttonType.white} onClick={lockUnlockElementContent}>
              {getElementFocused()?.contentEditable
                ? 'Verrouiller '
                : 'Déverrouiller '}
              le contenu du motif
            </Button>
          )}
        </Item>
        <Item>
          {isAdmin && (
            <Button $type={buttonType.white} onClick={downloadImage}>
              Télécharger le motif
            </Button>
          )}
        </Item>
      </Container>
    );
  }

  return (
    <Container>
      <RemoveElementModal
        visible={removeModalVisible}
        setVisible={setRemoveModalVisible}
        handleValidate={deleteIcon}
      />
      <CloseMenuButton />
      {Object.keys(availableMotifs).map((key, index) => {
        return (
          <Item key={key}>
            <CategoryTitle
              style={{ cursor: 'pointer' }}
              onClick={() =>
                categoryOpened === index
                  ? setCategoryOpened(-1)
                  : setCategoryOpened(index)
              }
            >
              <div>{key}</div>
              <ExpandIcon
                expanded={categoryOpened === index}
                src={'/svg/left_chevron.svg'}
              />
            </CategoryTitle>
            {categoryOpened === index && (
              <ColorContainer>
                {availableMotifs[key as keyof typeof availableMotifs].map(
                  (motif, indexMotif) => {
                    return (
                      <MotifItem
                        key={indexMotif}
                        index={indexMotif}
                        motif={motif}
                      />
                    );
                  },
                )}
              </ColorContainer>
            )}
          </Item>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: ${colors.white};
  color: ${colors.black};
  padding-top: 24px;

  box-sizing: border-box;
  gap: 24px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 8px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-family: 'Playfair Display';
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  color: ${colors.black};
`;

const CategoryTitle = styled(Title)`
  font-size: 18px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 2px;
  background-color: ${colors.gray100};

  margin-top: 8px;
`;

const ColorContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;

  gap: 10px;

  box-sizing: border-box;

  flex-wrap: wrap;

  & > div {
    margin-top: 8px;
  }
`;

const IconButtonLine = styled.div`
  display: flex;
  flex-direction: row;

  padding: 0 8px;

  justify-content: flex-start;

  gap: 16px;
`;

const ExpandIcon = styled.img<{ expanded: boolean }>`
  margin-right: 10px;
  scale: 0.5;
  transform: rotate(270deg);
  transition: transform 0.3s ease-in-out;

  ${(props) =>
    props.expanded
      ? `
    transform: rotate(90deg);
  `
      : ''}
`;

const SliderLabel = styled.div`
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export default MotifMenu;
