import { RefObject, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, zoomTo } from '../constants/initialStore';
import { ConvertMMToPixel } from '../utils/convertCMToPixel';
import { AnyAction } from 'redux';

const useCardZoneSize = (
  updateRatio: (ratio: number) => AnyAction,
  maxZoneRef: RefObject<HTMLDivElement>,
  heightToRemove?: number,
  widthToRemove?: number,
) => {
  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );

  const currentFace = useSelector((state: RootState) => state.face.value);

  const menu = useSelector((state: RootState) => state.menu.value);
  const [maxHeight, setMaxHeight] = useState<number>(99999);
  const [maxWidth, setMaxWidth] = useState<number>(99999);
  const [displayHeight, setDisplayHeight] = useState<number>(0);
  const [displayWidth, setDisplayWidth] = useState<number>(0);

  const dispatch = useDispatch();

  useEffect(() => {
    const updateMaxZoneSize = () => {
      setMaxHeight(
        (maxZoneRef.current?.clientHeight ?? 0) - (heightToRemove ?? 0),
      );
      setMaxWidth(
        (maxZoneRef.current?.clientWidth ?? 0) - (widthToRemove ?? 0),
      );
    };

    window.addEventListener('resize', updateMaxZoneSize);
    updateMaxZoneSize();
    return () => window.removeEventListener('resize', updateMaxZoneSize);
  }, [maxZoneRef, menu]);

  useEffect(() => {
    dispatch(zoomTo(1));
    if (currentFace < card.faces) {
      const ratioHeight =
        maxHeight / ConvertMMToPixel(card.facesSizes[currentFace].height);
      const ratioWidth =
        maxWidth / ConvertMMToPixel(card.facesSizes[currentFace].width);

      if (ratioWidth > ratioHeight && 1 > ratioHeight) {
        dispatch(updateRatio(ratioHeight));
        setDisplayHeight(
          Math.floor(
            ratioHeight * ConvertMMToPixel(card.facesSizes[currentFace].height),
          ),
        );
        setDisplayWidth(
          Math.floor(
            ratioHeight * ConvertMMToPixel(card.facesSizes[currentFace].width),
          ),
        );
      } else if (1 > ratioWidth) {
        dispatch(updateRatio(ratioWidth));
        setDisplayHeight(
          Math.floor(
            ratioWidth * ConvertMMToPixel(card.facesSizes[currentFace].height),
          ),
        );
        setDisplayWidth(
          Math.floor(
            ratioWidth * ConvertMMToPixel(card.facesSizes[currentFace].width),
          ),
        );
      } else {
        dispatch(updateRatio(1));
        setDisplayHeight(
          Math.floor(1 * ConvertMMToPixel(card.facesSizes[currentFace].height)),
        );
        setDisplayWidth(
          Math.floor(1 * ConvertMMToPixel(card.facesSizes[currentFace].width)),
        );
      }
    }
  }, [maxHeight, maxWidth, card, currentFace]);

  return [maxHeight, maxWidth, displayHeight, displayWidth];
};

export default useCardZoneSize;
