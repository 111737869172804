import { styled } from 'styled-components';
import colors from '../../constants/colors';
import QuestionComponent from './QuestionComponent';
import { CloseButton, TitleContainer, TitleItem } from './MenuStyles';
import { ReactSVG } from 'react-svg';
import { useDispatch } from 'react-redux';
import { closeMenu } from '../../constants/initialStore';
import i18next from '../../i18n';
import { useTranslation } from 'react-i18next';
import CloseMenuButton from '../layout/CloseMenuButton';
import styleConstants from '../../constants/styleConstants';

const questions = [
  {
    title: i18next.t('insertImages', { ns: ['help'] }),
    answer: i18next.t('insertImagesAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('findPhotos', { ns: ['help'] }),
    answer: i18next.t('findPhotosAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('createThemeFolders', { ns: ['help'] }),
    answer: i18next.t('createThemeFoldersAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('imageFormat', { ns: ['help'] }),
    answer: i18next.t('imageFormatAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('modifyPhoto', { ns: ['help'] }),
    answer: i18next.t('modifyPhotoAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('poorQualityPhoto', { ns: ['help'] }),
    answer: i18next.t('poorQualityPhotoAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('insertText', { ns: ['help'] }),
    answer: i18next.t('insertTextAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('modifyText', { ns: ['help'] }),
    answer: i18next.t('modifyTextAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('centeredText', { ns: ['help'] }),
    answer: i18next.t('centeredTextAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('modifyBackgroundColor', { ns: ['help'] }),
    answer: i18next.t('modifyBackgroundColorAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('modifyBackgroundPattern', { ns: ['help'] }),
    answer: i18next.t('modifyBackgroundPatternAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('addIcons', { ns: ['help'] }),
    answer: i18next.t('addIconsAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('modifyCreations', { ns: ['help'] }),
    answer: i18next.t('modifyCreationsAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('findCreations', { ns: ['help'] }),
    answer: i18next.t('findCreationsAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('renameCreation', { ns: ['help'] }),
    answer: i18next.t('renameCreationAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('preventCutOff', { ns: ['help'] }),
    answer: i18next.t('preventCutOffAnswer', { ns: ['help'] }),
  },
  {
    title: i18next.t('noAnswerFound', { ns: ['help'] }),
    answer: i18next.t('noAnswerFoundAnswer', { ns: ['help'] }),
  },
];

const HelpMenu: React.FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['common']);

  return (
    <Container>
      <CloseMenuButton isOutside={true} />
      <Item>
        {questions.map((q, index) => (
          <div>
            <QuestionComponent key={index} title={q.title} answer={q.answer} />
            <Line />
          </div>
        ))}
      </Item>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: ${colors.white};
  padding-top: 24px;
  box-sizing: border-box;
  gap: 24px;
  @media (max-width: ${styleConstants.breakpointLg}) {
    height: 20vh;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 8px;
`;

const Title = styled.div`
  font-family: 'Playfair Display';
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  color: ${colors.black};
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 2px;
  background-color: ${colors.gray100};

  margin-top: 8px;
`;

export default HelpMenu;
