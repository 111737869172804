import styled from 'styled-components';
import colors from '../../constants/colors';
import styleConstants from '../../constants/styleConstants';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: ${colors.white};
  padding-top: 24px;

  box-sizing: border-box;
  gap: 24px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 8px;
`;

export const TitleItem = styled(Item)`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  padding-top: 24px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-family: 'Playfair Display';
  font-size: 24px;
  font-weight: 400;
  text-align: left;
  color: ${colors.black};
`;

export const SubTitle = styled.div`
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: ${colors.black};
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 2px;
  background-color: ${colors.gray100};

  margin-top: 8px;
`;

export const CloseButton = styled.button<{
  $isSmall?: boolean;
  $isOutside?: boolean;
  $isHigher?: boolean;
  $isLeft?: boolean;
}>`
  display: flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: white;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  cursor: pointer;
  position: absolute;
  top: 92px;
  left: 412px;
  @media (max-width: ${styleConstants.breakpointLg}) {
    ${(props) =>
      props.$isOutside
        ? props.$isHigher
          ? props.$isLeft
            ? 'position: fixed; bottom: calc(20vh + 148px); right: 60px; top: auto; left: auto;'
            : 'position: fixed; bottom: calc(20vh + 148px); right: 20px; top: auto; left: auto;'
          : props.$isLeft
          ? 'position: fixed; bottom: calc(20vh + 48px); right: 60px; top: auto; left: auto;'
          : 'position: fixed; bottom: calc(20vh + 48px); right: 20px; top: auto; left: auto;'
        : 'position: relative; top: auto; left: auto;'}
    transition: bottom 0.5s ease-out;
  }
`;

export const ColorContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`;
