import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';

const GridOverlay = () => {
  const [numColumns, setNumColumns] = useState(0);
  const [numRows, setNumRows] = useState(0);
  const gridOverlayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateGrid = () => {
      const cellWidth = 24.5; // Largeur d'une cellule en pixels
      const cellHeight = 24.5; // Hauteur d'une cellule en pixels
      const containerWidth = gridOverlayRef.current?.offsetWidth ?? 0;
      const containerHeight = gridOverlayRef.current?.offsetHeight ?? 0;
      const calculatedNumColumns = Math.floor(containerWidth / cellWidth) + 1;
      const calculatedNumRows = Math.floor(containerHeight / cellHeight) + 1;

      setNumColumns(calculatedNumColumns);
      setNumRows(calculatedNumRows);
    };

    // Mettre à jour la grille lors du chargement de la page et du redimensionnement de la fenêtre
    window.addEventListener('resize', updateGrid);
    updateGrid();

    return () => {
      window.removeEventListener('resize', updateGrid);
    };
  }, []);

  return (
    <GridOverlayContainer
      ref={gridOverlayRef}
      $numColumns={numColumns}
      $numRows={numRows}
    >
      {/* Cellules de la grille */}
      {Array.from({ length: numColumns * numRows }).map((_, index) => (
        <GridCell key={index} />
      ))}
    </GridOverlayContainer>
  );
};

const GridOverlayContainer = styled.div<{
  $numColumns: number;
  $numRows: number;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: grid;
  ${(props) => `grid-template-columns: repeat(${props.$numColumns}, 24.5px);`}
  ${(props) => `grid-template-rows: repeat(${props.$numRows}, 24.5px);`}

  border: 0.5px solid ${colors.gray500};

  z-index: 999;

  overflow: hidden;
`;

const GridCell = styled.div`
  background-color: transparent;
  border-right: 0.5px solid ${colors.gray500};
  border-bottom: 0.5px solid ${colors.gray500};
`;

export default GridOverlay;
