import i18next from '../i18n';

export const availableColors = [
  { name: i18next.t('black', { ns: ['colors'] }), color: '#1e1c20' },
  { name: i18next.t('white', { ns: ['colors'] }), color: '#ffffff' },
  { name: i18next.t('copper', { ns: ['colors'] }), color: '#c27d69' },
  { name: i18next.t('gold', { ns: ['colors'] }), color: '#cda04f' },
  { name: i18next.t('bronze', { ns: ['colors'] }), color: '#957531' },
  { name: i18next.t('anthracite', { ns: ['colors'] }), color: '#3d3a3a' },
  { name: i18next.t('darkGray', { ns: ['colors'] }), color: '#4d5056' },
  { name: i18next.t('grayMouse', { ns: ['colors'] }), color: '#858f94' },
  { name: i18next.t('pearlGray', { ns: ['colors'] }), color: '#dfe0e0' },
  { name: i18next.t('gray', { ns: ['colors'] }), color: '#f0f0f0' },
  { name: i18next.t('feather', { ns: ['colors'] }), color: '#eee9e3' },
  { name: i18next.t('champagne', { ns: ['colors'] }), color: '#f4e7da' },
  { name: i18next.t('cream', { ns: ['colors'] }), color: '#fdf3e0' },
  { name: i18next.t('ivory', { ns: ['colors'] }), color: '#fffada' },
  { name: i18next.t('vanilla', { ns: ['colors'] }), color: '#ffe7b5' },
  { name: i18next.t('pastelYellow', { ns: ['colors'] }), color: '#fff38e' },
  { name: i18next.t('mustard', { ns: ['colors'] }), color: '#e3ca39' },
  { name: i18next.t('mango', { ns: ['colors'] }), color: '#fbc433' },
  { name: i18next.t('zestyOrange', { ns: ['colors'] }), color: '#f19300' },
  { name: i18next.t('apricot', { ns: ['colors'] }), color: '#f3a274' },
  { name: i18next.t('peach', { ns: ['colors'] }), color: '#f2a48e' },
  { name: i18next.t('caramel', { ns: ['colors'] }), color: '#de7d2a' },
  { name: i18next.t('seaBuckthorn', { ns: ['colors'] }), color: '#e85d10' },
  { name: i18next.t('bloodOrange', { ns: ['colors'] }), color: '#e75244' },
  { name: i18next.t('terracotta', { ns: ['colors'] }), color: '#ae3219' },
  { name: i18next.t('grenadine', { ns: ['colors'] }), color: '#e43433' },
  { name: i18next.t('red', { ns: ['colors'] }), color: '#e1001a' },
  { name: i18next.t('bordeaux', { ns: ['colors'] }), color: '#9a103d' },
  { name: i18next.t('wineLees', { ns: ['colors'] }), color: '#780334' },
  { name: i18next.t('eggplant', { ns: ['colors'] }), color: '#4b143d' },
  { name: i18next.t('plum', { ns: ['colors'] }), color: '#6e1b58' },
  { name: i18next.t('raspberry', { ns: ['colors'] }), color: '#d51a62' },
  { name: i18next.t('cherry', { ns: ['colors'] }), color: '#e31a4e' },
  { name: i18next.t('strawberry', { ns: ['colors'] }), color: '#df4d58' },
  { name: i18next.t('coral', { ns: ['colors'] }), color: '#e85f69' },
  { name: i18next.t('candyPink', { ns: ['colors'] }), color: '#e97192' },
  { name: i18next.t('peony', { ns: ['colors'] }), color: '#f2a2ab' },
  { name: i18next.t('magnolia', { ns: ['colors'] }), color: '#f7c3be' },
  { name: i18next.t('powderPink', { ns: ['colors'] }), color: '#f9dedb' },
  { name: i18next.t('pastelPink', { ns: ['colors'] }), color: '#fcedec' },
  { name: i18next.t('porcelain', { ns: ['colors'] }), color: '#f1dccf' },
  { name: i18next.t('hydrangea', { ns: ['colors'] }), color: '#d3b4ad' },
  { name: i18next.t('oldRose', { ns: ['colors'] }), color: '#c28b94' },
  { name: i18next.t('currant', { ns: ['colors'] }), color: '#b76b79' },
  { name: i18next.t('violet', { ns: ['colors'] }), color: '#873c8c' },
  { name: i18next.t('orchid', { ns: ['colors'] }), color: '#b481bb' },
  { name: i18next.t('blueViolet', { ns: ['colors'] }), color: '#5c5da0' },
  { name: i18next.t('coralBlue', { ns: ['colors'] }), color: '#66738e' },
  { name: i18next.t('lavender', { ns: ['colors'] }), color: '#a68aba' },
  { name: i18next.t('lilac', { ns: ['colors'] }), color: '#c49eb8' },
  { name: i18next.t('marshmallow', { ns: ['colors'] }), color: '#f9dbe8' },
  { name: i18next.t('frost', { ns: ['colors'] }), color: '#d6e1f1' },
  { name: i18next.t('lightBlue', { ns: ['colors'] }), color: '#dff1fa' },
  { name: i18next.t('layetteBlue', { ns: ['colors'] }), color: '#afddf4' },
  { name: i18next.t('skyBlue', { ns: ['colors'] }), color: '#009ddf' },
  { name: i18next.t('blue', { ns: ['colors'] }), color: '#0070b7' },
  { name: i18next.t('royalBlue', { ns: ['colors'] }), color: '#2e4693' },
  { name: i18next.t('ultramarineBlue', { ns: ['colors'] }), color: '#122372' },
  { name: i18next.t('navy', { ns: ['colors'] }), color: '#0b234a' },
  { name: i18next.t('blueberry', { ns: ['colors'] }), color: '#1d233d' },
  { name: i18next.t('indigo', { ns: ['colors'] }), color: '#332969' },
  { name: i18next.t('duckBlue', { ns: ['colors'] }), color: '#007691' },
  { name: i18next.t('turquoise', { ns: ['colors'] }), color: '#66c1cb' },
  { name: i18next.t('lagoonBlue', { ns: ['colors'] }), color: '#38b5b8' },
  { name: i18next.t('grayBlue', { ns: ['colors'] }), color: '#bbc9ca' },
  { name: i18next.t('stemGreen', { ns: ['colors'] }), color: '#c3ccb9' },
  { name: i18next.t('khakiGreen', { ns: ['colors'] }), color: '#889276' },
  { name: i18next.t('forestGreen', { ns: ['colors'] }), color: '#1d3e35' },
  { name: i18next.t('pineGreen', { ns: ['colors'] }), color: '#0f715c' },
  { name: i18next.t('appleGreen', { ns: ['colors'] }), color: '#b0c700' },
  { name: i18next.t('waterGreen', { ns: ['colors'] }), color: '#9aceb4' },
  { name: i18next.t('pistachio', { ns: ['colors'] }), color: '#b6d7ac' },
  { name: i18next.t('mintGreen', { ns: ['colors'] }), color: '#d6eadf' },
  { name: i18next.t('pastelGreen', { ns: ['colors'] }), color: '#e3eaba' },
  { name: i18next.t('sand', { ns: ['colors'] }), color: '#e0c0a4' },
  { name: i18next.t('pinkBeige', { ns: ['colors'] }), color: '#fad7bf' },
  { name: i18next.t('camel', { ns: ['colors'] }), color: '#e5b396' },
  { name: i18next.t('glazedBrown', { ns: ['colors'] }), color: '#b3928b' },
  { name: i18next.t('brown', { ns: ['colors'] }), color: '#8b5a2b' },
  { name: i18next.t('sahara', { ns: ['colors'] }), color: '#caa871' },
];
