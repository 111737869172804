import { useEffect } from 'react';
import { availableFonts } from '../constants/availableFonts';

const getFontFormat = (fontFile: string) => {
  const extension = fontFile.split('.').pop();
  switch (extension) {
    case 'ttf':
      return 'truetype';
    case 'otf':
      return 'opentype';
    case 'woff':
      return 'woff';
    case 'woff2':
      return 'woff2';
    default:
      return 'truetype';
  }
};

const useImportTextFonts = () => {
  useEffect(() => {
    availableFonts.forEach(
      ({
        name,
        bold,
        italic,
        regular,
        boldItalic,
        ascentOverride,
        descentOverride,
      }) => {
        new FontFace(
          name,
          `url(${encodeURI(regular)}) format(${getFontFormat(regular)})`,
          {
            ascentOverride,
            descentOverride,
            style: 'normal',
            weight: 'normal',
          },
        )
          .load()
          .then((font) => {
            document.fonts.add(font);
          });
        if (italic)
          new FontFace(
            name,
            `url(${encodeURI(italic as string)}) format(${getFontFormat(
              italic as string,
            )})`,
            {
              ascentOverride,
              descentOverride,
              style: 'italic',
              weight: 'normal',
            },
          )
            .load()
            .then((font) => {
              document.fonts.add(font);
            });
        if (bold)
          new FontFace(
            name,
            `url(${encodeURI(bold as string)}) format(${getFontFormat(
              bold as string,
            )})`,
            {
              ascentOverride,
              descentOverride,
              style: 'normal',
              weight: 'bold',
            },
          )
            .load()
            .then((font) => {
              document.fonts.add(font);
            });
        if (boldItalic)
          new FontFace(
            name,
            `url(${encodeURI(boldItalic as string)}) format(${getFontFormat(
              boldItalic as string,
            )})`,
            {
              ascentOverride,
              descentOverride,
              style: 'italic',
              weight: 'bold',
            },
          )
            .load()
            .then((font) => {
              document.fonts.add(font);
            });
      },
    );
  }, []);
};

export default useImportTextFonts;
