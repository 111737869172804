import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  deleteElement,
  modifyElement,
  resetFocus,
  store,
} from '../constants/initialStore';
import { ActionCreators } from 'redux-undo';
import { LoginState, useAuth } from './useAuth';
import { PhotoElementStyle } from '../types/models/Elements';

const useActionsShortcut = () => {
  const dispatch = useDispatch();
  const creationState = useSelector((state: RootState) => state.creation);

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const user = useAuth();

  useEffect(() => {
    if (
      user.userInfo?.state === LoginState.LOGGED_IN &&
      user.userInfo.isAdmin
    ) {
      setIsAdmin(true);
    }
  }, [user]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const focus = store.getState().focus.value;
      const elements = store.getState().creation.present.elements.value;
      if (e.metaKey || e.ctrlKey) {
        // Cmd ou CTRL enfoncé ?
        if (e.key === 'z' || e.key === 'Z') {
          dispatch(resetFocus());
          if (creationState.past.length > 0) {
            dispatch(ActionCreators.undo());
          }
        } else if (e.key === 'y' || e.key === 'Y') {
          dispatch(resetFocus());
          if (creationState.future.length > 0) {
            dispatch(ActionCreators.redo());
          }
        }
      } else if (
        (e.key === 'Delete' || e.key === 'Backspace') &&
        focus !== null
      ) {
        const elementFocused = elements.find((element) => element.id === focus);

        if (
          elementFocused?.type === 'PHOTO' &&
          elementFocused?.editable === false &&
          !isAdmin
        ) {
          const styleAsPhotoElementStyle =
            elementFocused.style as PhotoElementStyle;

          dispatch(
            modifyElement({
              id: focus,
              modification: {
                content: '',
                locked: false,
                style: {
                  brightness: 1,
                  saturation: 100,
                  opacity: 1,
                  zoom: 100,
                  rotation: styleAsPhotoElementStyle.rotation
                    ? styleAsPhotoElementStyle.rotation
                    : 0,
                  rotationFrame: styleAsPhotoElementStyle.rotationFrame
                    ? styleAsPhotoElementStyle.rotationFrame
                    : 0,
                  grayscale: false,
                  sepia: false,
                  mirrored: false,
                  shadow: false,
                  shape: styleAsPhotoElementStyle.shape
                    ? styleAsPhotoElementStyle.shape
                    : 'rectangle',
                  translateX: 0,
                  translateY: 0,
                },
              },
            }),
          );
        } else {
          dispatch(deleteElement({ id: focus }));
        }
        dispatch(resetFocus());
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};

export default useActionsShortcut;
