import { Range, getTrackBackground } from 'react-range';
import colors from '../../constants/colors';
import { styled } from 'styled-components';
import Button, { buttonType } from '../general/Button';
import useLittleScreen from '../../hook/useLittleScreen';

interface RangeSliderProps {
  min: number;
  max: number;
  step?: number;
  value: number;
  unit?: string;
  onChange: (value: number) => void;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  min,
  max,
  step = 1,
  value,
  unit = '',
  onChange,
}) => {
  const littleScreen = useLittleScreen();
  return (
    <Container style={littleScreen ? { paddingRight: '18px' } : {}}>
      <Line>
        <CustomButton
          $type={buttonType.white}
          onClick={() => onChange(value - step)}
          disabled={value === min}
          style={{ left: -15 }}
        >
          {' '}
          -{' '}
        </CustomButton>
        <Range
          min={min}
          max={max}
          step={step}
          values={[isNaN(value) ? min : Math.round(value / step) * step]}
          onChange={(values) => {
            onChange(values[0]);
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '24px',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '8px',
                  width: 'calc(100% - 24px)',
                  borderRadius: '4px',
                  background: getTrackBackground({
                    values: [value],
                    colors: [colors.gray200, colors.gray200],
                    min: 100,
                    max: 10000,
                  }),
                  alignSelf: 'center',
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '24px',
                width: '24px',
                borderRadius: '12px',
                backgroundColor: colors.white,
                border: 'solid 2px ' + colors.gray600,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxSizing: 'border-box',
                zIndex: 201,
              }}
            ></div>
          )}
        />
        <CustomButton
          $type={buttonType.white}
          onClick={() => onChange(value + step)}
          disabled={value === max}
          style={{ right: -15 }}
        >
          {' '}
          +{' '}
        </CustomButton>
      </Line>
      <ValueDisplayer>
        {(isNaN(value)
          ? min
          : Math.round(value / step) *
            step *
            (unit === '%' && step !== 1 ? 100 : 1)
        ).toFixed(0)}
        {unit}
      </ValueDisplayer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;

const ValueDisplayer = styled.div`
  font-family: 'DM Sans';
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

const Line = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const CustomButton = styled(Button)`
  border-radius: 50%;
  padding: 10px;
  width: 20px;
  height: 20px;
  top: 1px;
`;

export default RangeSlider;
