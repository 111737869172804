import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/de';

import i18next from './i18n';

import utc from 'dayjs/plugin/utc';
import TagManager from 'react-gtm-module';
import Hotjar from '@hotjar/browser';

dayjs.locale(i18next.language);
dayjs.extend(utc);

const hotjarVersion = 6;

const isPlanetPhotos = window.location.href.includes('planet-photo');

const tagManagerArgs = {
  gtmId: isPlanetPhotos ? 'GTM-P2F3RH' : 'GTM-K3QKC5PP',
};

TagManager.initialize(tagManagerArgs);

Hotjar.init(4933549, 6);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
