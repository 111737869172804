import { styled } from 'styled-components';
import { ReactSVG } from 'react-svg';
import Button, { buttonType } from '../../components/general/Button';
import colors from '../../constants/colors';
import { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../hook/useAuth';
import { useTranslation } from 'react-i18next';
import styleConstants from '../../constants/styleConstants';

interface ModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  initialEmail?: string;
}

const LogInPage: React.FC<ModalProps> = ({
  visible,
  setVisible,
  initialEmail,
}) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { t } = useTranslation(['common']);

  const { login, askregister, askresetpassword } = useAuth();

  const canSubmit = useMemo(
    () => !!email && email.includes('@') && !!password,
    [email, password],
  );

  useEffect(() => {
    if (initialEmail) {
      setEmail(initialEmail);
    }
  }, [initialEmail]);

  useEffect(() => {
    setErrorMessage('');
  }, [email, password]);

  const handleConnection = async () => {
    const res = await login({
      email,
      password,
    });

    if (!res) {
      setErrorMessage('Identifiants inconnus');
    }
  };

  const gotoRegister = async () => {
    askregister();
  };

  const gotoResetPassword = async () => {
    askresetpassword();
  };

  return (
    <Container $show={visible}>
      <FormTopContainer>
        <Title>{t('auth.loginToAccount')}</Title>
        <CloseIcon src="/svg/cross.svg" onClick={() => setVisible(false)} />
      </FormTopContainer>
      <FormContainer>
        <Label>{t('auth.email')}</Label>
        <CustomInput
          type="text"
          placeholder={t('auth.enterEmail')}
          autoComplete="email"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />
        <Label>{t('auth.password')}</Label>
        <CustomInput
          type="password"
          placeholder={t('auth.enterPassword')}
          value={password}
          autoComplete="current-password"
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <Link style={{ margin: '4px 0 20px' }} onClick={gotoResetPassword}>
          {t('auth.forgotPassword')}
        </Link>
        <Button
          $type={buttonType.black}
          onClick={handleConnection}
          disabled={!canSubmit}
          type="button"
        >
          {t('auth.signIn')}
        </Button>
        {errorMessage !== '' && <Error>{errorMessage}</Error>}
        <TextWithLine>
          <Line />
          {t('auth.or')}
          <Line />
        </TextWithLine>
        <Button $type={buttonType.black} onClick={gotoRegister} type="button">
          {t('auth.signUp')}
        </Button>
      </FormContainer>
    </Container>
  );
};

const Container = styled.div<{ $show: boolean }>`
  width: 100%;
  height: 100%;

  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;

  background-color: rgba(52, 64, 84, 0.7);
`;

const FormTopContainer = styled.div`
  width: 400px;
  height: 84px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;

  background-color: ${colors.white};
  color: ${colors.black};

  border-radius: 8px 8px 0 0;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const FormContainer = styled.form`
  width: 400px;

  display: flex;
  flex-direction: column;

  background-color: ${colors.gray100};
  color: ${colors.black};

  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  padding: 24px;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const Title = styled.div`
  font-family: 'Playfair Display';
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
`;

const CloseIcon = styled(ReactSVG)`
  position: absolute;

  top: 24px;
  right: 24px;

  cursor: pointer;

  @media (max-width: ${styleConstants.breakpointLg}) {
    top: 12px;
    right: 12px;
  }
`;

const Label = styled.div`
  margin: 0 0 6px 0;

  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`;

const CustomInput = styled.input`
  width: 100%;

  color: ${colors.gray500};
  background-color: ${colors.white};

  box-sizing: border-box;
  margin: 0 0 16px 0;
  border: solid 1px ${colors.gray300};
  padding: 14px 10px;

  font-family: 'DM Sans';
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
`;

const Link = styled.div`
  width: 100%;

  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 700;
  text-align: left;

  cursor: pointer;
`;

const TextWithLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  margin: 20px 0;

  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const Line = styled.div`
  height: 1px;
  flex: 1;

  background-color: ${colors.gray200};
`;

const Error = styled.div`
  color: ${colors.error600};
  text-align: center;
`;

export default LogInPage;
