import {
  PayloadAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { formats } from './formats';
import { Element, ElementType } from '../types/models/Elements';
import { v4 as uuidv4 } from 'uuid';
import undoable from 'redux-undo';
import { cutFinitions, paperFinitions } from './availableFinitions';
import { Calendar } from '../types/models/Calendar';
import { Album } from '../types/models/Album';
import { Other } from '../types/models/Other';
import { getOriginalPrice } from '../api/products';
import { albumFormats } from './albumFormats';
import { getPriceToAdd } from './priceToAddForPages';

const initialCardState = {
  ...formats.CFG,
  format: 'CFG',
  paperId: 0,
  ownerID: '',
};

const cardSlice = createSlice({
  name: 'card',
  initialState: {
    value: initialCardState,
  },
  reducers: {
    changeCard: (state, action) => {
      if (typeof action.payload === 'function') {
        state.value = action.payload(state.value);
        return;
      }
      state.value = action.payload;
    },
  },
});

export const selectCardPrice = (card: any) => {
  const { paperId, cutId, enveloppeOption } = card;
  const paperPrice = paperId
    ? paperFinitions.find((p) => p.id === card.paperId)!.price
    : '0';
  const cutPrice = cutId
    ? cutFinitions.find((c) => c.id === cutId)!.price
    : '0';
  const enveloppePrice = enveloppeOption
    ? ['BLUEP', 'BLUE', 'PINKP', 'PINK', 'KRAFT'].some((env) =>
        enveloppeOption.includes(env),
      )
      ? '0.1'
      : '0'
    : '0';
  return (
    parseFloat(paperPrice) + parseFloat(cutPrice) + parseFloat(enveloppePrice)
  );
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState: null as Calendar | null,
  reducers: {
    changeCalendar: (
      state,
      action: PayloadAction<Calendar | ((prev: Calendar | null) => Calendar)>,
    ) => {
      if (typeof action.payload === 'function') {
        const res = action.payload(state);
        return res;
      }
      return action.payload;
    },
  },
});

export const selectCalendarPrice = (calendar: Calendar) => {
  return 0;
};

const albumSlice = createSlice({
  name: 'album',
  initialState: null as Album | null,
  reducers: {
    changeAlbum: (
      state,
      action: PayloadAction<Album | ((prev: Album | null) => Album)>,
    ) => {
      if (typeof action.payload === 'function') {
        const res = action.payload(state as Album | null);
        return res;
      }
      return action.payload;
    },
    addPages: (
      state,
      action: PayloadAction<{ index: number; ids: string[] }>,
    ) => {
      if (!state) return;

      const { index, ids } = action.payload;
      const { width, height } = albumFormats[state.format];

      // Créez les nouvelles pages
      const newPages = ids.map((page, idx) => ({
        id: page,
        position: index + idx,
        backgroundColor: '#ffffff',
        backgroundTheme: undefined,
        pageElements: [],
      }));

      // Combinez les nouvelles pages avec les pages existantes
      const newAlbumPages = [
        ...state.albumPages.slice(0, index),
        ...newPages,
        ...state.albumPages.slice(index),
      ].map((page, idx) => ({
        ...page,
        position: idx,
      }));

      // Mettez à jour l'état de l'album
      state.albumPages = newAlbumPages;
    },
    removePages: (
      state,
      action: PayloadAction<{ index: number; count: number }>,
    ) => {
      if (!state) return;
      const { index, count } = action.payload;

      // Supprimez les pages à partir de l'index spécifié
      const newAlbumPages = state.albumPages
        .filter((_, idx) => idx < index || idx >= index + count)
        .map((page, idx) => ({
          ...page,
          position: idx,
        }));

      // Mettez à jour l'état de l'album
      state.albumPages = newAlbumPages;
    },
  },
});

export const selectAlbumPrice = (album: Album) => {
  const albumFormat = albumFormats[album.format];
  const pageAdded = album.albumPages.length - albumFormat.minPages;
  const pricePerPage = getPriceToAdd(album.format);
  return pageAdded * pricePerPage;
};

const otherSlice = createSlice({
  name: 'other',
  initialState: null as Other | null,
  reducers: {
    changeOther: (
      state,
      action: PayloadAction<Other | ((prev: Other | null) => Other)>,
    ) => {
      if (typeof action.payload === 'function') {
        const res = action.payload(state);
        return res;
      }
      return action.payload;
    },
  },
});

export const selectOtherPrice = (other: Other) => {
  if (other.format.includes('POS')) {
    const { paperId, accessory, format } = other;
    const accessoryPrice =
      accessory === 'P'
        ? ['POSC30', 'POS30X40', 'POS30X20'].includes(format)
          ? '12'
          : ['POSC40', 'POS40X60', 'POS40X30'].includes(format)
          ? '14'
          : ['POS60X40', 'POS60X80'].includes(format)
          ? '16'
          : '10'
        : accessory === 'C'
        ? ['POS20X30', 'POS30X20'].includes(format)
          ? '12'
          : ['POSC30'].includes(format)
          ? '14'
          : '16'
        : '0';
    const paperPrice = format.includes('POS') && paperId === 0 ? '0' : '0';
    return parseFloat(accessoryPrice) + parseFloat(paperPrice);
  } else {
    return 0;
  }
};

const gridSlice = createSlice({
  name: 'grid',
  initialState: {
    value: false,
  },
  reducers: {
    gridToggle: (state) => {
      state.value = !state.value;
    },
  },
});



const cutZoneSlice = createSlice({
  name: 'cutZone',
  initialState: {
    value: true,
  },
  reducers: {
    cutZoneToggle: (state) => {
      state.value = !state.value;
    },
  },
});

const zoomSlice = createSlice({
  name: 'zoom',
  initialState: {
    value: 1,
  },
  reducers: {
    zoomTo: (state, action) => {
      state.value = Math.min(5, Math.max(0.5, action.payload));
    },
  },
});

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    value: 0,
  },
  reducers: {
    openMenu: (state, action) => {
      state.value = action.payload;
    },
    closeMenu: (state) => {
      state.value = 0;
    },
  },
});

const elementsSlice = createSlice({
  name: 'elements',
  initialState: {
    value: [] as Element[],
  },
  reducers: {
    loadElements: (state, action) => {
      state.value = action.payload;
    },
    addElement: (state, action) => {
      state.value.push({ ...action.payload.element, id: uuidv4() });
    },
    deleteElement: (state, action) => {
      state.value = state.value.filter(({ id }) => id !== action.payload.id);
    },
    modifyElement: (state, action) => {
      const elementIndex = state.value.findIndex(
        ({ id }) => id === action.payload.id,
      );
      if (action.payload.element) {
        state.value[elementIndex] = action.payload.element;
      } else if (action.payload.modification) {
        const updatedStateValue = [...state.value];
        updatedStateValue[elementIndex] = {
          ...updatedStateValue[elementIndex],
          ...action.payload.modification,
        };
        state.value = updatedStateValue;
      }
    },
    unlockElements: (state) => {
      state.value = state.value.map((element) => ({
        ...element,
        locked: false,
      }));
    },
  },
});

const focusSlice = createSlice({
  name: 'focus',
  initialState: {
    value: null,
  },
  reducers: {
    resetFocus: (state) => {
      state.value = null;
    },
    setFocus: (state, action) => {
      state.value = action.payload;
    },
  },
});

const faceSlice = createSlice({
  name: 'face',
  initialState: {
    value: 0,
  },
  reducers: {
    changeFace: (state, action) => {
      state.value = action.payload;
    },
  },
});

const ratioSlice = createSlice({
  name: 'ratio',
  initialState: {
    value: 1,
  },
  reducers: {
    changeRatio: (state, action) => {
      state.value = action.payload;
    },
  },
});

const previewRatioSlice = createSlice({
  name: 'ratio',
  initialState: {
    value: 1,
  },
  reducers: {
    changePreviewRatio: (state, action) => {
      state.value = action.payload;
    },
  },
});

const popupSlice = createSlice({
  name: 'popup',
  initialState: {
    value: false,
  },
  reducers: {
    activatePopup: (state) => {
      state.value = true;
    },
    desactivatePopup: (state) => {
      state.value = false;
    },
  },
});

const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    value: true,
  },
  reducers: {
    startLoading: (state) => {
      state.value = true;
    },
    endLoading: (state) => {
      state.value = false;
    },
  },
});

const changeNameSlice = createSlice({
  name: 'changeNameVisible',
  initialState: {
    value: false,
  },
  reducers: {
    openModal: (state) => {
      state.value = true;
    },
    closeModal: (state) => {
      state.value = false;
    },
  },
});

const folderOpenedSlice = createSlice({
  name: 'folderOpened',
  initialState: {
    value: '',
  },
  reducers: {
    openFolder: (state, action) => {
      state.value = action.payload;
    },
    closeFolder: (state) => {
      state.value = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeCard } = cardSlice.actions;
export const { changeCalendar } = calendarSlice.actions;
export const { changeAlbum, addPages, removePages } = albumSlice.actions;
export const { changeOther } = otherSlice.actions;
export const { gridToggle } = gridSlice.actions;
export const { cutZoneToggle } = cutZoneSlice.actions;
export const { zoomTo } = zoomSlice.actions;
export const { openMenu, closeMenu } = menuSlice.actions;
export const {
  loadElements,
  addElement,
  deleteElement,
  modifyElement,
  unlockElements,
} = elementsSlice.actions;
export const { resetFocus, setFocus } = focusSlice.actions;
export const { changeFace } = faceSlice.actions;
export const { changeRatio } = ratioSlice.actions;
export const { changePreviewRatio } = previewRatioSlice.actions;
export const { activatePopup, desactivatePopup } = popupSlice.actions;
export const { startLoading, endLoading } = loaderSlice.actions;
export const { openModal, closeModal } = changeNameSlice.actions;
export const { openFolder, closeFolder } = folderOpenedSlice.actions;

export const store = configureStore({
  reducer: {
    creation: undoable(
      combineReducers({
        card: cardSlice.reducer,
        calendar: calendarSlice.reducer,
        album: albumSlice.reducer,
        other: otherSlice.reducer,
        elements: elementsSlice.reducer,
      }),
    ),
    // card: undoable(cardSlice.reducer),
    // calendar: undoable(calendarSlice.reducer),
    // album: undoable(albumSlice.reducer),
    // other: undoable(otherSlice.reducer),
    grid: gridSlice.reducer,
    cutZone: cutZoneSlice.reducer,
    zoom: zoomSlice.reducer,
    menu: menuSlice.reducer,
    // elements: undoable(elementsSlice.reducer),
    focus: focusSlice.reducer,
    face: faceSlice.reducer,
    ratio: ratioSlice.reducer,
    previewRatio: previewRatioSlice.reducer,
    popup: popupSlice.reducer,
    loader: loaderSlice.reducer,
    changeName: changeNameSlice.reducer,
    folderOpened: folderOpenedSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
