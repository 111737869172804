import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const squareLayouts2Photo: LayoutElement[][] = [
  [
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 1,
      top: 0,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 1,
      top: 0,
      left: 0.51,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.74,
      top: 0.13,
      left: 0.09,
    },
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.74,
      top: 0.13,
      left: 0.51,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 1,
      height: 0.49,
      top: 0,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 1,
      height: 0.49,
      top: 0.51,
      left: 0,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.74,
      height: 0.4,
      top: 0.09,
      left: 0.13,
    },
    {
      type: ElementType.PHOTO,
      width: 0.74,
      height: 0.4,
      top: 0.51,
      left: 0.13,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.65,
      height: 1,
      top: 0,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.33,
      height: 1,
      top: 0,
      left: 0.67,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.74,
      top: 0.13,
      left: 0.12,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.74,
      top: 0.13,
      left: 0.63,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.6,
      top: 0.18,
      left: 0.09,
    },
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.6,
      top: 0.18,
      left: 0.51,
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.86,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.43,
      height: 0.48,
      top: 0.21,
      left: 0.06,
    },
    {
      type: ElementType.PHOTO,
      width: 0.43,
      height: 0.48,
      top: 0.21,
      left: 0.51,
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.76,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.43,
      top: 0.06,
      left: 0.3,
    },
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.43,
      top: 0.51,
      left: 0.3,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.46,
      height: 0.46,
      top: 0.03,
      left: 0.1,
    },
    {
      type: ElementType.PHOTO,
      width: 0.46,
      height: 0.46,
      top: 0.51,
      left: 0.1,
    },
    {
      type: ElementType.TEXT,
      width: 0.3,
      height: 0.4,
      top: 0.06,
      left: 0.6,
    },
    {
      type: ElementType.TEXT,
      width: 0.3,
      height: 0.4,
      top: 0.54,
      left: 0.6,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.51,
      height: 0.41,
      top: 0.08,
      left: 0.34,
    },
    {
      type: ElementType.PHOTO,
      width: 0.51,
      height: 0.41,
      top: 0.51,
      left: 0.15,
    },
    {
      type: ElementType.TEXT,
      width: 0.17,
      height: 0.41,
      top: 0.08,
      left: 0.15,
    },
    {
      type: ElementType.TEXT,
      width: 0.17,
      height: 0.41,
      top: 0.51,
      left: 0.68,
    },
  ],
];
