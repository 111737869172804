// Question.tsx
import React, { useState } from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';

interface QuestionProps {
  title: string;
  answer: string;
}

const QuestionComponent: React.FC<QuestionProps> = ({ title, answer }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <QuestionContainer onClick={toggleExpansion}>
        <QuestionTitle>{title}</QuestionTitle>
        <ExpandIcon
          expanded={expanded}
          src={'/svg/left_chevron.svg'}
        ></ExpandIcon>
      </QuestionContainer>
      <Answer $expanded={expanded}>{answer}</Answer>
    </div>
  );
};

const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  color: ${colors.black};
`;

const QuestionTitle = styled.div`
  flex: 1;
  font-family: 'DM Sans';
  font-size: 12px;
  font-weight: 700;
  text-align: left;

  box-sizing: border-box;
  padding-right: 10px;
`;

const ExpandIcon = styled.img<{ expanded: boolean }>`
  margin-left: 10px;
  scale: 0.5;
  transform: rotate(270deg);
  transition: transform 0.3s ease-in-out;

  ${(props) =>
    props.expanded
      ? `
    transform: rotate(90deg);
  `
      : ''}
`;

const Answer = styled.div<{ $expanded: boolean }>`
  height: auto;
  font-family: 'DM Sans';
  font-size: 12px;
  font-weight: 400;
  text-align: left;

  margin-top: 10px;
  color: ${colors.black};
  transition: height 0.8s ease-in-out, opacity 0.3s ease-in-out;
  white-space: pre-line;

  ${(props) =>
    !props.$expanded
      ? `
      height: 0px;
      opacity: 0;
      overflow: hidden;
    `
      : ''}
`;

export default QuestionComponent;
