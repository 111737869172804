import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const squareLayouts5MorePhoto: LayoutElement[][] = [
  [
    {
      type: ElementType.PHOTO,
      width: 0.235,
      height: 0.49,
      top: 0,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.235,
      height: 0.49,
      top: 0,
      left: 0.255,
    },
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.49,
      top: 0,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.235,
      height: 0.49,
      top: 0.51,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.235,
      height: 0.49,
      top: 0.51,
      left: 0.765,
    },
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.49,
      top: 0.51,
      left: 0,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.195,
      height: 0.41,
      top: 0.08,
      left: 0.08,
    },
    {
      type: ElementType.PHOTO,
      width: 0.195,
      height: 0.41,
      top: 0.08,
      left: 0.295,
    },
    {
      type: ElementType.PHOTO,
      width: 0.195,
      height: 0.41,
      top: 0.51,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.195,
      height: 0.41,
      top: 0.51,
      left: 0.725,
    },
    {
      type: ElementType.PHOTO,
      width: 0.41,
      height: 0.41,
      top: 0.08,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.41,
      height: 0.41,
      top: 0.51,
      left: 0.08,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.235,
      height: 0.32,
      top: 0.17,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.235,
      height: 0.32,
      top: 0.17,
      left: 0.255,
    },
    {
      type: ElementType.PHOTO,
      width: 0.235,
      height: 0.32,
      top: 0.17,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.235,
      height: 0.32,
      top: 0.17,
      left: 0.765,
    },
    {
      type: ElementType.PHOTO,
      width: 0.235,
      height: 0.32,
      top: 0.51,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.235,
      height: 0.32,
      top: 0.51,
      left: 0.255,
    },
    {
      type: ElementType.PHOTO,
      width: 0.235,
      height: 0.32,
      top: 0.51,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.235,
      height: 0.32,
      top: 0.51,
      left: 0.765,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.32,
      top: 0.18,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.32,
      top: 0.18,
      left: 0.25,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.32,
      top: 0.18,
      left: 0.5,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.32,
      top: 0.18,
      left: 0.75,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.32,
      top: 0.5,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.32,
      top: 0.5,
      left: 0.25,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.32,
      top: 0.5,
      left: 0.5,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.32,
      top: 0.5,
      left: 0.75,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.24,
      left: 0.09,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.24,
      left: 0.3,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.24,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.24,
      left: 0.72,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.51,
      left: 0.09,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.51,
      left: 0.3,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.51,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.51,
      left: 0.72,
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.8,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.25,
      top: 0.105,
      left: 0.13,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.25,
      top: 0.105,
      left: 0.39,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.25,
      top: 0.105,
      left: 0.65,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.25,
      top: 0.375,
      left: 0.13,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.25,
      top: 0.375,
      left: 0.39,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.25,
      top: 0.375,
      left: 0.65,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.25,
      top: 0.645,
      left: 0.13,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.25,
      top: 0.645,
      left: 0.39,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.25,
      top: 0.645,
      left: 0.65,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.105,
      left: 0.09,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.105,
      left: 0.3,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.105,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.105,
      left: 0.72,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.375,
      left: 0.09,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.375,
      left: 0.3,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.375,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.375,
      left: 0.72,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.645,
      left: 0.09,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.645,
      left: 0.3,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.645,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.645,
      left: 0.72,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.47,
      top: 0.095,
      left: 0.12,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.47,
      top: 0.435,
      left: 0.38,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.47,
      top: 0.095,
      left: 0.64,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.32,
      top: 0.585,
      left: 0.12,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.32,
      top: 0.095,
      left: 0.38,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.32,
      top: 0.585,
      left: 0.12,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.32,
      top: 0.585,
      left: 0.64,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.4,
      top: 0.09,
      left: 0.145,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.4,
      top: 0.51,
      left: 0.145,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.4,
      top: 0.09,
      left: 0.615,
    },
    {
      type: ElementType.PHOTO,
      width: 0.24,
      height: 0.4,
      top: 0.51,
      left: 0.615,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.26,
      top: 0.09,
      left: 0.405,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.26,
      top: 0.37,
      left: 0.405,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.26,
      top: 0.65,
      left: 0.405,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.47,
      height: 0.24,
      top: 0.12,
      left: 0.095,
    },
    {
      type: ElementType.PHOTO,
      width: 0.47,
      height: 0.24,
      top: 0.38,
      left: 0.435,
    },
    {
      type: ElementType.PHOTO,
      width: 0.47,
      height: 0.24,
      top: 0.64,
      left: 0.095,
    },
    {
      type: ElementType.PHOTO,
      width: 0.32,
      height: 0.24,
      top: 0.12,
      left: 0.585,
    },
    {
      type: ElementType.PHOTO,
      width: 0.32,
      height: 0.24,
      top: 0.38,
      left: 0.095,
    },
    {
      type: ElementType.PHOTO,
      width: 0.32,
      height: 0.24,
      top: 0.12,
      left: 0.585,
    },
    {
      type: ElementType.PHOTO,
      width: 0.32,
      height: 0.24,
      top: 0.64,
      left: 0.585,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.24,
      top: 0.145,
      left: 0.09,
    },
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.24,
      top: 0.145,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.24,
      top: 0.615,
      left: 0.09,
    },
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.24,
      top: 0.615,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.26,
      height: 0.19,
      top: 0.405,
      left: 0.09,
    },
    {
      type: ElementType.PHOTO,
      width: 0.26,
      height: 0.19,
      top: 0.405,
      left: 0.37,
    },
    {
      type: ElementType.PHOTO,
      width: 0.26,
      height: 0.19,
      top: 0.405,
      left: 0.65,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.24,
      left: 0.195,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.24,
      left: 0.405,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.24,
      left: 0.615,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.51,
      left: 0.195,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.51,
      left: 0.405,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.25,
      top: 0.51,
      left: 0.615,
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.81,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.23,
      top: 0.26,
      left: 0.105,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.23,
      top: 0.26,
      left: 0.375,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.23,
      top: 0.26,
      left: 0.645,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.23,
      top: 0.51,
      left: 0.105,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.23,
      top: 0.51,
      left: 0.375,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.23,
      top: 0.51,
      left: 0.645,
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.81,
      left: 0.24,
    },
  ],
];
