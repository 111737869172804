import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const portraitLayouts3Photo: LayoutElement[][] = [
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.36,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.64,
          top: 0,
          left: 0,
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.64,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.36,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.64,
      height: 0.65,
      top: 0.17,
      left: 0.18,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.36,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.64,
          top: 0,
          left: 0,
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.73,
      height: 0.07,
      top: 0.86,
      left: 0.135,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.64,
      height: 0.56,
      top: 0.215,
      left: 0.18,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.73,
      height: 0.07,
      top: 0.86,
      left: 0.135,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.64,
      height: 0.56,
      top: 0.215,
      left: 0.18,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.73,
      height: 0.07,
      top: 0.86,
      left: 0.135,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.8,
      height: 0.24,
      top: 0.36,
      left: 0.1,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.73,
      height: 0.07,
      top: 0.74,
      left: 0.135,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.22,
      top: 0.07,
      left: 0.3,
    },
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.22,
      top: 0.38,
      left: 0.3,
    },
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.22,
      top: 0.7,
      left: 0.3,
    },
    {
      type: ElementType.TEXT,
      width: 0.4,
      height: 0.06,
      top: 0.31,
      left: 0.3,
    },
    {
      type: ElementType.TEXT,
      width: 0.4,
      height: 0.06,
      top: 0.62,
      left: 0.3,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.4,
      height: 0.61,
      top: 0.21,
      left: 0.3,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.74,
      height: 0.08,
      top: 0.845,
      left: 0.13,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.8,
      height: 0.79,
      top: 0.105,
      left: 0.1,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.6,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.TEXT,
              width: 0.4,
              height: 0.8,
              top: 0.1,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.6,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.TEXT,
              width: 0.4,
              height: 0.8,
              top: 0.1,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.6,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.TEXT,
              width: 0.4,
              height: 0.8,
              top: 0.1,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
];
