import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const squareLayouts3Photo: LayoutElement[][] = [
  [
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 1,
      top: 0,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.49,
      top: 0,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.49,
      top: 0.51,
      left: 0.51,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.37,
      height: 0.76,
      top: 0.12,
      left: 0.12,
    },
    {
      type: ElementType.PHOTO,
      width: 0.37,
      height: 0.37,
      top: 0.12,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.37,
      height: 0.37,
      top: 0.51,
      left: 0.51,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.64,
      height: 1,
      top: 0,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.34,
      height: 0.49,
      top: 0,
      left: 0.66,
    },
    {
      type: ElementType.PHOTO,
      width: 0.34,
      height: 0.49,
      top: 0.51,
      left: 0.66,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 1,
      height: 0.49,
      top: 0.51,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.49,
      top: 0,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.49,
      top: 0,
      left: 0.51,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.76,
      height: 0.37,
      top: 0.51,
      left: 0.12,
    },
    {
      type: ElementType.PHOTO,
      width: 0.37,
      height: 0.37,
      top: 0.12,
      left: 0.12,
    },
    {
      type: ElementType.PHOTO,
      width: 0.37,
      height: 0.37,
      top: 0.12,
      left: 0.51,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 1,
      height: 0.64,
      top: 0.36,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.34,
      top: 0,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.34,
      top: 0,
      left: 0.51,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.37,
      height: 0.76,
      top: 0.12,
      left: 0.17,
    },
    {
      type: ElementType.PHOTO,
      width: 0.27,
      height: 0.37,
      top: 0.12,
      left: 0.56,
    },
    {
      type: ElementType.PHOTO,
      width: 0.27,
      height: 0.37,
      top: 0.51,
      left: 0.56,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.32,
      height: 0.52,
      top: 0.25,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.32,
      height: 0.52,
      top: 0.25,
      left: 0.34,
    },
    {
      type: ElementType.PHOTO,
      width: 0.32,
      height: 0.52,
      top: 0.25,
      left: 0.68,
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.84,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.28,
      height: 0.42,
      top: 0.29,
      left: 0.06,
    },
    {
      type: ElementType.PHOTO,
      width: 0.28,
      height: 0.42,
      top: 0.29,
      left: 0.36,
    },
    {
      type: ElementType.PHOTO,
      width: 0.28,
      height: 0.42,
      top: 0.29,
      left: 0.66,
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.77,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.35,
      height: 0.49,
      top: 0,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.35,
      height: 0.49,
      top: 0.51,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.63,
      height: 1,
      top: 0,
      left: 0.37,
    },
  ],
];
