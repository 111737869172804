import { styled } from 'styled-components';
import colors from '../../constants/colors';

export enum buttonType {
  'black',
  'white',
  'white-noborder',
  'themed',
  'red',
}

const Button = styled.button<{
  $type: buttonType;
  disabled?: boolean;
  $themeColor?: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  box-sizing: border-box;
  border: 1px solid;
  border-radius: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  gap: 16px;

  background-color: ${(props) =>
    props.disabled
      ? props.$type === buttonType.white ||
        props.$type === buttonType['white-noborder']
        ? colors.white
        : colors.gray400
      : props.$type === buttonType.black
      ? colors.black
      : props.$type === buttonType.themed
      ? props.$themeColor || colors.green
      : props.$type === buttonType.red
      ? colors.error600
      : colors.white};
  color: ${(props) =>
    props.$type === buttonType.black ||
    props.$type === buttonType.themed ||
    props.$type === buttonType.red
      ? colors.white
      : colors.black};
  border: ${(props) =>
    props.$type === buttonType.white ? 'solid 1px ' + colors.gray300 : 'none'};

  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
`;

export default Button;
