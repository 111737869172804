import { css } from 'styled-components';
import { TextElementStyle } from '../../../types/models/Elements';
import { ConvertPt300DPIToPixel } from '../../../utils/convertCMToPixel';

export const TextDisplayer = css<{
  $elementstyle: TextElementStyle | null;
  $ratio: number;
}>`
  &:empty:before {
    content: attr(data-placeholder);
    color: grey;
  }
  max-width: 100%;
  max-height: 100%;
  background-color: transparent;
  z-index: 10;

  font-weight: ${(props) => (props.$elementstyle?.bold ? 'bold' : 'normal')};
  font-style: ${(props) => (props.$elementstyle?.italic ? 'italic' : 'normal')};
  text-decoration: ${(props) =>
    props.$elementstyle?.underlined ? 'underline' : 'none'};
  text-transform: ${(props) => props.$elementstyle?.transform};
  text-align: ${(props) => props.$elementstyle?.alignement};
  font-family: ${(props) => props.$elementstyle?.font};
  font-size: ${(props) =>
    ConvertPt300DPIToPixel(
      props.$elementstyle?.size ? (props.$elementstyle.size as number) : 10,
    ) * props.$ratio}px !important;
  letter-spacing: ${({ $elementstyle, $ratio }): any =>
    $elementstyle?.letterSpacing
      ? `${ConvertPt300DPIToPixel($elementstyle.letterSpacing) * $ratio}px`
      : 1};
  line-height: ${({ $elementstyle, $ratio }): any =>
    $elementstyle?.interline
      ? `${
          ConvertPt300DPIToPixel(
            ($elementstyle.size
              ? parseInt($elementstyle.size.toString())
              : 10) + parseFloat($elementstyle.interline.toString()),
          ) * $ratio
        }px`
      : 1};
  color: ${(props) => props.$elementstyle?.color};

  word-wrap: break-word;
  white-space: pre-wrap;
  overflow: visible;
  cursor: text;

  transform: ${(props) =>
    props.$elementstyle?.rotation
      ? 'rotate(' + props.$elementstyle.rotation + 'deg) '
      : ''};

  &:focus {
    outline: none;
  }
`;
