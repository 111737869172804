export const styleConstantsNumbers = {
  breakpointSm: 480,
  breakpointMd: 720,
  breakpointLg: 1300,
  breakpointXLg: 1920,
};

const styleConstants = Object.fromEntries(
  Object.entries(styleConstantsNumbers).map(([k, v]) => [
    k,
    v.toString() + 'px',
  ]),
);

export default styleConstants;
