import { FormatsObject } from '../types/models/Cards';
import { defaultElementsOnPage } from './defaultElementOnPage';

export const formats: FormatsObject = {
  G2H: {
    width: 148,
    height: 213,
    faces: 3,
    name: 'Save the date',
    facesSizes: [
      {
        width: 148,
        height: 106.5,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 148,
        height: 213,
        horizontalBend: [106.5],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 148,
        height: 106.5,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  G2H_old: {
    width: 148,
    height: 213,
    faces: 2,
    name: 'Save the date',
    facesSizes: [
      {
        width: 148,
        height: 213,
        horizontalBend: [106.5],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 148,
        height: 213,
        horizontalBend: [106.5],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  PKG1: {
    width: 218,
    height: 53,
    faces: 1,
    name: 'Save the date',
    facesSizes: [
      {
        width: 218,
        height: 53,
        horizontalBend: [],
        verticalBend: [52, 103, 154, 208],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  PKG1_old: {
    width: 218,
    height: 53,
    faces: 1,
    name: 'Save the date',
    facesSizes: [
      {
        width: 218,
        height: 53,
        horizontalBend: [],
        verticalBend: [52, 103, 154, 208],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  G2G: {
    width: 293,
    height: 108,
    faces: 3,
    name: 'Date the save',
    facesSizes: [
      {
        width: 146.5,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 293,
        height: 108,
        horizontalBend: [],
        verticalBend: [146.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 146.5,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  G2G_old: {
    width: 293,
    height: 108,
    faces: 2,
    name: 'Date the save',
    facesSizes: [
      {
        width: 293,
        height: 108,
        horizontalBend: [],
        verticalBend: [146.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 293,
        height: 108,
        horizontalBend: [],
        verticalBend: [146.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  G1G: {
    width: 213,
    height: 148,
    faces: 3,
    name: 'Date the save',
    facesSizes: [
      {
        width: 106.5,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 213,
        height: 148,
        horizontalBend: [],
        verticalBend: [106.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 106.5,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  G1G_old: {
    width: 213,
    height: 148,
    faces: 2,
    name: 'Date the save',
    facesSizes: [
      {
        width: 213,
        height: 148,
        horizontalBend: [],
        verticalBend: [106.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 213,
        height: 148,
        horizontalBend: [],
        verticalBend: [106.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  DL1R: {
    width: 108,
    height: 213,
    faces: 1,
    name: 'Date the save',
    facesSizes: [
      {
        width: 108,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  DL1R_old: {
    width: 108,
    height: 213,
    faces: 1,
    name: 'Date the save',
    facesSizes: [
      {
        width: 108,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  DL1RV: {
    width: 108,
    height: 213,
    faces: 2,
    name: 'Date the save',
    facesSizes: [
      {
        width: 108,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 108,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  DL1RV_old: {
    width: 108,
    height: 213,
    faces: 2,
    name: 'Date the save',
    facesSizes: [
      {
        width: 108,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 108,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  DL2R: {
    width: 213,
    height: 108,
    faces: 1,
    name: 'Date the save',
    facesSizes: [
      {
        width: 213,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  DL2R_old: {
    width: 213,
    height: 108,
    faces: 1,
    name: 'Date the save',
    facesSizes: [
      {
        width: 213,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  DL2RV: {
    width: 213,
    height: 108,
    faces: 2,
    name: 'Date the save',
    facesSizes: [
      {
        width: 213,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 213,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  DL2RV_old: {
    width: 213,
    height: 108,
    faces: 2,
    name: 'Date the save',
    facesSizes: [
      {
        width: 213,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 213,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  G2R: {
    width: 148,
    height: 108,
    faces: 1,
    name: 'Date the save',
    facesSizes: [
      {
        width: 148,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  G2R_old: {
    width: 148,
    height: 108,
    faces: 1,
    name: 'Date the save',
    facesSizes: [
      {
        width: 148,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  G2GD: {
    width: 293,
    height: 108,
    faces: 3,
    name: 'Date the save',
    facesSizes: [
      {
        width: 148,
        height: 108,
        horizontalBend: [],
        verticalBend: [74],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 293,
        height: 108,
        horizontalBend: [],
        verticalBend: [74, 219],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 148,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  G2GD_old: {
    width: 293,
    height: 108,
    faces: 2,
    name: 'Date the save',
    facesSizes: [
      {
        width: 293,
        height: 108,
        horizontalBend: [],
        verticalBend: [74, 219],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 293,
        height: 108,
        horizontalBend: [],
        verticalBend: [74, 219],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  G2RV: {
    width: 148,
    height: 108,
    faces: 2,
    name: 'Date the save',
    facesSizes: [
      {
        width: 148,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 148,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  G2RV_old: {
    width: 148,
    height: 108,
    faces: 2,
    name: 'Date the save',
    facesSizes: [
      {
        width: 148,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 148,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  G1R: {
    width: 108,
    height: 148,
    faces: 1,
    name: 'Date the save',
    facesSizes: [
      {
        width: 108,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  G1R_old: {
    width: 108,
    height: 148,
    faces: 1,
    name: 'Date the save',
    facesSizes: [
      {
        width: 108,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  G1RV: {
    width: 108,
    height: 148,
    faces: 2,
    name: 'Date the save',
    facesSizes: [
      {
        width: 108,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 108,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  G1RV_old: {
    width: 108,
    height: 148,
    faces: 2,
    name: 'Date the save',
    facesSizes: [
      {
        width: 108,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 108,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  M1R: {
    width: 58,
    height: 185,
    faces: 1,
    name: 'Date the save',
    facesSizes: [
      {
        width: 58,
        height: 185,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  M1R_old: {
    width: 58,
    height: 185,
    faces: 1,
    name: 'Date the save',
    facesSizes: [
      {
        width: 58,
        height: 185,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  M1RV: {
    width: 58,
    height: 185,
    faces: 2,
    name: 'Date the save',
    facesSizes: [
      {
        width: 58,
        height: 185,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 58,
        height: 185,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  M1RV_old: {
    width: 58,
    height: 185,
    faces: 2,
    name: 'Date the save',
    facesSizes: [
      {
        width: 58,
        height: 185,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 58,
        height: 185,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  MCRV: {
    width: 98,
    height: 98,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 98,
        height: 98,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 98,
        height: 98,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  MCRV_old: {
    width: 98,
    height: 98,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 98,
        height: 98,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 98,
        height: 98,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  P1R: {
    width: 148,
    height: 213,
    faces: 1,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 148,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  P1R_old: {
    width: 148,
    height: 213,
    faces: 1,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 148,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  P1RV: {
    width: 148,
    height: 213,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 148,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 148,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  P1RV_old: {
    width: 148,
    height: 213,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 148,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 148,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  P2R: {
    width: 213,
    height: 148,
    faces: 1,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 213,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  P2R_old: {
    width: 213,
    height: 148,
    faces: 1,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 213,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  P2RV: {
    width: 213,
    height: 148,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 213,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 213,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  P2RV_old: {
    width: 213,
    height: 148,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 213,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 213,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  CFG: {
    width: 284,
    height: 143,
    faces: 5,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 143,
        height: 143,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 284,
        height: 143,
        horizontalBend: [],
        verticalBend: [141.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 143,
        height: 143,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 40,
        height: 40,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [21.5, 21.5, 21.5, 21.5],
        forbiddenZones: [
          {
            width: 3,
            height: 3,
            top: 4,
            left: 18.5,
            radius: [2, 2, 2, 2],
          },
        ],
      },
      {
        width: 40,
        height: 40,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [21.5, 21.5, 21.5, 21.5],
        forbiddenZones: [
          {
            width: 3,
            height: 3,
            top: 4,
            left: 18.5,
            radius: [2, 2, 2, 2],
          },
        ],
      },
    ],
    stringId: 0,
  },
  CFG_old: {
    width: 284,
    height: 143,
    faces: 4,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 284,
        height: 143,
        horizontalBend: [],
        verticalBend: [141.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 284,
        height: 143,
        horizontalBend: [],
        verticalBend: [141.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 40,
        height: 40,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [20, 20, 20, 20],
        forbiddenZones: [
          {
            width: 3,
            height: 3,
            top: 4,
            left: 18.5,
            radius: [2, 2, 2, 2],
          },
        ],
      },
      {
        width: 40,
        height: 40,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [20, 20, 20, 20],
        forbiddenZones: [
          {
            width: 3,
            height: 3,
            top: 4,
            left: 18.5,
            radius: [2, 2, 2, 2],
          },
        ],
      },
    ],
    stringId: 0,
  },
  PFM: {
    width: 153,
    height: 205,
    faces: 5,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 76.5,
        height: 205,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 153,
        height: 205,
        horizontalBend: [],
        verticalBend: [76.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 76.5,
        height: 205,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 58,
        height: 185,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 58,
        height: 185,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  PFM_old: {
    width: 153,
    height: 205,
    faces: 4,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 153,
        height: 205,
        horizontalBend: [],
        verticalBend: [76.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 153,
        height: 205,
        horizontalBend: [],
        verticalBend: [76.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 58,
        height: 185,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 58,
        height: 185,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  P1G: {
    width: 303,
    height: 213,
    faces: 3,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 151.5,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 303,
        height: 213,
        horizontalBend: [],
        verticalBend: [151.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 151.5,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  P1G_old: {
    width: 303,
    height: 213,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 303,
        height: 213,
        horizontalBend: [],
        verticalBend: [151.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 303,
        height: 213,
        horizontalBend: [],
        verticalBend: [151.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  PF2HB: {
    width: 154,
    height: 258,
    faces: 6,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 154,
        height: 109.5,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 154,
        height: 41.5,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 154,
        height: 258,
        horizontalBend: [109.5, 217.5],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 154,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 43,
        height: 43,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [21.5, 21.5, 21.5, 21.5],
        forbiddenZones: [
          {
            width: 3,
            height: 3,
            top: 4,
            left: 18.5,
            radius: [2, 2, 2, 2],
          },
        ],
      },
      {
        width: 43,
        height: 43,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [21.5, 21.5, 21.5, 21.5],
        forbiddenZones: [
          {
            width: 3,
            height: 3,
            top: 4,
            left: 18.5,
            radius: [2, 2, 2, 2],
          },
        ],
      },
    ],
    stringId: 0,
  },
  PF2HB_old: {
    width: 154,
    height: 258,
    faces: 4,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 154,
        height: 258,
        horizontalBend: [109.5, 217.5],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 154,
        height: 258,
        horizontalBend: [109.5, 217.5],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 40,
        height: 40,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [20, 20, 20, 20],
        forbiddenZones: [
          {
            width: 3,
            height: 3,
            top: 4,
            left: 18.5,
            radius: [2, 2, 2, 2],
          },
        ],
      },
      {
        width: 40,
        height: 40,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [20, 20, 20, 20],
        forbiddenZones: [
          {
            width: 3,
            height: 3,
            top: 4,
            left: 18.5,
            radius: [2, 2, 2, 2],
          },
        ],
      },
    ],
    stringId: 0,
  },
  CG: {
    width: 283,
    height: 143,
    faces: 3,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 143,
        height: 143,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 283,
        height: 143,
        horizontalBend: [],
        verticalBend: [141.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 143,
        height: 143,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  CG_old: {
    width: 283,
    height: 143,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 283,
        height: 143,
        horizontalBend: [],
        verticalBend: [141.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 283,
        height: 143,
        horizontalBend: [],
        verticalBend: [141.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  CGD: {
    width: 303,
    height: 143,
    faces: 3,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 153,
        height: 143,
        horizontalBend: [],
        verticalBend: [76.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 303,
        height: 143,
        horizontalBend: [],
        verticalBend: [76.5, 226.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 153,
        height: 143,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  CGD_old: {
    width: 303,
    height: 143,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 303,
        height: 143,
        horizontalBend: [],
        verticalBend: [76.5, 226.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 303,
        height: 143,
        horizontalBend: [],
        verticalBend: [76.5, 226.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  R: {
    width: 143,
    height: 143,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 143,
        height: 143,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [72, 72, 72, 72],
      },
      {
        width: 143,
        height: 143,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [72, 72, 72, 72],
      },
    ],
  },
  R_old: {
    width: 143,
    height: 143,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 143,
        height: 143,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [72, 72, 72, 72],
      },
      {
        width: 143,
        height: 143,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [72, 72, 72, 72],
      },
    ],
  },
  DL2G: {
    width: 423,
    height: 103,
    faces: 3,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 213,
        height: 103,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 423,
        height: 103,
        horizontalBend: [],
        verticalBend: [211.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 213,
        height: 103,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  DL2G_old: {
    width: 423,
    height: 103,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 423,
        height: 103,
        horizontalBend: [],
        verticalBend: [211.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 423,
        height: 103,
        horizontalBend: [],
        verticalBend: [211.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  DL1G: {
    width: 213,
    height: 213,
    faces: 3,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 108,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 213,
        height: 213,
        horizontalBend: [],
        verticalBend: [106.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 108,
        height: 213,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  DL1G_old: {
    width: 213,
    height: 213,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 213,
        height: 213,
        horizontalBend: [],
        verticalBend: [106.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 213,
        height: 213,
        horizontalBend: [],
        verticalBend: [106.5],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  DL2H: {
    width: 213,
    height: 213,
    faces: 3,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 213,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 213,
        height: 213,
        horizontalBend: [106.5],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 213,
        height: 108,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  DL2H_old: {
    width: 213,
    height: 213,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 213,
        height: 213,
        horizontalBend: [106.5],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 213,
        height: 213,
        horizontalBend: [106.5],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  CR: {
    width: 143,
    height: 143,
    faces: 1,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 143,
        height: 143,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  CR_old: {
    width: 143,
    height: 143,
    faces: 1,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 143,
        height: 143,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  CRV: {
    width: 143,
    height: 143,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 143,
        height: 143,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 143,
        height: 143,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  CRV_old: {
    width: 143,
    height: 143,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 143,
        height: 143,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 143,
        height: 143,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
    cutId: 0,
  },
  MP: {
    width: 78,
    height: 113,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 78,
        height: 56.5,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 78,
        height: 56.5,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  MP_old: {
    width: 78,
    height: 113,
    faces: 1,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 78,
        height: 113,
        horizontalBend: [56.5],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  ARG: {
    width: 108,
    height: 148,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 108,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [40, 40, 0, 0],
      },
      {
        width: 108,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [40, 40, 0, 0],
      },
    ],
  },
  ARG_old: {
    width: 108,
    height: 148,
    faces: 2,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 108,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [40, 40, 0, 0],
      },
      {
        width: 108,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [40, 40, 0, 0],
      },
    ],
  },
  ARP: {
    width: 213,
    height: 148,
    faces: 1,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 213,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [60, 60, 0, 0],
      },
    ],
  },
  ARP_old: {
    width: 213,
    height: 148,
    faces: 1,
    name: 'Joli carré',
    facesSizes: [
      {
        width: 213,
        height: 148,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [60, 60, 0, 0],
      },
    ],
  },
  MAGVIN: {
    name: 'Magnet vintage 6.9x5.5cm (lot de 10)',
    width: 55,
    height: 69,
    faces: 10,
    facesSizes: [
      {
        width: 55,
        height: 69,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 55,
        height: 69,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 55,
        height: 69,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 55,
        height: 69,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 55,
        height: 69,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 55,
        height: 69,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 55,
        height: 69,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 55,
        height: 69,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 55,
        height: 69,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 55,
        height: 69,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  MAGCAB: {
    name: 'Magnet photo cabine 14.7x4.4cm (lot de 6)',
    width: 44,
    height: 147,
    faces: 6,
    defaultElementsOnPage: 'vertical4Photos',
    facesSizes: [
      {
        width: 44,
        height: 147,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 44,
        height: 147,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 44,
        height: 147,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 44,
        height: 147,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 44,
        height: 147,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 44,
        height: 147,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  MAGCAR6: {
    name: 'Magnet vintage 6x6cm (lot de 12)',
    width: 60,
    height: 60,
    faces: 12,
    facesSizes: [
      {
        width: 60,
        height: 60,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 60,
        height: 60,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 60,
        height: 60,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 60,
        height: 60,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 60,
        height: 60,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 60,
        height: 60,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 60,
        height: 60,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 60,
        height: 60,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 60,
        height: 60,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 60,
        height: 60,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 60,
        height: 60,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 60,
        height: 60,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  MAGCAR8: {
    name: 'Magnet carré 8x8cm (lot de 7)',
    width: 80,
    height: 80,
    faces: 7,
    facesSizes: [
      {
        width: 80,
        height: 80,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 80,
        height: 80,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 80,
        height: 80,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 80,
        height: 80,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 80,
        height: 80,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 80,
        height: 80,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
      {
        width: 80,
        height: 80,
        horizontalBend: [],
        verticalBend: [],
        bgColor: '#FFFFFF',
        bgTheme: undefined,
        radius: [0, 0, 0, 0],
      },
    ],
  },
};
