import {
  DefaultElementOnPage,
  defaultElementsOnPage,
} from './defaultElementOnPage';

export enum OtherType {
  'Toile',
  'Tableau_Alu',
  'Tableau_Carton',
  'Ornement_Metallique',
  'Affiche',
  'Toise',
  'Gourde',
  'Mug',
  'Magnet',
  'Sticker',
  'Tirage',
}

export type OtherFormat = {
  name: string;
  width: number;
  height: number;
  effectiveWidth?: number;
  effectiveHeight?: number;
  type: OtherType;
  defaultElementsOnPage?: string;
  forbiddenZones?: {
    width: number;
    height: number;
    top: number;
    left: number;
    radius: number[];
  }[];
  optionnalZones?: {
    width: number;
    height: number;
    top: number;
    left: number;
    radius: number[];
  }[];
};

type OtherFormats = Record<string, OtherFormat>;

export const otherFormats: OtherFormats = {
  TOICAR50: {
    name: 'Toile carrée 50x50cm',
    width: 550,
    height: 550,
    effectiveWidth: 500,
    effectiveHeight: 500,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 525,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 525,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 525,
        left: 525,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 500,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 500,
        top: 25,
        left: 525,
        radius: [0, 0, 0, 0],
      },
      {
        width: 500,
        height: 25,
        top: 525,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 500,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  TOICAR40: {
    name: 'Toile carrée 40x40cm',
    width: 450,
    height: 450,
    effectiveHeight: 400,
    effectiveWidth: 400,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 425,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 425,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 425,
        left: 425,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 400,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 400,
        top: 25,
        left: 425,
        radius: [0, 0, 0, 0],
      },
      {
        width: 400,
        height: 25,
        top: 425,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 400,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  TOICAR30: {
    name: 'Toile carrée 30x30cm',
    width: 350,
    height: 350,
    effectiveWidth: 300,
    effectiveHeight: 300,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 325,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 325,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 325,
        left: 325,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 300,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 300,
        top: 25,
        left: 325,
        radius: [0, 0, 0, 0],
      },
      {
        width: 300,
        height: 25,
        top: 325,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 300,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  TOICAR20: {
    name: 'Toile carrée 20x20cm',
    width: 250,
    height: 250,
    effectiveHeight: 200,
    effectiveWidth: 200,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 225,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 225,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 225,
        left: 225,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 200,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 200,
        top: 25,
        left: 225,
        radius: [0, 0, 0, 0],
      },
      {
        width: 200,
        height: 25,
        top: 225,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 200,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  TOIPOR20X30: {
    name: 'Toile portrait 20x30cm',
    width: 250,
    height: 350,
    effectiveWidth: 200,
    effectiveHeight: 300,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 225,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 325,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 325,
        left: 225,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 200,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 300,
        top: 25,
        left: 225,
        radius: [0, 0, 0, 0],
      },
      {
        width: 200,
        height: 25,
        top: 325,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 300,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  TOIPOR30X40: {
    name: 'Toile portrait 30x40cm',
    width: 350,
    height: 450,
    effectiveWidth: 300,
    effectiveHeight: 400,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 325,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 425,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 425,
        left: 325,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 300,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 400,
        top: 25,
        left: 325,
        radius: [0, 0, 0, 0],
      },
      {
        width: 300,
        height: 25,
        top: 425,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 400,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  TOIPOR40X60: {
    name: 'Toile portrait 40x60cm',
    width: 450,
    height: 650,
    effectiveWidth: 400,
    effectiveHeight: 600,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 425,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 625,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 625,
        left: 425,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 400,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 600,
        top: 25,
        left: 425,
        radius: [0, 0, 0, 0],
      },
      {
        width: 400,
        height: 25,
        top: 625,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 600,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  TOIPOR60X80: {
    name: 'Toile portrait 60x80cm',
    width: 650,
    height: 850,
    effectiveWidth: 600,
    effectiveHeight: 800,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 625,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 825,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 825,
        left: 625,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 600,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 800,
        top: 25,
        left: 625,
        radius: [0, 0, 0, 0],
      },
      {
        width: 600,
        height: 25,
        top: 825,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 800,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  TOIPOR30X70: {
    name: 'Toile panoramique portrait 30x70cm',
    width: 350,
    height: 750,
    effectiveWidth: 300,
    effectiveHeight: 700,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 325,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 725,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 725,
        left: 325,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 300,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 700,
        top: 25,
        left: 325,
        radius: [0, 0, 0, 0],
      },
      {
        width: 300,
        height: 25,
        top: 725,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 700,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  TOIPOR75X100: {
    name: 'Toile portrait 75x100cm',
    width: 800,
    height: 1050,
    effectiveWidth: 750,
    effectiveHeight: 1000,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 775,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 1025,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 1025,
        left: 775,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 750,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 1000,
        top: 25,
        left: 775,
        radius: [0, 0, 0, 0],
      },
      {
        width: 750,
        height: 25,
        top: 1025,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 1000,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  TOIPAY30X20: {
    name: 'Toile paysage 30x20cm',
    width: 350,
    height: 250,
    effectiveWidth: 300,
    effectiveHeight: 200,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 325,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 225,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 225,
        left: 325,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 300,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 200,
        top: 25,
        left: 325,
        radius: [0, 0, 0, 0],
      },
      {
        width: 300,
        height: 25,
        top: 225,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 200,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  TOIPAY40X30: {
    name: 'Toile paysage 40x30cm',
    width: 450,
    height: 350,
    effectiveWidth: 300,
    effectiveHeight: 400,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 425,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 325,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 325,
        left: 425,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 400,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 300,
        top: 25,
        left: 425,
        radius: [0, 0, 0, 0],
      },
      {
        width: 400,
        height: 25,
        top: 325,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 300,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  TOIPAY60X40: {
    name: 'Toile paysage 60x40cm',
    width: 650,
    height: 450,
    effectiveWidth: 600,
    effectiveHeight: 400,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 625,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 425,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 425,
        left: 625,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 600,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 400,
        top: 25,
        left: 625,
        radius: [0, 0, 0, 0],
      },
      {
        width: 600,
        height: 25,
        top: 425,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 400,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  TOIPAY80X60: {
    name: 'Toile paysage 80x60cm',
    width: 850,
    height: 650,
    effectiveWidth: 800,
    effectiveHeight: 600,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 825,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 625,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 625,
        left: 825,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 800,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 600,
        top: 25,
        left: 825,
        radius: [0, 0, 0, 0],
      },
      {
        width: 800,
        height: 25,
        top: 625,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 600,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  TOIPAY70X30: {
    name: 'Toile panoramique paysage 70x30cm',
    width: 750,
    height: 350,
    effectiveWidth: 700,
    effectiveHeight: 300,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 725,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 325,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 325,
        left: 725,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 700,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 300,
        top: 25,
        left: 725,
        radius: [0, 0, 0, 0],
      },
      {
        width: 700,
        height: 25,
        top: 325,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 300,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  TOIPAY100X75: {
    name: 'Toile paysage 100x75cm',
    width: 1050,
    height: 800,
    effectiveWidth: 1000,
    effectiveHeight: 750,
    type: OtherType.Toile,
    forbiddenZones: [
      {
        width: 25,
        height: 25,
        top: 0,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 0,
        left: 1025,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 775,
        left: 0,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 25,
        top: 775,
        left: 1025,
        radius: [0, 0, 0, 0],
      },
    ],
    optionnalZones: [
      {
        width: 1000,
        height: 25,
        top: 0,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 750,
        top: 25,
        left: 1025,
        radius: [0, 0, 0, 0],
      },
      {
        width: 1000,
        height: 25,
        top: 775,
        left: 25,
        radius: [0, 0, 0, 0],
      },
      {
        width: 25,
        height: 750,
        top: 25,
        left: 0,
        radius: [0, 0, 0, 0],
      },
    ],
  },
  ALUCAR30: {
    name: 'Tableau alu carré 30x30cm',
    width: 300,
    height: 300,
    type: OtherType.Tableau_Alu,
  },
  ALUCAR20: {
    name: 'Tableau alu carré 20x20cm',
    width: 200,
    height: 200,
    type: OtherType.Tableau_Alu,
  },
  ALUPOR30X40: {
    name: 'Tableau alu portrait 30x40cm',
    width: 300,
    height: 400,
    type: OtherType.Tableau_Alu,
  },
  ALUPOR40X60: {
    name: 'Tableau alu portrait 40x60cm',
    width: 400,
    height: 600,
    type: OtherType.Tableau_Alu,
  },
  ALUPOR60X80: {
    name: 'Tableau alu portrait 60x80cm',
    width: 600,
    height: 800,
    type: OtherType.Tableau_Alu,
  },
  ALUPOR30X70: {
    name: 'Tableau alu panoramique 30x70cm',
    width: 300,
    height: 700,
    type: OtherType.Tableau_Alu,
  },
  ALUPOR75X100: {
    name: 'Tableau alu portrait 75x100cm',
    width: 750,
    height: 1000,
    type: OtherType.Tableau_Alu,
  },
  ALUPAY40X30: {
    name: 'Tableau alu paysage 40x30cm',
    width: 400,
    height: 300,
    type: OtherType.Tableau_Alu,
  },
  ALUPAY60X40: {
    name: 'Tableau alu paysage 60x40cm',
    width: 600,
    height: 400,
    type: OtherType.Tableau_Alu,
  },
  ALUPAY80X60: {
    name: 'Tableau alu paysage 80x60cm',
    width: 800,
    height: 600,
    type: OtherType.Tableau_Alu,
  },
  ALUPAY70X30: {
    name: 'Tableau alu panoramique 70x30cm',
    width: 700,
    height: 300,
    type: OtherType.Tableau_Alu,
  },
  ALUPAY100X75: {
    name: 'Tableau alu paysage 100x75cm',
    width: 1000,
    height: 750,
    type: OtherType.Tableau_Alu,
  },
  BOARDCAR20: {
    name: 'Tableau carton carré 20x20 cm',
    width: 200,
    height: 200,
    type: OtherType.Tableau_Carton,
  },
  BOARDPAYA3: {
    name: 'Tableau carton paysage A3',
    width: 400,
    height: 300,
    type: OtherType.Tableau_Carton,
  },
  BOARDPAYA4: {
    name: 'Tableau carton paysage A4',
    width: 297,
    height: 210,
    type: OtherType.Tableau_Carton,
  },
  BOARDPAYA5: {
    name: 'Tableau carton paysage A5',
    width: 210,
    height: 150,
    type: OtherType.Tableau_Carton,
  },
  BOARDPORA3: {
    name: 'Tableau carton portrait A3',
    width: 300,
    height: 400,
    type: OtherType.Tableau_Carton,
  },
  BOARDPORA4: {
    name: 'Tableau carton portrait A4',
    width: 210,
    height: 297,
    type: OtherType.Tableau_Carton,
  },
  BOARDPORA5: {
    name: 'Tableau carton portrait A5',
    width: 150,
    height: 210,
    type: OtherType.Tableau_Carton,
  },
  POSC30: {
    name: 'Affiche carrée 30x30cm',
    width: 300,
    height: 300,
    type: OtherType.Affiche,
  },
  POSC40: {
    name: 'Affiche carrée 40x40cm',
    width: 400,
    height: 400,
    type: OtherType.Affiche,
  },
  POS20X30: {
    name: 'Affiche portrait 20x30cm',
    width: 200,
    height: 300,
    type: OtherType.Affiche,
  },
  POS30X40: {
    name: 'Affiche portrait 30x40cm',
    width: 300,
    height: 400,
    type: OtherType.Affiche,
  },
  POS40X60: {
    name: 'Affiche portrait 40x60cm',
    width: 400,
    height: 600,
    type: OtherType.Affiche,
  },
  POS60X80: {
    name: 'Affiche portrait 60x80cm',
    width: 600,
    height: 800,
    type: OtherType.Affiche,
  },
  POS30X20: {
    name: 'Affiche paysage 30x20cm',
    width: 300,
    height: 200,
    type: OtherType.Affiche,
  },
  POS40X30: {
    name: 'Affiche paysage 40x30cm',
    width: 400,
    height: 300,
    type: OtherType.Affiche,
  },
  POS60X40: {
    name: 'Affiche paysage 60x40cm',
    width: 600,
    height: 400,
    type: OtherType.Affiche,
  },
  POS80X60: {
    name: 'Affiche paysage 80x60cm',
    width: 800,
    height: 600,
    type: OtherType.Affiche,
  },
  TSB: {
    name: 'Toise sur toile 30x140cm',
    width: 300,
    height: 1400,
    type: OtherType.Toise,
  },
  GRD: {
    name: 'Gourde isotherme inox',
    width: 180,
    height: 100,
    type: OtherType.Gourde,
  },
  MUG2: {
    name: 'Mug photo céramique blanc',
    width: 200,
    height: 80,
    type: OtherType.Mug,
  },
  MUG4: {
    name: 'Mug photo couleur',
    width: 200,
    height: 80,
    type: OtherType.Mug,
  },
  'MUG4-2': {
    name: 'Mug photo couleur',
    width: 200,
    height: 80,
    type: OtherType.Mug,
  },
  'MUG4-3': {
    name: 'Mug photo couleur',
    width: 200,
    height: 80,
    type: OtherType.Mug,
  },
  'MUG4-4': {
    name: 'Mug photo couleur',
    width: 200,
    height: 80,
    type: OtherType.Mug,
  },
  TSE: {
    name: 'Mug photo émaillé',
    width: 180,
    height: 50,
    type: OtherType.Mug,
  },
  MUG6: {
    name: 'Mug photo magique',
    width: 200,
    height: 80,
    type: OtherType.Mug,
  },
  TIR10X10: {
    name: 'Tirage 10x10cm',
    width: 100,
    height: 100,
    type: OtherType.Tirage,
  },
  TIR10X15: {
    name: 'Tirage 10x15cm',
    width: 100,
    height: 150,
    type: OtherType.Tirage,
  },
  TIR15X15: {
    name: 'Tirage 15x15cm',
    width: 150,
    height: 150,
    type: OtherType.Tirage,
  },
  TIR15X20: {
    name: 'Tirage 15x20cm',
    width: 150,
    height: 200,
    type: OtherType.Tirage,
  },
  TIR20X30: {
    name: 'Tirage 20x30cm',
    width: 200,
    height: 300,
    type: OtherType.Tirage,
  },
  ONR: {
    name: 'Ornement métallique rond',
    width: 100,
    height: 100,
    type: OtherType.Ornement_Metallique,
    defaultElementsOnPage: 'circlePhoto',
  },
  ONE: {
    name: 'Ornement métallique rond',
    width: 100,
    height: 100,
    type: OtherType.Ornement_Metallique,
    defaultElementsOnPage: 'starPhoto',
  },
};
