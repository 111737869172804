import { useEffect } from 'react';
import { createCard, createCardFromSKU, getOngoingCard } from '../api/cards';
import { useAuth } from './useAuth';
import { useDispatch } from 'react-redux';
import {
  changeCard,
  endLoading,
  loadElements,
  store,
} from '../constants/initialStore';
import { formats } from '../constants/formats';
import { useParams, useSearchParams } from 'react-router-dom';
import { paperByFormat } from '../constants/availableFinitions';
import { ConvertMMToPixel } from '../utils/convertCMToPixel';
import { defaultElementsOnPage } from '../constants/defaultElementOnPage';

export const createCardFn = async () => {
  const searchParams = new URLSearchParams(window.location.search);
  const formatAsked = searchParams.get('format') || Object.keys(formats)[0];
  const languageAsked = searchParams.get('lng') || 'fr';
  const fromCart = searchParams.get('fromCart') || false;
  const format = formats[formatAsked];
  const payload = {
    format: formatAsked,
    paperId:
      paperByFormat.find(({ format }) => format === formatAsked)
        ?.availablePapers[0] ?? 0,
    faces: format.facesSizes.map((face) => {
      const width = ConvertMMToPixel(face.width);
      const height = ConvertMMToPixel(face.height);

      return {
        backgroundColor: face.bgColor,
        backgroundTheme: face.bgTheme,
        elements:
          format.defaultElementsOnPage &&
          defaultElementsOnPage[format.defaultElementsOnPage]
            ? defaultElementsOnPage[format.defaultElementsOnPage](width, height)
            : defaultElementsOnPage['oneFullSizePhoto'](width, height),
      };
    }),
  };
  const newCard = await createCard(payload);
  store.dispatch(
    changeCard({
      ...newCard.data,
      facesSizes: formats[newCard.data.format].facesSizes.map((face, index) => {
        const faceFromServer = newCard.data.CardFace.find(
          (cardFace) => cardFace.faceID === index,
        );
        return {
          ...face,
          id: faceFromServer?.id,
          bgColor: '#ffffff',
        };
      }),
    }),
  );
  window.location.replace(
    window.location.origin +
      `/carte/${newCard.data.id}?lng=${languageAsked}${
        fromCart ? '&fromCart=true' : ''
      }`,
  );
  return newCard.data;
};

export const fetchDispatchCard = async (id?: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  const languageAsked = searchParams.get('lng') || 'fr';
  const fromCart = searchParams.get('fromCart') || false;
  const savedCard = await getOngoingCard(id);
  if (savedCard) {
    if (!id)
      return window.location.replace(
        window.location.origin +
          `/carte/${savedCard.data.id}?lng=${languageAsked}${
            fromCart ? '&fromCart=true' : ''
          }`,
      );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // if (!savedCard.data) {
    //   return createCardFn();
    // }
    const { ownerID: _1, CardFace: _2, ...rest } = savedCard.data;
    const card = {
      ownerID: _1,
      ...formats[
        savedCard.data.isOldModel ? rest.format + '_old' : rest.format
      ],
      ...rest,
      facesSizes: formats[
        savedCard.data.isOldModel ? rest.format + '_old' : rest.format
      ].facesSizes.map((face, index) => {
        const faceFromServer = savedCard.data.CardFace.find(
          (cardFace) => cardFace.faceID === index,
        );
        return {
          ...face,
          id: faceFromServer?.id,
          locked: faceFromServer?.locked,
          bgColor: faceFromServer?.backgroundColor,
          bgTheme: faceFromServer?.backgroundTheme,
        };
      }),
    };
    store.dispatch(changeCard(card));
    store.dispatch(
      loadElements(
        savedCard.data.CardFace.flatMap((face) =>
          face.CardElement.map((element) => ({
            ...element,
            style: element.style ? JSON.parse(element.style) : undefined,
            face: face.faceID,
          })),
        ),
      ),
    );
    store.dispatch(endLoading());
    return card;
  }
};

// export const fetchDispatchCardNotLoggedIn = async (id: string) => {
//   const savedCard = await getOngoingCardNotLoggedIn(id);
//   const { ownerID: _1, CardFace: _2, ...rest } = savedCard.data;
//   const card = {
//     ownerID: _1,
//     ...formats[savedCard.data.isOldModel ? rest.format + '_old' : rest.format],
//     ...rest,
//     facesSizes: formats[
//       savedCard.data.isOldModel ? rest.format + '_old' : rest.format
//     ].facesSizes.map((face, index) => {
//       const faceFromServer = savedCard.data.CardFace.find(
//         (cardFace) => cardFace.faceID === index,
//       );
//       return {
//         ...face,
//         id: faceFromServer?.id,
//         locked: faceFromServer?.locked,
//         bgColor: faceFromServer?.backgroundColor,
//         bgTheme: faceFromServer?.backgroundTheme,
//       };
//     }),
//   };
//   store.dispatch(changeCard(card));
//   store.dispatch(
//     loadElements(
//       savedCard.data.CardFace.flatMap((face) =>
//         face.CardElement.map((element) => ({
//           ...element,
//           style: element.style ? JSON.parse(element.style) : undefined,
//           face: face.faceID,
//         })),
//       ),
//     ),
//   );
//   return card;
// };

export const useFetchOrCreateCard = () => {
  const { userInfo, isReady, useNewAccessToken } = useAuth();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const languageAsked = searchParams.get('lng') || 'fr';
  const fromCart = searchParams.get('fromCart') || false;

  const createCardWithSKU = async () => {
    const SKUAsked = searchParams.get('sku') ?? '';
    const paperId = searchParams.get('paperId') ?? '0';
    const cutId = searchParams.get('cutId') ?? null;
    const stringId = searchParams.get('stringId') ?? null;
    const enveloppe = searchParams.get('enveloppe') ?? null;
    const quantity = searchParams.get('quantity') ?? '1';

    const newCard = await createCardFromSKU(SKUAsked, {
      paperId: parseInt(paperId),
      cutId: cutId ? parseInt(cutId) : undefined,
      stringId: stringId ? parseInt(stringId) : undefined,
      enveloppeOption: enveloppe ? enveloppe : undefined,
      quantityAsked: quantity ? parseInt(quantity) : undefined,
    });
    dispatch(
      changeCard({
        ...newCard.data,
        facesSizes: formats[newCard.data.format].facesSizes.map(
          (face, index) => {
            const faceFromServer = newCard.data.CardFace.find(
              (cardFace) => cardFace.faceID === index,
            );
            return {
              ...face,
              id: faceFromServer?.id,
            };
          },
        ),
      }),
    );
    window.location.replace(
      window.location.origin +
        `/carte/${newCard.data.id}?lng=${languageAsked}${
          fromCart ? '&fromCart=true' : ''
        }`,
    );
  };

  useEffect(() => {
    if (isReady) {
      const newToken = searchParams.get('token');
      if (newToken) {
        const res = useNewAccessToken(newToken);
        if (res) {
          const urlActuelle = window.location.href;
          const urlSansToken = urlActuelle.replace(/token=[^&]*(&?)/, '');
          window.location.replace(urlSansToken);
        }
      }
    }
    if (
      window.location.pathname.includes('calendrier') ||
      window.location.pathname.includes('album') ||
      window.location.pathname.includes('autre') ||
      window.location.pathname.includes('admin') // Route type is inferred later
    ) {
      return;
    }

    // it is here to prevent multiple creation, but the useEffect should be transformed in the future so it is not triggered so often
    if (userInfo === null) {
      return;
    }

    if (id && id !== 'nouvelle' && id !== 'nouveau') {
      fetchDispatchCard(id);
      return;
    }
    if (searchParams.get('sku')) {
      createCardWithSKU();
      return;
    } else if (searchParams.get('format')) {
      createCardFn();
    }
  }, [userInfo, isReady, searchParams]);
};
