export const ConvertMMToPixel = (size: number) => {
  return (size * 300) / 25.4;
};

export const ConvertPixelToMM = (size: number) => {
  return (size * 25.4) / 300;
};

export const ConvertPt300DPIToPixel = (size: number) => (size * 300) / 72;

export const applyRatio =
  (value: number) =>
  ({ $ratio }: { $ratio: number }) =>
    value * $ratio;
