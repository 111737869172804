import React from 'react';
import { createPortal } from 'react-dom';
import { CloseButton } from '../functionnalities/MenuStyles';
import { useDispatch } from 'react-redux';
import { closeMenu, resetFocus } from '../../constants/initialStore';
import { ReactSVG } from 'react-svg';
import useLittleScreen from '../../hook/useLittleScreen';

interface CloseMenuButton {
  isOutside?: boolean;
  isBigger?: boolean;
}

const CloseMenuButton: React.FC<CloseMenuButton> = ({
  isOutside,
  isBigger,
}) => {
  const dispatch = useDispatch();

  const littleScreen = useLittleScreen();

  const handleCloseMenu = () => {
    dispatch(resetFocus());
    dispatch(closeMenu());
  };

  return littleScreen ? (
    isOutside ? (
      createPortal(
        <CloseButton
          onClick={handleCloseMenu}
          $isSmall={littleScreen}
          $isOutside={isOutside}
          $isHigher={isBigger}
        >
          <ReactSVG
            src="/svg/close.svg"
            width={'24px'}
            height={'24px'}
            wrapper="svg"
          />
        </CloseButton>,
        document.body,
      )
    ) : (
      <CloseButton onClick={handleCloseMenu} $isSmall={littleScreen}>
        <ReactSVG
          src="/svg/close.svg"
          width={'24px'}
          height={'24px'}
          wrapper="svg"
        />
      </CloseButton>
    )
  ) : (
    createPortal(
      <CloseButton onClick={handleCloseMenu} $isSmall={littleScreen}>
        <ReactSVG
          src="/svg/close.svg"
          width={'24px'}
          height={'24px'}
          wrapper="svg"
        />
      </CloseButton>,
      document.body,
    )
  );
};

export default CloseMenuButton;
