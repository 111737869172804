import { Dayjs } from 'dayjs';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import MonthDate from './MonthDay';
import Portal from '../../../components/general/Portal';
import colors from '../../../constants/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  changeCalendar,
  store,
} from '../../../constants/initialStore';
import { CalendarMonth } from '../../../types/models/Calendar';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';
import { ConvertPt300DPIToPixel } from '../../../utils/convertCMToPixel';
import { ReactSVG } from 'react-svg';
import { calendarFormats } from '../../../constants/calendarFormats';

type DayModalProps = {
  month: string;
  backgroundColor?: string;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number | null>>;
  daysElementRef: React.RefObject<(HTMLDivElement | null)[]>;
  days: Dayjs[];
};

const Modal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: 100;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const Dialogue = styled.div<{
  $backgroundColor?: string;
  $borderWidth: number;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ $backgroundColor }) => $backgroundColor};

  > div {
    width: 100%;
    height: 100%;
    border: ${({ $borderWidth }) => $borderWidth}px solid
      ${colors.black}!important;
    color: ${colors.error200}!important;
    &:hover {
      background-color: transparent !important;
    }
  }
`;

const MonthDayWrapper = styled(MonthDate)``;

const InputWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TextInput = styled(ContentEditable)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-height: 100%;
  outline: none !important;
  overflow: visible;
  text-align: center;
  color: ${colors.black};
  overflow: visible;
  word-break: break-all;
  white-space: pre-wrap;
  font-family: 'DM Sans';
  line-height: 1;
`;

const sanitizeConf = {
  allowedTags: ['b', 'i', 'p'],
};

const IconButton = styled.button`
  all: unset;
  position: absolute;

  top: 50%;
  transform: translateY(-50%);

  width: 48px;
  height: 48px;

  background-color: ${colors.white};
  border: 1px solid ${colors.gray300};
  border-radius: 8px;
  cursor: pointer;

  > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 32px;
    height: 32px;
    stroke: ${colors.black};
  }

  &:hover {
    background-color: ${colors.gray300};
  }
`;

const LeftButton = styled(IconButton)`
  left: calc(50% - 220px);
`;

const RightButton = styled(IconButton)`
  left: calc(50% + 220px);
  transform: translate(-100%, -50%);
`;

const CloseButton = styled(IconButton)`
  left: calc(50% + 200px);
  transform: translate(-100%, calc(-50% - 200px));

  svg {
    width: 16px;
    height: 16px;
  }
`;

const DayModal = ({
  month,
  backgroundColor = colors.white,
  days,
  index,
  setIndex,
  daysElementRef: elRef,
}: DayModalProps) => {
  const dispatch = useDispatch();
  const content = useSelector(
    (state: RootState) =>
      state.creation.present
        .calendar!.calendarMonths.find((cm) => cm.month === month)!
        .calendarDays.find((cd) => cd.day === days[index].date())?.text ?? '',
  );
  const ratio = useSelector((state: RootState) => state.ratio.value);
  const monthDayRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputWrapperRef = useRef<HTMLDivElement>(null);
  const day = days[index];
  const format = useSelector(
    (state: RootState) => state.creation.present.calendar!.format,
  );
  const { fonts } = calendarFormats[format];
  const dayTextFontSize = fonts['GRID']!.date.fontSize;

  useEffect(() => {
    const dayElement = elRef.current![index]!;
    const ratio = store.getState().ratio.value;

    const width = dayElement.clientWidth;
    const height = dayElement.clientHeight;
    const zoom = Math.min(300 / width, 300 / height);

    monthDayRef.current!.style.cssText = `width: ${width}px; height: ${height}px; transform: translate(-50%, -50%) scale(${zoom});`;

    const borderWidht = 1 * ratio;
    const inputWidht = `${
      (monthDayRef.current!.clientWidth - borderWidht) * zoom
    }px`;
    const inputHeight = `${
      (monthDayRef.current!.clientHeight - borderWidht) * zoom
    }px`;
    const inputFontSize = `${
      zoom * ConvertPt300DPIToPixel(dayTextFontSize) * ratio
    }px`;
    inputWrapperRef.current!.style.cssText = `width: ${inputWidht}; height: ${inputHeight};`;

    const input = inputRef.current!;
    input.style.cssText = `font-size: ${inputFontSize};`;
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(input);
    range.collapse(false);
    sel?.removeAllRanges();
    sel?.addRange(range);

    input.focus();
  }, [day]);

  const handleTextChange = () => {
    const text = sanitizeHtml(inputRef.current!.innerHTML, sanitizeConf);

    const updatedCalendar = {
      ...store.getState().creation.present.calendar!,
      calendarMonths: store.getState().creation.present.calendar!.calendarMonths.map(
        (cm: CalendarMonth) => {
          if (cm.month !== month) return cm;
          const days = [...cm.calendarDays];
          const calendarDayIndex = days.findIndex((d) => d.day === day.date());
          if (calendarDayIndex === -1) {
            days.push({
              day: day.date(),
              text: text,
            });
          } else {
            const calendarDay = { ...days[calendarDayIndex] };
            calendarDay.text = text;
            days[calendarDayIndex] = calendarDay;
          }
          return {
            ...cm,
            calendarDays: days,
          };
        },
      ),
    };
    
    dispatch(changeCalendar(updatedCalendar));
  }

  const onNextClick = () => {
    setIndex((prev) => (prev! + 1 < days.length ? prev! + 1 : null));
    handleTextChange();
  };

  const onPreviousClick = () => {
    setIndex((prev) => (prev! - 1 >= 0 ? prev! - 1 : null));
    handleTextChange();
  };

  const onCloseClick = () => {
    setIndex(null);
    handleTextChange();
  };

  const onKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isAlt = e.metaKey || e.ctrlKey || e.altKey;
    if (isAlt && e.key === 'ArrowLeft') {
      onPreviousClick();
    } else if (isAlt && e.key === 'ArrowRight') {
      onNextClick();
    } else if (e.key === 'Escape' || (e.key === 'Enter' && !e.shiftKey)) {
      onCloseClick();
    }
  };

  return (
    <Portal>
      <Modal>
        <Background onClick={onCloseClick} />
        <Dialogue
          ref={monthDayRef}
          $backgroundColor={backgroundColor}
          $borderWidth={ratio}
        >
          <MonthDayWrapper date={day} ratio={ratio} />
        </Dialogue>
        <InputWrapper ref={inputWrapperRef}>
          <TextInput
            innerRef={inputRef}
            key={day.toISOString()}
            onBlur={handleTextChange}
            onChange={handleTextChange}
            html={content}
            onKeyDown={onKeydown}
          />
        </InputWrapper>
        <CloseButton onClick={onCloseClick}>
          <ReactSVG src="/svg/cross.svg" />
        </CloseButton>
        <LeftButton
          onClick={onPreviousClick}
          aria-keyshortcuts="Ctrl+ArrowLeft Alt+ArrowLeft Meta+ArrowLeft"
        >
          <ReactSVG src="/svg/chevron_left.svg" />
        </LeftButton>
        <RightButton
          onClick={onNextClick}
          aria-keyshortcuts="Ctrl+ArrowRight Alt+ArrowRight Meta+ArrowRight"
        >
          <ReactSVG src="/svg/chevron_right.svg" />
        </RightButton>
      </Modal>
    </Portal>
  );
};

export default DayModal;
