import {
  ReactNode,
  RefObject,
  forwardRef,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ReactZoomPanPinchContentRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import { RootState, closeMenu, zoomTo } from '../../constants/initialStore';
import { mergeRefs } from 'react-merge-refs';
import useEffectSkipFirstRender from '../../hook/useEffectSkipFirstRender';
import NavigationMenu from './NavigationMenu';
import useCreationType from '../../hook/useCreationType';

const TransformZoom = forwardRef<
  ReactZoomPanPinchContentRef | HTMLDivElement,
  {
    children: ReactNode;
    contentStyle?: React.CSSProperties;
    view?: 'global' | 'page';
  }
>(({ children, contentStyle = {}, view = 'global' }, ref) => {
  const dispatch = useDispatch();
  const zoomRef = useRef<ReactZoomPanPinchContentRef>(null);
  const zoom = useSelector((state: RootState) => state.zoom.value);
  const menu = useSelector((state: RootState) => state.menu.value);
  const ratio = useSelector((state: RootState) => state.ratio.value);
  const [oldMenu, setOldMenu] = useState(menu);
  const [oldRatio, setOldRatio] = useState(ratio);

  const currenFace = useSelector((state: RootState) => state.face.value);

  const hasFocusSet = useSelector((state: RootState) => !!state.focus.value);

  const type = useCreationType();

  useEffectSkipFirstRender(() => {
    if (!zoomRef.current) return;
    const { positionX, positionY } = zoomRef.current.instance.transformState;
    zoomRef.current.setTransform(positionX, positionY, zoom);
  }, [zoom]);

  useEffect(() => {
    if (!zoomRef.current) return;
    zoomRef.current.resetTransform(0);
  }, [currenFace]);

  const onZoom = () => {
    if (!zoomRef.current) return;
    const { scale } = zoomRef.current.instance.transformState;
    dispatch(zoomTo(scale));
  };

  const updateZoom = () => {
    if (!zoomRef.current) return;
    const { scale } = zoomRef.current.instance.transformState;
    dispatch(zoomTo(scale));
  };

  const closeSideMenu = () => {
    dispatch(closeMenu());
    updateZoom();
  };

  useEffectSkipFirstRender(() => {
    if (zoomRef.current) {

      zoomRef.current.setTransform(
        (zoomRef.current.instance.transformState.positionX / oldRatio) * ratio,
        (zoomRef.current.instance.transformState.positionY / oldRatio) * ratio,
        zoom,
        0,
      );
      setOldRatio(ratio);
    }
  }, [ratio]);

  return (
    <TransformWrapper
      limitToBounds
      ref={mergeRefs([zoomRef, ref])}
      onZoom={onZoom}
      minScale={0.5}
      maxScale={5}
      doubleClick={{ disabled: true, mode: 'zoomIn' }}
      disablePadding={true}
      centerOnInit={false}
      wheel={{ wheelDisabled: true }}
      panning={{
        wheelPanning: true,
        excluded: ['input', 'textarea', 'textElement'],
      }}

      // onPanning={closeSideMenu}
    >
      {({ zoomToElement, resetTransform }) => (
        <>
          {(type === 'album' || type === 'calendrier') && (
            <NavigationMenu
              zoomTo={zoomToElement}
              view={view}
              updateZoom={updateZoom}
            />
          )}
          <TransformComponent
            wrapperStyle={{
              width: '100%',
              height: '100%',
              overflowY:
                type === 'album' || type === 'calendrier'
                  ? 'hidden'
                  : 'visible',
              overflowX:
                type === 'album' || type === 'calendrier'
                  ? 'hidden'
                  : 'visible',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
            contentStyle={{
              flexDirection: 'column',
              alignItems: 'center',
              ...contentStyle,
            }}
            wrapperClass="wrapperTransformClass"
          >
            {children}
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
});

export default memo(TransformZoom);
