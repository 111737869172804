import styled from 'styled-components';
import {
  CloseButton,
  ColorContainer,
  Container,
  Item,
  Line,
  SubTitle,
  Title,
  TitleContainer,
  TitleItem,
} from './MenuStyles';
import colors from '../../constants/colors';
import ReactSelect, { SingleValue } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  changeCard,
  changeOther,
  changeAlbum,
  closeMenu,
} from '../../constants/initialStore';
import {
  accessoryFinitions,
  cutByFormat,
  cutFinitions,
  formatLineFinitions,
  formatWithLineOption,
  mugFormatsColor,
  paperByFormat,
  paperFinitions,
  paperFinitionsPosters,
  stringFinitions,
} from '../../constants/availableFinitions';
import { formats } from '../../constants/formats';
import { ArrayElement } from '../../types/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import CloseMenuButton from '../layout/CloseMenuButton';
import useLittleScreen from '../../hook/useLittleScreen';
import useCreationType from '../../hook/useCreationType';
import { otherFormats } from '../../constants/otherFormats';
import ColorItem from '../cardElements/ColorItem';

type BorderItemProps = ArrayElement<typeof cutFinitions>;

const BorderContainer = styled.button<{ $isSelected: boolean }>`
  all: unset;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 38px;
  border: 1px solid transparent;
  border-color: ${({ $isSelected }) =>
    $isSelected ? colors.black : 'transparent'};
  border-radius: 4px;

  &:hover {
    border-color: ${({ $isSelected }) =>
      $isSelected ? colors.black : colors.gray200};
  }
`;

const ElementPrice = styled.span`
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
  color: ${colors.black};
  white-space: nowrap;
  width: 100%;
  text-align: center;
`;

const BorderImage = styled.img`
  margin-top: 2px;
  width: 36px;
  height: 36px;
`;

const BorderItem = ({ id, name, price, img }: BorderItemProps) => {
  const dispatch = useDispatch();
  const isSelected = useSelector(
    (state: RootState) => state.creation.present.card.value.cutId === id,
  );

  const onClick = () => {
    dispatch(changeCard((prev: any) => ({ ...prev, cutId: id })));
  };

  return (
    <BorderContainer $isSelected={isSelected} onClick={onClick}>
      <BorderImage src={`/accessories/${img}`} alt={`Coin ${name}`} />
      <ElementPrice>+{price} €</ElementPrice>
    </BorderContainer>
  );
};

const StringContainer = styled.button<{ $isSelected: boolean }>`
  all: unset;
  cursor: ${({ $isSelected }) => ($isSelected ? 'default' : 'pointer')};
  border-radius: 8px;
  border: 1px solid ${colors.gray200};
  border-color: ${({ $isSelected }) =>
    $isSelected ? colors.black : colors.gray200};
  padding: 9px;
  height: 82px;
  box-sizing: border-box;

  &:hover {
    border-color: ${({ $isSelected }) =>
      $isSelected ? colors.black : colors.gray300};
  }
`;

const StringImage = styled.img`
  width: 62px;
  height: 62px;
  background-color: ${colors.gray100};
`;

const StringItem = ({ id, img }: ArrayElement<typeof stringFinitions>) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const isSlected = useSelector(
    (state: RootState) => state.creation.present.card.value.stringId === id,
  );

  const onClick = () => {
    dispatch(changeCard((prev: any) => ({ ...prev, stringId: id })));
  };

  return (
    <StringContainer onClick={onClick} $isSelected={isSlected}>
      <StringImage src={`/accessories/${img}`} />
    </StringContainer>
  );
};

const AccessoryItem = ({
  id,
  img,
}: ArrayElement<typeof accessoryFinitions>) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const isSlected = useSelector(
    (state: RootState) => state.creation.present.other?.accessory === id,
  );

  const onClick = () => {
    dispatch(changeOther((prev: any) => ({ ...prev, accessory: id })));
  };

  return (
    <StringContainer onClick={onClick} $isSelected={isSlected}>
      <StringImage src={`/accessories/${img}`} />
    </StringContainer>
  );
};

const ColorMugItem = ({
  formatId,
  colorName,
  color,
}: ArrayElement<typeof mugFormatsColor>) => {
  const dispatch = useDispatch();
  const isSelected = useSelector(
    (state: RootState) => state.creation.present.other?.format === formatId,
  );

  const onClick = () => {
    dispatch(changeOther((prev: any) => ({ ...prev, format: formatId })));
  };

  return (
    <ColorItem
      color={{ name: colorName, color: color }}
      active={isSelected}
      update={onClick}
    />
  );
};

const LineItem = ({ id, img }: ArrayElement<typeof formatLineFinitions>) => {
  const dispatch = useDispatch();
  const isSelected = useSelector(
    (state: RootState) => state.creation.present.album?.lineFormatId === id,
  );

  const onClick = () => {
    dispatch(changeAlbum((prev: any) => ({ ...prev, lineFormatId: id })));
  };

  return (
    <StringContainer onClick={onClick} $isSelected={isSelected}>
      <StringImage src={`/accessories/${img}`} />
    </StringContainer>
  );
};

const PaperSelect = () => {
  const { t } = useTranslation(['common']);
  const typeCreation = useCreationType();
  const paperOptionsInitial =
    typeCreation === 'autre'
      ? paperFinitionsPosters.map(({ id, localTag, price }) => ({
          value: id,
          label: `${t(localTag)}`,
        }))
      : paperFinitions.map(({ id, localTag, price }) => ({
          value: id,
          label: `${t(localTag)}`,
        }));

  const [paperOptions, setPaperOptions] =
    useState<{ value: number; label: string }[]>(paperOptionsInitial);
  const dispatch = useDispatch();
  const type = useCreationType();
  const selectedPaperId = useSelector((state: RootState) =>
    type === 'carte'
      ? state.creation.present.card.value.paperId
      : state.creation.present.other?.paperId,
  );
  const formatName = useSelector(
    (state: RootState) => state.creation.present.card.value.format,
  );

  const value = paperOptionsInitial.find(
    ({ value }) => value === selectedPaperId,
  );

  const littleScreen = useLittleScreen();

  useEffect(() => {
    const papersAvailableForFormat = paperByFormat.find(({ format }) => {
      return format === formatName.split('_')[0];
    });

    setPaperOptions(
      typeCreation === 'autre'
        ? paperFinitionsPosters.map(({ id, localTag, price }) => ({
            value: id,
            label: `${t(localTag)}`,
          }))
        : paperFinitions
            .filter(({ id }) => {
              return papersAvailableForFormat?.availablePapers.includes(id);
            })
            .map(({ id, localTag, price }) => ({
              value: id,
              label: `${t(localTag)}`,
            })),
    );
  }, [formatName]);

  const onChange = (
    option: SingleValue<ArrayElement<typeof paperOptionsInitial>>,
  ) => {
    if (!option) return;
    if (type === 'carte') {
      dispatch(
        changeCard(
          (prev: RootState['creation']['present']['card']['value']) => ({
            ...prev,
            paperId: option.value,
          }),
        ),
      );
    } else if (type === 'autre') {
      dispatch(
        changeOther(({ ...prev }: any) => ({
          ...prev,
          paperId: option.value,
        })),
      );
    }
  };

  return (
    <ReactSelect
      options={paperOptions}
      isSearchable={!littleScreen}
      styles={{
        option: (baseStyles, { isFocused, isSelected }) => ({
          ...baseStyles,
          color: colors.black,
          backgroundColor: isSelected
            ? colors.gray300
            : isFocused
              ? colors.gray100
              : colors.white,
        }),
      }}
      value={value}
      onChange={onChange}
    />
  );
};

const FinitionsMenu: React.FC = () => {
  const { t } = useTranslation(['common']);
  const type = useCreationType();
  const fomatName = useSelector((state: RootState) =>
    type === 'carte'
      ? state.creation.present.card.value.format
      : type === 'album'
        ? state.creation.present.album?.format
        : state.creation.present.other?.format,
  );
  const format = type === 'carte' ? formats[fomatName ?? ''] : null;
  const dispatch = useDispatch();

  return (
    <Container>
      <CloseMenuButton />
      {format?.cutId !== undefined && (
        <Item>
          <SubTitle>{t('finishes.corners')}</SubTitle>
          <ColorContainer>
            {cutFinitions
              .filter((cut, index) => {
                const cutsAvailables = cutByFormat.find(
                  (cut) => cut.format === fomatName,
                );
                if (cutsAvailables) {
                  return cutsAvailables.availableCuts.includes(index);
                } else {
                  return false;
                }
              })
              .map((finition) => (
                <BorderItem key={finition.id} {...finition} />
              ))}
          </ColorContainer>
        </Item>
      )}
      {
        // this condition might have to be changed to be more generic
      }
      {!fomatName?.startsWith('CARN') && !fomatName?.includes('MUG4') ? (
        <Item>
          <SubTitle>{t('finishes.paper')}</SubTitle>
          <PaperSelect />
        </Item>
      ) : null}
      {format?.stringId !== undefined && (
        <Item>
          <SubTitle>{t('finishes.cords')}</SubTitle>
          <ColorContainer>
            {stringFinitions.map((finition) => (
              <StringItem key={finition.id} {...finition} />
            ))}
          </ColorContainer>
        </Item>
      )}
      {fomatName?.includes('POS') && fomatName !== 'POS80X60' && (
        <Item>
          <SubTitle>{t('finishes.accessories')}</SubTitle>{' '}
          <ColorContainer>
            {accessoryFinitions.map((finition) =>
              fomatName.includes('30') || finition.id !== 'C' ? (
                <AccessoryItem key={finition.id} {...finition} />
              ) : null,
            )}
          </ColorContainer>
        </Item>
      )}
      {!!fomatName && formatWithLineOption.includes(fomatName) && (
        <Item>
          <SubTitle>{t('finishes.line')}</SubTitle>
          <ColorContainer>
            {formatLineFinitions.map((finition) => (
              <LineItem key={finition.id} {...finition} />
            ))}
          </ColorContainer>
        </Item>
      )}
      {!!fomatName && fomatName.includes('MUG4') && (
        <Item>
          <SubTitle>{t('finishes.mugColor')}</SubTitle>
          <ColorContainer>
            {mugFormatsColor.map((finition) => (
              <ColorMugItem {...finition} />
            ))}
          </ColorContainer>
        </Item>
      )}
    </Container>
  );
};

export default FinitionsMenu;
