import {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { CalendarPage, MonthPage } from '../calendars/CalendarMonth';
import { useDispatch } from 'react-redux';
import { changeFace, changeRatio } from '../../constants/initialStore';
import { Calendar } from '../../types/models/Calendar';
import { fetchDispatchCalendar } from '../../hook/useFetchOrCreateCalendar';
import { fetchDispatchCard } from '../../hook/useFetchOrCreateCard';
import DrawZone from '../../components/cardElements/DrawZone';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import useImportTextFonts from '../../hook/useImportTextFonts';
import styled from 'styled-components';
import OtherDrawZone from '../others/OtherDrawZone';
import { otherFormats } from '../../constants/otherFormats';
import { Page } from '../albums/AlbumPage';
import { Album } from '../../types/models/Album';
import { fetchDispatchOther } from '../../hook/useFecthOrCreateOther';
import { fetchAlbum } from '../../hook/useFetchOrCreateAlbum';
import { LoginState, useAuth } from '../../hook/useAuth';
import { CardFromServer } from '../../types/models/Cards';
import { Other } from '../../types/models/Other';
import { formats } from '../../constants/formats';
import FacesDisplayer from '../../components/cardElements/FacesDisplayer';
import { ReactSVG } from 'react-svg';
import { SliceContainer } from '../albums/AlbumPageStyles';
import { Slice } from '../../components/functionnalities/creationPreview/albumPreview/CoverPreview';
import { calendarFormats } from '../../constants/calendarFormats';
import { cp } from 'fs';

export const GenerationPage = () => {
  const { id, type } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const pageNumber = parseInt(searchParams.get('page') ?? '') || 0;
  const [data, setData] = useState<
    Album | CardFromServer | Calendar | Other | null
  >(null);

  const { userInfo, isReady } = useAuth();

  const maxWidth = parseInt(searchParams.get('maxWidth') ?? '') || 0;
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [wrapperStyles, setWrapperStyles] = useState<
    CSSProperties | undefined
  >();

  useImportTextFonts();

  useEffect(() => {
    const getData = async () => {
      let dataFromServ = null;
      if (id === undefined) return;
      switch (type) {
        case 'calendrier':
          dataFromServ = await fetchDispatchCalendar(id);
          setData(dataFromServ as unknown as Calendar);
          break;
        case 'carte':
          dataFromServ = await fetchDispatchCard(id);
          dispatch(changeFace(pageNumber));
          setData(dataFromServ as unknown as CardFromServer);
          break;
        case 'album':
          dataFromServ = await fetchAlbum(id);
          setData(dataFromServ as unknown as Album);
          break;
        case 'autre':
          dataFromServ = await fetchDispatchOther(id);
          setData(dataFromServ as unknown as Other);
          break;
        default:
          throw new Error('Creation type not found');
      }
    };
    if (!type || !id) return;
    getData();
    dispatch(changeRatio(1));
  }, [id, type, userInfo]);

  if (!data) return <></>;


  const formatsCalendriersRigide = ['RIGCAR30', 'RIGCAR21', 'RIGA4PAY', 'RIGA5PAY', 'RIGA4POR'];

  const getPaddingOfWrapper = () => {
    // clicadmin seems to stick codebar right next to TOICAR20, so we add padding
    if (data.format === 'TOICAR20') {
      return '20px';
    }
    else if (formatsCalendriersRigide.includes(data.format) && pageNumber == 0) {
      return '120px';
    }
    else {
      return '0px';
    }
  };

  const getBgColor = () => {
    if (formatsCalendriersRigide.includes(data.format) && pageNumber == 0) {
      return (data as Album).albumCover?.backgroundColor;
    }
    return '';
  }


  return (
    <Wrapper
      ref={wrapperRef}
      id="content"
      style={{
        ...wrapperStyles,
        flexDirection:
          data && ['CFG', 'PFM'].includes(data.format) ? 'row' : 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: getPaddingOfWrapper(),
        backgroundColor: getBgColor()
      }}
    >
      <DndProvider backend={HTML5Backend}>
        {type === 'calendrier' &&
          (pageNumber > 0 || calendarFormats[data.format].noCover ? (
            <MonthPage
              {...(data as Calendar).calendarMonths[
              calendarFormats[data.format].noCover
                ? pageNumber + 1
                : pageNumber
              ]}
              isGeneration={true}
            />
          ) : (
            <CalendarPage
              {...(data as Calendar).calendarMonths[pageNumber]}
              isCover
              isGeneration={true}
            />
          ))}
        {type === 'autre' && (
          <OtherDrawZone
            displayHeight={ConvertMMToPixel(otherFormats[data.format].height)}
            displayWidth={ConvertMMToPixel(otherFormats[data.format].width)}
            displayForbiddenZones={false}
            isGeneration={true}
          />
        )}
        {type === 'album' &&
          (pageNumber >= 1 ? (
            <Page
              {...(data as Album).albumPages[pageNumber - 1]}
              isCover={false}
              isGeneration={true}
            />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row' }}>

              <Page
                {...(data as Album).albumBackCover}
                isCover={true}
                isGeneration={true}
                backgroundColor={(data as Album).albumCover?.backgroundColor}

              />
              {data.format.includes('RIG') && (
                <Slice
                  {...(data as Album).albumCover}
                  name={(data as Album).name ?? ''}
                  numberOfPages={(data as Album).albumPages.length + 2}
                  isGeneration={true}
                />
              )}
              <Page
                {...(data as Album).albumCover}
                isCover={false}
                isGeneration={true}
              />
            </div>
          ))}
        {type === 'carte' &&
          ['CFG', 'PFM'].includes(data.format.toString()) &&
          pageNumber === 1 && (
            <>
              {['CFG'].includes(data.format.toString()) ? (
                <ScaleFix></ScaleFix>
              ) : null}
              <DrawZone
                isGeneration={true}
                displayHeight={ConvertMMToPixel(
                  formats[data.format.toString() + '_old'].facesSizes[
                    pageNumber + 2
                  ].height + (['CFG'].includes(data.format.toString()) ? 3 : 0),
                )}
                displayWidth={ConvertMMToPixel(
                  formats[data.format.toString() + '_old'].facesSizes[
                    pageNumber + 2
                  ].width + (['CFG'].includes(data.format.toString()) ? 3 : 0),
                )}
                displayBends={false}
                faceToDisplay={pageNumber + 2}
                $canOverflow={false}
                $correctionTranslation={
                  ['PF2HB'].includes(data.format.toString())
                    ? [14, 27]
                    : ['CFG'].includes(data.format.toString())
                      ? [17, 17]
                      : [0, 0]
                }
              />
            </>
          )}
        {type === 'carte' && (
          <DrawZone
            isGeneration={true}
            displayHeight={ConvertMMToPixel(
              formats[
                data.format.toString() +
                (data.format.includes('MAG') ? '' : '_old')
              ].facesSizes[pageNumber].height,
            )}
            displayWidth={ConvertMMToPixel(
              formats[
                data.format.toString() +
                (data.format.includes('MAG') ? '' : '_old')
              ].facesSizes[pageNumber].width,
            )}
            displayBends={false}
            $canOverflow={false}
          />
        )}
        {type === 'carte' &&
          (['PF2HB'].includes(data.format.toString()) ||
            (['CFG', 'PFM'].includes(data.format.toString()) &&
              pageNumber === 0)) && (
            <>
              {['PF2HB', 'CFG'].includes(data.format.toString()) ? (
                <ScaleFix></ScaleFix>
              ) : null}
              <DrawZone
                isGeneration={true}
                displayHeight={ConvertMMToPixel(
                  formats[data.format.toString() + '_old'].facesSizes[
                    pageNumber + 2
                  ].height +
                  (['PF2HB', 'CFG'].includes(data.format.toString()) ? 3 : 0),
                )}
                displayWidth={ConvertMMToPixel(
                  formats[data.format.toString() + '_old'].facesSizes[
                    pageNumber + 2
                  ].width +
                  (['PF2HB', 'CFG'].includes(data.format.toString()) ? 3 : 0),
                )}
                displayBends={false}
                faceToDisplay={pageNumber + 2}
                $canOverflow={false}
                $correctionTranslation={
                  ['PF2HB'].includes(data.format.toString())
                    ? [14, 27]
                    : ['CFG'].includes(data.format.toString())
                      ? [17, 17]
                      : [0, 0]
                }
              />
            </>
          )}
      </DndProvider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  background-color: white;
  pointer-events: none;
  transform-origin: top left;

  > div {
    clip-path: none !important;
  }
`;

const ScaleFix = styled.div`
  + div > div {
    scale: 1.07;
  }
`;
