import styled from 'styled-components';
import colors from '../../constants/colors';

const CalendarInput = styled.input`
  width: 100%;
  padding: 12px 14px;
  border: solid 1px ${colors.gray300};
  border-radius: 8px;

  box-sizing: border-box;

  margin: 0 0 20px;

  font-family: 'DM Sans';
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

export default CalendarInput;
