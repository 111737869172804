import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const panoramicLayouts2Photos: LayoutElement[][] = [
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 0.7,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.5,
      height: 0.15,
      top: 0.775,
      left: 0.25,
    },
  ],
];
