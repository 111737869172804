import styled from 'styled-components';
import colors from '../../../constants/colors';
import dayjs, { Dayjs } from 'dayjs';
import { CalendarMonth } from '../../../types/models/Calendar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../constants/initialStore';
import { calendarFormats } from '../../../constants/calendarFormats';
import CalendarText, { getCalendarFontProps } from './CalendarText';
import { applyRatio } from '../../../utils/convertCMToPixel';
import { Fragment } from 'react';

const MonthTitle = styled(CalendarText)`
  color: ${colors.black};
  text-transform: capitalize;
`;

const CornerSquare = styled.div<{ $ratio: number }>`
  position: absolute;
  bottom: ${applyRatio(80)}px;
  right: ${applyRatio(80)}px;
  display: flex;
  flex-direction: column;
  gap: ${applyRatio(48)}px;
  align-items: flex-start;
  width: ${applyRatio(725)}px;
`;

const DaysSquare = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  align-self: stretch;
`;

const DayLabel = styled(CalendarText)<{ $ratio: number }>`
  color: ${colors.black};
  text-transform: capitalize;
  padding-bottom: ${applyRatio(40)}px;
`;

const DayNumber = styled(CalendarText)<{ $ratio: number }>`
  color: ${colors.black};
  padding: ${applyRatio(20)}px 0;
`;

const HLine = styled.div<{ $ratio: number }>`
  grid-column: 1 / -1;
  height: ${applyRatio(1)}px;
  background-color: ${colors.black};
  transform: scale(1, 0.3);
  transform-origin: center;
  width: 100%;
`;

type CalendarCornerSquareProps = Pick<
  CalendarMonth,
  'backgroundColor' | 'month' | 'calendarDays'
> & {
  days: Dayjs[];
  ratio: number;
};

const CalendarConerSquare = ({ days, ratio }: CalendarCornerSquareProps) => {
  const format = useSelector(
    (state: RootState) => state.creation.present.calendar!.format,
  );
  const { fonts: fontsByDisplay } = calendarFormats[format];
  const fonts = fontsByDisplay['CORNER_SQUARE']!;

  const nbEmptyDays = (days[0].day() + 6) % 7;

  return (
    <CornerSquare $ratio={ratio}>
      <MonthTitle {...getCalendarFontProps(fonts.title, ratio)}>
        {days[0].format('MMMM YYYYY').slice(0, -5)}
      </MonthTitle>
      <DaysSquare>
        {new Array(7).fill(0).map((_, i) => (
          <DayLabel
            {...getCalendarFontProps(fonts.days, ratio)}
            key={i}
            $ratio={ratio}
          >
            {dayjs()
              .day(i + 1)
              .format('ddd')
              .slice(0, -1)}
          </DayLabel>
        ))}
        {Array.from({ length: nbEmptyDays }, (_, index) => (
          <div key={index} />
        ))}
        {days.map((d, index) => (
          <Fragment key={index}>
            <DayNumber
              {...getCalendarFontProps(fonts.date, ratio)}
              key={d.date()}
              $ratio={ratio}
            >
              {d.date()}
            </DayNumber>
            {index !== days.length - 1 && d.day() === 0 && (
              <HLine $ratio={ratio} />
            )}
          </Fragment>
        ))}
        {days.length + nbEmptyDays < 36 && (
          <>
            <HLine $ratio={ratio} />
            <DayNumber
              {...getCalendarFontProps(fonts.date, ratio)}
              $ratio={ratio}
            >
              &nbsp;
            </DayNumber>
          </>
        )}
      </DaysSquare>
    </CornerSquare>
  );
};

export default CalendarConerSquare;
