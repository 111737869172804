import { Album, AlbumToSend } from '../types/models/Album';
import axios from 'axios';

export const getOngoingAlbum = async (id?: string) => {
  return axios.get<Album>(`/albums/${id ? id : ''}`).then((res) => res.data);
};

export const createAlbum = async (payload: AlbumToSend, locale: string) => {
  return axios
    .post<Album>(`/albums`, { album: payload, locale: locale })
    .then((res) => res.data);
};

export const saveAlbum = async (payload: AlbumToSend) => {
  return axios
    .put<Album>(`/albums/${payload.id}`, payload)
    .then((res) => res.data);
};

export const saveNameAlbum = async (id: string, newName: string) => {
  return axios.post<any>(`/albums/name/${id}`, { name: newName });
};

export const endPersonnalisationAlbum = async (
  albumID: string,
  locale: string,
  fromCart: boolean,
) => {
  return axios.post<string>(`/albums/end/${albumID}`, { locale, fromCart });
};
