import { CalendarType } from '../types/models/Calendar';

export type CalendarFont = {
  bold: boolean;
  fontSize: number;
  fontFamily: string;
};

export type CalendarFormat = {
  name: string;
  width: number;
  height: number;
  types: CalendarType[];
  isDouble?: boolean;
  noCover?: boolean;
  fonts: {
    [key in CalendarType]?: Record<'title' | 'days' | 'date', CalendarFont>;
  };
  forbiddenZones?: {
    width: number;
    height: number;
    top: number;
    left: number;
  }[];
};

type CalendarFormats = Record<string, CalendarFormat>;

export const calendarFormats: CalendarFormats = {
  CALA4POR2: {
    name: 'Calendrier mural double A4',
    width: 400,
    height: 300,
    isDouble: true,
    types: ['GRID'],
    fonts: {
      GRID: {
        title: {
          bold: true,
          fontSize: 35.22,
          fontFamily: 'DM SANS',
        },
        days: {
          bold: false,
          fontSize: 16.9,
          fontFamily: 'DM SANS',
        },
        date: {
          bold: true,
          fontSize: 14.09,
          fontFamily: 'DM SANS',
        },
      },
    },
  },
  CALA5POR1: {
    name: 'Calendrier mural simple A5',
    width: 150,
    height: 210,
    types: ['LINE', 'GRID'],
    fonts: {
      GRID: {
        title: {
          bold: true,
          fontSize: 16,
          fontFamily: 'DM SANS',
        },
        days: {
          bold: false,
          fontSize: 5,
          fontFamily: 'DM SANS',
        },
        date: {
          bold: true,
          fontSize: 5,
          fontFamily: 'DM SANS',
        },
      },
      LINE: {
        title: {
          bold: true,
          fontSize: 16,
          fontFamily: 'DM SANS',
        },
        days: {
          bold: false,
          fontSize: 5,
          fontFamily: 'DM SANS',
        },
        date: {
          bold: true,
          fontSize: 5,
          fontFamily: 'DM SANS',
        },
      },
    },
  },
  CALLONGPOR1: {
    name: 'Calendrier mural vertical long',
    width: 150,
    height: 410,
    types: ['LONG'],
    fonts: {
      LONG: {
        title: {
          bold: true,
          fontSize: 16,
          fontFamily: 'DM SANS',
        },
        days: {
          bold: false,
          fontSize: 8,
          fontFamily: 'DM SANS',
        },
        date: {
          bold: true,
          fontSize: 8,
          fontFamily: 'DM SANS',
        },
      },
    },
  },
  CALA4POR1: {
    name: 'Calendrier mural simple A4',
    width: 200,
    height: 300,
    types: ['GRID', 'LINE'],
    fonts: {
      GRID: {
        title: {
          bold: true,
          fontSize: 17,
          fontFamily: 'DM SANS',
        },
        days: {
          bold: false,
          fontSize: 10,
          fontFamily: 'DM SANS',
        },
        date: {
          bold: true,
          fontSize: 8,
          fontFamily: 'DM SANS',
        },
      },
      LINE: {
        title: {
          bold: true,
          fontSize: 22,
          fontFamily: 'DM SANS',
        },
        days: {
          bold: false,
          fontSize: 7,
          fontFamily: 'DM SANS',
        },
        date: {
          bold: true,
          fontSize: 7,
          fontFamily: 'DM SANS',
        },
      },
    },
  },
  CALA3POR1: {
    name: 'Calendrier mural simple A3',
    width: 300,
    height: 400,
    types: ['GRID', 'LINE'],
    fonts: {
      GRID: {
        title: {
          bold: true,
          fontSize: 25,
          fontFamily: 'DM SANS',
        },
        days: {
          bold: false,
          fontSize: 10,
          fontFamily: 'DM SANS',
        },
        date: {
          bold: true,
          fontSize: 12,
          fontFamily: 'DM SANS',
        },
      },
      LINE: {
        title: {
          bold: true,
          fontSize: 24,
          fontFamily: 'DM SANS',
        },
        days: {
          bold: false,
          fontSize: 12,
          fontFamily: 'DM SANS',
        },
        date: {
          bold: true,
          fontSize: 12,
          fontFamily: 'DM SANS',
        },
      },
    },
  },
  CALCAR30: {
    name: 'Calendrier mural carré 30x30 cm',
    width: 300,
    height: 300,
    types: ['LINE'],
    fonts: {
      LINE: {
        title: {
          bold: true,
          fontSize: 25,
          fontFamily: 'DM SANS',
        },
        days: {
          bold: false,
          fontSize: 11,
          fontFamily: 'DM SANS',
        },
        date: {
          bold: true,
          fontSize: 10,
          fontFamily: 'DM SANS',
        },
      },
    },
  },
  CAL21x15: {
    name: 'Calendrier à poser rectangle 21x15 cm',
    width: 210,
    height: 150,
    types: ['CORNER_SQUARE'],
    fonts: {
      CORNER_SQUARE: {
        title: {
          bold: true,
          fontSize: 16,
          fontFamily: 'DM SANS',
        },
        days: {
          bold: false,
          fontSize: 9,
          fontFamily: 'DM SANS',
        },
        date: {
          bold: true,
          fontSize: 8,
          fontFamily: 'DM SANS',
        },
      },
    },
  },
  CAL15x15: {
    name: 'Calendrier à poser carré 15x15 cm',
    width: 150,
    height: 150,
    types: ['LINE'],
    fonts: {
      LINE: {
        title: {
          bold: true,
          fontSize: 15,
          fontFamily: 'DM SANS',
        },
        days: {
          bold: false,
          fontSize: 5,
          fontFamily: 'DM SANS',
        },
        date: {
          bold: true,
          fontSize: 8,
          fontFamily: 'DM SANS',
        },
      },
    },
  },
  'CALGRR-BOIS': {
    name: 'Calendrier photo portrait avec support bois',
    width: 105,
    height: 145,
    noCover: true,
    types: ['SQUARE'],
    fonts: {
      SQUARE: {
        title: {
          bold: true,
          fontSize: 12,
          fontFamily: 'DM SANS',
        },
        days: {
          bold: false,
          fontSize: 6,
          fontFamily: 'DM SANS',
        },
        date: {
          bold: true,
          fontSize: 8,
          fontFamily: 'DM SANS',
        },
      },
    },
    forbiddenZones: [
      {
        width: 105,
        height: 25,
        top: 120,
        left: 0,
      },
    ],
  },
  'CALWR2-BOIS': {
    name: 'Calendrier photo carré avec support bois',
    width: 140,
    height: 140,
    noCover: true,
    types: ['LINE'],
    fonts: {
      LINE: {
        title: {
          bold: true,
          fontSize: 12,
          fontFamily: 'DM SANS',
        },
        days: {
          bold: false,
          fontSize: 5,
          fontFamily: 'DM SANS',
        },
        date: {
          bold: true,
          fontSize: 5,
          fontFamily: 'DM SANS',
        },
      },
    },
    forbiddenZones: [
      {
        width: 140,
        height: 25,
        top: 115,
        left: 0,
      },
    ],
  },
  'CALGRR-RECH': {
    name: 'Calendrier photo portrait sans support bois',
    width: 105,
    height: 145,
    noCover: true,
    types: ['SQUARE'],
    fonts: {
      SQUARE: {
        title: {
          bold: true,
          fontSize: 12,
          fontFamily: 'DM SANS',
        },
        days: {
          bold: false,
          fontSize: 6,
          fontFamily: 'DM SANS',
        },
        date: {
          bold: true,
          fontSize: 8,
          fontFamily: 'DM SANS',
        },
      },
    },
    forbiddenZones: [
      {
        width: 105,
        height: 25,
        top: 120,
        left: 0,
      },
    ],
  },
  'CALWR2-RECH': {
    name: 'Calendrier photo carré sans support bois',
    width: 140,
    height: 140,
    noCover: true,
    types: ['LINE'],
    fonts: {
      LINE: {
        title: {
          bold: true,
          fontSize: 12,
          fontFamily: 'DM SANS',
        },
        days: {
          bold: false,
          fontSize: 5,
          fontFamily: 'DM SANS',
        },
        date: {
          bold: true,
          fontSize: 5,
          fontFamily: 'DM SANS',
        },
      },
    },
    forbiddenZones: [
      {
        width: 140,
        height: 25,
        top: 115,
        left: 0,
      },
    ],
  },
};

// 'CAL21 V2': {
//   fonts: {
//     title: {
//       bold: true,
//       fontSize: 20,
//       fontFamily: 'DM SANS',
//     },
//     days: {
//       bold: false,
//       fontSize: 6,
//       fontFamily: 'DM SANS',
//     },
//     date: {
//       bold: true,
//       fontSize: 8,
//       fontFamily: 'DM SANS',
//     },
//   },
// },
