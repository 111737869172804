import { styled } from 'styled-components';
import colors from '../../constants/colors';

interface ColorItemProps {
  color: { name: string; color: string };
  update?: ({ color, name }: { color: string; name: string }) => void;
  active?: boolean;
}

const ColorItem: React.FC<ColorItemProps> = ({ color, update, active }) => {
  return (
    <Container
      $color={color.color}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick={update ? () => update(color) : () => {}}
      $active={active ?? false}
    >
      <TitleColor>{color.name}</TitleColor>
    </Container>
  );
};

const Container = styled.div<{ $color: string; $active: boolean }>`
  width: 36px;
  height: 36px;
  min-width: 36px;
  background-color: ${({ $color }) => $color};

  position: relative;

  box-sizing: border-box;

  border: solid
    ${({ $active }) =>
      $active ? '4px ' + colors.green : '1px ' + colors.gray400};
  border-radius: 4px;

  cursor: pointer;

  &:hover {
    & > div {
      display: flex;
    }
  }
`;

const TitleColor = styled.div`
  width: max-content;
  display: none;
  flex-wrap: nowrap;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${colors.white};
  border: solid 1px ${colors.gray200};
  color: ${colors.black};
  font-size: 11px;
  font-family: 'DM Sans';

  z-index: 200;
  white-space: nowrap;
  overflow: hidden;
`;

export default ColorItem;
