import React, { MouseEvent, useEffect, useState } from 'react';
import { Rnd } from 'react-rnd';
import colors from '../../../constants/colors';
import { styled } from 'styled-components';
import { Element, MotifElementStyle } from '../../../types/models/Elements';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  closeMenu,
  modifyElement,
  openMenu,
  resetFocus,
  setFocus,
} from '../../../constants/initialStore';
import { MotifDisplayer } from './MotifDisplayer';
import { LoginState, useAuth } from '../../../hook/useAuth';
import useLittleScreen from '../../../hook/useLittleScreen';
import { ReactSVG } from 'react-svg';

interface TextElementProps {
  element: Element;
  index: number;
  $correctionTranslation?: number[];
}

const MotifElement: React.FC<TextElementProps> = ({
  element,
  $correctionTranslation = [0, 0],
}) => {
  const dispatch = useDispatch();

  const [draggindDisabled, setDraggindDisabled] = useState(false);

  const { userInfo } = useAuth();

  const focus = useSelector((state: RootState) => state.focus.value);
  const ratio = useSelector((state: RootState) => state.ratio.value);
  const zoom = useSelector((state: RootState) => state.zoom.value);

  const littleScreen = useLittleScreen();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const concernedElement = document.getElementById(
        'motifDisplay-' + element.id,
      );
      const TextMenuElement = document.getElementById('menu');
      const RemoveModal = document.getElementById('removeModal');
      const SidebarElement = document.getElementById('sidebar');
      if (
        concernedElement &&
        !concernedElement.contains(event.target) &&
        TextMenuElement &&
        !TextMenuElement.contains(event.target) &&
        SidebarElement &&
        !SidebarElement.contains(event.target) &&
        RemoveModal &&
        !RemoveModal.contains(event.target)
      ) {
        if (focus === element.id) {
          dispatch(resetFocus());
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [focus]);

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(setFocus(element.id));
    dispatch(openMenu(5)); // 2 = Menu de texte
  };

  const user = useAuth();
  const contentIsEditable = () => {
    const isAdmin =
      user.userInfo?.state === LoginState.LOGGED_IN && user.userInfo.isAdmin;
    return !(!element.contentEditable && !isAdmin);
  };

  return (
    <RndCustom
      scale={zoom}
      size={{
        width: element.width * ratio,
        height: element.height * ratio,
      }}
      position={{
        x: element.left * ratio + $correctionTranslation[0],
        y: element.top * ratio + $correctionTranslation[1],
      }}
      bounds="body"
      onClickCapture={handleClick}
      onClick={handleClick}
      onTouchStart={(e: MouseEvent) => {
        handleClick(e);
      }}
      onResize={(event: any) => {
        event.stopPropagation();
        setDraggindDisabled(true);
        dispatch(setFocus(element.id));
      }}
      onDrag={(event: any) => {
        event.stopPropagation();
        dispatch(setFocus(element.id));
      }}
      onDragStop={(e: any, d: any) => {
        if (draggindDisabled) return;
        dispatch(
          modifyElement({
            id: element.id,
            element: { ...element, top: d.y / ratio, left: d.x / ratio },
          }),
        );
        dispatch(openMenu(5)); // 5 = Menu de motif
      }}
      onResizeStop={(e: any, direction: any, ref: any) => {
        dispatch(
          modifyElement({
            id: element.id,
            element: {
              ...element,
              width: parseFloat(ref.style.width.slice(0, -2)) / ratio,
              height: parseFloat(ref.style.height.slice(0, -2)) / ratio,
            },
          }),
        );
        dispatch(openMenu(5)); // 5 = Menu de motif
        setDraggindDisabled(false);
      }}
      enableResizing={{
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight:
          element.editable ||
          (userInfo?.state === LoginState.LOGGED_IN && userInfo.isAdmin),
        bottomLeft: false,
        topLeft: false,
      }}
      disableDragging={!element.editable && draggindDisabled}
      onDoubleClick={littleScreen ? null : handleClick}
      dragHandleClassName="dragHandle"
      lockAspectRatio={true}
      $active={focus === element.id}
      $index={element.zIndex}
      $seemsLikeBackground={element.top < 10 && element.left < 10}
      $canClick={element.editable || contentIsEditable()}
    >
      <DragHandle
        $active={focus === element.id && element.editable}
        className="dragHandle"
      >
        <div>
          <ReactSVG
            src="/svg/move.svg"
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 12px; height: 12px;');
            }}
            style={{
              display: 'flex',
              position: 'relative',
              top: '-2px',
              left: '2px',
            }}
          />
        </div>
      </DragHandle>
      <MotifDisplayer
        src={element.content}
        id={'motifDisplay-' + element.id}
        width={element.width * ratio}
        height={element.height * ratio}
        $elementstyle={element.style as MotifElementStyle}
      />
      {/* {element.editable && <SmallSquare $position="nw" />}
      {element.editable && <SmallSquare $position="ne" />} */}
      {(element.editable ||
        (userInfo?.state === LoginState.LOGGED_IN && userInfo.isAdmin)) && (
        <SmallSquare $position="se" />
      )}
      {/* {element.editable && <SmallSquare $position="sw" />} */}
    </RndCustom>
  );
};

const RndCustom = styled(Rnd)<{
  $active: boolean;
  $index: number | null;
  $seemsLikeBackground: boolean;
  $canClick: boolean;
}>`
  & > div {
    display: ${(props) => (props.$active ? 'flex' : 'none')};
  }
  display: flex !important;
  overflow: visible;

  z-index: ${(props) => props.$index};

  ${(props) => (props.$canClick ? '' : 'pointer-events: none;')}

  &:after {
    content: '';
    position: absolute;
    inset: -1px;
    border-radius: inherit;
    border: solid 1px
      ${(props) => (props.$active ? colors.gray400 : 'transparent')};
    pointer-events: none;
  }
`;

const DragHandle = styled.div<{
  $active: boolean;
}>`
  display: ${(props) => (props.$active ? 'flex' : 'none')};
  position: absolute;

  justify-content: center;
  padding-top: 2px;

  width: 26px;
  height: 26px;
  background-color: transparent;

  border-radius: 13px;

  top: -13px;
  left: -13px;
  cursor: move;
  z-index: 100;

  & > div {
    position: relative;

    background-color: ${colors.white};

    width: 18px;
    height: 18px;
    border: solid 1px ${colors.gray400};

    border-radius: 9px;
  }
`;

const SmallSquare = styled.div<{ $position: 'nw' | 'ne' | 'se' | 'sw' }>`
  display: none;
  width: 12px;
  height: 12px;
  position: absolute;
  pointer-events: none;
  background-color: ${colors.white};
  border: solid 1px ${colors.gray400};
  box-sizing: border-box;
  top: ${(props) =>
    props.$position === 'nw' || props.$position === 'ne' ? '-6px' : 'auto'};
  bottom: ${(props) =>
    props.$position === 'sw' || props.$position === 'se' ? '-6px' : 'auto'};
  left: ${(props) =>
    props.$position === 'nw' || props.$position === 'sw' ? '-6px' : 'auto'};
  right: ${(props) =>
    props.$position === 'se' || props.$position === 'ne' ? '-6px' : 'auto'};
  z-index: 100;
`;

export default MotifElement;
