import styled from 'styled-components';
import { PhotoElementStyle } from '../../../types/models/Elements';

export const PhotoDisplayer = styled.img<{
  $elementstyle: PhotoElementStyle | null;
  $mustCover: boolean;
  $ratio: number;
}>`
  object-fit: ${(props) => (props.$mustCover ? 'cover' : 'contain')};
  opacity: ${(props) => props.$elementstyle?.opacity ?? 1};
  filter: blur(0px)
    ${(props) =>
      (props.$elementstyle?.brightness
        ? 'brightness(' + props.$elementstyle.brightness + ') '
        : '') +
      (props.$elementstyle?.saturation
        ? 'contrast(' + props.$elementstyle.saturation + '%) '
        : '') +
      (props.$elementstyle?.grayscale ? 'grayscale(100%) ' : '') +
      (props.$elementstyle?.sepia ? 'sepia(100%) ' : '')};
  transform: ${(props) =>
    (props.$elementstyle?.mirrored ? 'scaleX(-1) ' : '') +
    'translate(' +
    (props.$elementstyle?.translateX
      ? props.$elementstyle?.translateX * props.$ratio
      : 0) +
    'px, ' +
    (props.$elementstyle?.translateY
      ? props.$elementstyle?.translateY * props.$ratio
      : 0) +
    'px) ' +
    (props.$elementstyle?.rotation
      ? 'rotate(' + props.$elementstyle.rotation + 'deg) '
      : '') +
    (props.$elementstyle?.zoom
      ? 'scale(' + props.$elementstyle.zoom / 100 + ') '
      : '')};

  -webkit-filter: blur(0px)
    ${(props) =>
      (props.$elementstyle?.brightness
        ? 'brightness(' + props.$elementstyle.brightness + ') '
        : '') +
      (props.$elementstyle?.saturation
        ? 'contrast(' + props.$elementstyle.saturation + '%) '
        : '') +
      (props.$elementstyle?.grayscale ? 'grayscale(100%) ' : '') +
      (props.$elementstyle?.sepia ? 'sepia(100%) ' : '')};
`;
