import {
  ElementType,
  PhotoElementStyle,
  TextElementStyle,
} from '../types/models/Elements';
import { v4 as uuid } from 'uuid';
import colors from './colors';

export interface DefaultElementOnPage {
  type: ElementType;
  id: string;
  content: string;
  width: number;
  height: number;
  top: number;
  left: number;
  zIndex: number;
  editable: boolean;
  contentEditable: boolean;
  isForSlice?: boolean;
  style: TextElementStyle | PhotoElementStyle;
}

type DefaultElementsOnPage = Record<
  string,
  (width: number, height: number) => DefaultElementOnPage[]
>;

export const defaultElementsOnPage: DefaultElementsOnPage = {
  oneFullSizePhoto: (width: number, height: number) => [
    {
      type: ElementType.PHOTO,
      id: uuid(),
      content: '',
      width: width,
      height: height,
      top: 0,
      left: 0,
      zIndex: 0,
      editable: false,
      contentEditable: true,
      style: JSON.stringify({
        brightness: 1,
        saturation: 100,
        opacity: 1,
        zoom: 100,
        rotation: 0,
        grayscale: false,
        sepia: false,
        mirrored: false,
        shadow: false,
        shape: 'rectangle',
        translateX: 0,
        translateY: 0,
        rotationFrame: 0,
      }) as unknown as PhotoElementStyle,
    },
  ],
  oneWindowPhoto: (width: number, height: number) => [
    {
      type: ElementType.PHOTO,
      id: uuid(),
      content: '',
      width: width * 0.8,
      height: height * 0.725,
      top: height * 0.1375,
      left: width * 0.1,
      zIndex: 0,
      editable: false,
      contentEditable: true,
      style: JSON.stringify({
        brightness: 1,
        saturation: 100,
        opacity: 1,
        zoom: 100,
        rotation: 0,
        grayscale: false,
        sepia: false,
        mirrored: false,
        shadow: false,
        shape: 'rectangle',
        translateX: 0,
        translateY: 0,
        rotationFrame: 0,
      }) as unknown as PhotoElementStyle,
    },
  ],
  windowPhotoAndText: (width: number, height: number) => [
    {
      type: ElementType.PHOTO,
      id: uuid(),
      content: '',
      width: width * 0.8,
      height: height * 0.6,
      top: height * 0.2,
      left: width * 0.1,
      zIndex: 0,
      editable: false,
      contentEditable: true,
      style: JSON.stringify({
        brightness: 1,
        saturation: 100,
        opacity: 1,
        zoom: 100,
        rotation: 0,
        grayscale: false,
        sepia: false,
        mirrored: false,
        shadow: false,
      }) as unknown as PhotoElementStyle,
    },
    {
      type: ElementType.TEXT,
      id: uuid(),
      content: '',
      width: width * 0.8,
      height: height * 0.05,
      top: height * 0.85,
      left: width * 0.1,
      zIndex: 0,
      editable: true,
      contentEditable: true,
      style: JSON.stringify({
        bold: false,
        italic: false,
        underlined: false,
        size: 12,
        letterSpacing: 0,
        interline: 1,
        alignement: 'center',
        font: 'DM Sans',
        color: colors.black,
        transform: 'none',
        rotation: 0,
      }) as unknown as TextElementStyle,
    },
  ],
  vertical4Photos: (width: number, height: number) => [
    {
      type: ElementType.PHOTO,
      id: uuid(),
      content: '',
      width: width * 1,
      height: height * 0.235,
      top: height * 0,
      left: width * 0,
      zIndex: 0,
      editable: false,
      contentEditable: true,
      style: JSON.stringify({
        brightness: 1,
        saturation: 100,
        opacity: 1,
        zoom: 100,
        rotation: 0,
        grayscale: false,
        sepia: false,
        mirrored: false,
        shadow: false,
      }) as unknown as PhotoElementStyle,
    },
    {
      type: ElementType.PHOTO,
      id: uuid(),
      content: '',
      width: width * 1,
      height: height * 0.235,
      top: height * 0.255,
      left: width * 0,
      zIndex: 0,
      editable: false,
      contentEditable: true,
      style: JSON.stringify({
        brightness: 1,
        saturation: 100,
        opacity: 1,
        zoom: 100,
        rotation: 0,
        grayscale: false,
        sepia: false,
        mirrored: false,
        shadow: false,
      }) as unknown as PhotoElementStyle,
    },
    {
      type: ElementType.PHOTO,
      id: uuid(),
      content: '',
      width: width * 1,
      height: height * 0.235,
      top: height * 0.51,
      left: width * 0,
      zIndex: 0,
      editable: false,
      contentEditable: true,
      style: JSON.stringify({
        brightness: 1,
        saturation: 100,
        opacity: 1,
        zoom: 100,
        rotation: 0,
        grayscale: false,
        sepia: false,
        mirrored: false,
        shadow: false,
      }) as unknown as PhotoElementStyle,
    },
    {
      type: ElementType.PHOTO,
      id: uuid(),
      content: '',
      width: width * 1,
      height: height * 0.235,
      top: height * 0.765,
      left: width * 0,
      zIndex: 0,
      editable: false,
      contentEditable: true,
      style: JSON.stringify({
        brightness: 1,
        saturation: 100,
        opacity: 1,
        zoom: 100,
        rotation: 0,
        grayscale: false,
        sepia: false,
        mirrored: false,
        shadow: false,
      }) as unknown as PhotoElementStyle,
    },
  ],
  circlePhoto: (width: number, height: number) => [
    {
      type: ElementType.PHOTO,
      id: uuid(),
      content: '',
      width: width,
      height: height,
      top: 0,
      left: 0,
      zIndex: 0,
      editable: false,
      contentEditable: true,
      style: JSON.stringify({
        brightness: 1,
        saturation: 100,
        opacity: 1,
        zoom: 100,
        rotation: 0,
        grayscale: false,
        sepia: false,
        mirrored: false,
        shadow: false,
        shape: 'circle',
        translateX: 0,
        translateY: 0,
        rotationFrame: 0,
      }) as unknown as PhotoElementStyle,
    },
  ],
  starPhoto: (width: number, height: number) => [
    {
      type: ElementType.PHOTO,
      id: uuid(),
      content: '',
      width: width,
      height: height,
      top: 0,
      left: 0,
      zIndex: 0,
      editable: false,
      contentEditable: true,
      style: JSON.stringify({
        brightness: 1,
        saturation: 100,
        opacity: 1,
        zoom: 100,
        rotation: 0,
        grayscale: false,
        sepia: false,
        mirrored: false,
        shadow: false,
        shape: 'star',
        translateX: 0,
        translateY: 0,
        rotationFrame: 0,
      }) as unknown as PhotoElementStyle,
    },
  ],
};
