import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useCreationType = () => {
  const location = useLocation();

  const type = useMemo(
    () =>
      location.pathname.includes('calendrier')
        ? 'calendrier'
        : location.pathname.includes('album')
        ? 'album'
        : location.pathname.includes('autre')
        ? 'autre'
        : 'carte',
    [location],
  );

  return type;
};

export const creationType = window.location.pathname.includes('calendrier')
  ? 'calendrier'
  : window.location.pathname.includes('album')
  ? 'album'
  : 'carte';

export default useCreationType;
