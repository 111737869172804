import i18next from '../i18n';

export const availableMotifs = {
  [i18next.t('icons.astro', { ns: ['common'] })]: [
    {
      name: '',
      src: '/motifs/Astro/Balance.png',
      group: 'Astro',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Astro/Belier.png',
      group: 'Astro',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Astro/Cancer.png',
      group: 'Astro',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Astro/Capricorne.png',
      group: 'Astro',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Astro/Gemeaux.png',
      group: 'Astro',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Astro/Lion.png',
      group: 'Astro',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Astro/Poisson.png',
      group: 'Astro',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Astro/Sagittaire.png',
      group: 'Astro',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Astro/Scorpion.png',
      group: 'Astro',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Astro/Taureau.png',
      group: 'Astro',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Astro/Verseau.png',
      group: 'Astro',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Astro/Vierge.png',
      group: 'Astro',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
  ],
  [i18next.t('icons.baby', { ns: ['common'] })]: [
    {
      name: '',
      src: '/motifs/Bebe/Biberon.png',
      group: 'Bebe',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Bebe/Chaussures.png',
      group: 'Bebe',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Bebe/Doudou.png',
      group: 'Bebe',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Bebe/Hochet_fille.png',
      group: 'Bebe',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Bebe/Hochet_garcon.png',
      group: 'Bebe',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Bebe/Nounou_fille.png',
      group: 'Bebe',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Bebe/Nounou_garcon.png',
      group: 'Bebe',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Bebe/Panier.png',
      group: 'Bebe',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
  ],
  [i18next.t('icons.patterns', { ns: ['common'] })]: [
    {
      name: '',
      src: '/motifs/Motifs/Arcenciel_bleu.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Motifs/Arcenciel_noir.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Motifs/Arcenciel_rose.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Motifs/Bateau.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Motifs/Coeur_blanc.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Motifs/Coeur_noir.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Motifs/Couronne.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Motifs/Etoile.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Motifs/Lune.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Motifs/Nuage.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Motifs/Nuage_pluie.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Motifs/Pied_blanc.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Motifs/Pied_noir.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Motifs/Sapin_noir.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Motifs/Smiley_noir.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Motifs/Soleil.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Motifs/Soleil_noir.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Motifs/Tampon.png',
      group: 'Motifs',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
  ],
  [i18next.t('icons.icons', { ns: ['common'] })]: [
    {
      name: '',
      src: '/motifs/Pictos/Agenda_blanc1.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Agenda_blanc2.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Agenda_noir.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Agenda_noir2.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Agenda_vegetal.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Appareilphoto_blanc.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Appareilphoto_noir.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Assiette.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Bagues.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Balance_blanc.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Balance_noir.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Balance_vegetal.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Bouledisco_blanc.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Bouledisco_noir.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Bulle_blanc.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Bulle_noir1.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Bulle_noir2.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Champagne.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Check.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Colombe.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Couple.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Croissantlune.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Croix.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Croix_vegetale1.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Croix_vegetale2.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Eglise.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Eglise_dore.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Eglise_noir.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Etoile_david.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Fleche_blanc1.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Fleche_blanc2.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Fleche_noir1.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Fleche_noir2.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Fleur.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Gateau.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Gateau_noir.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Horloge_fine.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Horloge_vegetal.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Ligne.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Main_fatma.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Mariage.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Menorah.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Micro.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Musique.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Musique2.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Plume.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Poids_blanc.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Poids_noir.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Point.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Regle_blanc.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Regle_blanc2.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Regle_noir.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Regle_noir2.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Regle_noir3.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Regle_vegetal.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Pictos/Reveil.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Ruban_noir.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Pictos/Voiture.png',
      group: 'Pictos',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
  ],
  [i18next.t('icons.animals', { ns: ['common'] })]: [
    {
      name: '',
      src: '/motifs/Animaux/Abeille.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Baleine_goutte.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Colombe_blanche.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Animaux/Colombe_claire.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Colombe_noire.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Cygne_reine.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Dinosaure1.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Dinosaure2.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Ecureuil.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Elephant.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Girafe.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Hippopotame.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Lapin.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Lapin_assis.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Lion.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Lion_assis.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Ours.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Papillon.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Papillon_rose.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Petit_ours.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Renard.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Singe.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Animaux/Zebre.png',
      group: 'Animaux',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
  ],
  [i18next.t('icons.nature', { ns: ['common'] })]: [
    {
      name: '',
      src: '/motifs/Nature/Bouquet_champetre1.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Bouquet_champetre2.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Bouquet_champetre_rose.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Couronne_fleurs_rose.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Eucalyptus1.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Eucalyptus2.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Eucalyptus_clair.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Eucalyptus_clair2.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Eucalyptus_coeur.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Eucalyptus_line.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Eucalyptus_line2.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Feuille.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Feuille_coeur.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Feuille_dore.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Feuille_fonce.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Feuille_tropicale.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Fleur_bleue.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Fleur_hiver_line.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Fleur_jaune.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Fleur_line.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Fleur_marguerite.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Fleur_marguerite2.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Fleur_marguerite_line.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Fleur_rose.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Fleur_violet.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Fougere.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Olivier.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Olivier_line.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Rose.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Nature/Rose_blanche.png',
      group: 'Nature',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
  ],
  [i18next.t('icons.party', { ns: ['common'] })]: [
    {
      name: '',
      src: '/motifs/Fete/Ballon.png',
      group: 'Fete',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Fete/Ballons.png',
      group: 'Fete',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Fete/Bougies.png',
      group: 'Fete',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Fete/Confettis_colores.png',
      group: 'Fete',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Fete/Confettis_dores.png',
      group: 'Fete',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Fete/Fanions_colores.png',
      group: 'Fete',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Fete/Fanions_jaune_bleu.png',
      group: 'Fete',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Fete/Fanions_naissance.png',
      group: 'Fete',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Fete/Feu_artifice_dore.png',
      group: 'Fete',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Fete/Feu_artifice_dore2.png',
      group: 'Fete',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Fete/Gateau.png',
      group: 'Fete',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
  ],
  [i18next.t('icons.christmas', { ns: ['common'] })]: [
    {
      name: '',
      src: '/motifs/Noel/Boule_aquarelle.png',
      group: 'Noel',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Noel/Boule_aquarelle_kraft.png',
      group: 'Noel',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Noel/Cadeau_aquarelle_bleu.png',
      group: 'Noel',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Noel/Cadeau_aquarelle_kraft.png',
      group: 'Noel',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Noel/Chaussette.png',
      group: 'Noel',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Noel/Flocon.png',
      group: 'Noel',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Noel/Flocon_blanc.png',
      group: 'Noel',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Noel/Houx.png',
      group: 'Noel',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Noel/Houx_aquarelle.png',
      group: 'Noel',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Noel/Renne.png',
      group: 'Noel',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Noel/Santa.png',
      group: 'Noel',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Noel/Sapin_aquarelle.png',
      group: 'Noel',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Noel/Sapin_blanc.png',
      group: 'Noel',
      width: 1772,
      height: 1772,
      greyBackground: true,
    },
    {
      name: '',
      src: '/motifs/Noel/Sapin_dore.png',
      group: 'Noel',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Noel/Sucreorge.png',
      group: 'Noel',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
  ],
  [i18next.t('icons.frames', { ns: ['common'] })]: [
    {
      name: '',
      src: '/motifs/Cadre/Carre.png',
      group: 'Cadre',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Cadre/Rectangle1.png',
      group: 'Cadre',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Cadre/Rectangle2.png',
      group: 'Cadre',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Cadre/Rectangle3.png',
      group: 'Cadre',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Cadre/Rectangle4.png',
      group: 'Cadre',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Cadre/Rectangle_vegetal.png',
      group: 'Cadre',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Cadre/Rond.png',
      group: 'Cadre',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Cadre/Rond_vegetal.png',
      group: 'Cadre',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Cadre/Rond_vegetal_euxalyptus.png',
      group: 'Cadre',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Cadre/Rond_vegetal_fleur.png',
      group: 'Cadre',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Cadre/Rond_vegetal_noir.png',
      group: 'Cadre',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Cadre/Rond_vegetal_noir2.png',
      group: 'Cadre',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
    {
      name: '',
      src: '/motifs/Cadre/Rond_vegetal_noir3.png',
      group: 'Cadre',
      width: 1772,
      height: 1772,
      greyBackground: false,
    },
  ],
};
