import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators as RedoActionCreators } from 'redux-undo';
import { RootState } from '../../constants/initialStore';
import { ReactSVG } from 'react-svg';
import Button, { buttonType } from '../general/Button';
import colors from '../../constants/colors';
import { useTranslation } from 'react-i18next';
import useLittleScreen from '../../hook/useLittleScreen';

const RedoButton: React.FC = () => {
  const dispatch = useDispatch();
  const creationState = useSelector((state: RootState) => state.creation);

  const { t } = useTranslation(['common']);

  const littleScreen = useLittleScreen();

  const handleRedo = () => {
    if (creationState.future.length > 0) {
      dispatch(RedoActionCreators.redo()); // Dispatch de l'action Undo
    }
  };

  return (
    <Button
      $type={buttonType['white-noborder']}
      onClick={handleRedo}
      disabled={creationState.future.length === 0}
      style={{
        flexDirection: 'column',
        padding: littleScreen ? '5px 12px 5px 5px' : '5px 18px',
        gap: '5px',
        color:
          creationState.future.length === 0 ? colors.gray500 : colors.black,
      }}
    >
      <ReactSVG
        src={
          creationState.future.length === 0
            ? '/svg/redo_icon_gray.svg'
            : '/svg/redo_icon_black.svg'
        }
      />
      {!littleScreen && t('general.redo')}
    </Button>
  );
};

export default RedoButton;
