import styled from 'styled-components';
import { MotifElementStyle, Element } from '../../../types/models/Elements';
import { MotifDisplayer } from './MotifDisplayer';

export const ImmutableMotif = styled(MotifDisplayer)<{
  $elementstyle: MotifElementStyle | null;
  $element: Element;
  $ratio: number;
  $index: number;
}>`
  position: absolute;
  width: ${(props) => `${props.$element.width * props.$ratio}px`};
  height: ${(props) => `${props.$element.height * props.$ratio}px`};
  left: ${(props) => `${props.$element.left * props.$ratio}px`};
  top: ${(props) => `${props.$element.top * props.$ratio}px`};

  z-index: ${(props) => props.$index};
`;
