import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const squareLayouts4Photo: LayoutElement[][] = [
  [
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.49,
      top: 0,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.49,
      top: 0,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.49,
      top: 0.51,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.49,
      top: 0.51,
      left: 0.51,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.42,
      height: 0.4,
      top: 0.09,
      left: 0.07,
    },
    {
      type: ElementType.PHOTO,
      width: 0.42,
      height: 0.4,
      top: 0.51,
      left: 0.07,
    },
    {
      type: ElementType.PHOTO,
      width: 0.42,
      height: 0.4,
      top: 0.09,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.42,
      height: 0.4,
      top: 0.51,
      left: 0.51,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.28,
      height: 0.4,
      top: 0.09,
      left: 0.07,
    },
    {
      type: ElementType.PHOTO,
      width: 0.28,
      height: 0.4,
      top: 0.51,
      left: 0.65,
    },
    {
      type: ElementType.PHOTO,
      width: 0.56,
      height: 0.4,
      top: 0.09,
      left: 0.37,
    },
    {
      type: ElementType.PHOTO,
      width: 0.56,
      height: 0.4,
      top: 0.51,
      left: 0.07,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.35,
      height: 0.49,
      top: 0.0,
      left: 0.14,
    },
    {
      type: ElementType.PHOTO,
      width: 0.35,
      height: 0.49,
      top: 0.0,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.35,
      height: 0.49,
      top: 0.51,
      left: 0.14,
    },
    {
      type: ElementType.PHOTO,
      width: 0.35,
      height: 0.49,
      top: 0.51,
      left: 0.51,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.27,
      height: 0.39,
      top: 0.1,
      left: 0.22,
    },
    {
      type: ElementType.PHOTO,
      width: 0.27,
      height: 0.39,
      top: 0.1,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.27,
      height: 0.39,
      top: 0.51,
      left: 0.22,
    },
    {
      type: ElementType.PHOTO,
      width: 0.27,
      height: 0.39,
      top: 0.51,
      left: 0.51,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.35,
      top: 0.14,
      left: 0.0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.35,
      top: 0.51,
      left: 0.0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.35,
      top: 0.14,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.49,
      height: 0.35,
      top: 0.51,
      left: 0.51,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.39,
      height: 0.27,
      top: 0.22,
      left: 0.1,
    },
    {
      type: ElementType.PHOTO,
      width: 0.39,
      height: 0.27,
      top: 0.51,
      left: 0.1,
    },
    {
      type: ElementType.PHOTO,
      width: 0.39,
      height: 0.27,
      top: 0.22,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.39,
      height: 0.27,
      top: 0.51,
      left: 0.51,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.28,
      top: 0.19,
      left: 0.21,
    },
    {
      type: ElementType.PHOTO,
      width: 0.19,
      height: 0.28,
      top: 0.49,
      left: 0.6,
    },
    {
      type: ElementType.PHOTO,
      width: 0.37,
      height: 0.28,
      top: 0.19,
      left: 0.42,
    },
    {
      type: ElementType.PHOTO,
      width: 0.37,
      height: 0.28,
      top: 0.49,
      left: 0.21,
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.81,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.2,
      height: 0.29,
      top: 0.3,
      left: 0.07,
    },
    {
      type: ElementType.PHOTO,
      width: 0.2,
      height: 0.29,
      top: 0.3,
      left: 0.29,
    },
    {
      type: ElementType.PHOTO,
      width: 0.2,
      height: 0.29,
      top: 0.3,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.2,
      height: 0.29,
      top: 0.3,
      left: 0.73,
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.67,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.26,
      height: 0.24,
      top: 0.22,
      left: 0.23,
    },
    {
      type: ElementType.PHOTO,
      width: 0.26,
      height: 0.24,
      top: 0.22,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.26,
      height: 0.24,
      top: 0.48,
      left: 0.23,
    },
    {
      type: ElementType.PHOTO,
      width: 0.26,
      height: 0.24,
      top: 0.48,
      left: 0.51,
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.77,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.62,
      height: 1,
      top: 0,
      left: 0,
    },
    {
      type: ElementType.PHOTO,
      width: 0.36,
      height: 0.32,
      top: 0,
      left: 0.64,
    },
    {
      type: ElementType.PHOTO,
      width: 0.36,
      height: 0.32,
      top: 0.34,
      left: 0.64,
    },
    {
      type: ElementType.PHOTO,
      width: 0.36,
      height: 0.32,
      top: 0.68,
      left: 0.64,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.42,
      height: 0.62,
      top: 0.19,
      left: 0.16,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.2,
      top: 0.19,
      left: 0.59,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.2,
      top: 0.4,
      left: 0.59,
    },
    {
      type: ElementType.PHOTO,
      width: 0.25,
      height: 0.2,
      top: 0.61,
      left: 0.59,
    },
  ],
];
