import styled from 'styled-components';
import colors from '../../../constants/colors';
import { Dayjs } from 'dayjs';
import { CalendarMonth } from '../../../types/models/Calendar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../constants/initialStore';
import { calendarFormats } from '../../../constants/calendarFormats';
import CalendarText, { getCalendarFontProps } from './CalendarText';
import { applyRatio, ConvertMMToPixel } from '../../../utils/convertCMToPixel';

const MonthTitle = styled(CalendarText)`
  color: ${colors.black};
  text-transform: capitalize;
`;

const Line = styled.div<{ $ratio: number; $upper: boolean }>`
  position: absolute;
  inset: ${({ $ratio }) => `auto ${$ratio * 85}px ${$ratio * 100}px`};
  bottom: ${({ $ratio, $upper }) =>
    $upper
      ? $ratio * ConvertMMToPixel(28) + `px`
      : $ratio * ConvertMMToPixel(5) + `px`};
  display: flex;
  flex-direction: column;
  gap: ${applyRatio(70)}px;
  align-items: center;
`;

const DaysLine = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-self: stretch;
`;

const DayColumn = styled.div<{ $ratio: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${applyRatio(30)}px;
`;

const DayLabel = styled(CalendarText)`
  color: ${colors.black};
  text-transform: capitalize;
`;

const DayNumber = styled(CalendarText)`
  color: ${colors.black};
`;

type CalendarGridProps = Pick<
  CalendarMonth,
  'backgroundColor' | 'month' | 'calendarDays'
> & {
  days: Dayjs[];
  ratio: number;
};

const CalendarLine = ({ days, ratio }: CalendarGridProps) => {
  const format = useSelector(
    (state: RootState) => state.creation.present.calendar!.format,
  );
  const { fonts: fontsByDisplay } = calendarFormats[format];
  const fonts = fontsByDisplay['LINE']!;

  return (
    <Line
      $ratio={ratio}
      $upper={format === 'CALWR2-BOIS' || format === 'CALWR2-RECH'}
    >
      <MonthTitle {...getCalendarFontProps(fonts.title, ratio)}>
        {days[0].format('MMMM YYYYY').slice(0, -5)}
      </MonthTitle>
      <DaysLine>
        {days.map((d) => (
          <DayColumn key={d.date()} $ratio={ratio}>
            <DayLabel {...getCalendarFontProps(fonts.days, ratio)}>
              {d.format('ddd').slice(0, -1)}
            </DayLabel>
            <DayNumber {...getCalendarFontProps(fonts.date, ratio)}>
              {d.format('D')}
            </DayNumber>
          </DayColumn>
        ))}
      </DaysLine>
    </Line>
  );
};

export default CalendarLine;
