import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const landscapeLayouts3Photo: LayoutElement[][] = [
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.8,
      height: 0.8,
      top: 0.1,
      left: 0.1,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 0.65,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 0.35,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.7,
      height: 0.8,
      top: 0.1,
      left: 0.15,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 0.65,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 0.35,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.64,
      height: 0.38,
      top: 0.31,
      left: 0.18,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.74,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 0.5,
      top: 0.25,
      left: 0,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.84,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.76,
      height: 0.36,
      top: 0.32,
      left: 0.12,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.74,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.35,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 0.65,
          height: 1,
          top: 0,
          left: 0,
        },
      ],
    },
  ],
];
