import styled from 'styled-components';
import { CalendarDay } from '../../../types/models/Calendar';
import colors from '../../../constants/colors';
import { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../constants/initialStore';
import { ConvertPt300DPIToPixel } from '../../../utils/convertCMToPixel';
import { calendarFormats } from '../../../constants/calendarFormats';
import { MouseEvent, forwardRef } from 'react';
import CalendarText, { getCalendarFontProps } from './CalendarText';

const DESIGN_DAY_WIDTH = 79;

const getPercentWidth = (value: number) =>
  `${(value / DESIGN_DAY_WIDTH) * 100}%`;

const Day = styled.div<{ $borderWidth: number; onClickCapture: any }>`
  position: relative;
  display: grid;
  place-content: center;
  min-width: 0;
  cursor: ${({ onClickCapture }) => (onClickCapture ? `text` : 'default')};

  border-right: ${({ $borderWidth }) => $borderWidth}px solid ${colors.black};
  border-top: ${({ $borderWidth }) => $borderWidth}px solid ${colors.black};
  /* border-bottom: ${({ $borderWidth }) => $borderWidth}px solid transparent;
  border-left: ${({ $borderWidth }) => $borderWidth}px solid transparent; */

  &:hover {
    background-color: ${({ onClickCapture }) =>
      onClickCapture ? `rgba(242, 244, 247, 0.3)` : 'transparent'};
  }

  &:nth-child(7n + 1) {
    border-right: none;
  }

  &:nth-last-child(-n + 7) {
    border-bottom: ${({ $borderWidth }) => $borderWidth}px solid ${colors.black};
  }
`;

type DayNumberProps = {
  $ratio: number;
  $fontSize: number;
};

const DayNumber = styled(CalendarText)<DayNumberProps>`
  position: absolute;
  top: ${getPercentWidth(5)};
  right: ${getPercentWidth(5)};
  color: ${colors.black};
`;

const DayPhoto = styled.img`
  position: absolute;
  inset: 0;
  object-fit: cover;
`;

const DayText = styled.span<{ $ratio: number; $fontSize: number }>`
  font: 400
    ${({ $ratio, $fontSize }) => ConvertPt300DPIToPixel($fontSize) * $ratio}px /
    1 DM Sans;
  color: ${colors.black};
  width: 100%;
  max-height: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  overflow: visible;
  letter-spacing: 0;
`;

type MonthDayProps = Partial<CalendarDay> & {
  date?: Dayjs;
  onClickCapture?: (e: MouseEvent) => any;
  ratio: number;
};

const MonthDay = forwardRef<HTMLDivElement, MonthDayProps>(
  ({ date, photo, text, onClickCapture, ratio }, ref) => {
    const format = useSelector(
      (state: RootState) => state.creation.present.calendar!.format,
    );
    const { fonts } = calendarFormats[format];
    const font = fonts['GRID']!.date;
    const dayTextFontSize = font.fontSize;

    return (
      <Day ref={ref} $borderWidth={ratio} onClickCapture={onClickCapture}>
        {date && (
          <DayNumber $ratio={ratio} {...getCalendarFontProps(font, ratio)}>
            {date.date()}
          </DayNumber>
        )}
        {photo && (
          <DayPhoto
            src={photo.pictureURL}
            alt={`${date ? date.toLocaleString() : ''}`}
          />
        )}
        {text && (
          <DayText
            $ratio={ratio}
            $fontSize={dayTextFontSize}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
      </Day>
    );
  },
);

export default MonthDay;
