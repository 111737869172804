import React, { useRef, useState, useEffect } from 'react';
import { styled } from 'styled-components';
import { ReactSVG } from 'react-svg';
import colors from '../../constants/colors';
import { PhotoFolder } from '../../types/models/Photos';
import { useDrop } from 'react-dnd';
import { putPhotoInFolder } from '../../api/photos';
import { useTranslation } from 'react-i18next';

interface FolderProps {
  folder: PhotoFolder;
  totalPhotos: number;
  openFolder: (id: string) => void;
  renameFolder: (id: string) => void;
  deleteFolder: (id: string) => void;
  reloadPhotos: () => void;
}

const Folder: React.FC<FolderProps> = ({
  folder,
  totalPhotos,
  openFolder,
  renameFolder,
  deleteFolder,
  reloadPhotos,
}) => {
  const [buttonModalVisible, setButtonModalVisible] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation(['common']);

  const [, drop] = useDrop({
    accept: ['PhotoItem'],
    drop: async (
      item: {
        src: string;
        id: string;
        type: 'PHOTO';
      },
      monitor,
    ) => {
      if (monitor.didDrop()) {
        return;
      }
      await putPhotoInFolder(folder.id, item.id);
      reloadPhotos();
    },
  });

  const folderClick = () => {
    openFolder(folder.id);
  };

  const verticalDotsClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setButtonModalVisible(!buttonModalVisible);
  };

  const renameClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setButtonModalVisible(false);
    renameFolder(folder.id);
  };

  const deleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setButtonModalVisible(false);
    deleteFolder(folder.id);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        buttonModalVisible &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setButtonModalVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [buttonModalVisible]);

  return (
    <Container onClick={folderClick} ref={drop}>
      {buttonModalVisible && (
        <ButtonModal ref={modalRef}>
          <Button onClick={renameClick}>{t('photos.rename')}</Button>
          <RedButton onClick={deleteClick}>{t('photos.delete')}</RedButton>
        </ButtonModal>
      )}
      <FolderDisplayer>
        <ReactSVG src={'/svg/folder.svg'} />
        <div onClick={verticalDotsClick}>
          <VerticalDots src={'/svg/dots_vertical.svg'} />
        </div>
      </FolderDisplayer>
      <Title>{folder.name}</Title>
      <PhotoNumber>{totalPhotos} photos</PhotoNumber>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
`;

const FolderDisplayer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 112px;
  height: 96px;
  position: relative;
  background-color: ${colors.gray100};
  text-overflow: 'ellipsis';
`;

const Title = styled.div`
  width: 112px;
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 500;
  text-align: left;
  color: ${colors.black};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PhotoNumber = styled.div`
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  color: ${colors.gray700};
`;

const VerticalDots = styled(ReactSVG)`
  position: absolute;
  top: 4px;
  right: 0;
  cursor: pointer;
`;

const ButtonModal = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${colors.white};
  width: 150px;
  height: 100px;
  top: 26px;
  right: -26px;
  z-index: 100;
  border: solid 1px ${colors.gray200};
  border-radius: 4px;
`;

const Button = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  font-family: 'DM Sans';
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  color: ${colors.gray700};

  &:hover {
    background-color: ${colors.gray100};
  }
`;

const RedButton = styled(Button)`
  color: ${colors.error600};
  border-top: solid 1px ${colors.gray200};
`;

export default Folder;
