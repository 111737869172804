import styled from 'styled-components';
import colors from '../../constants/colors';
import { SubTitle } from '../functionnalities/MenuStyles';
import useCreationType from '../../hook/useCreationType';
import { useSelector } from 'react-redux';
import { RootState } from '../../constants/initialStore';
import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import useLittleScreen from '../../hook/useLittleScreen';

interface NavigationProps {
  zoomTo: (
    node: HTMLElement | string,
    scale?: number,
    animationTime?: number,
    animationType?:
      | 'linear'
      | 'easeOut'
      | 'easeInQuad'
      | 'easeOutQuad'
      | 'easeInOutQuad'
      | 'easeInCubic'
      | 'easeOutCubic'
      | 'easeInOutCubic'
      | 'easeInQuart'
      | 'easeOutQuart'
      | 'easeInOutQuart'
      | 'easeInQuint'
      | 'easeOutQuint'
      | 'easeInOutQuint'
      | undefined,
  ) => void;
  view: 'global' | 'page';
  updateZoom: () => void;
}

const NavigationMenu: React.FC<NavigationProps> = ({
  zoomTo,
  view,
  updateZoom,
}) => {
  const [currentElementNumber, setCurrentElementNumber] = useState<number>(-1);
  const [maxElement, setMaxElement] = useState<number>(0);

  const littleScreen = useLittleScreen();
  const zoom = useSelector((store: RootState) => store.zoom.value);

  const type = useCreationType();
  const calendar = useSelector(
    (state: RootState) => state.creation.present.calendar,
  );
  const album = useSelector((state: RootState) => state.creation.present.album);
  const menu = useSelector((state: RootState) => state.menu.value);

  const onClickDown = () => {
    if (currentElementNumber < maxElement) {
      const newCurrent = currentElementNumber + 1;
      setCurrentElementNumber(newCurrent);
      const myDiv = document.getElementsByClassName('wrapperTransformClass');
      if (myDiv && myDiv.length > 0) {
        myDiv[0].scrollTop = 0;
      }

      const elementId =
        view === 'page' && newCurrent >= 0
          ? 'creationElementPage-' + newCurrent
          : 'creationElement-' + newCurrent;

      zoomTo(elementId, zoom);
    }
  };

  const onClickUp = () => {
    if (currentElementNumber > -1) {
      const newCurrent = currentElementNumber - 1;
      setCurrentElementNumber(newCurrent);
      const myDiv = document.getElementsByClassName('wrapperTransformClass');
      if (myDiv && myDiv.length > 0) {
        myDiv[0].scrollTop = 0;
      }
      zoomTo(
        view === 'page' && newCurrent >= 0
          ? 'creationElementPage-' + newCurrent
          : 'creationElement-' + newCurrent,
        zoom,
      );
    }
  };

  useEffect(() => {
    if (type === 'album' && album) {
      if (view === 'global') {
        setMaxElement(album.albumPages.length / 2);
      } else {
        setMaxElement(album.albumPages.length);
      }
    } else {
      if (view === 'global') {
        setMaxElement(5);
        if (currentElementNumber > 5) {
          setCurrentElementNumber(5);
        }
      } else {
        setMaxElement(11);
        if (currentElementNumber > 11) {
          setCurrentElementNumber(11);
        }
      }
    }
  }, [view, type, album]);

  if (littleScreen) return null;

  return (
    <Container>
      <ReactSVG
        src={'/svg/arrow_down.svg'}
        beforeInjection={(svg) => {
          svg.setAttribute(
            'style',
            'width: 24px; height: 24px; transform: rotate(180deg); cursor: pointer;',
          );
        }}
        onClick={onClickUp}
      />
      <ReactSVG
        src={'/svg/arrow_down.svg'}
        beforeInjection={(svg) => {
          svg.setAttribute(
            'style',
            'width: 24px; height: 24px; cursor: pointer;',
          );
        }}
        onClick={onClickDown}
      />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  display: flex;
  top: 12px;
  right: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  padding: 8px;
  z-index: 200;
  background-color: ${colors.gray100};
  border: solid 1px ${colors.gray300};
  border-radius: 24px;
`;

export default NavigationMenu;
