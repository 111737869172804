import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const panoramicLayouts1Photo: LayoutElement[][] = [
  [
    {
      type: ElementType.PHOTO,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.9,
      height: 0.8,
      top: 0.1,
      left: 0.05,
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.9,
      height: 0.7,
      top: 0.1,
      left: 0.05,
    },
    {
      type: ElementType.TEXT,
      width: 0.4,
      height: 0.05,
      top: 0.85,
      left: 0.3,
    },
  ],
];
