import {
  ErrorBoundaryProps,
  FallbackProps,
  ErrorBoundary as REBErrorBoundary,
} from 'react-error-boundary';

const fallbackRender = ({ error, resetErrorBoundary }: FallbackProps) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const ErrorBoundary = ({
  children,
}: {
  children: ErrorBoundaryProps['children'];
}) => {
  return (
    <REBErrorBoundary fallbackRender={fallbackRender}>
      {children}
    </REBErrorBoundary>
  );
};

export default ErrorBoundary;
