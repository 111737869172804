import { styled } from 'styled-components';
import { ReactZoomPanPinchContentRef } from 'react-zoom-pan-pinch';
import colors from '../../constants/colors';
import { useEffect, useRef, useState } from 'react';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import { useSelector, useDispatch } from 'react-redux';
import GridOverlay from '../../components/functionnalities/GridOverlay';
import {
  RootState,
  changeRatio,
  resetFocus,
  zoomTo,
} from '../../constants/initialStore';

import SavingPopup from '../../components/functionnalities/SavingPopup';
import TransformZoom from '../../components/general/TransformZoom';
import { otherFormats } from '../../constants/otherFormats';
import { useAutoSaveOther } from '../../hook/useAutoSaveOther';
import { useFetchOrCreateOther } from '../../hook/useFecthOrCreateOther';
import OtherDrawZone from './OtherDrawZone';
import CutZoneOverlay from '../../components/functionnalities/CutZoneOverlay';
import styleConstants from '../../constants/styleConstants';

const MainOtherZone: React.FC = () => {
  const [maxHeight, setMaxHeight] = useState<number>(99999);
  const [maxWidth, setMaxWidth] = useState<number>(99999);
  const [displayHeight, setDisplayHeight] = useState<number>(0);
  const [displayWidth, setDisplayWidth] = useState<number>(0);
  const maxZoneRef = useRef<HTMLDivElement>(null);

  const creation = useSelector(
    (state: RootState) => state.creation.present.other,
  );
  const cutZoneDisplay = useSelector((state: RootState) => state.cutZone.value);
  const gridDisplay = useSelector((state: RootState) => state.grid.value);
  const menu = useSelector((state: RootState) => state.menu.value);
  const zoomRef = useRef<ReactZoomPanPinchContentRef>(null);

  useAutoSaveOther();

  const dispatch = useDispatch();

  useEffect(() => {
    const updateMaxZoneSize = () => {
      setMaxHeight(maxZoneRef.current?.clientHeight ?? 0);
      setMaxWidth(maxZoneRef.current?.clientWidth ?? 0);
    };

    window.addEventListener('resize', updateMaxZoneSize);
    updateMaxZoneSize();
    return () => window.removeEventListener('resize', updateMaxZoneSize);
  }, [maxZoneRef, menu]);

  useEffect(() => {
    zoomRef.current?.setTransform(
      (maxWidth - displayWidth) / 2,
      (maxHeight - displayHeight) / 2,
      1,
    );
  }, [maxWidth, maxHeight, displayWidth, displayHeight]);

  useEffect(() => {
    dispatch(zoomTo(1));
    if (!creation) return;
    const format = otherFormats[creation?.format];
    const ratioHeight = maxHeight / ConvertMMToPixel(format.height);
    const ratioWidth = maxWidth / ConvertMMToPixel(format.width);

    if (ratioWidth > ratioHeight && 1 > ratioHeight) {
      dispatch(changeRatio(ratioHeight));
      setDisplayHeight(
        Math.floor(ratioHeight * ConvertMMToPixel(format.height)),
      );
      setDisplayWidth(Math.floor(ratioHeight * ConvertMMToPixel(format.width)));
    } else if (1 > ratioWidth) {
      dispatch(changeRatio(ratioWidth));
      setDisplayHeight(
        Math.floor(ratioWidth * ConvertMMToPixel(format.height)),
      );
      setDisplayWidth(Math.floor(ratioWidth * ConvertMMToPixel(format.width)));
    } else {
      dispatch(changeRatio(1));
      setDisplayHeight(Math.floor(1 * ConvertMMToPixel(format.height)));
      setDisplayWidth(Math.floor(1 * ConvertMMToPixel(format.width)));
    }
  }, [maxHeight, maxWidth, creation]);

  return (
    <Container onClick={() => dispatch(resetFocus())} $menuOpened={menu !== 0}>
      <MaxZone ref={maxZoneRef}>
        <SavingPopup />
        <TransformZoom ref={zoomRef}>
          {gridDisplay && <GridOverlay />}
          {cutZoneDisplay && <CutZoneOverlay />}
          <OtherDrawZone
            displayHeight={displayHeight}
            displayWidth={displayWidth}
          />
        </TransformZoom>
      </MaxZone>
    </Container>
  );
};

const Container = styled.div<{ $menuOpened: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  overflow: visible;

  justify-content: center;
  align-items: center;

  background-color: ${colors.gray100};

  padding: ${(props) =>
    props.$menuOpened ? '90px 40px 45px' : '90px 135px 45px'};

  @media (max-width: ${styleConstants.breakpointLg}) {
    padding: 12px 12px 0px 12px;
  }
`;

const MaxZone = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;

  position: relative;

  justify-content: center;
  align-items: center;
`;

export default MainOtherZone;
