import { useEffect, useState } from 'react';
import { v4 as uuidv4, validate } from 'uuid';
import { LoginState, useAuth } from '../../hook/useAuth';
import axios from 'axios';
import { getInitialProps } from 'react-i18next';

export const getCookieValue = (name: string): string | null => {
  const cookieName = name + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
};

const CookieHandler = () => {
  const [uuid, setUUID] = useState('');
  const { userInfo, setUUIDForCookie } = useAuth();

  const { initialLanguage } = getInitialProps();

  useEffect(() => {
    const actualCookie = getCookieValue('uuid');
    if (
      (!userInfo || userInfo.state !== LoginState.LOGGED_IN) &&
      uuid === '' &&
      (!actualCookie || actualCookie === '' || !validate(actualCookie))
    ) {
      const newUUID = uuidv4();
      setUUID(newUUID);
      setUUIDForCookie(newUUID);
      const daysUntilExpiration = 30;
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + daysUntilExpiration);

      var cookieString = null;
      if (window.location.hostname.includes('localhost')) {
        cookieString = `uuid=${newUUID}; expires=${expirationDate.toUTCString()}; path=/`;
      } else {
        cookieString = `uuid=${newUUID}; domain=.${
          window.location.hostname.split('.')[1]
        }.${
          window.location.hostname.split('.')[2]
        }; expires=${expirationDate.toUTCString()}; path=/`;
      }

      document.cookie = cookieString;
    } else if (actualCookie && actualCookie !== '') {
      setUUID(actualCookie);
      setUUIDForCookie(actualCookie);

      const daysUntilExpiration = 30;
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + daysUntilExpiration);

      const cookieString = `uuid=${actualCookie}; domain=.planet-cards.${window.location.hostname
        .split('.')
        .pop()}; expires=${expirationDate.toUTCString()}; path=/`;
      // const cookieString = `uuid=${newUUID}; expires=${expirationDate.toUTCString()}; path=/`;

      document.cookie = cookieString;
    }
  }, [userInfo, uuid]);

  if (uuid === '') {
    return null;
  }

  return <></>;
};

export default CookieHandler;
