import { styled } from 'styled-components';
import colors from '../../constants/colors';

interface CheckboxProps {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  children: any;
  style?: any;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  setChecked,
  children,
  style,
}) => {
  return (
    <CheckboxContainer style={style ?? {}}>
      <CheckboxInput
        type="checkbox"
        checked={checked}
        onChange={(e: any) => setChecked(e.target.checked)}
      />
      <LabelContainer>{children}</LabelContainer>
    </CheckboxContainer>
  );
};

const CheckboxContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 12px;

  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 500;
  text-align: left;
`;

const CheckboxInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;

  min-width: 20px;
  min-height: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  border: solid 1px ${colors.black};
  border-radius: 6px;

  background-color: ${colors.white};

  cursor: pointer;

  &:checked {
    &:before {
      content: url('/svg/check.svg');
    }
  }
`;

const LabelContainer = styled.div`
  & > span {
    font-family: 'DM Sans';
    font-size: 10px;
    font-weight: 700;
    text-align: left;
    text-decoration: underline;

    cursor: pointer;
  }
`;

export default Checkbox;
