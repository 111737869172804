import { styled } from 'styled-components';
import { ReactSVG } from 'react-svg';
import { useEffect, useState } from 'react';
import colors from '../../../constants/colors';
import Button, { buttonType } from '../../general/Button';
import { useTranslation } from 'react-i18next';
import styleConstants from '../../../constants/styleConstants';

interface ChangeNameModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  initialName: string;
  handleValidate: (name: string) => void;
}

const ChangeNameModal: React.FC<ChangeNameModalProps> = ({
  visible,
  setVisible,
  initialName,
  handleValidate,
}) => {
  const [name, setName] = useState<string>(initialName);

  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  const { t } = useTranslation(['common']);
  return (
    <Container $show={visible}>
      <FormTopContainer>
        <Title>{t('general.renameYourCreation')}</Title>
        <CloseIcon src="/svg/cross.svg" onClick={() => setVisible(false)} />
      </FormTopContainer>
      <FormContainer>
        <Label>{t('general.name')}</Label>
        <CustomInput
          type="text"
          value={name}
          onChange={(e: any) => setName(e.target.value)}
          maxLength={24}
        />
        <InfoText>
          ({24 - (name?.length ?? 0)} {t('album.remainingChar')})
        </InfoText>
        <Button $type={buttonType.black} onClick={() => handleValidate(name)}>
          {t('general.confirm')}
        </Button>
      </FormContainer>
    </Container>
  );
};

const Container = styled.div<{ $show: boolean }>`
  width: 100%;
  height: 100%;

  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  background-color: rgba(52, 64, 84, 0.7);
`;

const FormTopContainer = styled.div`
  width: 400px;
  height: 84px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;

  background-color: ${colors.white};
  color: ${colors.black};

  border-radius: 8px 8px 0 0;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const FormContainer = styled.div`
  width: 400px;

  display: flex;
  flex-direction: column;

  background-color: ${colors.gray100};
  color: ${colors.black};

  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  padding: 24px;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const Title = styled.div`
  font-family: 'Playfair Display';
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
`;

const CloseIcon = styled(ReactSVG)`
  position: absolute;

  top: 24px;
  right: 24px;

  cursor: pointer;

  @media (max-width: ${styleConstants.breakpointLg}) {
    top: 12px;
    right: 12px;
  }
`;

const Label = styled.div`
  margin: 0 0 6px 0;

  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`;

const CustomInput = styled.input`
  width: 100%;

  color: ${colors.gray500};
  background-color: ${colors.white};

  box-sizing: border-box;
  margin-bottom: 4px;
  border: solid 1px ${colors.gray300};
  padding: 14px 10px;

  font-family: 'DM Sans';
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
`;

const InfoText = styled.div`
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 400;
  color: ${colors.error600};

  margin-bottom: 16px;
`;

export default ChangeNameModal;
