import styled from 'styled-components';
import colors from '../../../constants/colors';
import { Dayjs } from 'dayjs';
import { CalendarMonth } from '../../../types/models/Calendar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../constants/initialStore';
import { calendarFormats } from '../../../constants/calendarFormats';
import CalendarText, { getCalendarFontProps } from './CalendarText';
import { applyRatio } from '../../../utils/convertCMToPixel';

const MonthTitle = styled(CalendarText)`
  color: ${colors.black};
  text-transform: capitalize;
  align-self: flex-end;
`;

const LongGrid = styled.div<{ $ratio: number }>`
  position: absolute;
  top: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(16, 1fr);
  gap: 0 ${applyRatio(180)}px;
  left: ${applyRatio(140)}px;
  right: ${applyRatio(140)}px;
  bottom: ${applyRatio(140)}px;
  grid-auto-flow: column;
`;

const DayElement = styled.div<{ $ratio: number }>`
  position: relative;
  display: flex;
  gap: ${applyRatio(28)}px;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: ${applyRatio(4)}px;
`;

const DayLabel = styled(CalendarText)<{ $ratio: number }>`
  color: ${colors.black};
  text-transform: capitalize;
`;

const DayNumber = styled(CalendarText)<{ $ratio: number }>`
  color: ${colors.black};
`;

const HLine = styled.div<{ $ratio: number }>`
  position: absolute;
  bottom: 0;
  height: ${applyRatio(1)}px;
  background-color: ${colors.black};
  transform: scale(1, 0.3);
  transform-origin: center;
  width: 100%;
`;

type CalendarLongProps = Pick<
  CalendarMonth,
  'backgroundColor' | 'month' | 'calendarDays'
> & {
  days: Dayjs[];
  ratio: number;
};

const CalendarLong = ({ days, ratio }: CalendarLongProps) => {
  const format = useSelector(
    (state: RootState) => state.creation.present.calendar!.format,
  );
  const { fonts: fontsByDisplay } = calendarFormats[format];
  const fonts = fontsByDisplay['LONG']!;

  return (
    <LongGrid $ratio={ratio}>
      <MonthTitle {...getCalendarFontProps(fonts.title, ratio)}>
        {days[0].format('MMMM YYYYY').slice(0, -5)}
      </MonthTitle>
      {days.map((d) => (
        <DayElement key={d.date()} $ratio={ratio}>
          <DayLabel $ratio={ratio} {...getCalendarFontProps(fonts.days, ratio)}>
            {d.format('ddd').slice(0, -1)}
          </DayLabel>
          <DayNumber
            {...getCalendarFontProps(fonts.date, ratio)}
            key={d.date()}
            $ratio={ratio}
          >
            {d.date()}
          </DayNumber>
          <HLine $ratio={ratio} />
        </DayElement>
      ))}
    </LongGrid>
  );
};

export default CalendarLong;
