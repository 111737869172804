import axios from 'axios';
import { CardFromServer } from '../types/models/Cards';

export const getOngoingCard = async (id?: string) => {
  return axios
    .get<CardFromServer>(`/cards/${id ? id : ''}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return null;
    });
};

// export const getOngoingCardNotLoggedIn = async (id?: string) => {
//   return axios.get<CardFromServer>(`/cards/public/${id ? id : ''}`);
// };

export const createCard = async (payload: any) => {
  return axios.post<CardFromServer>(`/cards`, payload);
};

export const saveCard = async (payload: any) => {
  return axios.put(`/cards/${payload.id}`, payload);
};

// export const saveCardPublic = async (payload: any) => {
//   return axios.put(`/cards/public/${payload.id}`, payload);
// };

export const openModel = async (sku: string) => {
  return axios.get<CardFromServer>(`/cards/model/${sku}`);
};

export const saveNameCard = async (id: string, newName: string) => {
  return axios.post<any>(`/cards/name/${id}`, { name: newName });
};

export const duplicateCard = async (id: string) => {
  return axios.post<any>(`/cards/duplicate/${id}`);
};

export const endPersonnalisationCard = async (
  cardID: string,
  locale: string,
  fromCart: boolean,
) => {
  return axios.post<string>(`/cards/end/${cardID}`, { locale, fromCart });
};

// export const endPersonnalisationCardPublic = async (
//   cardID: string,
//   locale: string,
//   fromCart: boolean,
// ) => {
//   return axios.post<string>(`/cards/public/end/${cardID}`, {
//     locale,
//     fromCart,
//   });
// };

export const createCardFromSKU = async (
  sku: string,
  data: {
    paperId?: number;
    cutId?: number;
    stringId?: number;
    enveloppeOption?: string;
    quantityAsked?: number;
  },
) => {
  return axios.post<CardFromServer>(`/cards/fromSKU/${sku}`, data);
};

export const getCardOwnership = async (cardID: string) => {
  return axios.post<any>(`/cards/getOwnership/${cardID}`);
};

export const publishCard = async (cardID: string) => {
  return axios.post<any>(`/creator/publish/${cardID}`);
};
