import styled, { keyframes } from 'styled-components';
import colors from '../../constants/colors';

interface LoaderProps {
  size?: 'small' | 'normal';
  loaderColor?: string;
}

const Loader = (props: LoaderProps) => {
  return (
    <Container>
      <LoaderContainer>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LoaderContainer>
    </Container>
  );
};

const ldsRingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const LoaderContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 2px;
    border: 2px solid;
    border-radius: 50%;
    animation: ${ldsRingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${colors.blue} transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export default Loader;
