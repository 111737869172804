export const availableThemes = [
  {
    name: 'Aquarelle bleue',
    path: '/themes/Aquarelle_bleue.png',
    thumbnailPath: '/themes/thumbnails/Aquarelle_bleue.png',
    greyBG: false,
  },
  {
    name: 'Aquarelle rose',
    path: '/themes/Aquarelle_rose.png',
    thumbnailPath: '/themes/thumbnails/Aquarelle_rose.png',
    greyBG: false,
  },
  {
    name: 'Aquarelle rouge',
    path: '/themes/Aquarelle_rouge.png',
    thumbnailPath: '/themes/thumbnails/Aquarelle_rouge.png',
    greyBG: false,
  },
  {
    name: 'Ardoise',
    path: '/themes/Ardoise.png',
    thumbnailPath: '/themes/thumbnails/Ardoise.png',
    greyBG: false,
  },
  {
    name: 'Bois',
    path: '/themes/Bois.png',
    thumbnailPath: '/themes/thumbnails/Bois.png',
    greyBG: false,
  },
  {
    name: 'Bois blanc',
    path: '/themes/Bois_blanc.png',
    thumbnailPath: '/themes/thumbnails/Bois_blanc.png',
    greyBG: false,
  },
  {
    name: 'Carreaux',
    path: '/themes/Carreaux.png',
    thumbnailPath: '/themes/thumbnails/Carreaux.png',
    greyBG: false,
  },
  {
    name: 'Carte',
    path: '/themes/Carte.png',
    thumbnailPath: '/themes/thumbnails/Carte.png',
    greyBG: false,
  },
  {
    name: 'Constellation',
    path: '/themes/Constellation.png',
    thumbnailPath: '/themes/thumbnails/Constellation.png',
    greyBG: true,
  },
  {
    name: 'Eucalyptus',
    path: '/themes/Eucalyptus.png',
    thumbnailPath: '/themes/thumbnails/Eucalyptus.png',
    greyBG: false,
  },
  {
    name: 'Feu artifice',
    path: '/themes/Feu_artifice.png',
    thumbnailPath: '/themes/thumbnails/Feu_artifice.png',
    greyBG: true,
  },
  {
    name: 'Feuilles blanc',
    path: '/themes/Feuilles_blanc.png',
    thumbnailPath: '/themes/thumbnails/Feuilles_blanc.png',
    greyBG: true,
  },
  {
    name: 'Feuilles vert',
    path: '/themes/Feuilles_vert.png',
    thumbnailPath: '/themes/thumbnails/Feuilles_vert.png',
    greyBG: false,
  },
  {
    name: 'Fleurs',
    path: '/themes/Fleurs.png',
    thumbnailPath: '/themes/thumbnails/Fleurs.png',
    greyBG: true,
  },
  {
    name: 'Fleurs Bas',
    path: '/themes/Fleurs_bas.png',
    thumbnailPath: '/themes/thumbnails/Fleurs_bas.png',
    greyBG: true,
  },
  {
    name: 'Fleurs Champs',
    path: '/themes/Fleurs_champs.png',
    thumbnailPath: '/themes/thumbnails/Fleurs_champs.png',
    greyBG: true,
  },
  {
    name: 'Gynko',
    path: '/themes/Gynko.png',
    thumbnailPath: '/themes/thumbnails/Gynko.png',
    greyBG: true,
  },
  {
    name: 'Kraft',
    path: '/themes/Kraft.png',
    thumbnailPath: '/themes/thumbnails/Kraft.png',
    greyBG: false,
  },
  {
    name: 'Kraft clair',
    path: '/themes/Kraft_clair.png',
    thumbnailPath: '/themes/thumbnails/Kraft_clair.png',
    greyBG: false,
  },
  {
    name: 'Kraft lisse',
    path: '/themes/Kraft_lisse.png',
    thumbnailPath: '/themes/thumbnails/Kraft_lisse.png',
    greyBG: false,
  },
  {
    name: 'Kraft old',
    path: '/themes/Kraft_old.png',
    thumbnailPath: '/themes/thumbnails/Kraft_old.png',
    greyBG: false,
  },
  {
    name: 'Mer',
    path: '/themes/Mer.png',
    thumbnailPath: '/themes/thumbnails/Mer.png',
    greyBG: false,
  },
  {
    name: 'Motifs',
    path: '/themes/Motifs.png',
    thumbnailPath: '/themes/thumbnails/Motifs.png',
    greyBG: false,
  },
  {
    name: 'Motifs blanc',
    path: '/themes/Motifs_blanc.png',
    thumbnailPath: '/themes/thumbnails/Motifs_blanc.png',
    greyBG: true,
  },
  {
    name: 'Motifs Fleurs',
    path: '/themes/Motifs_fleurs.png',
    thumbnailPath: '/themes/thumbnails/Motifs_fleurs.png',
    greyBG: false,
  },
  {
    name: 'Motifs old',
    path: '/themes/Motifs_old.png',
    thumbnailPath: '/themes/thumbnails/Motifs_old.png',
    greyBG: true,
  },
  {
    name: 'Vichy',
    path: '/themes/Vichy.png',
    thumbnailPath: '/themes/thumbnails/Vichy.png',
    greyBG: true,
  },
];
