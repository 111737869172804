import axios from 'axios';
import { PhotoType } from '../types/models/Photos';

export const getFolders = async () => {
  return axios.get(`/photos/folder`);
};

export const createFolder = async (name: string) => {
  return axios.post(`/photos/folder`, { name: name });
};

export const renameFolder = async (newName: string, id: string) => {
  return axios.put(`/photos/folder/${id}`, { name: newName });
};

export const deleteFolder = async (id: string) => {
  return axios.delete(`/photos/folder/${id}`);
};

export const getPhotos = async () => {
  return axios.get(`/photos`);
};

export const deletePhoto = async (id: string) => {
  return axios.delete(`/photos/${id}`);
};

export const importPhotos = async (folderID: string, photos: FormData) => {
  return axios.post<PhotoType[]>(
    `/photos/${folderID === '' ? 'undefined' : folderID}`,
    photos,
  );
};

export const putPhotoInFolder = async (folderID: string, photoID: string) => {
  return axios.post(`/photos/inFolder`, {
    folderID: folderID,
    photoID: photoID,
  });
};
