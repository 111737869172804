import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const portraitLayoutsText: LayoutElement[][] = [
  [
    {
      type: ElementType.TEXT,
      width: 0.82,
      height: 0.76,
      top: 0.12,
      left: 0.09,
    },
  ],
];
