import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const portraitLayouts4Photo: LayoutElement[][] = [
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.82,
      height: 0.86,
      top: 0.07,
      left: 0.09,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.82,
      height: 0.86,
      top: 0.07,
      left: 0.09,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.65,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.35,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.35,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.65,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.43,
      top: 0.07,
      left: 0.09,
    },
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.28,
      top: 0.65,
      left: 0.09,
    },
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.28,
      top: 0.07,
      left: 0.51,
    },
    {
      type: ElementType.PHOTO,
      width: 0.4,
      height: 0.43,
      top: 0.5,
      left: 0.51,
    },
    {
      type: ElementType.TEXT,
      width: 0.4,
      height: 0.07,
      top: 0.54,
      left: 0.09,
    },
    {
      type: ElementType.TEXT,
      width: 0.4,
      height: 0.07,
      top: 0.39,
      left: 0.51,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.82,
      height: 0.86,
      top: 0.07,
      left: 0.09,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.35,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 1,
          height: 0.35,
          top: 0,
          left: 0,
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 0.76,
      top: 0.12,
      left: 0,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.72,
      height: 0.54,
      top: 0.22,
      left: 0.14,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.72,
      height: 0.08,
      top: 0.81,
      left: 0.14,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.72,
      height: 0.44,
      top: 0.27,
      left: 0.14,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.72,
      height: 0.08,
      top: 0.81,
      left: 0.14,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.77,
      height: 0.44,
      top: 0.26,
      left: 0.115,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 2 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 2 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.72,
      height: 0.08,
      top: 0.81,
      left: 0.14,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.68,
      height: 0.58,
      top: 0.21,
      left: 0.16,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.75,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.25,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.25,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.75,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 0.54,
      top: 0.23,
      left: 0,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 0.54,
      top: 0.23,
      left: 0,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 2 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 2 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
];
