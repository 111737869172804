import { useDispatch, useSelector } from 'react-redux';
import { RootState, activatePopup } from '../constants/initialStore';
import { LoginState, useAuth } from './useAuth';
import { saveCard } from '../api/cards';
import { useEffect, useRef } from 'react';
import { saveOther } from '../api/other';
import { PhotoElementStyle, TextElementStyle } from '../types/models/Elements';
import useCreationType from './useCreationType';

export const useAutoSaveOther = () => {
  const dispatch = useDispatch();
  const { userInfo, asklogin, isReady } = useAuth();
  const creation = useSelector(
    (state: RootState) => state.creation.present.other,
  );
  const elements = useSelector(
    (state: RootState) => state.creation.present.elements.value,
  );
  const saveTimeoutRef = useRef<any | null>(null);

  const type = useCreationType();

  const save = async () => {
    if (type !== 'autre') {
      return;
    }
    // if (userInfo?.state !== LoginState.LOGGED_IN) {
    //   // await asklogin(false);
    //   return;
    // }
    if (!creation) {
      return;
    }
    const payload = {
      id: creation.id,
      format: creation.format,
      backgroundColor: creation.backgroundColor,
      backgroundTheme: creation.backgroundTheme,
      accessory: creation.accessory,
      paperId: creation.paperId,
      elements: elements.map(
        ({ face: face, locked: locked, style: style, ...el }) => ({
          ...el,
          style: JSON.stringify(style) as unknown as
            | TextElementStyle
            | PhotoElementStyle,
        }),
      ),
    };

    await saveOther(payload);
    dispatch(activatePopup());
    saveTimeoutRef.current = null;
  };

  useEffect(() => {
    if (!isReady) return;
    if (userInfo?.state === LoginState.LOGGED_IN && userInfo.isAdmin) return;
    if (saveTimeoutRef.current !== null) {
      clearTimeout(saveTimeoutRef.current);
    }
    saveTimeoutRef.current = setTimeout(
      save,
      userInfo?.state === LoginState.LOGGED_IN ? 5000 : 5000,
    );
  }, [
    elements,
    creation,
    userInfo?.state,
    creation?.accessory,
    creation?.paperId,
  ]);

  useEffect(() => {
    const getOwnership = async () => {
      if (userInfo?.state === LoginState.LOGGED_IN && creation?.id) {
        // await getCardOwnership(card.id);
      }
    };
    getOwnership();
  }, [userInfo?.state, creation?.ownerID, creation?.id]);

  return save;
};
