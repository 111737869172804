import { styled } from 'styled-components';
import Button, { buttonType } from '../../general/Button';
import colors from '../../../constants/colors';
import { ReactSVG } from 'react-svg';
import useTheme from '../../../hook/useTheme';
import styleConstants from '../../../constants/styleConstants';

interface ModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleValidate: () => void;
}

const UndoneModal: React.FC<ModalProps> = ({
  visible,
  setVisible,
  handleValidate,
}) => {
  const { color } = useTheme();

  return (
    <Container $show={visible}>
      <FormTopContainer>
        <Title>Vous n'avez pas encore terminé !</Title>
      </FormTopContainer>
      <FormContainer>
        <WarningText>
          <ReactSVG src="/svg/alert_circle.svg" />
          <b>Vous avez des pages vides</b>
          N'oubliez pas de les remplir pour mettre votre produit au panier
        </WarningText>
        <ButtonContainer>
          <Button
            $type={buttonType.white}
            onClick={() => setVisible(false)}
            style={{ width: 'calc(50% - 8px)' }}
          >
            Modifier
          </Button>
          <Button
            $type={buttonType.themed}
            onClick={() => handleValidate()}
            style={{ width: 'calc(50% - 8px)' }}
            $themeColor={color}
          >
            Commander
          </Button>
        </ButtonContainer>
      </FormContainer>
    </Container>
  );
};

const Container = styled.div<{ $show: boolean }>`
  width: 100%;
  height: 100%;

  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  background-color: rgba(52, 64, 84, 0.7);
`;

const FormTopContainer = styled.div`
  width: 400px;
  height: 84px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 0 0 0 18px;

  box-sizing: border-box;

  position: relative;

  background-color: ${colors.white};
  color: ${colors.black};

  border-radius: 8px 8px 0 0;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const FormContainer = styled.div`
  width: 400px;

  display: flex;
  flex-direction: column;

  background-color: ${colors.gray100};
  color: ${colors.black};

  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  padding: 24px;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const Title = styled.div`
  font-family: 'Playfair Display';
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;

  color: ${colors.black};
`;

const WarningText = styled.div`
  display: flex;
  flex-direction: column;

  border: solid 1px ${colors.warning300};
  border-radius: 12px;

  background-color: ${colors.warning25};

  padding: 16px;

  color: ${colors.warning700};

  margin-bottom: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-around;

  gap: 16px;
`;

export default UndoneModal;
