import i18next from 'i18next';
import { ConvertMMToPixel } from '../utils/convertCMToPixel';

export const stringFinitions = [
  // { id: 0, localTag: 'finishes.kraft', img: 'string_1.jpg' },
  { id: 1, localTag: 'finishes.whiteBlack', img: 'string_2.jpg' },
  { id: 2, localTag: 'finishes.whiteGold', img: 'string_3.jpg' },
];

export const accessoryFinitions = [
  { id: '', localTag: 'finishes.kraft', img: 'none.svg' },
  { id: 'P', localTag: 'finishes.whiteBlack', img: 'porte_affiche.svg' },
  { id: 'C', localTag: 'finishes.whiteGold', img: 'frame.svg' },
];

export const formatLineFinitions = [
  { id: 0, img: 'no-line.svg' },
  { id: 1, img: 'line.svg' },
  { id: 2, img: 'dotted_line.svg' },
];

export const mugFormatsColor = [
  {
    formatId: 'MUG4',
    colorName: i18next.t('red', { ns: ['colors'] }),
    color: '#921B1D',
  },
  {
    formatId: 'MUG4-2',
    colorName: i18next.t('green', { ns: ['colors'] }),
    color: '#104642',
  },
  {
    formatId: 'MUG4-3',
    colorName: i18next.t('black', { ns: ['colors'] }),
    color: '#000000',
  },
  {
    formatId: 'MUG4-4',
    colorName: i18next.t('blue', { ns: ['colors'] }),
    color: '#609DB8',
  },
];

export const cutFinitions = [
  {
    id: 0,
    name: 'Square corners',
    price: '0.00',
    path: undefined,
    img: 'cut_1.jpg',
  },
  {
    id: 1,
    name: 'Round corners',
    price: '0.20',
    path: (width: number, height: number, ratio: number) => {
      const cornerLength = ConvertMMToPixel(8) * ratio;
      const borderXLength = width - 2 * cornerLength;
      const borderYLength = height - 2 * cornerLength;
      const startCorner2X = width - cornerLength;
      const curveDXY = (cornerLength * 2) / 3;
      const curveDXY2 = (cornerLength * 1) / 3;
      const value = `m ${startCorner2X} 0 h -${borderXLength} c -${curveDXY} 0 -${cornerLength} ${curveDXY2} -${cornerLength} ${cornerLength} v ${borderYLength} c 0 ${curveDXY} ${curveDXY2} ${cornerLength} ${cornerLength} ${cornerLength} h ${borderXLength} c ${curveDXY} 0 ${cornerLength} -${curveDXY2} ${cornerLength} -${cornerLength} v -${borderYLength} c 0 -${curveDXY} -${curveDXY2} -${cornerLength} -${cornerLength} -${cornerLength} z`;
      return value;
    },
    img: 'cut_2.jpg',
  },
  {
    id: 3,
    name: 'Inverted corners',
    price: '0.20',
    path: (width: number, height: number, ratio: number) => {
      const cornerLength = ConvertMMToPixel(8) * ratio;
      const borderXLength = width - 2 * cornerLength;
      const borderYLength = height - 2 * cornerLength;
      const startCorner2X = width - cornerLength;
      const curveDXY = (cornerLength * 2) / 3;
      const curveDXY2 = (cornerLength * 1) / 3;
      const value = `m ${startCorner2X} 0 l -${borderXLength} 0 c 0 ${curveDXY} -${curveDXY2} ${cornerLength} -${cornerLength} ${cornerLength} v ${borderYLength} c ${curveDXY} 0 ${cornerLength} ${curveDXY2} ${cornerLength} ${cornerLength} h ${borderXLength} c 0 -${curveDXY} ${curveDXY2} -${cornerLength} ${cornerLength} -${cornerLength} v -${borderYLength} c -${curveDXY} 0 -${cornerLength} -${curveDXY2} -${cornerLength} -${cornerLength} z`;
      return value;
    },
    img: 'cut_3.jpg',
  },
];

export const paperFinitions = [
  {
    id: 0,
    localTag: 'finishes.mat',
    price: '0.00',
  },
  {
    id: 1,
    localTag: 'finishes.recycled',
    price: '0.00',
  },
  {
    id: 2,
    localTag: 'finishes.satin',
    price: '0.00',
  },
  {
    id: 3,
    localTag: 'finishes.offwhite',
    price: '0.00',
  },
  {
    id: 4,
    localTag: 'finishes.satinMat',
    price: '0.20',
  },
  {
    id: 5,
    localTag: 'finishes.satinGlow',
    price: '0.20',
  },
  {
    id: 6,
    localTag: 'Magnétique',
    price: '0.30',
  },
  {
    id: 7,
    localTag: 'finishes.pearl',
    price: '0.10',
  },
];

export const paperFinitionsPosters = [
  {
    id: 0,
    localTag: 'finishes.glow',
    price: '0.00',
  }
  // {
  //   id: 0,
  //   localTag: 'finishes.matPriced',
  //   price: '2.00',
  // },
  // {
  //   id: 1,
  //   localTag: 'finishes.glow',
  //   price: '0.00',
  // },
];

export const envelopesFinitions = [
  { id: 0, name: 'Blanc', color: '#fff', price: '0.00' },
  { id: 1, name: 'Bleu', color: '#009ff2', price: '0.10' },
  { id: 2, name: 'Bleu paster', color: '#b7e3f7', price: '0.10' },
  { id: 3, name: 'Rose', color: '#eb9bbd', price: '0.10' },
  { id: 4, name: 'Rose Pastel', color: '#fcedec', price: '0.10' },
  { id: 5, name: 'Kraft', color: '#c9b38e', price: '0.10' },
];

export const finishFinitions = [
  { id: 0, name: 'Magnetic', price: '2.00' },
  { id: 1, name: 'Remove logo', price: '3.00' },
  { id: 2, name: 'Satiny Poster Paper', price: '0.00' },
  { id: 3, name: 'Gloss finish', price: '5.00' },
  { id: 4, name: 'Satiny', price: '0.00' },
];

export const stickerFinitions = [
  { id: 0, name: 'Round', img: 'sticker_1.jpg' },
  { id: 1, name: 'Square', img: 'sticker_2.jpg' },
];

export const paperByFormat = [
  { format: 'G2H', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'G2G', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'PKG1', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'G1G', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'DL1R', availablePapers: [6] },
  { format: 'DL1RV', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'DL2R', availablePapers: [6] },
  { format: 'DL2RV', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'G2R', availablePapers: [6] },
  { format: 'G2GD', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'G2RV', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'G1R', availablePapers: [6] },
  { format: 'G1RV', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'M1R', availablePapers: [6] },
  { format: 'M1RV', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'MCR', availablePapers: [6] },
  { format: 'MCRV', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'P1R', availablePapers: [6] },
  { format: 'P2R', availablePapers: [6] },
  { format: 'PFM', availablePapers: [0, 1, 3, 7] },
  { format: 'P1G', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'PF2HB', availablePapers: [0, 1, 3, 7] },
  { format: 'PR2V', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'CFG', availablePapers: [0, 1, 3, 7] },
  { format: 'P1RV', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'CG', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'CGD', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'R', availablePapers: [0, 1, 2, 3, 7] },
  { format: 'DL2G', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'DL1G', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'DL2H', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'CR', availablePapers: [6] },
  { format: 'CRV', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'LOS', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'MP', availablePapers: [0, 1, 2, 3, 4, 5, 7] },
  { format: 'ARG', availablePapers: [0, 1, 2, 3, 7] },
  { format: 'ARP', availablePapers: [0, 1, 2, 3, 7] },
];

export const formatWithLineOption = [
  'CARNSOU10',
  'CARNSOU15',
  'CARNSPI10',
  'CARNSPI15',
];

export const cutByFormat = [
  { format: 'G2H', availableCuts: [0, 1] },
  { format: 'G2G', availableCuts: [0, 1] },
  { format: 'PKG1', availableCuts: [0, 1, 2] },
  { format: 'G1G', availableCuts: [0, 1] },
  { format: 'DL1R', availableCuts: [0, 1, 2] },
  { format: 'DL1RV', availableCuts: [0, 1, 2] },
  { format: 'DL2R', availableCuts: [0, 1, 2] },
  { format: 'DL2RV', availableCuts: [0, 1, 2] },
  { format: 'G2R', availableCuts: [0, 1, 2] },
  { format: 'G2GD', availableCuts: [0, 1] },
  { format: 'G2RV', availableCuts: [0, 1, 2] },
  { format: 'G1R', availableCuts: [0, 1, 2] },
  { format: 'G1RV', availableCuts: [0, 1, 2] },
  { format: 'M1R', availableCuts: [0, 1, 2] },
  { format: 'M1RV', availableCuts: [0, 1, 2] },
  { format: 'MCR', availableCuts: [0, 1, 2] },
  { format: 'MCRV', availableCuts: [0, 1, 2] },
  { format: 'P1R', availableCuts: [0, 1, 2] },
  { format: 'P2R', availableCuts: [0, 1, 2] },
  { format: 'PFM', availableCuts: [0, 1] },
  { format: 'P1G', availableCuts: [0, 1] },
  { format: 'PF2HB', availableCuts: [0, 1] },
  { format: 'PR2V', availableCuts: [0, 1, 2] },
  { format: 'CFG', availableCuts: [0, 1] },
  { format: 'P1RV', availableCuts: [0, 1, 2] },
  { format: 'CG', availableCuts: [0, 1] },
  { format: 'CGD', availableCuts: [0, 1] },
  { format: 'R', availableCuts: [0, 1, 2] },
  { format: 'DL2G', availableCuts: [0, 1] },
  { format: 'DL1G', availableCuts: [0, 1] },
  { format: 'DL2H', availableCuts: [0, 1] },
  { format: 'CR', availableCuts: [0, 1, 2] },
  { format: 'CRV', availableCuts: [0, 1, 2] },
  { format: 'LOS', availableCuts: [0, 1, 2] },
  { format: 'MP', availableCuts: [0, 1] },
  { format: 'ARG', availableCuts: [0, 1, 2] },
  { format: 'ARP', availableCuts: [0, 1, 2] },
];
