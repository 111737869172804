import { styled } from 'styled-components';
import colors from '../../constants/colors';
import TextElement from './textElements/TextElement';
import { MouseEvent, forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  resetFocus,
  modifyElement,
} from '../../constants/initialStore';
import { ElementType } from '../../types/models/Elements';
import MotifElement from './motifElements/MotifElement';
import { ConvertMMToPixel } from '../../utils/convertCMToPixel';
import PhotoElement from './photoElements/PhotoElement';
import { cutFinitions } from '../../constants/availableFinitions';
import useElementDrop from '../../hook/useElementDrop';
import { mergeRefs } from 'react-merge-refs';
import { cp } from 'fs';

interface DrawZoneProps {
  displayHeight: number;
  displayWidth: number;
  displayBends?: boolean;
  faceToDisplay?: number;
  $canOverflow?: boolean;
  $correctionTranslation?: number[];
  isGeneration?: boolean;
}

const DrawZone: React.FC<DrawZoneProps> = forwardRef(
  (
    {
      displayHeight,
      displayWidth,
      displayBends = true,
      faceToDisplay,
      $canOverflow = true,
      $correctionTranslation,
      isGeneration = false,
    },
    ref,
  ) => {
    const [translationCorrection, setTranslationCorrection] = useState([0, 0]);
    const cardElements = useSelector(
      (state: RootState) => state.creation.present.elements.value,
    );

    const currentFace = useSelector((state: RootState) => state.face.value);
    const card = useSelector(
      (state: RootState) => state.creation.present.card.value,
    );
    const ratio = useSelector((state: RootState) => state.ratio.value);
    const focus = useSelector((state: RootState) => state.focus.value);

    const cardPath = cutFinitions.find((cut) => cut.id === card.cutId)?.path;
    const dispatch = useDispatch();

    const drop = useElementDrop({ face: currentFace });


    useEffect(() => {
      dispatch(resetFocus());
    }, [currentFace]);

    useEffect(() => {
      // console.log($correctionTranslation);
    }, [$correctionTranslation]);

    return (
      <Container
        ref={mergeRefs([drop, ref])}
        id="dropZone"
        $height={displayHeight}
        $width={displayWidth}
        $ratio={ratio}
        $bgcolor={card.facesSizes[currentFace].bgColor}
        $bgtheme={card.facesSizes[currentFace].bgTheme}
        $radius={card.facesSizes[currentFace].radius}
        $path={cardPath && cardPath(displayWidth, displayHeight, ratio)}
        $canOverflow={$canOverflow}
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <ContainerUnmovable
          $height={displayHeight}
          $width={displayWidth}
          $ratio={ratio}
          $radius={card.facesSizes[currentFace].radius}
          $path={cardPath && cardPath(displayWidth, displayHeight, ratio)}
        >
          {cardElements.map((element, index) => {
            if (
              (faceToDisplay
                ? faceToDisplay === element.face
                : currentFace === element.face) &&
              !element.editable
            ) {
              if (element.noPrint === true && isGeneration) {
                return null;
              }
              switch (element.type) {
                case ElementType.TEXT:
                  if (element.content === '' && isGeneration) {
                    return null;
                  }
                  return (
                    <TextElement
                      key={element.id}
                      element={element}
                      index={index}
                      $correctionTranslation={$correctionTranslation}
                    />
                  );
                case ElementType.PHOTO:
                  return (
                    <PhotoElement
                      key={element.id}
                      element={element}
                      index={index}
                      displayPlaceholder={displayBends}
                      $correctionTranslation={$correctionTranslation}
                    />
                  );
                case ElementType.MOTIF:
                  return (
                    <MotifElement
                      key={element.id}
                      element={element}
                      index={index}
                      $correctionTranslation={$correctionTranslation}
                    />
                  );
              }
            }
          })}
        </ContainerUnmovable>
        {cardElements.map((element, index) => {
          if (element.noPrint === true && isGeneration) {
            return null;
          }
          if (
            (faceToDisplay
              ? faceToDisplay === element.face
              : currentFace === element.face) &&
            element.editable
          ) {
            switch (element.type) {
              case ElementType.TEXT:
                if (element.content === '' && isGeneration) {
                  return null;
                }
                return (
                  <TextElement
                    key={element.id}
                    element={element}
                    index={index}
                    $correctionTranslation={$correctionTranslation}
                  />
                );
              case ElementType.PHOTO:
                return (
                  <PhotoElement
                    key={element.id}
                    element={element}
                    index={index}
                    displayPlaceholder={displayBends}
                    $correctionTranslation={$correctionTranslation}
                  />
                );
              case ElementType.MOTIF:
                return (
                  <MotifElement
                    key={element.id}
                    element={element}
                    index={index}
                    $correctionTranslation={$correctionTranslation}
                  />
                );
            }
          }
        })}
        {displayBends &&
          card.facesSizes[currentFace].horizontalBend.map((hBend, index) => {
            return (
              <HBend key={index} $position={ConvertMMToPixel(hBend) * ratio} />
            );
          })}
        {displayBends &&
          card.facesSizes[currentFace].verticalBend.map((vBend, index) => {
            return (
              <VBend key={index} $position={ConvertMMToPixel(vBend) * ratio} />
            );
          })}
        {displayBends &&
          card.facesSizes[currentFace].forbiddenZones?.map((zone, index) => {
            return (
              <ForbiddenZone
                key={index}
                $top={ConvertMMToPixel(zone.top) * ratio}
                $left={ConvertMMToPixel(zone.left) * ratio}
                $width={ConvertMMToPixel(zone.width) * ratio}
                $height={ConvertMMToPixel(zone.height) * ratio}
                $radius={zone.radius.map(
                  (thisRadius) => ConvertMMToPixel(thisRadius) * ratio,
                )}
              />
            );
          })}
      </Container>
    );
  },
);

const Container = styled.div<{
  $width: number;
  $height: number;
  $ratio: number;
  $bgcolor?: string;
  $bgtheme?: string;
  $radius: number[];
  $path?: string;
  $canOverflow: boolean;
}>`
  overflow: ${(props) => (props.$canOverflow ? 'visible' : 'hidden')};
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  background-color: ${(props) =>
    props.$bgcolor && props.$bgcolor !== 'null'
      ? props.$bgcolor
      : colors.gray300};
  background-image: ${(props) =>
    props.$bgtheme && props.$bgtheme !== 'null'
      ? 'url(' + props.$bgtheme + ')'
      : 'none'};
  background-size: cover;

  position: relative;
  transform: translate(0, 0);

  box-sizing: border-box;

  ${({ $path }) => ($path ? `clip-path: path('${$path}');` : '')}
  border-radius: ${(props) =>
    props.$radius.map(
      (radius) => ConvertMMToPixel(radius) * props.$ratio + 'px ',
    )};
`;

const ContainerUnmovable = styled.div<{
  $width: number;
  $height: number;
  $ratio: number;
  $radius: number[];
  $path?: string;
}>`
  position: absolute;
  overflow: hidden;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  background-color: transparent;
  ${({ $path }) => ($path ? `clip-path: path('${$path}');` : '')}
  border-radius: ${(props) =>
    props.$radius.map(
      (radius) => ConvertMMToPixel(radius) * props.$ratio + 'px ',
    )};
`;

const HBend = styled.div<{ $position: number }>`
  position: absolute;
  top: ${(props) => props.$position - 0.5}px;
  height: 1px;
  width: 100%;
  background-color: ${colors.gray700};
  -webkit-box-shadow: 0px 0px 10px 2px ${colors.black};
  box-shadow: 0px 0px 10px 2px ${colors.black};
  z-index: 200;
`;

const VBend = styled.div<{ $position: number }>`
  position: absolute;
  top: 0px;
  left: ${(props) => props.$position - 0.5}px;
  width: 1px;
  height: 100%;
  background-color: ${colors.gray700};
  -webkit-box-shadow: 0px 0px 10px 2px ${colors.black};
  box-shadow: 0px 0px 10px 2px ${colors.black};
  z-index: 200;
`;

const ForbiddenZone = styled.div<{
  $top: number;
  $left: number;
  $width: number;
  $height: number;
  $radius: number[];
}>`
  position: absolute;

  background-color: ${colors.gray900};

  top: ${(props) => props.$top}px;
  left: ${(props) => props.$left}px;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;

  border-radius: ${(props) => props.$radius.join('px ')}px;

  z-index: 250;
`;

export default DrawZone;
