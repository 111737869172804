import React from 'react';
import { styled } from 'styled-components';
import { Element, TextElementStyle } from '../../../types/models/Elements';
import { TextDisplayer } from './TextDisplayer';
import ContentEditable from 'react-contenteditable';

interface ImmutableTextProps {
  element: Element;
  ratio: number;
}

const ImmutableText: React.FC<ImmutableTextProps> = ({ element, ratio }) => {
  return (
    <Text
      $elementstyle={'bold' in element.style ? element.style : null}
      $width={element.width}
      $height={element.height}
      $top={element.top}
      $left={element.left}
      $ratio={ratio}
      $index={element.zIndex}
      html={element.content}
      onChange={() => {}}
      disabled={true}
    />
  );
};

const Text = styled(ContentEditable)<{
  $elementstyle: TextElementStyle | null;
  $width: number;
  $height: number;
  $top: number;
  $left: number;
  $ratio: number;
  $index: number;
}>`
  position: absolute;
  width: ${(props) => `${props.$width * props.$ratio}px`};
  height: ${(props) => `${props.$height * props.$ratio}px`};
  left: ${(props) => `${props.$left * props.$ratio}px`};
  top: ${(props) => `${props.$top * props.$ratio}px`};

  z-index: ${(props) => props.$index};

  ${TextDisplayer};
`;

export default ImmutableText;
