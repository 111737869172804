import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const panoramicLayouts5MorePhotos: LayoutElement[][] = [
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: { gap: 50, direction: 'row' },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.7,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.BLOCK,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.6,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.4,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
            {
              type: ElementType.BLOCK,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.4,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.6,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 0.3,
          height: 1,
          top: 0,
          left: 0,
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.8,
      height: 0.8,
      top: 0.1,
      left: 0.1,
      style: { gap: 50, direction: 'row' },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.7,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.BLOCK,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.6,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.4,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
            {
              type: ElementType.BLOCK,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.4,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.6,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 0.3,
          height: 1,
          top: 0,
          left: 0,
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.8,
      height: 0.8,
      top: 0.1,
      left: 0.1,
      style: { gap: 50, direction: 'column' },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.28,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.44,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.28,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.28,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.44,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.28,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 0.8,
      top: 0.1,
      left: 0,
      style: { gap: 50, direction: 'row' },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: { gap: 50, direction: 'row' },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.4,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.BLOCK,
              width: 1,
              height: 1 / 3,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 2 / 3,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 0.6,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.BLOCK,
              width: 1,
              height: 2 / 3,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 1 / 3,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.8,
      height: 0.6,
      top: 0.1,
      left: 0.1,
      style: { gap: 50, direction: 'row' },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.7,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.BLOCK,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.6,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.4,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
            {
              type: ElementType.BLOCK,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.4,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.6,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 0.3,
          height: 1,
          top: 0,
          left: 0,
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.5,
      height: 0.1,
      left: 0.25,
      top: 0.8,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 0.6,
      top: 0.1,
      left: 0,
      style: { gap: 50, direction: 'row' },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.PHOTO,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.5,
      height: 0.1,
      left: 0.25,
      top: 0.8,
    },
  ],
];
