import { Routes, Route } from 'react-router-dom';
import HomePage from '../pages/home/HomePage';
import Page404 from '../pages/404';
import MainCalendarZone from '../pages/calendars/MainCalendarZone';
import MainCardZone from '../pages/cards/MainCardZone';
import { GenerationPage } from '../pages/generation/GenerationPage';
import MainAlbumZone from '../pages/albums/MainAlbumZone';
import MainOtherZone from '../pages/others/MainOtherZone';
import { LoginState, useAuth } from '../hook/useAuth';
import { NewCreationPage } from '../pages/adminCreations/newCreationPage';

const AppRoutes: React.FC = () => {
  const { userInfo, isReady } = useAuth();

  return (
    <Routes>
      <Route path="/generation/:type/:id" element={<GenerationPage />} />
      {isReady &&
        userInfo &&
        userInfo.state === LoginState.LOGGED_IN &&
        userInfo.isAdmin && (
          <Route path="/admin/nouveau" element={<NewCreationPage />} />
        )}
      <Route element={<HomePage />}>
        <Route path="/" element={<MainCardZone />} />
        <Route path="/carte" element={<MainCardZone />} />
        <Route path="/carte/:id" element={<MainCardZone />} />
        <Route path="/calendrier/:id" element={<MainCalendarZone />} />
        <Route path="/album/:id" element={<MainAlbumZone />} />
        <Route path="/autre/:id" element={<MainOtherZone />} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default AppRoutes;
