import { styled } from 'styled-components';
import { ReactNode, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { RootState } from '../../../../constants/initialStore';
import { calendarFormats } from '../../../../constants/calendarFormats';
import { ConvertMMToPixel } from '../../../../utils/convertCMToPixel';
import {
  ElementType,
  MotifElementStyle,
} from '../../../../types/models/Elements';
import CalendarGrid from '../../../../pages/calendars/displays/CalendarGrid';
import CalendarLine from '../../../../pages/calendars/displays/CalendarLine';
import CalendarConerSquare from '../../../../pages/calendars/displays/CalendarConerSquare';
import CalendarSquare from '../../../../pages/calendars/displays/CalendarSquare';
import CalendarLong from '../../../../pages/calendars/displays/CalendrierLong';
import colors from '../../../../constants/colors';
import ImmutableText from '../../../cardElements/textElements/ImmutableText';
import { ImmutableMotif } from '../../../cardElements/motifElements/ImmutableMotif';
import {
  CalendarCover as CalendarCoverType,
  CalendarMonth as CalendarMonthType,
} from '../../../../types/models/Calendar';
import ImmutablePhoto from '../../../cardElements/photoElements/ImmutablePhoto';
import { useTranslation } from 'react-i18next';

const CalendarPage = ({
  id,
  backgroundColor,
  backgroundTheme,
  isCover,
  children,
}: Pick<CalendarMonthType, 'id' | 'backgroundColor' | 'backgroundTheme'> & {
  children?: ReactNode;
  isCover?: boolean;
}) => {
  const format = useSelector(
    (state: RootState) => state.creation.present.calendar!.format,
  );
  const ratio = useSelector((state: RootState) => state.previewRatio!.value);
  const { width, height, isDouble: rawIsDouble } = calendarFormats[format];
  const isDouble = !isCover && rawIsDouble;
  const displayHeight = (isDouble ? 2 : 1) * ConvertMMToPixel(height) * ratio;
  const displayCutZone = useSelector((state: RootState) => state.cutZone.value);

  const monthElements = useSelector(
    (state: RootState) =>
      state.creation.present.elements.value.filter(
        (el) => el.calendarMonthID === id,
      ),
    { equalityFn: shallowEqual },
  );

  return (
    <PageContainer
      $width={ConvertMMToPixel(width) * ratio}
      $height={displayHeight}
      $backgroundColor={backgroundColor}
      $backgroundTheme={backgroundTheme}
      $displaycutzone={displayCutZone}
      $ratio={ratio}
    >
      {children}
      <Watermark src={'/watermark.png'} />
      {monthElements.map((element) => {
        switch (element.type) {
          case ElementType.TEXT:
            return (
              <ImmutableText key={element.id} element={element} ratio={ratio} />
            );
          case ElementType.PHOTO:
            return (
              <ImmutablePhoto
                key={element.id}
                src={element.content}
                element={element}
                elementstyle={
                  element.style && 'opacity' in element.style
                    ? element.style
                    : null
                }
                ratio={ratio}
              />
            );
          case ElementType.MOTIF:
            return (
              <ImmutableMotif
                src={element.content}
                key={element.id}
                $element={element}
                $elementstyle={element.style as MotifElementStyle}
                $ratio={ratio}
                $index={element.zIndex}
              />
            );
        }
      })}
    </PageContainer>
  );
};

export const CalendarCoverPreview = (props: CalendarCoverType) => {
  const { t } = useTranslation(['common']);
  return (
    <CoverContainer>
      <CoverTitle>{t('album.cover')}</CoverTitle>
      <CalendarPage {...props} isCover />
    </CoverContainer>
  );
};

const MonthPage = (props: CalendarMonthType) => {
  const { month } = props;
  const type = useSelector(
    (state: RootState) => state.creation.present.calendar!.type,
  );
  const format = useSelector(
    (state: RootState) => state.creation.present.calendar!.format,
  );
  const { isDouble } = calendarFormats[format];
  const ratio = useSelector((state: RootState) => state.previewRatio!.value);

  const days = useMemo(() => {
    const monthStart = dayjs.utc(month);
    const monthNumber = monthStart.month();
    const days = [];
    let currentDate = dayjs.utc(monthStart);
    while (currentDate.month() === monthNumber) {
      days.push(dayjs.utc(currentDate));
      currentDate = currentDate.add(1, 'day');
    }
    return days;
  }, [month]);

  return (
    <CalendarPage {...props}>
      {isDouble && <DoubleSeparator />}
      {type === 'GRID' && <CalendarGrid days={days} ratio={ratio} {...props} />}
      {type === 'LINE' && <CalendarLine days={days} ratio={ratio} {...props} />}
      {type === 'CORNER_SQUARE' && (
        <CalendarConerSquare days={days} ratio={ratio} {...props} />
      )}
      {type === 'SQUARE' && (
        <CalendarSquare days={days} ratio={ratio} {...props} />
      )}
      {type === 'LONG' && <CalendarLong days={days} ratio={ratio} {...props} />}
    </CalendarPage>
  );
};

const CalendarMonthPreview = (props: CalendarMonthType) => {
  return (
    <MonthContainer>
      <MonthTitle>{dayjs.utc(props.month).format('MMMM YYYY')}</MonthTitle>
      <MonthPage {...props} />
    </MonthContainer>
  );
};

const MonthContainer = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MonthTitle = styled.span`
  color: ${colors.black};
  font: 500 20px/29px DM Sans;
  line-height: 29.442px; /* 142.857% */
  align-self: flex-start;
  text-transform: capitalize;
  cursor: pointer;
`;

const CoverContainer = styled(MonthContainer)`
  grid-column: 1 / -1;
  margin-top: 30px;
`;

const CoverTitle = styled(MonthTitle)`
  align-self: center;
`;

type PageContainerProps = {
  $height: number;
  $width: number;
  $backgroundColor: string;
  $backgroundTheme?: string;
  $ratio: number;
  $displaycutzone: boolean;
};

const PageContainer = styled.div<PageContainerProps>`
  position: relative;
  overflow: hidden;
  background-size: cover;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  background-color: ${({ $backgroundColor }) =>
    $backgroundColor && $backgroundColor !== 'null'
      ? $backgroundColor
      : colors.white};
  background-image: ${({ $backgroundTheme }) =>
    $backgroundTheme ? 'url(' + $backgroundTheme + ')' : 'none'};

  &:after {
    position: absolute;
    content: '';
    inset: 0;
    pointer-events: none;
    opacity: 0.75;

    border: ${(props) =>
    props.$displaycutzone
      ? 'solid ' + ConvertMMToPixel(1.5) * props.$ratio + 'px ' + colors.gray500
      : 'solid ' + ConvertMMToPixel(1.5) * props.$ratio + 'px transparent'};
    z-index: 10000;
  }
`;

const Watermark = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 1000;
`;

const DoubleSeparator = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  transform: translateY(-50%);
  background-color: ${colors.gray200};
`;

export default CalendarMonthPreview;
