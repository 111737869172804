import { ElementType, LayoutElement } from '../../../types/models/Elements';

export const landscapeLayouts5MorePhoto: LayoutElement[][] = [
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.8,
      height: 0.76,
      top: 0.12,
      left: 0.1,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.PHOTO,
          width: 0.5,
          height: 1,
          top: 0,
          left: 0,
        },
        {
          type: ElementType.BLOCK,
          width: 0.25,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 1,
      top: 0,
      left: 0,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.BLOCK,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.BLOCK,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.8,
      height: 0.76,
      top: 0.12,
      left: 0.1,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.BLOCK,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.BLOCK,
              width: 0.5,
              height: 1,
              top: 0,
              left: 0,
              style: {
                gap: 50,
                direction: 'row',
              },
              children: [
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
                {
                  type: ElementType.PHOTO,
                  width: 0.5,
                  height: 1,
                  top: 0,
                  left: 0,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 1,
      height: 0.66,
      top: 0.17,
      left: 0,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.82,
      height: 0.52,
      top: 0.24,
      left: 0.09,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 0.5,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.8,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.61,
      height: 0.79,
      top: 0.105,
      left: 0.195,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1 / 3,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.82,
      height: 0.79,
      top: 0.105,
      left: 0.09,
      style: {
        gap: 50,
        direction: 'column',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1,
          height: 1 / 3,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'row',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 0.25,
              height: 1,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.76,
      height: 0.81,
      top: 0.095,
      left: 0.12,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.6,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.4,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.4,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.6,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.6,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.4,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.71,
      height: 0.81,
      top: 0.095,
      left: 0.145,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 0.35,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 0.3,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 1 / 3,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 1 / 3,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 1 / 3,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 0.35,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.61,
      height: 0.52,
      top: 0.24,
      left: 0.195,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.81,
      left: 0.24,
    },
  ],
  [
    {
      type: ElementType.BLOCK,
      width: 0.79,
      height: 0.48,
      top: 0.26,
      left: 0.105,
      style: {
        gap: 50,
        direction: 'row',
      },
      children: [
        {
          type: ElementType.BLOCK,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
        {
          type: ElementType.BLOCK,
          width: 1 / 3,
          height: 1,
          top: 0,
          left: 0,
          style: {
            gap: 50,
            direction: 'column',
          },
          children: [
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
            {
              type: ElementType.PHOTO,
              width: 1,
              height: 0.5,
              top: 0,
              left: 0,
            },
          ],
        },
      ],
    },
    {
      type: ElementType.TEXT,
      width: 0.52,
      height: 0.09,
      top: 0.81,
      left: 0.24,
    },
  ],
];
