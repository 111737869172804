import styled from 'styled-components';
import { CalendarFont } from '../../../constants/calendarFormats';
import { ConvertPt300DPIToPixel } from '../../../utils/convertCMToPixel';

type CalendarTextProps = {
  $bold?: boolean;
  $fontSize: number;
  $fontFamily: string;
};

export const getCalendarFontProps = (
  { bold, fontSize, fontFamily }: CalendarFont,
  ratio: number,
) => {
  return {
    $bold: bold,
    $fontSize: ConvertPt300DPIToPixel(fontSize) * ratio,
    $fontFamily: fontFamily,
  };
};

const CalendarText = styled.span<CalendarTextProps>`
  font: ${({ $bold }) => ($bold ? 700 : 400)} ${({ $fontSize }) => $fontSize}px /
    1 ${({ $fontFamily }) => $fontFamily};
`;

export default CalendarText;
