import React, { KeyboardEvent, forwardRef } from 'react';
import { styled } from 'styled-components';
import colors from '../../constants/colors';
import { ReactSVG } from 'react-svg';

interface NumberChooserProps {
  number: number;
  setNumber: React.Dispatch<React.SetStateAction<number>>;
  step: number;
}

const SmallNumberChooser: React.FC<NumberChooserProps> = ({
  number,
  setNumber,
  step,
}) => {
  const handleInputChange = (event: any) => {
    const value = parseFloat(event.target.value);
    if (!isNaN(value)) {
      const roundedValue = value.toFixed(1);
      setNumber(parseFloat(roundedValue));
    }
  };

  const onKeyDown = (e: KeyboardEvent) => {
    e.stopPropagation();
  };

  return (
    <Container>
      <InputContainer>
        <CustomNumberInput
          type="number"
          value={number}
          onChange={handleInputChange}
          step={step}
          onKeyDown={onKeyDown}
        />
        <ButtonContainer>
          <ButtonSVG
            src="/svg/left_chevron.svg"
            onClick={() =>
              setNumber(
                parseFloat((parseFloat(number.toString()) + step).toFixed(1)),
              )
            }
          />
          <ButtonSVG
            src="/svg/right_chevron.svg"
            onClick={() =>
              setNumber(
                parseFloat((parseFloat(number.toString()) - step).toFixed(1)),
              )
            }
          />
        </ButtonContainer>
      </InputContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  color: ${colors.black};
  flex-direction: column;

  gap: 6px;
`;

const InputContainer = styled.div`
  min-width: 80px;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  border: solid 1px ${colors.gray300};
  border-radius: 8px;

  padding: 0px;
`;

const CustomNumberInput = styled.input`
  max-width: 40px;
  flex-grow: 1;
  flex-shrink: 1;
  border-radius: 8px;
  text-align: right;
  margin-right: 5px;

  border: none;
  outline: none;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

const Button = styled.button`
  all: unset;
  cursor: pointer;
`;

const ReactSVGStyle = styled(ReactSVG)`
  transform: rotate(90deg) scale(0.4);
  height: 20px;
`;

const ButtonSVG = forwardRef<HTMLButtonElement, any>(
  ({ onClick, ...props }, ref) => (
    <Button ref={ref} onClick={onClick}>
      <ReactSVGStyle {...props} />
    </Button>
  ),
);

export default SmallNumberChooser;
