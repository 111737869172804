import styled from 'styled-components';
import { Container, ColorContainer } from './MenuStyles';
import colors from '../../constants/colors';
import ReactSelect, { SingleValue } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  changeCard,
  changeOther,
  closeMenu,
} from '../../constants/initialStore';
import {
  accessoryFinitions,
  cutByFormat,
  cutFinitions,
  mugFormatsColor,
  paperByFormat,
  paperFinitions,
  paperFinitionsPosters,
  stringFinitions,
} from '../../constants/availableFinitions';
import { formats } from '../../constants/formats';
import { ArrayElement } from '../../types/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import CloseMenuButton from '../layout/CloseMenuButton';
import useLittleScreen from '../../hook/useLittleScreen';
import ValidateMenuButton from '../layout/ValidateMenuButton';
import useCreationType from '../../hook/useCreationType';
import ColorItem from '../cardElements/ColorItem';

const FinitionsMenuOptions: {
  titlePath: string;
  svgPath: string;
  menuIndex: number;
  toCheck?: 'cutId' | 'stringId';
  forPoster?: boolean;
}[] = [
  {
    titlePath: 'finishes.corners',
    svgPath: '/svg/menu/finitions_coins.svg',
    menuIndex: 1,
    toCheck: 'cutId',
  },
  {
    titlePath: 'finishes.paper',
    svgPath: '/svg/menu/finitions_papiers.svg',
    menuIndex: 2,
  },
  {
    titlePath: 'finishes.cords',
    svgPath: '/svg/menu/finitions_ficelles.svg',
    menuIndex: 3,
    toCheck: 'stringId',
  },
  {
    titlePath: 'finishes.accessories',
    svgPath: '/svg/menu/finitions_cadres.svg',
    menuIndex: 4,
    forPoster: true,
  },
  {
    titlePath: 'finishes.mugColor',
    svgPath: '/svg/menu/fonds_uni.svg',
    menuIndex: 5,
  },
];

type BorderItemProps = ArrayElement<typeof cutFinitions>;

const BorderContainer = styled.button<{ $isSelected: boolean }>`
  all: unset;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 38px;
  border: 1px solid transparent;
  border-color: ${({ $isSelected }) =>
    $isSelected ? colors.black : 'transparent'};
  border-radius: 4px;

  &:hover {
    border-color: ${({ $isSelected }) =>
      $isSelected ? colors.black : colors.gray200};
  }
`;

const ElementPrice = styled.span`
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  color: ${colors.black};
  white-space: nowrap;
  width: 100%;
  text-align: center;
`;

const BorderImage = styled.img`
  margin-top: 2px;
  width: 24px;
  height: 24px;
`;

const BorderItem = ({ id, name, price, img }: BorderItemProps) => {
  const dispatch = useDispatch();
  const isSelected = useSelector(
    (state: RootState) => state.creation.present.card.value.cutId === id,
  );

  const onClick = () => {
    dispatch(changeCard((prev: any) => ({ ...prev, cutId: id })));
  };

  return (
    <BorderContainer $isSelected={isSelected} onClick={onClick}>
      <BorderImage src={`/accessories/${img}`} alt={`Coin ${name}`} />
      <ElementPrice>+{price} €</ElementPrice>
    </BorderContainer>
  );
};

const StringContainer = styled.button<{ $isSelected: boolean }>`
  all: unset;
  cursor: ${({ $isSelected }) => ($isSelected ? 'default' : 'pointer')};
  border-radius: 8px;
  border: 1px solid ${colors.gray200};
  border-color: ${({ $isSelected }) =>
    $isSelected ? colors.black : colors.gray200};
  padding: 2px;
  height: 42px;
  box-sizing: border-box;

  &:hover {
    border-color: ${({ $isSelected }) =>
      $isSelected ? colors.black : colors.gray300};
  }
`;

const StringImage = styled.img`
  width: 36px;
  height: 36px;
  background-color: ${colors.gray100};
`;

const StringItem = ({ id, img }: ArrayElement<typeof stringFinitions>) => {
  const dispatch = useDispatch();
  const isSlected = useSelector(
    (state: RootState) => state.creation.present.card.value.stringId === id,
  );

  const onClick = () => {
    dispatch(changeCard((prev: any) => ({ ...prev, stringId: id })));
  };

  return (
    <StringContainer onClick={onClick} $isSelected={isSlected}>
      <StringImage src={`/accessories/${img}`} />
    </StringContainer>
  );
};

const AccessoryItem = ({
  id,
  img,
}: ArrayElement<typeof accessoryFinitions>) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common']);
  const isSlected = useSelector(
    (state: RootState) => state.creation.present.other?.accessory === id,
  );

  const onClick = () => {
    dispatch(changeOther((prev: any) => ({ ...prev, accessory: id })));
  };

  return (
    <StringContainer onClick={onClick} $isSelected={isSlected}>
      <StringImage src={`/accessories/${img}`} />
    </StringContainer>
  );
};

const PaperSelect = () => {
  const { t } = useTranslation(['common']);
  const type = useCreationType();

  const paperOptionsInitial =
    type === 'autre'
      ? paperFinitionsPosters.map(({ id, localTag, price }) => ({
          value: id,
          label: `${t(localTag)}`,
        }))
      : paperFinitions.map(({ id, localTag, price }) => ({
          value: id,
          label: `${t(localTag)}`,
        }));

  paperFinitions.map(({ id, localTag, price }) => ({
    value: id,
    label: `${t(localTag)} ${price !== '0.00' ? '+' + price + '€' : ''}`,
  }));
  const [paperOptions, setPaperOptions] =
    useState<{ value: number; label: string }[]>(paperOptionsInitial);
  const dispatch = useDispatch();
  const selectedPaperId = useSelector((state: RootState) =>
    type === 'carte'
      ? state.creation.present.card.value.paperId
      : state.creation.present.other?.paperId,
  );
  const formatName = useSelector(
    (state: RootState) => state.creation.present.card.value.format,
  );
  const value = paperOptionsInitial.find(
    ({ value }) => value === selectedPaperId,
  );

  const littleScreen = useLittleScreen();

  useEffect(() => {
    const papersAvailableForFormat = paperByFormat.find(({ format }) => {
      return format === formatName.split('_')[0];
    });
    setPaperOptions(
      type === 'autre'
        ? paperFinitionsPosters.map(({ id, localTag, price }) => ({
            value: id,
            label: `${t(localTag)}`,
          }))
        : paperFinitions
            .filter(({ id }) => {
              return papersAvailableForFormat?.availablePapers.includes(id);
            })
            .map(({ id, localTag, price }) => ({
              value: id,
              label: `${t(localTag)}`,
            })),
    );
  }, [formatName]);

  const onChange = (
    option: SingleValue<ArrayElement<typeof paperOptionsInitial>>,
  ) => {
    if (!option) return;
    if (type === 'carte') {
      dispatch(
        changeCard(
          (prev: RootState['creation']['present']['card']['value']) => ({
            ...prev,
            paperId: option.value,
          }),
        ),
      );
    } else if (type === 'autre') {
      dispatch(
        changeOther(({ ...prev }: any) => ({
          ...prev,
          paperId: option.value,
        })),
      );
    }
  };

  return (
    <ReactSelect
      options={paperOptions}
      isSearchable={!littleScreen}
      styles={{
        option: (baseStyles, { isFocused, isSelected }) => ({
          ...baseStyles,
          color: colors.black,
          backgroundColor: isSelected
            ? colors.gray300
            : isFocused
              ? colors.gray100
              : colors.white,
        }),
        menu: (baseStyles) => ({
          ...baseStyles,
          marginBottom: '42px',
        }),
      }}
      menuPortalTarget={document.body}
      menuPlacement="top"
      value={value}
      onChange={onChange}
    />
  );
};

const ColorMugItem = ({
  formatId,
  colorName,
  color,
}: ArrayElement<typeof mugFormatsColor>) => {
  const dispatch = useDispatch();
  const isSelected = useSelector(
    (state: RootState) => state.creation.present.other?.format === formatId,
  );

  const onClick = () => {
    dispatch(changeOther((prev: any) => ({ ...prev, format: formatId })));
  };

  return (
    <ColorItem
      color={{ name: colorName, color: color }}
      active={isSelected}
      update={onClick}
    />
  );
};

const FinitionsMenuMobile: React.FC = () => {
  const [openedMenu, setOpenedMenu] = useState<number>(0);
  const [notFullyScrolled, setNotFullyScrolled] = useState<boolean>(false);
  const [scrollStarted, setScrollStarted] = useState<boolean>(false);
  const { t } = useTranslation(['common']);
  const type = useCreationType();
  const fomatName = useSelector((state: RootState) =>
    type === 'carte'
      ? state.creation.present.card.value.format
      : state.creation.present.other?.format,
  );
  const format = type === 'carte' ? formats[fomatName ?? ''] : null;
  const dispatch = useDispatch();

  const elementRef = useRef<HTMLDivElement>(null);

  const CloseSubMenu = () => {
    setOpenedMenu(0);
  };

  const handleScroll = () => {
    const element = elementRef.current;
    if (element && element.scrollLeft !== 0) {
      setScrollStarted(true);
    } else {
      setScrollStarted(false);
    }
    if (
      element &&
      element.scrollWidth <= Math.ceil(element.scrollLeft + element.clientWidth)
    ) {
      setNotFullyScrolled(false);
    } else {
      setNotFullyScrolled(true);
    }
  };

  useEffect(() => {
    const element = elementRef.current;
    if (element && element.scrollLeft !== 0) {
      setScrollStarted(true);
    } else {
      setScrollStarted(false);
    }
    if (
      element &&
      element.scrollWidth <= Math.ceil(element.scrollLeft + element.clientWidth)
    ) {
      setNotFullyScrolled(false);
    } else {
      setNotFullyScrolled(true);
    }
  }, [elementRef]);

  if (openedMenu === 0) {
    return (
      <MobileContainerBig>
        {scrollStarted && <FadeEffectLeft />}
        <TabContainer ref={elementRef} onScroll={handleScroll}>
          {FinitionsMenuOptions.map((option, index) => {
            // to modify to be more generic
            if (
              option.menuIndex === 2 &&
              (fomatName?.startsWith('CARN') || fomatName?.includes('MUG4'))
            ) {
              return null;
            }
            if (option.menuIndex === 5 && !fomatName?.includes('MUG4')) {
              return null;
            }

            if (option.toCheck) {
              if (!format || format[option.toCheck] === undefined) {
                return null;
              }
            }
            if (
              (option.forPoster &&
                fomatName &&
                fomatName.includes('POS') &&
                fomatName !== 'POS80X60') ||
              !option.forPoster
            ) {
              return (
                <Tab
                  onClick={() => {
                    setOpenedMenu(option.menuIndex);
                  }}
                  key={index}
                >
                  <ReactSVG
                    src={option.svgPath}
                    beforeInjection={(svg) => {
                      svg.setAttribute('style', 'width: 24px; height: 24px;');
                    }}
                  />
                  <Title>{t(option.titlePath)}</Title>
                </Tab>
              );
            }
          })}
        </TabContainer>
        {notFullyScrolled && <FadeEffectRight />}
        <CloseMenuButton />
      </MobileContainerBig>
    );
  } else if (openedMenu === 1) {
    return (
      <MobileContainerBig>
        <Item>
          <SliderLabel>Forme des coins</SliderLabel>
          <ColorContainer>
            {cutFinitions
              .filter((cut, index) => {
                const cutsAvailables = cutByFormat.find(
                  (cut) => cut.format === fomatName,
                );
                if (cutsAvailables) {
                  return cutsAvailables.availableCuts.includes(index);
                } else {
                  return false;
                }
              })
              .map((finition) => (
                <BorderItem key={finition.id} {...finition} />
              ))}
          </ColorContainer>
        </Item>
        <ValidateMenuButton handleValidate={CloseSubMenu} />
      </MobileContainerBig>
    );
  } else if (openedMenu === 2) {
    return (
      <MobileContainerBig>
        <Item>
          <SliderLabel>{t('finishes.paper')}</SliderLabel>
          <PaperSelect />
        </Item>
        <ValidateMenuButton handleValidate={CloseSubMenu} />
      </MobileContainerBig>
    );
  } else if (openedMenu === 3) {
    return (
      <MobileContainerBig>
        <Item>
          <SliderLabel>{t('finishes.cords')}</SliderLabel>
          <ColorContainer>
            {stringFinitions.map((finition) => (
              <StringItem key={finition.id} {...finition} />
            ))}
          </ColorContainer>
        </Item>
        <ValidateMenuButton handleValidate={CloseSubMenu} />
      </MobileContainerBig>
    );
  } else if (openedMenu === 4) {
    return (
      <MobileContainerBig>
        <Item>
          <SliderLabel>{t('finishes.accessories')}</SliderLabel>{' '}
          <ColorContainer>
            {accessoryFinitions.map((finition) =>
              fomatName?.includes('30') || finition.id !== 'C' ? (
                <AccessoryItem key={finition.id} {...finition} />
              ) : null,
            )}
          </ColorContainer>
        </Item>
        <ValidateMenuButton handleValidate={CloseSubMenu} />
      </MobileContainerBig>
    );
  } else if (openedMenu === 5) {
    return (
      <MobileContainerBig>
        <Item>
          <SliderLabel>{t('finishes.mugColor')}</SliderLabel>{' '}
          <ColorContainer>
            {mugFormatsColor.map((finition) => (
              <ColorMugItem {...finition} />
            ))}
          </ColorContainer>
        </Item>
        <ValidateMenuButton handleValidate={CloseSubMenu} />
      </MobileContainerBig>
    );
  }

  return (
    <Container>
      <CloseMenuButton />
      {format && format.cutId !== undefined && (
        <Item>
          <SliderLabel>Forme des coins</SliderLabel>
          <ColorContainer>
            {cutFinitions
              .filter((cut, index) => {
                const cutsAvailables = cutByFormat.find(
                  (cut) => cut.format === fomatName,
                );
                if (cutsAvailables) {
                  return cutsAvailables.availableCuts.includes(index);
                } else {
                  return false;
                }
              })
              .map((finition) => (
                <BorderItem key={finition.id} {...finition} />
              ))}
          </ColorContainer>
        </Item>
      )}
      {!fomatName?.startsWith('CARN') && !fomatName?.includes('MUG4') ? (
        <Item>
          <SliderLabel>{t('finishes.paper')}</SliderLabel>
          <PaperSelect />
        </Item>
      ) : null}
      {format && format.stringId !== undefined && (
        <Item>
          <SliderLabel>{t('finishes.cords')}</SliderLabel>
          <StringContainerList>
            {stringFinitions.map((finition) => (
              <StringItem key={finition.id} {...finition} />
            ))}
          </StringContainerList>
        </Item>
      )}
      {fomatName?.includes('POS') && fomatName !== 'POS80X60' && (
        <Item>
          <SliderLabel>{t('finishes.accessories')}</SliderLabel>{' '}
          <ColorContainer>
            {accessoryFinitions.map((finition) =>
              fomatName.includes('30') || finition.id !== 'C' ? (
                <AccessoryItem key={finition.id} {...finition} />
              ) : null,
            )}
          </ColorContainer>
        </Item>
      )}
      {!!fomatName && fomatName.includes('MUG4') && (
        <Item>
          <SliderLabel>{t('finishes.mugColor')}</SliderLabel>
          <ColorContainer>
            {mugFormatsColor.map((finition) => (
              <ColorMugItem {...finition} />
            ))}
          </ColorContainer>
        </Item>
      )}
    </Container>
  );
};

const MobileContainer = styled.div`
  display: flex;
  min-width: 100%;
  height: 48px;

  justify-content: space-between;
  align-items: center;

  flex-direction: row;

  gap: 6px;
`;

const MobileContainerBig = styled(MobileContainer)`
  height: 80px;
  color: black;
`;

const TabContainer = styled.div`
  height: 48px;
  width: calc(100% - 48px);

  display: flex;
  flex-direction: row;
  gap: 6px;

  padding: 0 2px;

  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

const FadeEffect = styled.div`
  width: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 2;
`;

const FadeEffectRight = styled(FadeEffect)`
  right: 50px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
`;

const FadeEffectLeft = styled(FadeEffect)`
  left: 0px;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
`;

const Tab = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  font-size: 8px;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover {
    background-color: ${colors.gray200};
  }
`;

const Title = styled.div`
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  color: ${colors.black};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: calc(100% - 48px);
  gap: 8px;
`;

const SliderLabel = styled.div`
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const StringContainerList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export default FinitionsMenuMobile;
