import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import colors from '../../constants/colors';
import { useDispatch, useSelector } from 'react-redux';
import { availableColors } from '../../constants/availableColors';
import ColorItem from '../cardElements/ColorItem';
import {
  RootState,
  changeAlbum,
  changeCalendar,
  changeCard,
  changeOther,
  closeMenu,
} from '../../constants/initialStore';
import { ReactSVG } from 'react-svg';
import { availableThemes } from '../../constants/availableThemes';
import ThemeItem from '../cardElements/ThemeItem';
import {
  HorizontalTab,
  HorizontalTabContainer,
} from '../general/HorizontalTab';
import { Calendar } from '../../types/models/Calendar';
import { Album } from '../../types/models/Album';
import useCreationType from '../../hook/useCreationType';
import { useTranslation } from 'react-i18next';
import { CloseButton, TitleContainer, TitleItem } from './MenuStyles';
import Button, { buttonType } from '../general/Button';
import { LoginState, useAuth } from '../../hook/useAuth';
import CloseMenuButton from '../layout/CloseMenuButton';
import ValidateMenuButton from '../layout/ValidateMenuButton';
import styleConstants from '../../constants/styleConstants';

const BackGroundMenuOptions = [
  {
    titlePath: 'backgrounds.colors',
    svgPath: '/svg/menu/fonds_uni.svg',
    menuIndex: 1,
  },
  // {
  //   titlePath: 'backgrounds.patterns',
  //   svgPath: '/svg/menu/fonds_themes.svg',
  //   menuIndex: 2,
  // },
];

const BackGroundMenuMobile: React.FC = () => {
  const [chosenColor, setChosenColor] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<number>(0);

  const [notFullyScrolled, setNotFullyScrolled] = useState<boolean>(false);
  const [scrollStarted, setScrollStarted] = useState<boolean>(false);

  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );
  const currentFace = useSelector((state: RootState) => state.face.value);
  const focus = useSelector((state: RootState) => state.focus.value);
  const dispatch = useDispatch();

  const typeCreation = useCreationType();

  const { t } = useTranslation(['common']);

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const user = useAuth();

  useEffect(() => {
    if (
      user.userInfo?.state === LoginState.LOGGED_IN &&
      user.userInfo.isAdmin
    ) {
      setIsAdmin(true);
    }
  }, [user]);

  useEffect(() => {
    if (card && currentFace && currentFace < card.facesSizes.length) {
      setChosenColor(card.facesSizes[currentFace].bgColor ?? null);
    } else {
      setChosenColor(null);
    }
  });

  const handleLockBackground = () => {
    if (typeCreation === 'carte') {
      dispatch(
        changeCard({
          ...card,
          facesSizes: card.facesSizes.map((face, index) => {
            if (index === currentFace) {
              return { ...face, locked: !face.locked };
            } else {
              return face;
            }
          }),
        }),
      );
    } else if (typeCreation === 'calendrier') {
      dispatch(
        changeCalendar(({ calendarMonths, ...prev }: any) => ({
          ...prev,
          calendarMonths: (calendarMonths as Calendar['calendarMonths']).map(
            (month) =>
              month.id === focus
                ? {
                    ...month,
                    locked: !calendarMonths.locked,
                  }
                : month,
          ),
        })),
      );
    } else if (typeCreation === 'album') {
      dispatch(
        changeAlbum(
          ({ albumPages, albumCover, albumBackCover, ...prev }: any) => ({
            ...prev,
            albumCover:
              albumCover.id === focus
                ? { ...albumCover, locked: !albumCover.locked }
                : albumCover,
            albumBackCover:
              albumBackCover.id === focus
                ? { ...albumBackCover, locked: !albumBackCover.locked }
                : albumBackCover,
            albumPages: (albumPages as Album['albumPages']).map((page) =>
              page.id === focus
                ? { ...page, locked: !albumPages.locked }
                : page,
            ),
          }),
        ),
      );
    } else if (typeCreation === 'autre') {
      dispatch(
        changeOther(({ ...prev }: any) => ({
          ...prev,
          locked: !prev.locked,
        })),
      );
    }
  };

  const handleChangeColor = (color: { name: string; color: string }) => {
    setChosenColor(color.color);
    if (typeCreation === 'carte') {
      dispatch(
        changeCard({
          ...card,
          facesSizes: card.facesSizes.map((face, index) => {
            if (index === currentFace) {
              return { ...face, bgColor: color.color };
            } else {
              return face;
            }
          }),
        }),
      );
    } else if (typeCreation === 'calendrier') {
      dispatch(
        changeCalendar(({ calendarMonths, ...prev }: any) => ({
          ...prev,
          calendarMonths: (calendarMonths as Calendar['calendarMonths']).map(
            (month) =>
              month.id === focus
                ? {
                    ...month,
                    backgroundColor: color.color,
                  }
                : month,
          ),
        })),
      );
    } else if (typeCreation === 'album') {
      dispatch(
        changeAlbum(
          ({ albumPages, albumCover, albumBackCover, ...prev }: any) => ({
            ...prev,
            albumCover:
              albumCover.id === focus
                ? { ...albumCover, backgroundColor: color.color }
                : albumCover,
            albumBackCover:
              albumBackCover.id === focus
                ? { ...albumBackCover, backgroundColor: color.color }
                : albumBackCover,
            albumPages: (albumPages as Album['albumPages']).map((page) =>
              page.id === focus
                ? { ...page, backgroundColor: color.color }
                : page,
            ),
          }),
        ),
      );
    } else if (typeCreation === 'autre') {
      dispatch(
        changeOther(({ ...prev }: any) => ({
          ...prev,
          backgroundColor: color.color,
        })),
      );
    }
  };
  const handleNoColor = () => {
    setChosenColor(null);
    if (typeCreation === 'carte') {
      dispatch(
        changeCard({
          ...card,
          facesSizes: card.facesSizes.map((face, index) => {
            if (index === currentFace) {
              return { ...face, bgColor: undefined };
            } else {
              return face;
            }
          }),
        }),
      );
    } else if (typeCreation === 'calendrier') {
      dispatch(
        changeCalendar(({ calendarMonths, ...prev }: any) => ({
          ...prev,
          calendarMonths: (calendarMonths as Calendar['calendarMonths']).map(
            (month) =>
              month.id === focus
                ? {
                    ...month,
                    backgroundColor: undefined,
                  }
                : month,
          ),
        })),
      );
    } else if (typeCreation === 'album') {
      dispatch(
        changeAlbum(
          ({ albumPages, albumCover, albumBackCover, ...prev }: any) => ({
            ...prev,
            albumCover:
              albumCover.id === focus
                ? { ...albumCover, backgroundColor: undefined }
                : albumCover,
            albumBackCover:
              albumBackCover.id === focus
                ? { ...albumBackCover, backgroundColor: undefined }
                : albumBackCover,
            albumPages: (albumPages as Album['albumPages']).map((page) =>
              page.id === focus
                ? { ...page, backgroundColor: undefined }
                : page,
            ),
          }),
        ),
      );
    } else if (typeCreation === 'autre') {
      dispatch(
        changeOther(({ ...prev }: any) => ({
          ...prev,
          backgroundColor: undefined,
        })),
      );
    }
  };

  const handleNoTheme = () => {
    setChosenColor(null);
    if (typeCreation === 'carte') {
      dispatch(
        changeCard({
          ...card,
          facesSizes: card.facesSizes.map((face, index) => {
            if (index === currentFace) {
              return { ...face, bgTheme: undefined };
            } else {
              return face;
            }
          }),
        }),
      );
    } else if (typeCreation === 'calendrier') {
      dispatch(
        changeCalendar(({ calendarMonths, ...prev }: any) => ({
          ...prev,
          calendarMonths: (calendarMonths as Calendar['calendarMonths']).map(
            (month) =>
              month.id === focus
                ? {
                    ...month,
                    backgroundTheme: undefined,
                  }
                : month,
          ),
        })),
      );
    } else if (typeCreation === 'album') {
      dispatch(
        changeAlbum(
          ({ albumPages, albumCover, albumBackCover, ...prev }: any) => ({
            ...prev,
            albumCover:
              albumCover.id === focus
                ? { ...albumCover, backgroundTheme: undefined }
                : albumCover,
            albumBackCover:
              albumBackCover.id === focus
                ? { ...albumBackCover, backgroundTheme: undefined }
                : albumBackCover,
            albumPages: (albumPages as Album['albumPages']).map((page) =>
              page.id === focus
                ? { ...page, backgroundTheme: undefined }
                : page,
            ),
          }),
        ),
      );
    } else if (typeCreation === 'autre') {
      dispatch(
        changeOther(({ ...prev }: any) => ({
          ...prev,
          backgroundTheme: undefined,
        })),
      );
    }
  };

  const elementRef = useRef<HTMLDivElement>(null);

  const CloseSubMenu = () => {
    setActiveTab(0);
  };

  const handleScroll = () => {
    const element = elementRef.current;
    if (element && element.scrollLeft !== 0) {
      setScrollStarted(true);
    } else {
      setScrollStarted(false);
    }
    if (
      element &&
      element.scrollWidth <= Math.ceil(element.scrollLeft + element.clientWidth)
    ) {
      setNotFullyScrolled(false);
    } else {
      setNotFullyScrolled(true);
    }
  };

  useEffect(() => {
    const element = elementRef.current;
    if (element && element.scrollLeft !== 0) {
      setScrollStarted(true);
    } else {
      setScrollStarted(false);
    }
    if (
      element &&
      element.scrollWidth <= Math.ceil(element.scrollLeft + element.clientWidth)
    ) {
      setNotFullyScrolled(false);
    } else {
      setNotFullyScrolled(true);
    }
  }, [elementRef, activeTab]);

  if (card.facesSizes[currentFace].locked && !isAdmin) {
    return (
      <>
        <CloseMenuButton />
        <Container>
          <Item>
            {isAdmin && (
              <Button $type={buttonType.white} onClick={handleLockBackground}>
                {!card.facesSizes[currentFace].locked
                  ? 'Verrouiller '
                  : 'Déverrouiller '}
                le fond
              </Button>
            )}
            {!isAdmin && <SubTitle>{'Le fond a été verrouillé'}</SubTitle>}
          </Item>
        </Container>
      </>
    );
  }

  if (activeTab === 0) {
    return (
      <MobileContainerBig>
        {scrollStarted && <FadeEffectLeft />}
        <TabContainer ref={elementRef} onScroll={handleScroll}>
          {BackGroundMenuOptions.map((option, index) => (
            <Tab
              onClick={() => {
                setActiveTab(option.menuIndex);
              }}
              key={index}
            >
              <ReactSVG
                src={option.svgPath}
                beforeInjection={(svg) => {
                  svg.setAttribute('style', 'width: 24px; height: 24px;');
                }}
              />
              <Title>{t(option.titlePath)}</Title>
            </Tab>
          ))}
        </TabContainer>
        {notFullyScrolled && <FadeEffectRight />}
        <CloseMenuButton />
      </MobileContainerBig>
    );
  } else if (activeTab === 1) {
    return (
      <MobileContainerBig>
        {scrollStarted && <FadeEffectLeft />}
        <TabContainer ref={elementRef} onScroll={handleScroll}>
          {availableColors.map((color, index) => (
            <ColorItem
              key={index}
              color={color}
              active={chosenColor === color.color}
              update={handleChangeColor}
            />
          ))}
        </TabContainer>
        {notFullyScrolled && <FadeEffectRight />}
        <ValidateMenuButton handleValidate={CloseSubMenu} />
      </MobileContainerBig>
    );
  } else if (activeTab === 2) {
    return (
      <MobileContainerBig>
        {scrollStarted && <FadeEffectLeft />}
        <TabContainer ref={elementRef} onScroll={handleScroll}>
          <NoThemeItem onClick={handleNoTheme}>
            <ReactSVG src="/svg/forbidden.svg" />
          </NoThemeItem>
          {availableThemes.map((theme, index) => (
            <ThemeItem key={theme.path} theme={theme} index={index} />
          ))}
        </TabContainer>
        {notFullyScrolled && <FadeEffectRight />}
        <ValidateMenuButton handleValidate={CloseSubMenu} />
      </MobileContainerBig>
    );
  }

  return (
    <>
      <CloseMenuButton />
      <Container>
        <HorizontalTabContainer>
          <HorizontalTab
            $active={activeTab === 1}
            onClick={() => setActiveTab(1)}
          >
            {t('backgrounds.colors')}
          </HorizontalTab>
          <HorizontalTab
            $active={activeTab === 2}
            onClick={() => setActiveTab(2)}
          >
            {t('backgrounds.patterns')}
          </HorizontalTab>
        </HorizontalTabContainer>

        {/*
        <Item>
          <SubTitle>{t('backgrounds.recommendedColors')}</SubTitle>
          <ColorContainer>
            {card.recommandedColorsIDs.map((colorID) => (
              <ColorItem
                color={availableColors[colorID]}
                active={chosenColor === availableColors[colorID].color}
                update={handleChangeColor}
              />
            ))}
          </ColorContainer>
        </Item>
            )*/}

        {activeTab === 0 && (
          <Item>
            <ColorContainer>
              {availableColors.map((color, index) => (
                <ColorItem
                  key={index}
                  color={color}
                  active={chosenColor === color.color}
                  update={handleChangeColor}
                />
              ))}
            </ColorContainer>
          </Item>
        )}

        {activeTab === 1 && (
          <Item>
            <SubTitle>{t('backgrounds.allPatterns')}</SubTitle>
            <ColorContainer>
              <NoThemeItem onClick={handleNoTheme}>
                <ReactSVG src="/svg/forbidden.svg" />
              </NoThemeItem>
              {availableThemes.map((theme, index) => (
                <ThemeItem key={theme.path} theme={theme} index={index} />
              ))}
            </ColorContainer>
          </Item>
        )}
        <Item>
          {isAdmin && card.facesSizes[currentFace].locked && (
            <SubTitle>Le fond a été verrouillé pour les utilisateurs</SubTitle>
          )}
          {isAdmin && (
            <Button $type={buttonType.white} onClick={handleLockBackground}>
              {!card.facesSizes[currentFace].locked
                ? 'Verrouiller '
                : 'Déverrouiller '}
              le fond
            </Button>
          )}
        </Item>
      </Container>
    </>
  );
};

const MobileContainer = styled.div`
  display: flex;
  min-width: 100%;
  height: 48px;

  justify-content: space-between;
  align-items: center;

  flex-direction: row;

  gap: 6px;
`;

const MobileContainerBig = styled(MobileContainer)`
  height: 80px;
  color: black;
`;

const TabContainer = styled.div`
  height: 48px;
  width: calc(100% - 48px);

  display: flex;
  flex-direction: row;
  gap: 6px;

  padding: 0 2px;

  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

const FadeEffect = styled.div`
  width: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 2;
`;

const FadeEffectRight = styled(FadeEffect)`
  right: 50px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
`;

const FadeEffectLeft = styled(FadeEffect)`
  left: 0px;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
`;

const Tab = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  font-size: 8px;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover {
    background-color: ${colors.gray200};
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: ${colors.white};

  box-sizing: border-box;
  gap: 24px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 8px;
`;

const Title = styled.div`
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  color: ${colors.black};
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 2px;
  background-color: ${colors.gray100};

  margin-top: 8px;
`;

const SubTitle = styled.div`
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: ${colors.black};
`;

const ColorContainer = styled.div`
  width: 100%;
  padding: 4px 16px 16px;

  display: flex;
  flex-direction: row;

  gap: 4px;

  box-sizing: border-box;

  flex-wrap: wrap;

  & > div {
    margin-top: 12px;
  }
`;

const NoColorItem = styled.div`
  display: flex;
  width: 36px;
  height: 36px;
  background-color: ${colors.white};

  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  border: solid 1px ${colors.gray400};
  border-radius: 4px;
  padding-top: 5px;

  cursor: pointer;
`;

const NoThemeItem = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  background-color: ${colors.white};

  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  border: solid 1px ${colors.gray400};
  border-radius: 4px;
  padding-top: 5px;

  cursor: pointer;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 36px;
    height: 36px;
    min-width: 36px;
  }
`;

export default BackGroundMenuMobile;
