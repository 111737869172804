import styled from 'styled-components';
import colors from '../../constants/colors';

export const ButtonLine = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  border: solid 1px ${colors.gray300};
  border-radius: 6px;
`;

export const InlinedButton = styled.div<{ $active: boolean }>`
  height: 40px;
  display: flex;
  flex: 1;

  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 12px;
  font-weight: 500;

  color: ${(props) => (props.$active ? colors.white : colors.black)};
  background-color: ${(props) => (props.$active ? colors.black : colors.white)};
  border-radius: 6px;

  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.$active ? colors.black : colors.gray100};
  }
`;

export const VLine = styled.div`
  width: 1px;
  height: 16px;
  border-radius: 2px;
  background-color: ${colors.gray200};
`;
