import React from 'react';
import { createPortal } from 'react-dom';
import { CloseButton } from '../functionnalities/MenuStyles';
import { useDispatch } from 'react-redux';
import { closeMenu } from '../../constants/initialStore';
import { ReactSVG } from 'react-svg';
import useLittleScreen from '../../hook/useLittleScreen';
import styled from 'styled-components';

interface BiggerMenuButtonProps {
  $isBigger: boolean;
  $changeSizeMenu: () => void;
}

const BiggerMenuButton: React.FC<BiggerMenuButtonProps> = ({
  $isBigger,
  $changeSizeMenu,
}) => {
  return createPortal(
    <CloseButton
      onClick={$changeSizeMenu}
      $isSmall={true}
      $isOutside={true}
      $isHigher={$isBigger}
      $isLeft={true}
    >
      <ExpandIcon expanded={!$isBigger} src={'/svg/left_chevron.svg'} />
    </CloseButton>,
    document.body,
  );
};

const ExpandIcon = styled.img<{ expanded: boolean }>`
  margin-bottom: 2px;
  margin-right: 2px;
  scale: 0.5;
  transform: rotate(270deg);
  transition: transform 0.3s ease-in-out;

  ${(props) =>
    props.expanded
      ? `
    transform: rotate(90deg);
  `
      : ''}
`;

export default BiggerMenuButton;
