import { styled } from 'styled-components';
import colors from '../../constants/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  addElement,
  closeMenu,
  deleteElement,
  modifyElement,
} from '../../constants/initialStore';
import React, { useEffect, useRef, useState } from 'react';
import Button, { buttonType } from '../general/Button';
import IconButton from '../general/IconButton';
import ReactSelect from 'react-select';
import { ReactSVG } from 'react-svg';
import { ElementType } from '../../types/models/Elements';
import NumberChooser from '../general/NumberChooser';
import RemoveElementModal from './modals/RemoveElementModal';
import ColorPicker from '../cardElements/ColorPicker';
import ColorItem from '../cardElements/ColorItem';
import { availableColors } from '../../constants/availableColors';
import {
  Font,
  availableFonts,
  fontsGroups,
} from '../../constants/availableFonts';
import useCreationType from '../../hook/useCreationType';
import { ButtonLine, InlinedButton, VLine } from '../general/ButtonLine';
import { useTranslation } from 'react-i18next';
import { CloseButton, TitleContainer, TitleItem } from './MenuStyles';
import RangeSlider from './Slider';
import { LoginState, useAuth } from '../../hook/useAuth';
import ManagePixel from '../general/ManagePixel';
import {
  ConvertMMToPixel,
  ConvertPt300DPIToPixel,
} from '../../utils/convertCMToPixel';
import ManageElementSize from '../general/ManageElementSize';
import CloseMenuButton from '../layout/CloseMenuButton';
import useLittleScreen from '../../hook/useLittleScreen';
import SmallNumberChooser from '../general/SmallNumberChooser';
import SmallIconButton from '../general/SmallIconButton';
import ValidateMenuButton from '../layout/ValidateMenuButton';
import {
  getFreeSpaceHeightAlbum,
  getFreeSpaceHeightCalendar,
  getFreeSpaceWidthAlbum,
  getFreeSpaceWidthCalendar,
} from '../../utils/getFreeSpace';
import { calendarFormats } from '../../constants/calendarFormats';
import { albumFormats } from '../../constants/albumFormats';
import { otherFormats } from '../../constants/otherFormats';

const mapTransformToCasse = ['none', 'uppercase', 'lowercase', 'capitalize'];

const mapAlignement = ['left', 'center', 'right', 'justify'];

const TextMenuOptions: {
  titlePath: string;
  svgPath: string;
  menuIndex: number;
}[] = [
  {
    titlePath: 'text.font',
    svgPath: '/svg/menu/texte_police.svg',
    menuIndex: 1,
  },
  {
    titlePath: 'text.color',
    svgPath: '/svg/menu/texte_couleur.svg',
    menuIndex: 2,
  },
  {
    titlePath: 'text.size',
    svgPath: '/svg/menu/texte_taille.svg',
    menuIndex: 3,
  },
  {
    titlePath: 'text.style',
    svgPath: '/svg/menu/texte_format.svg',
    menuIndex: 4,
  },
  { titlePath: 'photos.delete', svgPath: '/svg/trash.svg', menuIndex: 5 },
];

const TextMenuMobile: React.FC = () => {
  const [openedMenu, setOpenedMenu] = useState<number>(0);
  const [notFullyScrolled, setNotFullyScrolled] = useState<boolean>(false);
  const [scrollStarted, setScrollStarted] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [initialTop, setInitialTop] = useState<number>(100);
  const [initialLeft, setInitialLeft] = useState<number>(100);
  const [activeCasse, setActiveCasse] = useState<number>(0);
  const [activeAlignement, setActiveAlignement] = useState<number>(0);
  const [isBoldActive, setIsBoldActive] = useState<boolean>(false);
  const [isItalicActive, setIsItalicActive] = useState<boolean>(false);
  const [isUnderLineActive, setIsUnderLineActive] = useState<boolean>(false);
  const [selectedFont, setSelectedFont] = useState<string>('DM Sans');
  const [fontSize, setFontSize] = useState<number>(14);
  const [interLine, setInterLine] = useState<number>(0);
  const [letterSpace, setLetterSpace] = useState<number>(1);
  const [isSupExpoActive, setIsSupExpoActive] = useState<boolean>(false);
  const [colorChosen, setColorChosen] = useState<{
    color: string;
    name: string;
  }>(availableColors[0]);
  const [rotationValue, setRotationValue] = useState<number>(0);
  const [pixelHorizontalPerfect, setPixelHorizontalPerfect] =
    useState<number>(0);
  const [pixelVerticalPerfect, setPixelVerticalPerfect] = useState<number>(0);
  const [planValue, setPlanValue] = useState<number>(0);
  const [removeModalVisible, setRemoveModalVisible] = useState<boolean>(false);
  const [colorPickerOpen, setColorPickerOpen] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const [isLabel, setIsLabel] = useState<boolean>(false);

  const [font, setFont] = useState<Font | undefined>(availableFonts[0]);
  const [fontValue, setFontValue] = useState<
    | {
        value: string;
        label: string;
      }
    | undefined
  >({ value: `'${availableFonts[0].name}'`, label: availableFonts[0].name });

  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );
  const calendar = useSelector(
    (state: RootState) => state.creation.present.calendar,
  );
  const album = useSelector((state: RootState) => state.creation.present.album);
  const autre = useSelector((state: RootState) => state.creation.present.other);

  const type = useCreationType();

  const currentFace = useSelector((state: RootState) => state.face.value);

  const [maxWidth, setMaxWidth] = useState<number>(0);
  const [maxHeight, setMaxHeight] = useState<number>(0);

  const littleScreen = useLittleScreen();

  const lockUnlockElement = async () => {
    if (elementFocused) {
      dispatch(
        modifyElement({
          id: elementFocused.id,
          element: {
            ...elementFocused,
            editable: !elementFocused.editable,
          },
        }),
      );
    }
  };

  const lockUnlockElementContent = async () => {
    if (elementFocused) {
      dispatch(
        modifyElement({
          id: elementFocused.id,
          element: {
            ...elementFocused,
            contentEditable: !elementFocused.contentEditable,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (type === 'carte' && card) {
      setMaxWidth(
        parseInt(
          ConvertMMToPixel(card.facesSizes[currentFace].width).toFixed(0),
        ),
      );
      setMaxHeight(
        parseInt(
          ConvertMMToPixel(card.facesSizes[currentFace].height).toFixed(0),
        ),
      );
      if (
        card.facesSizes[currentFace].width ===
          card.facesSizes[currentFace].height &&
        (card.facesSizes[currentFace].width === 43 ||
          card.facesSizes[currentFace].width === 40)
      ) {
        setIsLabel(true);
      }
    } else if (type === 'calendrier' && calendar) {
      setMaxWidth(
        getFreeSpaceWidthCalendar(
          calendar.type,
          calendarFormats[calendar.format],
        ),
      );
      setMaxHeight(
        getFreeSpaceHeightCalendar(
          calendar.type,
          calendarFormats[calendar.format],
        ),
      );
    } else if (type === 'album' && album) {
      setMaxWidth(
        getFreeSpaceWidthAlbum(
          albumFormats[album.format].type,
          albumFormats[album.format],
          false,
        ),
      );
      setMaxHeight(getFreeSpaceHeightAlbum(albumFormats[album.format]));
    } else if (type === 'autre' && autre) {
      setMaxWidth(
        parseInt(ConvertMMToPixel(otherFormats[autre.format].width).toFixed(0)),
      );
      setMaxHeight(
        parseInt(
          ConvertMMToPixel(otherFormats[autre.format].height).toFixed(0),
        ),
      );
    }
  }, [type, card, calendar, album, autre]);

  const focus = useSelector((state: RootState) => state.focus.value);
  const elementFocused = useSelector((state: RootState) =>
    state.creation.present.elements.value.find(
      ({ id, type }: { id?: string; type: ElementType }) =>
        id === focus && type === ElementType.TEXT,
    ),
  );
  const elementMaxZIndex = useSelector(
    (state: RootState) => state.creation.present.elements.value.length,
  );
  const dispatch = useDispatch();

  const { t } = useTranslation(['common']);

  const DeleteText = () => {
    dispatch(deleteElement({ id: focus }));
    setRemoveModalVisible(false);
  };

  const AddText = () => {
    dispatch(
      addElement({
        element: {
          type: 'TEXT',
          top: maxHeight / 2 - (isLabel ? 118 : 150), // centered
          left: maxWidth / 2 - (isLabel ? 86 : 350), // centered
          width: isLabel ? 172 : 700,
          height: isLabel ? 236 : 300,
          face: type === 'carte' ? currentFace : undefined,
          calendarMonthID: type === 'calendrier' ? focus : undefined,
          pageID: type === 'album' ? focus : undefined,
          content: '',
          zIndex: elementMaxZIndex,
          style: {
            bold: isBoldActive,
            italic: isItalicActive,
            underlined: isUnderLineActive,
            transform: mapTransformToCasse[activeCasse],
            alignement: mapAlignement[activeAlignement],
            font: selectedFont,
            size: fontSize,
            letterSpacing: letterSpace,
            interline: interLine,
            color: colorChosen.color,
            rotation: rotationValue,
          },
          editable: true,
          contentEditable: true,
        },
      }),
    );
    setInitialTop((prevValue) => (prevValue >= 200 ? 100 : prevValue + 20));
    setInitialLeft((prevValue) => prevValue + 20);
  };

  const DuplicateText = () => {
    if (elementFocused) {
      const elementToDuplicate = {
        ...elementFocused,
        top: elementFocused.top + 20,
        left: elementFocused.left + 20,
      };
      delete elementToDuplicate.id;
      dispatch(addElement({ element: elementToDuplicate }));
    }
  };

  const handleOpenColorPicker = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setColorPickerOpen(!colorPickerOpen);
  };

  const changeColor = (color: { name: string; color: string }) => {
    setColorChosen(color);
    setColorPickerOpen(false);
  };

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const user = useAuth();

  useEffect(() => {
    if (
      user.userInfo?.state === LoginState.LOGGED_IN &&
      user.userInfo.isAdmin
    ) {
      setIsAdmin(true);
    }
  }, [user]);

  useEffect(() => {
    if (elementFocused) {
      setWidth(parseInt(String(elementFocused.width)));
      setHeight(parseInt(String(elementFocused.height)));
      setPlanValue(elementFocused.zIndex || 0);
      if ('bold' in elementFocused.style) {
        setIsBoldActive(elementFocused.style.bold);
        setIsItalicActive(elementFocused.style.italic);
        setIsUnderLineActive(elementFocused.style.underlined);
        setActiveAlignement(
          mapAlignement.findIndex((alignement) =>
            'alignement' in elementFocused.style
              ? alignement === elementFocused.style.alignement
              : false,
          ),
        );
        setActiveCasse(
          mapTransformToCasse.findIndex((casse) =>
            'transform' in elementFocused.style
              ? casse === elementFocused.style.transform
              : false,
          ),
        );
        setSelectedFont(elementFocused.style.font);
        setInterLine(elementFocused.style.interline as number);
        setFontSize(elementFocused.style.size as number);
        setLetterSpace(elementFocused.style.letterSpacing as number);
        setColorChosen(
          availableColors.find((color) =>
            'transform' in elementFocused.style
              ? color.color === elementFocused.style.color.toLowerCase()
              : color.color === '',
          ) ?? availableColors[0],
        );
        setRotationValue(elementFocused.style.rotation as number);
        setIsReady(true);
        setPixelHorizontalPerfect(parseInt(String(elementFocused.left)));
        setPixelVerticalPerfect(parseInt(String(elementFocused.top)));
      }
    } else {
      setIsBoldActive(false);
      setIsItalicActive(false);
      setIsUnderLineActive(false);
      setActiveAlignement(0);
      setActiveCasse(0);
      setSelectedFont('DM Sans');
      setInterLine(0);
      setFontSize(14);
      setLetterSpace(0);
      setColorChosen(availableColors[0]);
      setRotationValue(0);
      setIsReady(true);
    }
  }, [focus, elementFocused]);

  useEffect(() => {
    if (elementFocused && isReady) {
      dispatch(
        modifyElement({
          id: elementFocused.id,
          element: {
            ...elementFocused,
            width,
            height,
            content: document.getElementById('textInput-' + elementFocused?.id)!
              .innerHTML,
            top: pixelVerticalPerfect,
            left: pixelHorizontalPerfect,
            zIndex: planValue,
            style: {
              bold: isBoldActive,
              italic: isItalicActive,
              underlined: isUnderLineActive,
              transform: mapTransformToCasse[activeCasse],
              alignement: mapAlignement[activeAlignement],
              font: selectedFont,
              size: fontSize,
              letterSpacing: letterSpace,
              interline: interLine,
              color: colorChosen.color,
              rotation: rotationValue,
            },
          },
        }),
      );
    }
  }, [
    isReady,
    isBoldActive,
    isItalicActive,
    isUnderLineActive,
    activeCasse,
    activeAlignement,
    selectedFont,
    fontSize,
    letterSpace,
    interLine,
    colorChosen,
    rotationValue,
    pixelHorizontalPerfect,
    pixelVerticalPerfect,
    width,
    height,
    planValue,
    isSupExpoActive,
  ]);

  useEffect(() => {
    const chosenFont = availableFonts.find(
      ({ name }) => `'${name}'` === selectedFont,
    );
    setFont(chosenFont);
    setFontValue(
      chosenFont
        ? {
            value: `'${chosenFont.name}'`,
            label: chosenFont.name,
          }
        : undefined,
    );
  }, [selectedFont]);

  const onSetInterline = (value: any) => setInterLine(value);

  if (elementFocused && !elementFocused.editable && !isAdmin) {
    return (
      <Container>
        <CloseMenuButton />
        <Item>
          <SubTitle>
            {"L'élément a été verrouillé par l'administrateur"}
          </SubTitle>
        </Item>
      </Container>
    );
  }

  const handleSupExpo = () => {
    const selection = window.getSelection();
    const selectedText = selection?.toString();

    if (selectedText && selectedText.length > 0 && selection) {
      const range = selection.getRangeAt(0);
      const containerElement = range.commonAncestorContainer.parentElement;

      if (containerElement && containerElement.tagName === 'SUP') {
        const docFrag = document.createDocumentFragment();
        while (containerElement.firstChild) {
          const child = containerElement.removeChild(
            containerElement.firstChild,
          );
          docFrag.appendChild(child);
        }

        if (containerElement.parentNode) {
          containerElement.parentNode.replaceChild(docFrag, containerElement);
          setIsSupExpoActive(false);
        }
      } else {
        const sup = document.createElement('sup');
        range.surroundContents(sup);
        setIsSupExpoActive(true);
      }

      selection.removeAllRanges();
    }
  };

  const elementRef = useRef<HTMLDivElement>(null);

  const CloseSubMenu = () => {
    setOpenedMenu(0);
  };

  const handleScroll = () => {
    const element = elementRef.current;
    if (element && element.scrollLeft !== 0) {
      setScrollStarted(true);
    } else {
      setScrollStarted(false);
    }
    if (
      element &&
      element.scrollWidth <= Math.ceil(element.scrollLeft + element.clientWidth)
    ) {
      setNotFullyScrolled(false);
    } else {
      setNotFullyScrolled(true);
    }
  };

  useEffect(() => {
    const element = elementRef.current;
    if (element && element.scrollLeft !== 0) {
      setScrollStarted(true);
    } else {
      setScrollStarted(false);
    }
    if (
      element &&
      element.scrollWidth <= Math.ceil(element.scrollLeft + element.clientWidth)
    ) {
      setNotFullyScrolled(false);
    } else {
      setNotFullyScrolled(true);
    }
  }, [elementRef, openedMenu]);

  if (openedMenu === 0) {
    return (
      <MobileContainerBig>
        <RemoveElementModal
          visible={removeModalVisible}
          setVisible={setRemoveModalVisible}
          handleValidate={DeleteText}
        />
        {scrollStarted && <FadeEffectLeft />}
        <TabContainer ref={elementRef} onScroll={handleScroll}>
          <Button $type={buttonType.themed} onClick={AddText}>
            <ReactSVG
              src={'/svg/plus_white.svg'}
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 16px; height: 16px;');
              }}
            />
          </Button>
          {TextMenuOptions.map((option, index) => {
            if (option.menuIndex !== 5) {
              return (
                <Tab
                  onClick={() => {
                    setOpenedMenu(option.menuIndex);
                  }}
                  key={index}
                >
                  <ReactSVG
                    src={option.svgPath}
                    beforeInjection={(svg) => {
                      svg.setAttribute('style', 'width: 24px; height: 24px;');
                    }}
                  />
                  <Title>{t(option.titlePath)}</Title>
                </Tab>
              );
            } else {
              if (elementFocused) {
                return (
                  <Tab
                    onClick={() => {
                      setRemoveModalVisible(true);
                    }}
                    key={index}
                  >
                    <ReactSVG
                      src={option.svgPath}
                      beforeInjection={(svg) => {
                        svg.setAttribute('style', 'width: 24px; height: 24px;');
                      }}
                    />
                    <Title style={{ color: colors.red }}>
                      {t(option.titlePath)}
                    </Title>
                  </Tab>
                );
              }
            }
          })}
          <Tab
            onClick={() => setPixelHorizontalPerfect((maxWidth - width) / 2)}
            style={{ padding: '0px 6px' }}
          >
            <ReactSVG
              src={'/svg/alignement_horizontal.svg'}
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 24px; height: 24px;');
              }}
            />
            <Title>{''}</Title>
          </Tab>
          <Tab
            onClick={() => setPixelVerticalPerfect((maxHeight - height) / 2)}
            style={{ padding: '0px 6px' }}
          >
            <ReactSVG
              src={'/svg/alignement_vertical.svg'}
              beforeInjection={(svg) => {
                svg.setAttribute('style', 'width: 24px; height: 24px;');
              }}
            />
            <Title>{''}</Title>
          </Tab>
        </TabContainer>
        {notFullyScrolled && <FadeEffectRight />}
        <CloseMenuButton />
      </MobileContainerBig>
    );
  } else if (openedMenu === 1) {
    return (
      <MobileContainerBig>
        <Item>
          <ReactSelect
            options={fontsGroups}
            // formatGroupLabel={formatGroupLabel}
            isSearchable={!littleScreen}
            styles={{
              container: (styles) => {
                return {
                  ...styles,
                  minHeight: '42px',
                  width: '90%',
                };
              },
              option: (styles, { data, isSelected, isFocused }) => {
                return {
                  ...styles,
                  fontFamily: data.value,
                  color: colors.black,
                  backgroundColor: isSelected
                    ? colors.gray300
                    : isFocused
                    ? colors.gray100
                    : colors.white,
                };
              },
              singleValue: (styles, { data }) => {
                return {
                  ...styles,
                  fontFamily: data.value,
                  color: colors.black,
                };
              },
              groupHeading: (base, props) => {
                return {
                  ...base,
                  backgroundColor: colors.gray400,
                  textDecoration: 'underline',
                  color: colors.white,
                  padding: '6px',
                };
              },
              menu: (baseStyles) => ({
                ...baseStyles,
                marginBottom: '26px',
              }),
            }}
            value={fontValue}
            onChange={(newValue) => {
              if (newValue) {
                setSelectedFont(newValue.value);
              } else {
                setSelectedFont('');
              }
            }}
            menuPortalTarget={document.body}
            menuPlacement="top"
          />
        </Item>
        <ValidateMenuButton handleValidate={CloseSubMenu} />
      </MobileContainerBig>
    );
  } else if (openedMenu === 2) {
    return (
      <MobileContainerBig>
        {scrollStarted && <FadeEffectLeft />}
        <TabContainer ref={elementRef} onScroll={handleScroll}>
          {availableColors.map((color, index) => (
            <ColorItem
              key={index}
              color={color}
              active={colorChosen === color}
              update={changeColor}
            />
          ))}
        </TabContainer>
        {notFullyScrolled && <FadeEffectRight />}
        <ValidateMenuButton handleValidate={CloseSubMenu} />
      </MobileContainerBig>
    );
  } else if (openedMenu === 3) {
    return (
      <MobileContainerBig>
        <Item style={{ flexDirection: 'column' }}>
          <SliderLabel>{t('text.size')}</SliderLabel>
          <RangeSlider
            value={fontSize}
            onChange={(newValue) => {
              setFontSize(newValue);
            }}
            max={200}
            min={8}
            step={0.5}
          />
        </Item>
        <ValidateMenuButton handleValidate={CloseSubMenu} />
      </MobileContainerBig>
    );
  } else if (openedMenu === 4) {
    return (
      <MobileContainerBig>
        <Item>
          <SmallIconButton
            src={isBoldActive ? '/svg/bold_white.svg' : '/svg/bold_black.svg'}
            disabled={font && font.bold === false}
            active={isBoldActive}
            onClick={() => setIsBoldActive(!isBoldActive)}
          />
          <SmallIconButton
            src={
              isItalicActive ? '/svg/italic_white.svg' : '/svg/italic_black.svg'
            }
            disabled={font && font.italic === false}
            active={isItalicActive}
            onClick={() => setIsItalicActive(!isItalicActive)}
          />
          <SmallIconButton
            src={
              isUnderLineActive
                ? '/svg/underline_white.svg'
                : '/svg/underline_black.svg'
            }
            disabled={false}
            active={isUnderLineActive}
            onClick={() => setIsUnderLineActive(!isUnderLineActive)}
          />
          <ButtonLine>
            <InlinedButton
              $active={activeAlignement === 0}
              onClick={() => setActiveAlignement(0)}
            >
              <ReactSVG
                src={
                  activeAlignement === 0
                    ? '/svg/align_left_white.svg'
                    : '/svg/align_left_black.svg'
                }
              />
            </InlinedButton>
            <VLine />
            <InlinedButton
              $active={activeAlignement === 1}
              onClick={() => setActiveAlignement(1)}
            >
              <ReactSVG
                src={
                  activeAlignement === 1
                    ? '/svg/align_center_white.svg'
                    : '/svg/align_center_black.svg'
                }
              />
            </InlinedButton>
            <VLine />
            <InlinedButton
              $active={activeAlignement === 2}
              onClick={() => setActiveAlignement(2)}
            >
              <ReactSVG
                src={
                  activeAlignement === 2
                    ? '/svg/align_right_white.svg'
                    : '/svg/align_right_black.svg'
                }
              />
            </InlinedButton>
          </ButtonLine>
        </Item>
        <ValidateMenuButton handleValidate={CloseSubMenu} />
      </MobileContainerBig>
    );
  }

  return (
    <Container>
      <RemoveElementModal
        visible={removeModalVisible}
        setVisible={setRemoveModalVisible}
        handleValidate={DeleteText}
      />
      {colorPickerOpen && (
        <ColorPicker
          visible={colorPickerOpen}
          setVisible={setColorPickerOpen}
          update={changeColor}
          chosenColor={colorChosen}
        />
      )}
      <CloseMenuButton />

      <Row>
        <Button $type={buttonType.themed} onClick={AddText}>
          <ReactSVG
            src={'/svg/plus_white.svg'}
            beforeInjection={(svg) => {
              svg.setAttribute('style', 'width: 16px; height: 16px;');
            }}
          />
        </Button>
        <ReactSelect
          options={fontsGroups}
          // formatGroupLabel={formatGroupLabel}
          isSearchable={!littleScreen}
          styles={{
            menuList: (styles) => {
              return {
                ...styles,
                height: '120px',
                position: 'absolute',
                top: '-50px',
                width: '100%',
              };
            },
            container: (styles) => {
              return {
                ...styles,
                width: '60%',
                minHeight: '42px',
              };
            },
            option: (styles, { data, isSelected, isFocused }) => {
              return {
                ...styles,
                fontFamily: data.value,
                color: colors.black,
                backgroundColor: isSelected
                  ? colors.gray300
                  : isFocused
                  ? colors.gray100
                  : colors.white,
              };
            },
            singleValue: (styles, { data }) => {
              return { ...styles, fontFamily: data.value, color: colors.black };
            },
            groupHeading: (base, props) => {
              return {
                ...base,
                backgroundColor: colors.gray400,
                textDecoration: 'underline',
                color: colors.white,
                padding: '6px',
              };
            },
          }}
          value={fontValue}
          onChange={(newValue) => {
            if (newValue) {
              setSelectedFont(newValue.value);
            } else {
              setSelectedFont('');
            }
          }}
        />
        <SmallContainer onClick={handleOpenColorPicker}>
          <ColorItem color={colorChosen} />
          <IconSVG src="/svg/right_chevron.svg" />
        </SmallContainer>
        <SmallNumberChooser
          number={fontSize}
          setNumber={setFontSize}
          step={1}
        />
      </Row>

      <Row>
        <IconButtonLine>
          <SmallIconButton
            src={isBoldActive ? '/svg/bold_white.svg' : '/svg/bold_black.svg'}
            disabled={font && font.bold === false}
            active={isBoldActive}
            onClick={() => setIsBoldActive(!isBoldActive)}
          />
          <SmallIconButton
            src={
              isItalicActive ? '/svg/italic_white.svg' : '/svg/italic_black.svg'
            }
            disabled={font && font.italic === false}
            active={isItalicActive}
            onClick={() => setIsItalicActive(!isItalicActive)}
          />
          <SmallIconButton
            src={
              isUnderLineActive
                ? '/svg/underline_white.svg'
                : '/svg/underline_black.svg'
            }
            disabled={false}
            active={isUnderLineActive}
            onClick={() => setIsUnderLineActive(!isUnderLineActive)}
          />
        </IconButtonLine>
        <ButtonLine style={{ display: 'flex', flex: '1' }}>
          <InlinedButton
            $active={activeAlignement === 0}
            onClick={() => setActiveAlignement(0)}
          >
            <ReactSVG
              src={
                activeAlignement === 0
                  ? '/svg/align_left_white.svg'
                  : '/svg/align_left_black.svg'
              }
            />
          </InlinedButton>
          <VLine />
          <InlinedButton
            $active={activeAlignement === 1}
            onClick={() => setActiveAlignement(1)}
          >
            <ReactSVG
              src={
                activeAlignement === 1
                  ? '/svg/align_center_white.svg'
                  : '/svg/align_center_black.svg'
              }
            />
          </InlinedButton>
          <VLine />
          <InlinedButton
            $active={activeAlignement === 2}
            onClick={() => setActiveAlignement(2)}
          >
            <ReactSVG
              src={
                activeAlignement === 2
                  ? '/svg/align_right_white.svg'
                  : '/svg/align_right_black.svg'
              }
            />
          </InlinedButton>
        </ButtonLine>
        <IconButtonLine>
          <SmallIconButton
            src="/svg/duplicate.svg"
            disabled={focus === null}
            onClick={DuplicateText}
          />
          <SmallIconButton
            src="/svg/trash.svg"
            disabled={focus === null}
            onClick={() => setRemoveModalVisible(true)}
          />
        </IconButtonLine>
      </Row>
    </Container>
  );
};

const MobileContainer = styled.div`
  display: flex;
  min-width: 100%;
  height: 48px;

  justify-content: space-between;
  align-items: center;

  flex-direction: row;

  gap: 6px;
`;

const MobileContainerBig = styled(MobileContainer)`
  height: 80px;
  color: black;
`;

const TabContainer = styled.div`
  height: 48px;
  width: calc(100% - 48px);

  display: flex;
  flex-direction: row;
  gap: 6px;

  padding: 0 2px;

  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;

const FadeEffect = styled.div`
  width: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 2;
`;

const FadeEffectRight = styled(FadeEffect)`
  right: 50px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
`;

const FadeEffectLeft = styled(FadeEffect)`
  left: 0px;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
`;

const Tab = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  font-size: 8px;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover {
    background-color: ${colors.gray200};
  }
`;

const Title = styled.div`
  font-family: 'DM Sans';
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  color: ${colors.black};
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: calc(100% - 48px);
  gap: 8px;
`;

const SliderLabel = styled.div`
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: ${colors.white};
  padding-top: 24px;

  box-sizing: border-box;
  gap: 24px;
`;

const HorizontalItem = styled(Item)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 2px;
  background-color: ${colors.gray100};

  margin-top: 8px;
`;

const SubTitle = styled.div`
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: ${colors.black};
`;

const IconButtonLine = styled.div`
  display: flex;
  flex-direction: row;

  padding: 0 8px;

  justify-content: flex-start;

  gap: 12px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;

const SmallContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  cursor: pointer;
`;

const IconSVG = styled(ReactSVG)`
  transform: rotate(90deg) scale(0.4);

  height: 20px;
  margin-left: 10px;

  cursor: pointer;
`;

const ColorContainer = styled.div`
  width: 100%;
  padding: 4px 16px 16px;

  display: flex;
  flex-direction: row;

  gap: 4px;

  box-sizing: border-box;

  & > div {
    margin-top: 12px;
  }
`;

export default TextMenuMobile;
