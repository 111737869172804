import { styled } from 'styled-components';
import { ReactSVG } from 'react-svg';
import Button, { buttonType } from '../../general/Button';
import colors from '../../../constants/colors';
import { PhotoFolder } from '../../../types/models/Photos';
import styleConstants from '../../../constants/styleConstants';
import { createPortal } from 'react-dom';

interface ModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleValidate: (id: string) => void;
  folder: PhotoFolder | null;
}

const DeleteFolderModal: React.FC<ModalProps> = ({
  visible,
  setVisible,
  handleValidate,
  folder,
}) => {
  return createPortal(
    <Container $show={visible}>
      <FormTopContainer>
        <ReactSVG src="/svg/delete_folder_icon.svg" />
        <CloseIcon src="/svg/cross.svg" onClick={() => setVisible(false)} />
      </FormTopContainer>
      <FormContainer>
        <Title>Supprimer ce dossier ?</Title>
        <Text>
          Êtes-vous sûr de vouloir supprimer ce dossier ? Cette action est
          définitive
        </Text>
        <ButtonContainer>
          <Button $type={buttonType.white} onClick={() => setVisible(false)}>
            Annuler
          </Button>
          <Button
            $type={buttonType.red}
            onClick={() => handleValidate(folder?.id ?? '')}
          >
            Supprimer
          </Button>
        </ButtonContainer>
      </FormContainer>
    </Container>,
    document.body,
  );
};

const Container = styled.div<{ $show: boolean }>`
  width: 100%;
  height: 100%;

  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  background-color: rgba(52, 64, 84, 0.7);
`;

const FormTopContainer = styled.div`
  width: 400px;
  height: 84px;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 0 0 18px;

  box-sizing: border-box;

  position: relative;

  background-color: ${colors.white};
  color: ${colors.black};

  border-radius: 8px 8px 0 0;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const FormContainer = styled.div`
  width: 400px;

  display: flex;
  flex-direction: column;

  background-color: ${colors.white};
  color: ${colors.black};

  box-sizing: border-box;
  border-radius: 0 0 8px 8px;
  padding: 24px;

  @media (max-width: ${styleConstants.breakpointLg}) {
    width: 320px;
  }
`;

const Title = styled.div`
  font-family: 'Playfair Display';
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
`;

const CloseIcon = styled(ReactSVG)`
  position: absolute;

  top: 24px;
  right: 24px;

  cursor: pointer;

  @media (max-width: ${styleConstants.breakpointLg}) {
    top: 12px;
    right: 12px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-around;
`;

const Text = styled.div`
  display: flex;
  gap: 8px;

  margin: 20px 0;

  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`;

export default DeleteFolderModal;
