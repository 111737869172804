import {
  CloseButton,
  Container,
  Item,
  Line,
  SubTitle,
  Title,
  TitleContainer,
  TitleItem,
} from './MenuStyles';
import {
  changeAlbum,
  changeCalendar,
  changeCard,
  changeOther,
  closeMenu,
  modifyElement,
  openMenu,
  RootState,
  setFocus,
} from '../../constants/initialStore';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Element, ElementType } from '../../types/models/Elements';
import { useDrag } from 'react-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useEffect } from 'react';
import { StrictModeDroppable } from '../general/StrictModeDroppable';
import Button, { buttonType } from '../general/Button';
import { Calendar } from '../../types/models/Calendar';
import { Album } from '../../types/models/Album';
import useCreationType from '../../hook/useCreationType';
import CloseMenuButton from '../layout/CloseMenuButton';

const SettingsMenu: React.FC = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['common']);

  const lockUnlockElement = async (item: Element) => {
    if (item) {
      dispatch(
        modifyElement({
          id: item.id,
          element: {
            ...item,
            editable: !item.editable,
          },
        }),
      );
    }
  };

  const lockAllElements = async () => {
    if (orderElements) {
      orderElements.forEach((item: Element) => {
        dispatch(
          modifyElement({
            id: item.id,
            element: {
              ...item,
              editable: false,
            },
          }),
        );
      });
    }
  };

  const unlockAllElements = async () => {
    if (orderElements) {
      orderElements.forEach((item: Element) => {
        dispatch(
          modifyElement({
            id: item.id,
            element: {
              ...item,
              editable: true,
            },
          }),
        );
      });
    }
  };

  const seeItem = async (item: Element) => {
    dispatch(setFocus(item.id));
    switch (item.type) {
      case ElementType.TEXT:
        dispatch(openMenu(2)); // 2 = Menu de texte
        break;
      case ElementType.PHOTO:
        dispatch(openMenu(1)); // 1 = Menu de photo
        break;
      case ElementType.MOTIF:
        dispatch(openMenu(5));
        break;
    }
  };

  const typeCreation = useCreationType();
  const focus = useSelector((state: RootState) => state.focus.value);

  const handleLockBackground = () => {
    if (typeCreation === 'carte') {
      dispatch(
        changeCard({
          ...card,
          facesSizes: card.facesSizes.map((face, index) => {
            if (index === currentFace) {
              return { ...face, locked: !face.locked };
            } else {
              return face;
            }
          }),
        }),
      );
    } else if (typeCreation === 'calendrier') {
      dispatch(
        changeCalendar(({ calendarMonths, ...prev }: any) => ({
          ...prev,
          calendarMonths: (calendarMonths as Calendar['calendarMonths']).map(
            (month) =>
              month.id === focus
                ? {
                    ...month,
                    locked: !calendarMonths.locked,
                  }
                : month,
          ),
        })),
      );
    } else if (typeCreation === 'album') {
      dispatch(
        changeAlbum(
          ({ albumPages, albumCover, albumBackCover, ...prev }: any) => ({
            ...prev,
            albumCover:
              albumCover.id === focus
                ? { ...albumCover, locked: !albumCover.locked }
                : albumCover,
            albumBackCover:
              albumBackCover.id === focus
                ? { ...albumBackCover, locked: !albumBackCover.locked }
                : albumBackCover,
            albumPages: (albumPages as Album['albumPages']).map((page) =>
              page.id === focus
                ? { ...page, locked: !albumPages.locked }
                : page,
            ),
          }),
        ),
      );
    } else if (typeCreation === 'autre') {
      dispatch(
        changeOther(({ ...prev }: any) => ({
          ...prev,
          locked: !prev.locked,
        })),
      );
    }
  };

  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );
  const currentFace = useSelector((state: RootState) => state.face.value);
  const cardElements = useSelector(
    (state: RootState) => state.creation.present.elements.value,
  );

  const [orderElements, setOrderElements] = React.useState(cardElements);

  const sortElements = (elements: any) => {
    return elements.toSorted((a: any, b: any) => {
      return b.zIndex - a.zIndex;
    });
  };

  useEffect(() => {
    const elements = sortElements(cardElements);
    switch (typeCreation) {
      case 'carte':
        setOrderElements(
          elements.filter((elt: { face: number }) => elt.face === currentFace),
        );
        break;
      case 'album':
        setOrderElements(
          elements.filter((elt: { pageID: string }) => elt.pageID === focus),
        );
        break;
      case 'autre':
        setOrderElements(elements);
        break;
    }
  }, [cardElements, currentFace, focus]);

  const dragEnded = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(orderElements);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    items.forEach((item, index) => {
      if (item.id === reorderedItem.id) {
        dispatch(
          modifyElement({
            id: reorderedItem.id,
            element: {
              ...reorderedItem,
              zIndex: orderElements.length - result.destination.index,
            },
          }),
        );
      } else {
        dispatch(
          modifyElement({
            id: item.id,
            element: {
              ...item,
              zIndex: orderElements.length - index,
            },
          }),
        );
      }
    });
    setOrderElements(items);
  };

  return (
    <Container>
      <CloseMenuButton />
      <Item style={{ color: 'black' }}>
        <SubTitle>Liste des éléments</SubTitle>
        <DragDropContext onDragEnd={dragEnded}>
          <StrictModeDroppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  //backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'lightgrey',
                  gap: '3px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {orderElements.map((item, index) => {
                  return (
                    <Draggable
                      draggableId={item.id as string}
                      index={index}
                      key={item.id as string}
                    >
                      {(provided, _snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: '1px solid lightgrey',
                            padding: '8px',
                            backgroundColor: 'white',
                            ...provided.draggableProps.style,
                          }}
                        >
                          <p>
                            {index} {item.type}
                          </p>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            {item.type === ElementType.TEXT && (
                              <p
                                style={{
                                  maxWidth: '150px',
                                  maxHeight: '50px',
                                  overflow: 'hidden',
                                }}
                              >
                                {item.content?.replace(/<[^>]*>/g, '')}
                              </p>
                            )}
                            {item.type === ElementType.PHOTO && (
                              <img
                                src={item.content}
                                alt={item.content}
                                style={{ width: '50px', height: '50px' }}
                              />
                            )}
                            {item.type === ElementType.MOTIF && (
                              <img
                                src={item.content}
                                alt={item.content}
                                style={{ width: '50px', height: '50px' }}
                              />
                            )}
                            <ReactSVG
                              src="/svg/target.svg"
                              width={'24px'}
                              height={'24px'}
                              wrapper="svg"
                              style={{ cursor: 'pointer', minWidth: '24px' }}
                              onClick={() => seeItem(item)}
                              beforeInjection={(svg) => {
                                svg.setAttribute(
                                  'style',
                                  'width: 24px; height: 24px;',
                                );
                              }}
                            />
                            {!item.editable && (
                              <ReactSVG
                                src="/svg/lock_security_icon.svg"
                                width={'24px'}
                                height={'24px'}
                                wrapper="svg"
                                style={{ cursor: 'pointer', minWidth: '24px' }}
                                onClick={() => lockUnlockElement(item)}
                              />
                            )}
                            {item.editable && (
                              <ReactSVG
                                src="/svg/unlock_security_icon.svg"
                                width={'24px'}
                                height={'24px'}
                                wrapper="svg"
                                style={{ cursor: 'pointer', minWidth: '24px' }}
                                onClick={() => lockUnlockElement(item)}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </Item>
      <Item style={{ color: 'black' }}>
        <Button $type={buttonType.black} onClick={lockAllElements}>
          {t('general.lockAllElements')}
        </Button>
        <Button $type={buttonType.black} onClick={unlockAllElements}>
          {t('general.unlockAllElements')}
        </Button>
        <Button $type={buttonType.white} onClick={handleLockBackground}>
          {!card.facesSizes[currentFace].locked
            ? 'Verrouiller '
            : 'Déverrouiller '}
          le fond
        </Button>
      </Item>
    </Container>
  );
};

export default SettingsMenu;
