import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState, changeFace } from '../../constants/initialStore';
import { ReactSVG } from 'react-svg';
import colors from '../../constants/colors';
import Hotjar from '@hotjar/browser';

const FacesDisplayer: React.FC = () => {
  const card = useSelector(
    (state: RootState) => state.creation.present.card.value,
  );
  const currentFace = useSelector((state: RootState) => state.face.value);

  const dispatch = useDispatch();

  const onClickNext = () => {
    Hotjar.event(
      'Changement de face : ' + currentFace + ' --> ' + (currentFace + 1),
    );
    dispatch(changeFace((currentFace + 1) % card.faces));
  };

  const onClickPrevious = () => {
    Hotjar.event(
      'Changement de face : ' + currentFace + ' --> ' + (currentFace - 1),
    );
    dispatch(
      changeFace(
        currentFace - 1 < 0 ? currentFace + card.faces - 1 : currentFace - 1,
      ),
    );
  };

  const createOnFaceClick = (id: number) => () => {
    Hotjar.event('Changement de face : --> ' + id);
    dispatch(changeFace(id));
  };
  return (
    <MainContainer>
      <ChangeCardArrow src="/svg/left_chevron.svg" onClick={onClickPrevious} />
      {Array.from(Array(card.faces).keys()).map((value, id) => {
        return (
          <CardIndex
            key={id}
            $active={currentFace === id}
            onClick={createOnFaceClick(id)}
          >
            {id + 1}
          </CardIndex>
        );
      })}
      <ChangeCardArrow src="/svg/right_chevron.svg" onClick={onClickNext} />
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  gap: 24px;
  z-index: 100;
`;

const ChangeCardArrow = styled(ReactSVG)`
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const CardIndex = styled.button<{ $active: boolean }>`
  all: unset;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'DM Sans';
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: ${colors.black};

  box-sizing: border-box;
  border: ${(props) => (props.$active ? 'solid 1px ' + colors.black : 'none')};
  cursor: pointer;
  border-radius: 3px;

  background-color: ${colors.gray200};
`;

export default FacesDisplayer;
