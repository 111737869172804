import { styled } from 'styled-components';
import { useEffect } from 'react';
import { LoginState, useAuth } from '../../hook/useAuth';
import TopBar from '../../components/layout/TopBar';
import SideBar from '../../components/layout/sidebar/SideBar';
import BottomBar from '../../components/layout/BottomBar';
import TabMenu from '../../components/layout/sidebar/TabMenu';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ErrorBoundary from '../../components/layout/ErrorBoundary';
import useImportTextFonts from '../../hook/useImportTextFonts';
import useActionsShortcut from '../../hook/useActionsShortcut';
import { Outlet } from 'react-router-dom';
import { useFetchOrCreateCard } from '../../hook/useFetchOrCreateCard';
import styleConstants from '../../constants/styleConstants';
import useLittleScreen from '../../hook/useLittleScreen';
import CookieHandler from './CookieHandler';
import { useSelector } from 'react-redux';
import { RootState } from '../../constants/initialStore';
import Loader from '../../components/general/Loader';
import BigLoader from '../../components/general/BigLoader';
import TopBarMobile from '../../components/layout/TopBarMobile';
import { useFetchOrCreateCalendar } from '../../hook/useFetchOrCreateCalendar';
import { useFetchOrCreateAlbum } from '../../hook/useFetchOrCreateAlbum';
import { useFetchOrCreateOther } from '../../hook/useFecthOrCreateOther';

const HomePage: React.FC = () => {
  const { asklogin, userInfo } = useAuth();

  const loading = useSelector((state: RootState) => state.loader.value);

  useImportTextFonts();
  useFetchOrCreateCard();
  useFetchOrCreateCalendar({
    setIsCalendarStart: () => null,
  });
  useFetchOrCreateAlbum();
  useFetchOrCreateOther();
  useActionsShortcut();

  useEffect(() => {
    const openLoginPage = async () => {
      if (userInfo && userInfo.state !== LoginState.LOGGED_IN) {
        await asklogin(false);
      }
    };
    // openLoginPage();
  }, [userInfo]);

  const littleScreen = useLittleScreen();

  return (
    <Container>
      <CookieHandler />
      <ErrorBoundary>
        {littleScreen ? <TopBarMobile /> : <TopBar />}
        <DndProvider backend={HTML5Backend}>
          <MainContainerRow>
            {littleScreen ? (
              <SideBar>
                <TabMenu />
              </SideBar>
            ) : (
              <SideBar />
            )}
            <MainContainerCol>
              <MainContainerCenter>
                {!littleScreen && <TabMenu />}
                {loading ? <BigLoader /> : <Outlet />}
              </MainContainerCenter>
              <BottomBar />
            </MainContainerCol>
          </MainContainerRow>
        </DndProvider>
      </ErrorBoundary>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  // height: -webkit-fill-available;
`;

const MainContainerRow = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  position: relative;
  max-width: 100%;
  max-height: calc(100% - 72px); /* 72px is TopBar height */

  @media (max-width: ${styleConstants.breakpointLg}) {
    max-height: calc(100% - 64px - 48px); /* 64px is TopBar height */
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }
`;

const MainContainerCol = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-width: 0;

  height: 100%;

  @media (max-width: ${styleConstants.breakpointLg}) {
    flex-grow: 1;
    position: relative;
  }
`;

const MainContainerCenter = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  flex-direction: row;
  max-width: 100%;
  min-height: 0;
  height: calc(100% - 58px); /* 58px is BottomBar height */
  @media (max-width: ${styleConstants.breakpointLg}) {
    flex-grow: 1;
  }
`;

export default HomePage;
