import axios from 'axios';
import { Calendar } from '../types/models/Calendar';

export const getOngoingCalendar = async (id?: string) => {
  return axios
    .get<Calendar>(`/calendars/${id ? id : ''}`)
    .then((res) => res.data);
};

export const createCalendar = async (payload: Calendar, locale: string) => {
  return axios
    .post<Calendar>(`/calendars`, { calendar: payload, locale })
    .then((res) => res.data);
};

export const saveCalendar = async (payload: Calendar) => {
  return axios
    .put<Calendar>(`/calendars/${payload.id}`, payload)
    .then((res) => res.data);
};

export const saveNameCalendar = async (id: string, newName: string) => {
  return axios.post<any>(`/calendars/name/${id}`, { name: newName });
};

export const endPersonnalisationCalendar = async (
  calendarID: string,
  locale: string,
  fromCart: boolean,
) => {
  return axios.post<string>(`/calendars/end/${calendarID}`, {
    locale,
    fromCart,
  });
};
