import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';

interface CleanOverlayProps {
  $radius: number[];
}

const CleanOverlay: React.FC<CleanOverlayProps> = ({ $radius }) => {
  return <CleanOverlayContainer $radius={$radius} />;
};

const CleanOverlayContainer = styled.div<{ $radius: number[] }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  border: dotted 3px ${colors.error600};
  ${(props) =>
    props.$radius ? 'border-radius: ' + props.$radius.join('% ') + '%;' : ''}

  z-index: 500;

  overflow: hidden;
`;

export default CleanOverlay;
